angular.module('contactsList', [])
.component('contactsList', {
	templateUrl: assetsURL+'components/shared/contacts/contacts-list/contacts-list.template.html',
	controller: ['$scope', 'models',  function($scope ,models ) {
		$scope.type='contacts';
		$scope.path='contatti';
		$scope.limit=20;
		$scope.showphonetag=[];
		$scope.showemailtag=[];
		$scope.decodeObject = function(scope, data) {
			scope.object.fullname = (data.iscompany ? data.companyname : (data.firstname+' '+data.lastname));
			if( !scope.object.fullname.length && !data.iscompany ) {
				scope.object.fullname = data.companyname;
			}
		};
		
		$scope.encodeEditObject = function(obj) {
		};
		$scope.decodeEditObject = function(scope, data) {
			if( !scope.object.phones ) {scope.object.phones=[];}
			if( !scope.object.phones.length ) { scope.object.phones.push({ tag:'',phone:'' }); }
			if( !scope.object.emails ) {scope.object.emails=[];}
			if( !scope.object.emails.length ) { scope.object.emails.push({ tag:'',email:'' }); }
			if( !scope.object.images ) {scope.object.images=[];}
			if( scope.object.birthdate ) {scope.object.birthdate = new Date(scope.object.birthdate);}
		};
		$scope.setupEditObject = function(scope) {
	  		scope.object = {iscompany: false, title: '', firstname: '', lastname: '', role:'Legale rappresentante', companyname: '', fiscalid:'', vat:'', emails: [{tag:'', email:''}], phones:[{tag:'', phone:''}], address:new models.Address()};
		};
	}
]});
