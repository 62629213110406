angular.module('worksiteEnterprisesDocumentsNotes', [])
.directive('worksiteEnterprisesDocumentNotes', ['$window','$timeout','$uibModal','models','$http', function ($window, $timeout, $uibModal, models, $http ) {
    return {
		scope: { doc : '=' },
		restrict: 'C',
		templateUrl: assetsURL+'components/app/worksites/worksite-enterprises-documents-notes/templates/worksite-enterprises-document-notes.template.html',
		link: function (scope, elem, attrs) {
		}
	}
}])
.directive('worksiteEnterprisesDocumentsNotes',['checkEnterpriseDocs', function (checkEnterpriseDocs) {
    return {
		scope: { enterprise: '=' },
		restrict: 'C',
		templateUrl: assetsURL+'components/app/worksites/worksite-enterprises-documents-notes/templates/worksite-enterprises-documents-notes.template.html',
		link: function (scope, elem, attrs) {
			const checkoverall = checkEnterpriseDocs.checkOverall(scope.enterprise);
			scope.enterprisedocs = checkoverall.enterprisedocs;
			scope.operatingdocs = checkoverall.operatingdocs;
			scope.employees = checkoverall.employees;
			scope.temporaryworks = checkoverall.temporaryworks;
			scope.machines = checkoverall.machines;
			scope.substances = checkoverall.substances;
		}
	}
}]);
		
