angular.module('worksiteReports', [])
.directive('worksiteReport',['models','$http', '$uibModal','lettertextService', function (models,$http, $uibModal, lettertextService) {
    return {
		restrict: 'C',
		templateUrl: assetsURL+'components/app/worksites/worksite-reports/templates/worksite-report.template.html?t='+RootSeed,
        scope: { report: '=', worksite:'=', reporttypes : '=', mailinglist : '=' },
		link: function (scope, elem, attrs) {
			scope.categoryreports = [];
			scope.categoryreportname = '';
			scope.addRecipients = false;
			scope.originalText = null;
			scope.enterpriseeditindex = -1;
			scope.tmpenterpriseeditcurrentwork = "";
			scope.tmpenterpriseeditplace = "";
			scope.enterprisesforprescriptions = [];
			
			scope.editSignatureToAttendant = function(attendant) {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/app/worksites/worksite-reports/templates/worksite-report-editSignatureModal.html',
					controller: ['$scope', '$uibModalInstance', 'signature',  function ( scope, $uibModalInstance, signature ) {
						scope.signature = signature;
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function (signature) {
							$uibModalInstance.close(signature);
						};												
					}],
					resolve: { 
						signature: function() { return attendant.signature; },
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
						attendant.signature = data;
					}, function errorCallback() {
					}
				);
			};

			scope.setRecipients = function() {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/app/worksites/worksite-reports/templates/worksite-report-setRecipientsModal.html',
					controller: ['$scope', '$uibModalInstance', 'mailinglist','recipients', function ( scope, $uibModalInstance, mailinglist, recipients ) {
						// Update recipients
						let tmpAvailableRecipients = JSON.parse(JSON.stringify(mailinglist));
						scope.availableRecipients = [];
						// First insert cse, csp, etc.
						tmpAvailableRecipients.forEach( recipient => {
							if( recipient.role != 'Impresa' ) {
								scope.availableRecipients.push(recipient);
							} else {
								
							}
						});
						// Next insert only main contractors
						tmpAvailableRecipients.forEach( recipient => {
							if( recipient.role == 'Impresa' && (!recipient.parentcontractor || !recipient.parentcontractor._id)) {
								scope.availableRecipients.push(recipient);
							}
						});
						// Last insert sub contractors after main contractors
						tmpAvailableRecipients.forEach(function(recipient) {
							if( recipient.role == 'Impresa' && recipient.parentcontractor && recipient.parentcontractor._id ) {
								for( var i=0;i<scope.availableRecipients.length; i++ ) {
									if( scope.availableRecipients[i].contact._id == recipient.parentcontractor._id ) {
										scope.availableRecipients.splice(i+1, 0, recipient);
										break;
									}
								}
							}
						});
						
						
						var recipientsGraph = {};
						recipients.forEach(function(recipient) {
							if( recipient.contact && recipient.contact._id ) {
								recipientsGraph[recipient.contact._id]=recipient;
							}
						});
						
						scope.availableRecipients.forEach(function(recipient) {
							if( recipient.contact && recipient.contact._id ) {
								hashedRecipient = recipientsGraph[recipient.contact._id];
								if( hashedRecipient ) {
									hashedRecipient.contact.emails.forEach(function(email) {
										for( var i=0;i<recipient.contact.emails.length; i++ ) {
											if( email.email == recipient.contact.emails[i].email ) {
												recipient.contact.emails[i].selected = email.selected;
												break;
											}
										}
									});
								}
								recipient.selected = false;
								recipient.contact.emails.forEach(function(email) {
									if( email.selected ) {recipient.selected=true;}
								});
							}
						});
						scope.selectAll = function() {
							scope.availableRecipients.forEach(function(recipient) {
								recipient.contact.emails.forEach(function(email) {
									email.selected = true;
								})
							});
						};
						scope.deselectAll = function() {
							scope.availableRecipients.forEach(function(recipient) {
								recipient.contact.emails.forEach(function(email) {
									email.selected = false;
								})
							});
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function () {
							scope.availableRecipients.forEach(function(recipient) {
								var atLeastOneSelected = false;
								recipient.contact.emails.forEach(function(email) {
									if( email.selected ) { atLeastOneSelected = true; }
								});
								recipient.selected = atLeastOneSelected;
							});
							$uibModalInstance.close(scope.availableRecipients);
						};												
					}],
					resolve: { 
						mailinglist: function() { return scope.mailinglist; },
						recipients: function() { return scope.report.recipients; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
						scope.report.recipients = data;
					}, function errorCallback() {
					}
				);
			};
			
			scope.setAttendants = function() {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/app/worksites/worksite-reports/templates/worksite-report-setAttendantsModal.html',
					controller: ['$scope', '$uibModalInstance', 'mailinglist','attendants','prevCustomAttendants',  function ( scope, $uibModalInstance, mailinglist, attendants,prevCustomAttendants) {
						// Update attendants
						scope.availableAttendants = JSON.parse(JSON.stringify(mailinglist));
						if( prevCustomAttendants ) {
							scope.availableCustomAttendants = JSON.parse(JSON.stringify(prevCustomAttendants));
						} else {
							scope.availableCustomAttendants = [];
						}
						scope.customAttendants = [];
						var attendantsGraph = {};
						var customAttendantsGraph = {};
						scope.availableAttendants.forEach(function(attendant) {
							attendant.selected = false;
							if( attendant.contact && attendant.contact._id ) {
								if( attendant.isCustom === undefined ) { attendant.isCustom = false; }
								if( attendant.person === undefined ) { attendant.person = ""; }
								attendantsGraph[attendant.contact._id+attendant.role]=attendant;
							}
						});
						scope.availableCustomAttendants.forEach(function(attendant) {
							attendant.selected = false;
							if( attendant.person ) {
								customAttendantsGraph[attendant.person+attendant.role]=attendant;
							}
						});
						
						attendants.forEach(function(attendant) {
							if( attendant.contact && attendant.contact._id ) {
								hashedAttendant = attendantsGraph[attendant.contact._id+attendant.role];
								if( hashedAttendant ) {
									hashedAttendant.selected = true;
									hashedAttendant.person = attendant.person;
									if( attendant.personrole !== undefined ) {
										hashedAttendant.personrole = attendant.personrole;
									}
									hashedAttendant.isCustom = false;
								} 
							} else {
								hashedAttendant = customAttendantsGraph[attendant.person+attendant.role];
								var idx = scope.availableCustomAttendants.indexOf(hashedAttendant);
								if( idx >= 0 ) {
									scope.availableCustomAttendants.splice(idx,1);
								}
								scope.customAttendants.push(attendant);
							}
						});

						scope.deleteCustomAttendant = function(index) {
							if( index>=0 && index < scope.customAttendants.length ) {
								scope.customAttendants.splice(index,1);
							}
						};
						scope.addCustomAttendant = function() {
							scope.customAttendants.push({person: scope.data.customPerson, role: scope.data.customRole});
							scope.data.customPerson = "";
							scope.data.customRole = "";
						};
						
						scope.selectAll = function() {
							scope.availableAttendants.forEach(function(attendant) {
								attendant.selected = true;
							});
							scope.availableCustomAttendants.forEach(function(attendant) {
								attendant.selected = true;
							});
						};
						scope.deselectAll = function() {
							scope.availableAttendants.forEach(function(attendant) {
								attendant.selected = false;
							});
							scope.availableCustomAttendants.forEach(function(attendant) {
								attendant.selected = false;
							});
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function () {
							var selectedAttendants = [];
							scope.availableAttendants.forEach(function(attendant) {
								if( attendant.selected) {
									var newAttendant = { contact: attendant.contact, role: attendant.role, person: attendant.person, isCustom: attendant.isCustom };
									if( attendant.personrole !== undefined ) {
										newAttendant.personrole = attendant.personrole;
									}
									selectedAttendants.push(newAttendant);
								}
							});
							scope.availableCustomAttendants.forEach(function(attendant) {
								if( attendant.selected) {
									var newAttendant = { contact: {}, role: attendant.role, person: attendant.person, isCustom: true };
									selectedAttendants.push(newAttendant);
								}
							});
							scope.customAttendants.forEach(function(attendant) {
								var newAttendant = { contact: {}, role: attendant.role, person: attendant.person, isCustom: true };
								selectedAttendants.push(newAttendant);
							});
							$uibModalInstance.close(selectedAttendants);
						};												
					}],
					resolve: { 
						mailinglist: function() { return scope.mailinglist; },
						attendants: function() { return scope.report.attendants; },
						prevCustomAttendants: function() { return scope.report.prevCustomAttendants; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
						scope.report.attendants = data;
					}, function errorCallback() {
					}
				);
			};
			
			scope.setPresentEmployeesAndAssets = function(type) {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/app/worksites/worksite-reports/templates/worksite-report-setPresentEmployeesAndAssetsModal.html',
					controller: ['$scope', '$uibModalInstance','type','presentemployees','presentassets', 'enterprises',  function ( scope, $uibModalInstance, type, presentemployees, presentassets, enterprises ) {
						scope.type = type;
						var presenceGraph = {};
						scope.getAuthStatus = function(docs) {
							var statuses = [0,0,0,0];
							var nowTime = (new Date()).getTime();
							docs.forEach(function(doc) {
								var basicCheckFailed = false;
								if( doc.required && !doc.files.length ) {
									basicCheckFailed = true;
								} else if( doc.files.length ) {
									if( doc.validity.type > 0 ) {
										var expirydate = doc.expirydate ? new Date(doc.expirydate) : new Date("2000-01-01");
										if( expirydate.getTime() < nowTime ) {
											basicCheckFailed = true;
										}
									} 
								}
								if( basicCheckFailed ) {
									statuses[2]=statuses[2]+1;
								} else {
									if( doc.files.length ) {
										statuses[doc.validationstatus]=statuses[doc.validationstatus]+1;
									} else {
										statuses[1]=statuses[1]+1;
									}
								}
							});
							return (statuses[0]>0 || statuses[2]>0)?false:true;
						};
						
						if( type === 'employees' ) {
							presentemployees.forEach(function(enterprise) {
								enterprise.employees.forEach( employee => {
									presenceGraph[enterprise.companyname+employee.fullname] = true;
								});
							});
						} else {
							presentassets.forEach(function(enterprise) {
								enterprise.assets.forEach( asset => {
									presenceGraph[enterprise.companyname+asset.name] = true;
								});
							});
						}
						scope.availableEnterprises = [];						
						var idx = 0;
						for( var i=0;i<enterprises.length;i++ ) {
							var enterprise = enterprises[i];
							if( enterprise.referencedenterprise && enterprise.referencedenterprise._id && enterprise.referencedenterprise.status == 2 ) {
								var newrefenterprise = { companyname: enterprise.contact.companyname };
								if( type === 'employees') {
									newrefenterprise.employees = [];
									enterprise.refemployees.forEach( employee => {
										var isAuth = scope.getAuthStatus(employee.documents);
										if( isAuth ) {
											isAuth = scope.getAuthStatus(employee.certificates);
											if( isAuth ) {
												var newEmployee = { fullname: employee.fullname, selected: false, index : idx };
												idx = idx +1;
												if( presenceGraph[newrefenterprise.companyname+employee.fullname] )  {
													newEmployee.selected = true;
												}
												newrefenterprise.employees.push(newEmployee);
											}
										}
									});
									if( newrefenterprise.employees.length ) {
										scope.availableEnterprises.push(newrefenterprise);
									}
								} else {
									newrefenterprise.assets = [];
									var refAssets = enterprise.reftemporaryworks.concat(enterprise.refmachines).concat(enterprise.refsubstances);
									refAssets.forEach( asset => {
										var isAuth = scope.getAuthStatus(asset.documents);
										if( isAuth ) {
											var nameArray = [];
											if( asset.category && asset.category.length ) { nameArray.push(asset.category);}
											if( asset.manifacturer && asset.manifacturer.length ) { nameArray.push(asset.manifacturer);}
											if( asset.model && asset.model.length ) { nameArray.push(asset.model);}
											if( asset.serialid && asset.serialid.length ) { nameArray.push(asset.serialid);}
											var name = nameArray.join(" - ");
											if( name.length ) {
												var newAsset = { name: name, selected: false, index : idx };
												idx = idx +1;
												if( presenceGraph[newrefenterprise.companyname+name] )  {
													newAsset.selected = true;
												}
												newrefenterprise.assets.push(newAsset);
											}
										}
									});
									if( newrefenterprise.assets.length ) {
										scope.availableEnterprises.push(newrefenterprise);
									}
								}
							}
						}

						scope.selectAll = function() {
							if( type === 'employees' ) {
								scope.availableEnterprises.forEach(function(enterprise) {
									enterprise.employees.forEach(function(employee) {
										employee.selected = true;
									});
								});
							} else {
								scope.availableEnterprises.forEach(function(enterprise) {
									enterprise.assets.forEach(function(asset) {
										asset.selected = true;
									});
								});
							}
						};
						scope.deselectAll = function() {
							if( type === 'employees' ) {
								scope.availableEnterprises.forEach(function(enterprise) {
									enterprise.employees.forEach(function(employee) {
										employee.selected = false;
									});
								});
							} else {
								scope.availableEnterprises.forEach(function(enterprise) {
									enterprise.assets.forEach(function(asset) {
										asset.selected = false;
									});
								});
							}
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function () {
							if( type === 'employees' ) {
								var selectedPresentEmployees = [];
								scope.availableEnterprises.forEach(function(enterprise) {
									var newEnterprise = { companyname: enterprise.companyname, employees:[] };
									enterprise.employees.forEach(function(employee) {
										if( employee.selected) {
											var newEmployee = {fullname: employee.fullname};
											newEnterprise.employees.push(newEmployee);
										}
									});
									if( newEnterprise.employees.length ) {
										selectedPresentEmployees.push(newEnterprise);	
									}
								});
								
								$uibModalInstance.close(selectedPresentEmployees);
							} else {
								var selectedPresentAssets = [];
								scope.availableEnterprises.forEach(function(enterprise) {
									var newEnterprise = { companyname: enterprise.companyname, assets:[] };
									enterprise.assets.forEach(function(asset) {
										if( asset.selected) {
											var newAsset = {name: asset.name};
											newEnterprise.assets.push(newAsset);
										}
									});
									if( newEnterprise.assets.length ) {
										selectedPresentAssets.push(newEnterprise);	
									}
								});
								$uibModalInstance.close(selectedPresentAssets);
							}
						};												
					}],
					resolve: { 
						type: function() { return type; },
						presentassets: function() { return scope.report.presentassets; },
						presentemployees: function() { return scope.report.presentemployees; },
						enterprises: function() { return scope.worksite.enterprises; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
						if( type === 'employees' ) {
							scope.report.presentemployees = data;
						} else {
							scope.report.presentassets = data;
						}
					}, function errorCallback() {
					}
				);
			};

			scope.addEnterprise = function() {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/app/worksites/worksite-reports/templates/worksite-report-addEnterpriseModal.html',
					controller: ['$scope', '$uibModalInstance', 'enterprises','refenterprises',  function ( scope, $uibModalInstance, enterprises, refenterprises ) {
						scope.availableEnterprises = [];
						var availableEnterprises = [];

						scope.selectAll = function() {
							scope.availableEnterprises.forEach(function(enterprise) {
								enterprise.selected = true;
							});
						};
						scope.deselectAll = function() {
							scope.availableEnterprises.forEach(function(enterprise) {
								enterprise.selected = false;
							});
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function () {
							scope.availableEnterprises.forEach(function(enterprise) {
								if( enterprise.selected ) {
									refenterprises.push(enterprise);
								}
							});
							$uibModalInstance.close(null);
						};
						
						var refEnterprisesGraphs = {};
						refenterprises.forEach( ent => {
							refEnterprisesGraphs[ent.refId]=ent;
						});
						for( var i=0;i<enterprises.length;i++ ) {
							var enterprise = enterprises[i];
							if( enterprise.referencedenterprise && enterprise.referencedenterprise._id && enterprise.referencedenterprise.status == 2 && refEnterprisesGraphs[enterprise.referencedenterprise._id] === undefined ) {
								var newrefenterprise = { companyname: enterprise.contact.companyname, _id: enterprise.contact._id, refId: enterprise.referencedenterprise._id, work:enterprise.work, currentwork:"", place:"", selected: false, parentcontractor: enterprise.parentcontractor };
								availableEnterprises.push(newrefenterprise);
							}
						}
						// Sort for subcontractors
						var tmpEnterprises = [];
						availableEnterprises.forEach(function(ent) {
							if( !ent.parentcontractor || !ent.parentcontractor._id ) {
								tmpEnterprises.push(ent);
							}
						});
						availableEnterprises.forEach(function(ent) {
							if( ent.parentcontractor && ent.parentcontractor._id ) {
								for( var i=0;i<tmpEnterprises.length; i++ ) {
									if( tmpEnterprises[i]._id == ent.parentcontractor._id ) {
										tmpEnterprises.splice(i+1, 0, ent);
										break;
									}
								}
							}
						});
						scope.availableEnterprises = tmpEnterprises;
						
					}],
					resolve: { 
						enterprises: function() { return scope.worksite.enterprises; },
						refenterprises: function() { return scope.report.enterprises; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
					}, function errorCallback() {
					}
				);
			};
			scope.removeEnterprise = function(index) {
				if( index < 0 || index >= scope.report.enterprises.length ) { return; }
				scope.report.enterprises.splice(index, 1);
			};
			scope.editEnterprise = function(index) {
				if( index < 0 || index >= scope.report.enterprises.length ) { return; }
				scope.tmpenterpriseeditcurrentwork = scope.report.enterprises[index].currentwork;
				scope.tmpenterpriseeditplace = scope.report.enterprises[index].place;
				scope.enterpriseeditindex = index;
			};
			scope.cancelEnterpriseEdit = function() {
				scope.report.enterprises[scope.enterpriseeditindex].currentwork=scope.tmpenterpriseeditcurrentwork;
				scope.report.enterprises[scope.enterpriseeditindex].place=scope.tmpenterpriseeditplace;
				scope.enterpriseeditindex = -1;
			};
			scope.closeEnterpriseEdit = function() {
				scope.enterpriseeditindex = -1;
			};
			scope.updateReport = function() {
				if( scope.report.text && scope.report.text.length>1 ) {
					scope.report.text = lettertextService.substituteClasses(scope.report.text, {report: scope.report, doc: scope.worksite});
				} else if( scope.originalText ) {
					scope.report.text = lettertextService.substituteTags(scope.originalText, {report: scope.report, doc: scope.worksite});
				}
			};
			scope.updateReportCategory = function( ) {
				scope.report.text = "";
				scope.report.type = null;
				if( scope.reporttypes === undefined ) { return; }
				scope.reporttypes.forEach(function(reporttype) {
					if( reporttype.category == scope.report.category ) {
						scope.categoryreports = reporttype.reports;
					}
				});
			};
			scope.updateReportType= function( ) {
				scope.report.text = null;
				scope.originalText = null;
			};

			// Next two trigger if category is changed
			scope.$watch('report.category', function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				if( scope.reporttypes === undefined ) { return; }
				scope.reporttypes.forEach(function(reporttype) {
					if( reporttype.category === scope.report.category ) {
						scope.categoryreports = reporttype.reports;
					}
				});
			});
			scope.$watch('reporttypes', function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				if( scope.report === undefined ) { return; }
				scope.reporttypes.forEach(function(reporttype) {
					if( reporttype.category === scope.report.category ) {
						scope.categoryreports = reporttype.reports;
					}
				});
			}, true);
			
			// Next triggers if type is changed
			scope.$watchGroup(['report.type', 'categoryreports'], function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				//if( angular.equals(newVal, oldVal ) ) { return; }
				scope.categoryreports.forEach(function(c) {
					if( c.name === scope.report.type) {
						scope.addRecipients = c.addRecipients;
					}
				});
				if( scope.report.type == "" ) {
					scope.report.text = "";
					scope.originalText = null;
					return;
				}
				if( scope.report.type && scope.report.type.length ) {
					$http.get(appURL+'/crud/reporttexts/type/'+encodeURIComponent(scope.report.type), {}).then( 
						function successCallback(response) {
							var data=response.data;
							if( data && data.text) {
								scope.originalText = data.text;
								scope.report.title = data.description;
								scope.report.subcategory = data.subcategory;
								scope.updateReport();
							}
						}, function errorCallback(response) {
						}
					);
				}
			});
			
			// Next trigger if data are changed

			scope.$watch('report.recipients', function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				if( angular.equals(newVal, oldVal ) ) { return; }
				scope.updateReport();
			}, true);
			scope.$watchGroup(['report.issuedate'], function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				if( angular.equals(newVal, oldVal ) ) { return; }
				scope.updateReport();
			});

			scope.showRecipients = function() {
				return scope.report.category && scope.report.type && scope.addRecipients;
			};
			
			
			// Setup enterprises list for prescriptions
			var flattedEnterprises = [];
			var hashedEnterprises = {};
			for( var i=0;i<scope.worksite.enterprises.length;i++ ) {
				var enterprise = scope.worksite.enterprises[i];
				if( enterprise.referencedenterprise && enterprise.referencedenterprise._id && enterprise.referencedenterprise.status == 2 ) {
					var newrefenterprise = { companyname: enterprise.contact.companyname, _id: enterprise.contact._id, parentcontractor: enterprise.parentcontractor };
					hashedEnterprises[enterprise.contact._id] = newrefenterprise;
					flattedEnterprises.push(newrefenterprise);
				}
			}
			flattedEnterprises.forEach(function(ent) {
				if( !ent.parentcontractor || !ent.parentcontractor._id ) {
					scope.enterprisesforprescriptions.push(ent.companyname);
				}
			});
			flattedEnterprises.forEach(function(ent) {
				var currentEnt = ent;
				var description = "";
				while( currentEnt.parentcontractor && currentEnt.parentcontractor._id ) {
					if( !hashedEnterprises[currentEnt.parentcontractor._id] ) {
						break;
					}
					description = "("+currentEnt.companyname+") "+description;
					currentEnt = hashedEnterprises[currentEnt.parentcontractor._id];
				}
				if( description.length ) {
					description = currentEnt.companyname + " " + description;
					for( var i=0;i<scope.enterprisesforprescriptions.length; i++ ) {
						if( scope.enterprisesforprescriptions[i] == currentEnt.companyname ) {
							scope.enterprisesforprescriptions.splice(i+1, 0, description);
							break;
						}
					}
				}
			});
			if( scope.report.presentemployees === undefined ) {
				scope.report.presentemployees = [];
			}
			if( scope.report.presentassets === undefined ) {
				scope.report.presentassets = [];
			}
		}
	};
}]).directive('worksiteReports', ['$timeout', '$http','$uibModal', 'warningService','models', function ($timeout, $http, $uibModal, warningService, models) {
    return {
        scope: { reports: '=', docs: '=', type:'@', worksite : '=', mailinglist : '=', saveaction: '&'},
		restrict: 'C',
		templateUrl: assetsURL+'components/app/worksites/worksite-reports/templates/worksite-reports.template.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.reporttypes = [];
			scope.addReport= function() {
				$http.post(appURL+'/services/worksites-reports/new', {'worksiteId':scope.worksite._id, 'type':scope.type }).then(
					function successCallback(response){							
						var newObject = new models.Report();
						newObject._id = response.data._id;
						newObject.prescriptions_prev = response.data.prescriptions;
						newObject.prescriptions_prev.forEach(function(prescription) {
							prescription.prescriptiondeadline = new Date(prescription.prescriptiondeadline);
						});
						if( response.data.layouts && response.data.layouts.length ) {
							newObject.addlayoutsection = true;
							newObject.layouts = response.data.layouts;
						}
						newObject.prevCustomAttendants = response.data.prevCustomAttendants;
						scope.editReport(-1, newObject);
					}, function errorCallback(response){
					}
				);
			};

			scope.deleteReport = function(index) {
				if( index<0 || index >= scope.reports.length ) { return; }
				scope.reports.splice(index, 1);
			};
			scope.duplicateReport = function(index) {
				if( index<0 || index >= scope.reports.length ) { return; }
				var newReport = angular.copy(scope.reports[index]);
				newReport.reportindex = newReport.reportindex+1;
				scope.reports.push(newReport);
			};
			scope.editReport = function(index, newObject) {
				var report = newObject ? newObject : scope.reports[index];
				var isNew = newObject ? true : false;
				var modalInstance = $uibModal.open({
					size: 'lg',
					templateUrl: assetsURL+'components/app/worksites/worksite-reports/templates/worksite-report-editReportModal.html?t='+RootSeed,
					controller: ['$http', '$scope', '$uibModalInstance', 'reports', 'docs', 'index', 'report','reporttypes','worksite','mailinglist', 'type', 'isNew','saveaction', function ($http, scope, $uibModalInstance, reports, docs, index, report, reporttypes, worksite, mailinglist, type, isNew, saveaction ) {
						scope.isSaving = false;
						scope.report = JSON.parse(JSON.stringify(report));
						scope.report.issuedate = new Date(scope.report.issuedate);
						scope.report.starttime = new Date(scope.report.starttime);
						scope.report.endtime = new Date(scope.report.endtime);
						if( isNew ) {
							scope.report.reportindex = index+1;
						}
						var prescriptions = [scope.report.prescriptions_prev, scope.report.prescriptions_A, scope.report.prescriptions_B, scope.report.prescriptions_C];
						for( var j=0; j<prescriptions.length; j++ ) {
							prescriptions[j].forEach(function(prescription) {
								prescription.prescriptiondeadline = new Date(prescription.prescriptiondeadline);
							});
						}
						
						scope.reporttypes = reporttypes;
						scope.worksite = worksite;
						scope.mailinglist = mailinglist;
						scope.isNew = isNew;
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function (createdoc) {
							scope.isSaving = true;

							const filename = (worksite.filename ? worksite.filename : worksite.job+"_VR"+("000" + (index+1)).substr(-3)+"_"+report.title);
							const destObjKeyPath = type.substr(6); // Remove custom from field name
							if( isNew ) {
								reports.push(scope.report);
							} else {
								reports[index] = scope.report;
							}
							var successCb = function() {
								if( createdoc == false ) {
									scope.isSaving = false;
									$uibModalInstance.close(null);
									return;
								}
								$http.post(appURL+'/services/worksites/print', { 'worksiteId':scope.worksite._id, 'objType':'report', destObjKeyPath:destObjKeyPath, 'srcObjKeyPath': type, 'srcObjId': scope.report._id, 'filename': filename }).then(
									function successCallback(response){																
										const data = response.data;
										if( data.fileId == null ) {
											scope.isSaving = false;
											$uibModalInstance.dismiss('cancel');
											alert("Attenzione! Non e' stato possibile generare il PDF. Riprova")
											return;
										}
										var newDoc = {};
										newDoc.creationdate = new Date();
										newDoc.fileId = data.fileId;
										newDoc.type = 'application/pdf';
										newDoc.category = type;
										newDoc.description = filename;
										newDoc.issuedate = new Date();
										newDoc.revision = (scope.report.file.fileId !== undefined && scope.report.file.revision !== undefined) ? scope.report.file.revision+1:0;
										newDoc.notes = "";
										newDoc.filename = filename;
										docs.docs.push(newDoc);
										
										scope.report.file = { fileId:data.fileId, status:'success', error:null, revision:newDoc.revision };
										var successSaveCallback = function() {
											scope.isSaving = false;
											$uibModalInstance.close(null);
										};
										
										var errorSaveCallback = function() {
											scope.isSaving = false;
											$uibModalInstance.dismiss('cancel');
											alert("Attenzione! Non e' stato possibile salvare le modifiche. Riprova")
										};
										saveaction()(successSaveCallback, errorSaveCallback);
									}, function errorCallback(response){
										scope.isSaving = false;
										$uibModalInstance.dismiss('cancel');
										alert("Attenzione! Non e' stato possibile generare il PDF. Riprova")
									}
								);
							};
							var errorCb = function() {
								scope.isSaving = false;
								$uibModalInstance.dismiss('cancel');
								alert("Attenzione! Non e' stato possibile salvare le modifiche. Riprova")
							};
							saveaction()(successCb, errorCb);
						};
					}], resolve: { 
						reports:  function() { return scope.reports; },
						docs:  function() { return scope.docs; },
						index:  function() { return index; },
						report: function() { return report; },
						reporttypes: function() { return scope.reporttypes; },
						worksite: function() { return scope.worksite; },
						mailinglist: function() { return scope.mailinglist; },
						type: function() { return scope.type; },
						isNew: function() { return isNew; },
						saveaction: function () { return scope.saveaction; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
					}, function errorCallback() {
					}
				);
			};
			
			scope.getReports = function() {
				$http.get(appURL+'/crud/reporttexts/?nolimit=true', {}).then( 
					function successCallback(response) {
						var data=response.data;
						if( data && data.length) {
							var reportsByCategory = {};
							var category;
							data.forEach(function(c) {
								if( c.category && c.category.length ) {
									if( reportsByCategory[c.category] === undefined ) { 
										reportsByCategory[c.category] = []; 
									}
									category = c.category;
								} else {
									if( reportsByCategory['Categoria non definita']=== undefined ) {
										reportsByCategory['Categoria non definita'] = [];
									}
									category = 'Categoria non definita';
								}
								reportsByCategory[category].push(c);
							});
							var sortedKeys = Object.keys(reportsByCategory).sort();
							var reporttypes = [];
							sortedKeys.forEach(function(key) {
								reporttypes.push({category:key, reports:reportsByCategory[key]});
							});
							scope.reporttypes = reporttypes;
						}
					}, function errorCallback(response) {
						alert('Si è verificato un errore. Si prega di riprovare');
					}
				);
			}
			scope.getReports();
		}
	}
}]).directive('worksiteReportSignature',['$uibModal','$window', function ($uibModal, $window) {
    return {
		restrict: 'C',
		templateUrl: assetsURL+'components/app/worksites/worksite-reports/templates/worksite-report-signature.template.html?t='+RootSeed,
		scope: { signature : '=', cancelaction:'&', okaction:'&', },
		link: function (scope, elem, attrs) {
			var w = angular.element($window);
	        w.bind('resize', function () {
	            scope.$apply();
	        });
			scope.getWindowWidth = function () { return w.width(); };
			scope.$watch(scope.getWindowWidth, function (newWidth, oldWidth) {
				if( scope.canvas ) {
					scope.setupCanvas();
				}
			});
			
			scope.canvas = elem.find('.drawcanvas')[0];
			scope.canSave = false;
			scope.isDrawing = false;
			scope.saveDisabled = function() {
				return !scope.canSave;
			}
			scope.startDrawing = function(e) {
				var canvasX = e.pageX - $(scope.canvas).offset().left,
	  				canvasY = e.pageY - $(scope.canvas).offset().top;				
				scope.isDrawing = true;
				scope.ctx.beginPath();
				scope.ctx.moveTo(canvasX, canvasY);
			}
			
			scope.draw = function(e) {
				if (scope.isDrawing == true) {
					var canvasX = e.pageX - $(scope.canvas).offset().left,
		  				canvasY = e.pageY - $(scope.canvas).offset().top;				
					scope.ctx.lineTo(canvasX, canvasY);
					scope.ctx.stroke();
					if( !scope.canSave ) {
						scope.canSave = true;
						scope.$apply();
					}
				}
			}
			
			scope.stopDrawing = function(e)  {
				scope.isDrawing = false;
			}			
			
			scope.erase = function() {
				scope.ctx.clearRect(0, 0, scope.canvas.width, scope.canvas.height);
				scope.canSave = false;
			}
			scope.canvas.addEventListener('touchstart', scope.startDrawing, false);
			scope.canvas.addEventListener('touchend', scope.stopDrawing, false);
			scope.canvas.addEventListener('touchmove', scope.draw, false);
			scope.canvas.addEventListener('touchleave', scope.stopDrawing, false);
			scope.canvas.addEventListener('mousedown', scope.startDrawing, false);
			scope.canvas.addEventListener('mouseup', scope.stopDrawing, false);
			scope.canvas.addEventListener('mousemove', scope.draw, false);
			scope.canvas.addEventListener('mouseout', scope.stopDrawing, false);
			
			scope.setupCanvas = function() {
				scope.canvas.width = elem.innerWidth();
				scope.canvas.height = 200;
				scope.ctx = scope.canvas.getContext("2d");
	
				var width = scope.canvas.width;
				var height = scope.canvas.height;
				scope.ctx.clearRect(0, 0, scope.canvas.width, scope.canvas.height);
				if( scope.signature ) {
					var img = new Image;
					img.onload = function(){
						scope.ctx.drawImage(img,0,0); // Or at whatever offset you like
					};
					img.src = scope.signature;			
				}
				scope.ctx.strokeStyle = "black";
				scope.ctx.lineWidth = 2;
				scope.canvas.focus();
			};

			
			scope.cancel = function() {
				scope.cancelaction()();
			};
			scope.ok = function() {
				var dataURL = scope.canvas.toDataURL();
				scope.okaction()(dataURL); 
			};
			
		}
	}
}]);

		