angular.module('worksiteEnterprises', [])
.directive('worksiteEnterprise',['$uibModal','$http','models','warningService', function($uibModal, $http, models, warningService)  {
    return {
		transclude: true,
		templateUrl: assetsURL+'components/app/worksites/worksite-enterprises/templates/worksite-enterprise.template.html',
        scope: { enterprise: '=', subcontractors:'=', enterprisesprofiles:'=', deletecallback:'&'},
		link: function ($scope, elem, attrs) {
			$scope.editEnterprise = function() {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/app/worksites/worksite-enterprises/templates/worksite-enterprise-editModal.html',
					size: 'lg',
					controller: ['$scope', '$uibModalInstance', 'contact', function (scope, $uibModalInstance, contact ) {
						
						scope.updatecontacts = true;
						scope.object = (contact === undefined ? {} : angular.copy(contact));
						if( scope.object._id === undefined ) {
							scope.object._id = models.clientId();
						}
						scope.originalid = scope.object._id;
						scope.updatestoredcontactdisabled = false;
						scope.$watch('object', function(newval) {
							if( newval === undefined ) { return; }
							if( scope.object._id !== scope.originalid ) {
								scope.updatecontacts = false;
								scope.updatestoredcontactdisabled = true;
							}
						}, true);
						scope.getContacts = function(val) {
							return $http.get( appURL+'/utils/enterprises'+( val ? '?search=' + val+"&type=society" : '' ) ).then( 
								function successCallback( response ) {
									return response.data; 
								}, function errorCallback( response ) {}
							);
						};
						scope.getAteco = function(val) {
							return $http.get( appURL+'/utils/ateco'+( val ? '?search=' + encodeURIComponent(val) : '' ) ).then( 
								function successCallback( response ) {
									return response.data; 
								}, function errorCallback( response ) {}
							);
						};
						scope.autocompleteContact = function($item) {
							var isfreelancer = scope.object.isfreelancer;
							scope.object = $item;
							if( scope.object.isfreelancer === undefined ) {
								scope.object.isfreelancer = isfreelancer;
							}
						};
						scope.autocompleteAteco = function($item) {
							scope.object.ateco.code = $item.code;
							scope.object.ateco.description = $item.description;
						};
						
						scope.getCities = function(val) {
							return $http.get( appURL+'/utils/cities'+( val ? '?search=' + encodeURIComponent(val) : '' ) ).then( 
								function successCallback( response ) {
									return response.data; 
								}, function errorCallback( response ) {}
							);
						};
					
						scope.autocompleteAddress=function(address) {
							$http.get( appURL+'/utils/cities?search='+encodeURIComponent(address.city), {}).then(
								function successCallback( response ) {
									var data=response.data;
									if( data.length ) {
										if( data[0].zipcode ) { address.zipcode=data[0].zipcode;}
										if( data[0].stateshort ) { address.state=data[0].stateshort;}
										if( data[0].region ) { address.region=data[0].region;}
										address.country = 'Italia';
									}
								}, function errorCallback( response ) {}
							);
						};
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function() {
							$uibModalInstance.close({object: scope.object, update: scope.updatecontacts, originalid: scope.originalid});
						};
					}],
					resolve: {
						contact: function () { return $scope.enterprise.contact; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
						if( data.update == true ) {
							$http.post(appURL+'/crud/contacts', {'object':data.object }).then(
								function successCallback(response){							
									data.object._id = response.data;
									$scope.enterprise.contact = angular.copy(data.object);
									if( data.originalid != data.object._id  ) {
										for( var i=0;i<$scope.subcontractors.length;i++ ) {
											if( $scope.subcontractors[i].parentcontractor && $scope.subcontractors[i].parentcontractor._id ) { 
												if( $scope.subcontractors[i].parentcontractor._id == data.originalid ) {
													$scope.subcontractors[i].parentcontractor._id = data.object._id;
												}
											}
										}
									}
								}, function errorCallback(response){
								}
							);
						} else {
							$scope.enterprise.contact = angular.copy(data.object);
						}
					}, function errorCallback() {}
				);
			};
			
			$scope.resetEnterprise = function() {
				$scope.enterprise.contact = undefined;
				if( $scope.deletecallback !== undefined ) {
					$scope.deletecallback();
				}
			};
			$scope.$watch('subcontractors', function(newValue) {
				if( newValue ) {
					$scope.updateContractors();
				}
			}, true);
			$scope.checkIfFirstAccessAuth = function() {
				if( $scope.enterprise.firstaccessstatus == 2 ) {
					warningService.showModal("warning", "Attenzione! L'impresa non è ancora stata autorizzata. Vuoi procedere?", "Annulla", "Procedi", function() { $scope.enterprise.isnotified = false; }, null);

				}
			}
			$scope.checkIfNotified = function() {
				if( $scope.enterprise.isnotified == false && $scope.enterprise.firstaccessstatus != 2 ) {
					alert("ATTENZIONE! L'impresa non è ancora stata inserita in notifica preliminare");
				}
			}
			$scope.updateContractors = function() {
				$scope.enterprises=[];
				if( !$scope.subcontractors ) { return;}
				var found=false;
				var tmpContractors = [];
				for( var i=0;i<$scope.subcontractors.length;i++ ) {
					if( !found && $scope.subcontractors[i].contact ) { 
						if( $scope.enterprise.contact && $scope.subcontractors[i].contact._id==$scope.enterprise.contact._id) { 
							found=true;
						} else {
							var cnt = $scope.subcontractors[i].contact;
							tmpContractors.push({_id:cnt._id, fullname:cnt.iscompany?cnt.companyname:(cnt.firstname+' '+cnt.lastname)});
						}
					}
				}
				$scope.enterprises=tmpContractors;
			};
			$scope.updateColorAndProfile = function() {
				$scope.updateProfileIfNotSet(true);
				if( $scope.enterprise.parentcontractor == '' || $scope.enterprise.parentcontractor == null ) { return; }
				for( var i=0; i<$scope.subcontractors.length;i++ ) {
					if( $scope.subcontractors[i].contact && $scope.subcontractors[i].contact._id == $scope.enterprise.parentcontractor._id ) {
						$scope.enterprise.color = $scope.subcontractors[i].color;
						break;
					}
				}
			};
			$scope.updateProfileIfNotSet = function(force) {
				if( (!$scope.enterprise.enterpriseprofile || force) && $scope.enterprisesprofiles ) {
					if( $scope.enterprise.issupplier ) {
						$scope.enterprise.enterpriseprofile = $scope.enterprisesprofiles.filter( el => el.description.indexOf("Fornitore")!== -1 )[0]._id;
					} else {
						if( $scope.enterprise.contact && $scope.enterprise.contact.isfreelancer ) {
							$scope.enterprise.enterpriseprofile = $scope.enterprisesprofiles.filter( el => el.description.indexOf("Lavoratore autonomo")!== -1 )[0]._id;
						} else {
							$scope.enterprise.enterpriseprofile = $scope.enterprisesprofiles.filter( el => el.description.indexOf("Impresa")!== -1 )[0]._id;
						}
					}
				}
			}
			$scope.updateProfileIfNotSet(false );
		}
	}
}])
.directive('worksiteEnterprisesList',['$uibModal','$http','models',function($uibModal, $http, models)  {
    return {
		transclude: true,
		templateUrl: assetsURL+'components/app/worksites/worksite-enterprises/templates/worksite-enterprises-list.template.html',
        scope: { enterprises: '=', enterprisesprofiles:'=', linkaction: '&' },
		link: function ($scope, elem, attrs) {
			$scope.openLink = function(enterprise, section) {
				if( $scope.linkaction && enterprise.referencedenterprise ) {
					$scope.linkaction()(enterprise.referencedenterprise._id, section);
				}
			};
			$scope.updateConformance = function(enterprise) {
				var obj = { status:1, notes:0, enterprise:{}, documents:{}, workers:{}};
				
				var getDocsStatuses = function(docs) {
					var getNotesCount = function(doc) {
						var notesCount = 0;
						doc.files.forEach(function(file) {
							if( file.validations && file.validations.length ) {
								const validation = file.validations[file.validations.length-1];
								validation.pages.forEach(function(page) {
									page.sections.forEach(function(section) {
										if( section.notes.length ) { notesCount = notesCount+1; }
									});
								});
								if( validation.notes.length ) { notesCount = notesCount+1; }
							}	
						});
						return notesCount;
					};
					var notesCount = 0;
					var statuses = [0,0,0,0];
					var nowTime = (new Date()).getTime();
					docs.forEach(function(doc) {
						notesCount = notesCount + getNotesCount(doc);
						var basicCheckFailed = false;
						if( doc.required && !doc.files.length ) {
							basicCheckFailed = true;
						} else if( doc.files.length ) {
							if( doc.validity.type > 0 ) {
								var expirydate = doc.expirydate ? new Date(doc.expirydate) : new Date("2000-01-01");
								if( expirydate.getTime() < nowTime ) {
									basicCheckFailed = true;
								}
							} 
						}
						if( basicCheckFailed ) {
							statuses[2]=statuses[2]+1;
						} else {
							if( doc.validationstatus || (doc.validationstatus == 0 && doc.files.length > 0 )) {
								statuses[doc.validationstatus]=statuses[doc.validationstatus]+1;
							}
						}
					});
					return { notesCount: notesCount, statuses: statuses };
				};
				
				var getConformance = function(statuses) {
					// Gives precedence in this order: errors, warnings, pendings, ok
					var obj = { status:1, warnings:statuses[3], errors:statuses[2] };
					if( statuses[2]) {
						obj.status = 2;
					} else if( statuses[3] ) {
						obj.status = 3;
					} else if( statuses[0] ) {
						obj.status = 0;
					} else if( !statuses[1] ) {
						obj.status = 0;
					}
					return obj;
				};
				
				var notesCount = 0;
				var totalStatuses = [0,0,0,0];	
				
				// Workers	
				var statuses = [0,0,0,0];
				var results = null;
					
				enterprise.refemployees.forEach(function(item) {
					results = getDocsStatuses(item.documents);
					notesCount += results.notesCount;
					for( var i=0;i<4;i++ ) { statuses[i]=statuses[i]+results.statuses[i]; }
					results = getDocsStatuses(item.certificates);
					notesCount += results.notesCount;
					for( var i=0;i<4;i++ ) { statuses[i]=statuses[i]+results.statuses[i]; }
				});
				obj.workers = getConformance(statuses);
				for( var i=0;i<4;i++ ) { totalStatuses[i]=totalStatuses[i]+statuses[i]; }
				
				// Enterprise
				results = getDocsStatuses(enterprise.refenterprisedocs);
				notesCount += results.notesCount;
				obj.enterprise = getConformance(results.statuses);
				for( var i=0;i<4;i++ ) { totalStatuses[i]=totalStatuses[i]+results.statuses[i]; }

				// Other documents
				statuses = [0,0,0,0];
				results = getDocsStatuses(enterprise.refoperatingdocs);
				notesCount += results.notesCount;
				for( var i=0;i<4;i++ ) { statuses[i]=statuses[i]+results.statuses[i]; }
				
				enterprise.reftemporaryworks.forEach(function(item) {
					results = getDocsStatuses(item.documents);
					notesCount += results.notesCount;
					for( var i=0;i<4;i++ ) { statuses[i]=statuses[i]+results.statuses[i]; }
				});
				enterprise.refmachines.forEach(function(item) {
					results = getDocsStatuses(item.documents);
					notesCount += results.notesCount;
					for( var i=0;i<4;i++ ) { statuses[i]=statuses[i]+results.statuses[i]; }
				});
				enterprise.refsubstances.forEach(function(item) {
					results = getDocsStatuses(item.documents);
					notesCount += results.notesCount;
					for( var i=0;i<4;i++ ) { statuses[i]=statuses[i]+results.statuses[i]; }
				});
				obj.documents = getConformance(statuses);
				for( var i=0;i<4;i++ ) { totalStatuses[i]=totalStatuses[i]+statuses[i]; }

				if( totalStatuses[2]) {
					obj.status = 2;
				} else if( totalStatuses[3] ) {
					obj.status = 3;
				} else if( totalStatuses[0]) {
					obj.status = 0;
				} else if( !totalStatuses[1] ) {
					obj.status = 2;
				}
				obj.notes = notesCount;
				enterprise.conformance = obj;
			};
			
			for( const ent of $scope.enterprises ) {
				$scope.updateConformance(ent);
			}
		}
	}
}]);
