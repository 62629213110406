angular.module('worksiteCheckReports', [])
.directive('worksiteCheckReport',['models','$http', '$uibModal','lettertextService', function (models,$http, $uibModal, lettertextService) {
    return {
		restrict: 'C',
		templateUrl: assetsURL+'components/app/worksites/worksite-checkreports/templates/worksite-checkreport.template.html?t='+RootSeed,
        scope: { report: '=', worksite:'=', mailinglist : '='},
		link: function (scope, elem, attrs) {
			scope.checkpos = scope.report.pos !== undefined;
			scope.checkitp = scope.report.itp !== undefined;
			scope.checkoverall = scope.report.overall !== undefined;
			scope.setRecipients = function() {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/app/worksites/worksite-checkreports/templates/worksite-checkreport-setRecipientsModal.html',
					controller: ['$scope', '$uibModalInstance', 'mailinglist','recipients',  function ( scope, $uibModalInstance, mailinglist, recipients ) {
						// Update recipients
						scope.availableRecipients = JSON.parse(JSON.stringify(mailinglist));
						var recipientsGraph = {};
						recipients.forEach(function(recipient) {
							if( recipient.contact && recipient.contact._id ) {
								recipientsGraph[recipient.contact._id]=recipient;
							}
						});
						
						scope.availableRecipients.forEach(function(recipient) {
							if( recipient.contact && recipient.contact._id ) {
								hashedRecipient = recipientsGraph[recipient.contact._id];
								if( hashedRecipient ) {
									hashedRecipient.contact.emails.forEach(function(email) {
										for( var i=0;i<recipient.contact.emails.length; i++ ) {
											if( email.email == recipient.contact.emails[i].email ) {
												recipient.contact.emails[i].selected = email.selected;
												break;
											}
										}
									});
								}
								recipient.selected = false;
								recipient.contact.emails.forEach(function(email) {
									if( email.selected ) {recipient.selected=true;}
								});
							}
						});

						scope.selectAll = function() {
							scope.availableRecipients.forEach(function(recipient) {
								recipient.contact.emails.forEach(function(email) {
									email.selected = true;
								})
							});
						};
						scope.deselectAll = function() {
							scope.availableRecipients.forEach(function(recipient) {
								recipient.contact.emails.forEach(function(email) {
									email.selected = false;
								})
							});
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function () {
							scope.availableRecipients.forEach(function(recipient) {
								var atLeastOneSelected = false;
								recipient.contact.emails.forEach(function(email) {
									if( email.selected ) { atLeastOneSelected = true; }
								});
								recipient.selected = atLeastOneSelected;
							});
							$uibModalInstance.close(scope.availableRecipients);
						};												
					}],
					resolve: { 
						mailinglist: function() { return scope.mailinglist; },
						recipients: function() { return scope.report.recipients; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
						scope.report.recipients = data;
					}, function errorCallback() {
					}
				);
			};
		}
	};
}]).directive('worksiteCheckReports', ['$timeout', '$http','$uibModal', 'warningService','models','checkEnterpriseDocs', function ($timeout, $http, $uibModal, warningService, models, checkEnterpriseDocs) {
    return {
        scope: { reports: '=', docs: '=', type:'@', worksite : '=', mailinglist : '=', saveaction: '&'},
		restrict: 'C',
		templateUrl: assetsURL+'components/app/worksites/worksite-checkreports/templates/worksite-checkreports.template.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			if( scope.type=="customcheckreportspos" ) {
				scope.worksite.worksite.enterprise.checkpos = checkEnterpriseDocs.checkPos(scope.worksite.worksite.enterprise);
			} else if( scope.type=="customcheckreportsitp" ) {
				scope.worksite.worksite.enterprise.checkitp = checkEnterpriseDocs.checkItp(scope.worksite.worksite.enterprise);
			} else {
				scope.worksite.worksite.enterprise.checkoverall = checkEnterpriseDocs.checkOverall(scope.worksite.worksite.enterprise);
			}
			scope.addReport= function() {
				$http.post(appURL+'/services/worksites-checkreports/new', {'worksiteId':scope.worksite._id, 'type':scope.type }).then(
					function successCallback(response){							
						var newObject = new models.CheckReport();
						newObject._id = response.data._id;
						if( scope.type=="customcheckreportspos" ) {
							newObject.pos = scope.worksite.worksite.enterprise.checkpos;
						} else if( scope.type=="customcheckreportsitp" ) {
							newObject.itp = scope.worksite.worksite.enterprise.checkitp;
						} else {
							newObject.overall = scope.worksite.worksite.enterprise.checkoverall;
						}
						scope.editReport(0, newObject);
					}, function errorCallback(response){
					}
				);
			};

			scope.deleteReport = function(index) {
				if( index<0 || index >= scope.reports.length ) { return; }
				scope.reports.splice(index, 1);
			};
			scope.duplicateReport = function(index) {
				if( index<0 || index >= scope.reports.length ) { return; }
				var newReport = angular.copy(scope.reports[index]);
				newReport.reportindex = newReport.reportindex+1;
				scope.reports.push(newReport);
			};
			scope.editReport = function(index, newObject) {
				var report = newObject ? newObject : scope.reports[index];
				var isNew = newObject ? true : false;
				var modalInstance = $uibModal.open({
					size: 'lg',
					templateUrl: assetsURL+'components/app/worksites/worksite-checkreports/templates/worksite-checkreport-editReportModal.html?t='+RootSeed,
					controller: ['$http', '$scope', '$uibModalInstance','reports', 'docs', 'index', 'report','worksite','mailinglist', 'type', 'isNew','saveaction', function ($http, scope, $uibModalInstance, reports, docs, index, report, worksite, mailinglist, type, isNew, saveaction ) {
						scope.isSaving = false;
						scope.checkpos = type=="customcheckreportspos";
						scope.checkitp = type=="customcheckreportsitp";
						scope.report = JSON.parse(JSON.stringify(report));
						scope.report.issuedate = new Date(scope.report.issuedate);
						scope.canvalidate = scope.checkpos ? (scope.report.pos.status>0 ) : (scope.checkitp ? (scope.report.itp.status>0 ) : (scope.report.overall.status>0 ));
						if( isNew ) {
							scope.report.reportindex = index+1;
							scope.report.title = (scope.checkpos ? "Verifica dell'idoneità Piano Operativo di Sicurezza" : (scope.checkitp ?  "Verifica dell'idoneità Tecnico Professionale" : "Verifica della documentazione trasmessa"))
						}						
						scope.worksite = worksite;
						scope.mailinglist = mailinglist;
						scope.isNew = isNew;
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						
						scope.ok = function (createdoc) {
							scope.isSaving = true;

							const filename = worksite.filename ? worksite.filename : worksite.job+((scope.checkpos ? "_VIP" : (scope.checkitp ? "_ITP" : "_VDOC")) +("000" + (index+1)).substr(-3)+"_"+report.title);
							const destObjKeyPath = type.substr(6); // Remove custom from field name
							if( isNew ) {
								reports.push(scope.report);
							} else {
								reports[index] = scope.report;
							}
							var successCb = function() {
								if( createdoc == false ) {
									scope.isSaving = false;
									$uibModalInstance.close(null);
									return;
								}
								$http.post(appURL+'/services/worksites/print', { 'worksiteId':scope.worksite._id, 'objType':'checkreport', destObjKeyPath:destObjKeyPath, 'srcObjKeyPath': 'enterprises.'+type, 'srcObjId': scope.report._id, 'filename': filename }).then(
									function successCallback(response){																
										const data = response.data;
										if( data.fileId == null ) {
											scope.isSaving = false;
											$uibModalInstance.dismiss('cancel');
											alert("Attenzione! Non e' stato possibile generare il PDF. Riprova")
											return;
										}
										var newDoc = {};
										newDoc.creationdate = new Date();
										newDoc.fileId = data.fileId;
										newDoc.type = 'application/pdf';
										newDoc.category = type;
										newDoc.description = filename;
										newDoc.issuedate = new Date();
										newDoc.revision = (scope.report.file.fileId !== undefined && scope.report.file.revision !== undefined) ? scope.report.file.revision+1:0;
										newDoc.notes = "";
										newDoc.additionalData = { enterprise: {contact: worksite.enterprise.contact}, work: worksite.enterprise.work };
										newDoc.filename = filename;
										docs.docs.push(newDoc);
										
										scope.report.file = { fileId:data.fileId, status:'success', error:null, revision:newDoc.revision };
										var successSaveCallback = function() {
											scope.isSaving = false;
											$uibModalInstance.close(null);
										};
										
										var errorSaveCallback = function() {
											scope.isSaving = false;
											$uibModalInstance.dismiss('cancel');
											alert("Attenzione! Non e' stato possibile salvare le modifiche. Riprova")
										};
										saveaction()(successSaveCallback, errorSaveCallback);
									}, function errorCallback(response){
										scope.isSaving = false;
										$uibModalInstance.dismiss('cancel');
										alert("Attenzione! Non e' stato possibile generare il PDF. Riprova")
									}
								);
							};
							var errorCb = function() {
								scope.isSaving = false;
								$uibModalInstance.dismiss('cancel');
								alert("Attenzione! Non e' stato possibile salvare le modifiche. Riprova")
							};
							saveaction()(successCb, errorCb);
						};
					}], resolve: { 
						reports:  function() { return scope.reports; },
						docs:  function() { return scope.docs; },
						index:  function() { return index; },
						report: function() { return report; },
						worksite: function() { return scope.worksite.worksite; },
						mailinglist: function() { return scope.mailinglist; },
						type: function() { return scope.type; },
						isNew: function() { return isNew; },
						saveaction: function () { return scope.saveaction; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
					}, function errorCallback() {
					}
				);
			};
		}
	}
}]);
		