angular.module('objectsList', [])
.controller('ObjectsCtrl', ['$rootScope', '$scope', '$routeParams','$http','$timeout','$location',   function($rootScope, $scope, $routeParams, $http, $timeout, $location ) {
	$scope.search='';
	$scope.skip=0;
	$scope.selectedObjects = [];
	$scope.objects = [];
	$scope.resetObjects = false;
	$scope.moreObjectsToLoad = true;
	$scope.filterpostemplates = false;
	$scope.loaded = false;
	if( $scope.supportCustomRefresh ) {
		$scope.$watch($scope.refresh, function(newValues) {			
			$scope.resetObjects = true;
			$scope.moreObjectsToLoad = true;
			$scope.skip=0;
			$scope.getObjects();
		});
	}
	if( $scope.supportDaterange ) {
		var prefs = $rootScope.currentUser.prefs[$scope.type];
		if( prefs === undefined ) {
			var today = new Date();
			$rootScope.currentUser.prefs[$scope.type] = {rangetag:'',startdate:today, enddate:today };
			prefs =  $rootScope.currentUser.prefs[$scope.type];
		}
		$scope.rangetag = prefs.rangetag ;
		$scope.startdate = (new Date(prefs.startdate)).toISOString().substr(0,10);
		$scope.enddate = (new Date(prefs.enddate)).toISOString().substr(0,10);
		$scope.$watchCollection('[startdate,enddate,rangetag]', function(newValues) {	
			if( $rootScope.currentUser.prefs[$scope.type] === undefined ) {
				$rootScope.currentUser.prefs[$scope.type] = {};
			}		
			$rootScope.currentUser.prefs[$scope.type].startdate = newValues[0];
			$rootScope.currentUser.prefs[$scope.type].enddate = newValues[1];
			$rootScope.currentUser.prefs[$scope.type].rangetag = newValues[2];
			$scope.resetObjects = true;
			$scope.moreObjectsToLoad = true;
			$scope.skip=0;
			$scope.getObjects();
		});
	}

	if( !$scope.useSelfOrder ) {
		var prefs = $rootScope.currentUser.prefs;
		if( prefs && prefs[$scope.type] && prefs[$scope.type].sortby) {
			$scope.sortby = prefs[$scope.type].sortby;
		}
	}
	$scope.updateSearch=function() {
		$scope.resetObjects = true;
		$scope.moreObjectsToLoad = true;
		$scope.skip=0;
		$scope.getObjects();
	}
	$scope.changeOrder=function(order) {
		if( $scope.sortby.key == order ) {
			var dir = -$scope.sortby.direction;
			$scope.sortby = { key : order, direction: dir };
		} else {
			$scope.sortby = { key : order, direction:1 };
		}
		$rootScope.currentUser.prefs[$scope.type] = { sortby : $scope.sortby };
		$scope.skip=0;
	};
	$scope.selectObject = function(object) {
		var index = -1;
		object.selected = !object.selected;
		if( $scope.multiselection==true ) {
			for( var i=0;i<$scope.selectedObjects.length; i++ ) {
				if( object._id == $scope.selectedObjects[i] ) { index=i; break;}
			}
			if( index == -1 ) { 
				$scope.selectedObjects.push(object._id);
			} else {
				$scope.selectedObjects.splice(index,1);
			}
		} else {
			var deselect = false;
			if( $scope.selectedObjects.length ) {
				$scope.objects.forEach(function(d) {
					if( d._id == $scope.selectedObjects[0] ) {
						d.selected = false;
					}
				});
				if( $scope.selectedObjects[0] == object._id ) {
					deselect = true;
					$scope.selectedObjects = [];
				}
			}
			if( !deselect ) {
				$scope.selectedObjects=[object._id];
			}
		}
	};
	$scope.canCopy = function() { 
		return $scope.selectedObjects.length ? true : false; 
	};
	
	$scope.copyObjects = function() {
		console.log($scope.selectedObjects);
	};
	$scope.$watch('search', function(newValue, oldValue) { 
		if( newValue == oldValue ) return;
		$scope.skip = 0;
		$scope.resetObjects = true;
		$scope.moreObjectsToLoad = true;
		$scope.getObjects();
	}); 

	$scope.$watch('skip', function(newValue, oldValue) { 
		if( newValue == oldValue ) return;
		$scope.getObjects();
	}); 
	
	$scope.$watch('sortby', function(newValue, oldValue) { 
		if( newValue == oldValue ) return;
		$scope.skip = 0;
		$scope.resetObjects = true;
		$scope.moreObjectsToLoad = true;
		$scope.getObjects();
	}); 
	// infinite scroll logic
	$scope.busyloading = false;

	$scope.getObjects = function() {
		if( $scope.busyloading ) { return; }
		$scope.busyloading = true;
		var sort = '';
		if( $scope.sortby ) {
			sort = '&sortby='+$scope.sortby.key+'&order='+$scope.sortby.direction;
		}
		var url = appURL+'/crud/'+$scope.type;
		var timerange = '';
		var queryparams = '';
		
		if( $scope.getObjectsUrl ) { url =$scope.getObjectsUrl(); }
		if( $scope.getQueryParams ) { queryparams= $scope.getQueryParams($scope);}
		if( $scope.startdate && $scope.enddate ) { timerange="&startdate="+$scope.startdate+"&enddate="+$scope.enddate; }
		
	    $http.get(url+'?search='+$scope.search+'&skip='+$scope.skip+'&limit='+$scope.limit+sort+timerange+queryparams).then(
		    function successCallback(response){
			    var data = response.data;
				$scope.loaded = true;
				if( $scope.resetObjects ) {
					$scope.objects = [];
					$scope.resetObjects = false;
				}
		    	$scope.selectedObjects = [];
				$scope.busyloading = false;
				if( data.length) {
			    	data.forEach(function(c) {
			    		$scope.objects.push(c);
			    	});
			    	if( $scope.decodeObjects ) { $scope.decodeObjects($scope, data); }
			    }
			    if( data.length < $scope.limit )  {
					$scope.moreObjectsToLoad = false;
				}
		    }, function errorCallback(response) {
		    	if( response.status==404 ) { $location.path('/404');}
		    }
		);
	};
	$scope.loadNext = function() {
		if( $scope.busyloading ) { return; }
		if( $scope.moreObjectsToLoad == true ) {
			$timeout(function() {
				$scope.skip += $scope.limit;
			}, 200);
		}
	}
	$scope.openLink = function(link) {
		$location.path(link);
	}
	$scope.getObjects();
}]);
