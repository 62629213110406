angular.module('objectEdit', [])
.controller('ObjectEditCtrl',['$timeout', '$rootScope', '$scope', '$location', '$routeParams', '$http', function($timeout, $rootScope, $scope, $location,$routeParams, $http ) {
	$scope.remoteObject = undefined;
	$scope.loaded = false;
	$scope.slugalreadytaken = false;
	
	$scope.checkslug = function(type) {
		if( $scope.object.slug === undefined ) { 
	    	$scope.slugalreadytaken = false;
			return;
		}
		if( $scope.remoteObject && ($scope.remoteObject.slug === $scope.object.slug) ) { 
	    	$scope.slugalreadytaken = false;
			return;
		}
	    $http.post(appURL+'/services/checkslug/'+type,{slug:$scope.object.slug, _id:$scope.object._id}).then(
	    	function successCallback(response){
		    	$scope.slugalreadytaken = response.data.exists;
		    }, function errorCallback(response) {}
		);
	};
	$scope.slugify = function( text, object, slug ) {
		const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
		const b = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
		const p = new RegExp(a.split('').join('|'), 'g')
		
		if( object[slug] && object[slug].length ) { return; }
		object[slug]= text.toString().toLowerCase()
		.replace(/\s+/g, '-')           // Replace spaces with -
		.replace(p, c =>
		b.charAt(a.indexOf(c)))     // Replace special chars
		.replace(/&/g, '-and-')         // Replace & with 'and'
		.replace(/[^\w\-]+/g, '')       // Remove all non-word chars
		.replace(/\-\-+/g, '-')         // Replace multiple - with single -
		.replace(/^-+/, '')             // Trim - from start of text
		.replace(/-+$/, '')             // Trim - from end of text
		.replace(/\-al\-/g,'-')
		.replace(/\-alla\-/g,'-')
		.replace(/\-allo\-/g,'-')
		.replace(/\-alle\-/g,'-')
		.replace(/\-agli\-/g,'-')
		.replace(/\-il\-/g,'-')
		.replace(/\-la\-/g,'-')
		.replace(/\-lo\-/g,'-')
		.replace(/\-le\-/g,'-')
		.replace(/\-gli\-/g,'-')
		.replace(/\-un\-/g,'-')
		.replace(/\-uno\-/g,'-')
		.replace(/\-una\-/g,'-')
		.replace(/\-di\-/g,'-')
		.replace(/\-del\-/g,'-')
		.replace(/\-dei\-/g,'-')
		.replace(/\-della\-/g,'-')
		.replace(/\-delle\-/g,'-')
		.replace(/\-degli\-/g,'-')
		.replace(/\-per\-/g,'-')
		.replace(/\-e\-/g,'-')
	};
	$scope.get = function() {
		var getUrl = appURL+'/crud/'+$scope.type+'/';
		var params = '?edit=true&';
		if( $scope.getSelf ) {
			var queryUrl = $scope.getSelf($scope);
			getUrl = queryUrl.url;
			params += queryUrl.params;
		}
		if( $routeParams.id !== undefined && $routeParams.id ) {// edit
		    $http.get(getUrl+$routeParams.id+params).then(
			    function successCallback(response){
				    var data=response.data;
					$scope.object = data;
					if( $scope.decodeEditObject ) { $scope.decodeEditObject($scope, data);}
					if( $routeParams.edittype !== undefined && $routeParams.edittype=="duplica" ) {
						delete $scope.object._id;
						$scope.remoteObject  = undefined;
						$rootScope.prevLocation = $scope.path;
					} else {
						$scope.remoteObject = angular.copy($scope.object);
					}
					$scope.loaded = true;
			    }, function errorCallback(response){
			    	if( response.status==404 ) { $location.path('/404');}
			    }
			);
		} else {
			$scope.loaded = true;
		}
	};
	$scope.duplicate = function() {
		if( $scope.duplicateSelf ) {
			$scope.duplicateSelf()
		} else {
			if( $routeParams.id !== undefined && $routeParams.id ) {
				$location.path($scope.path+"/"+$routeParams.id+"/duplica");
			}
		}
	}

	$scope.getContacts = function(val) {
		return $http.get(appURL+'/utils/contacts'+( val ? '?search=' + val : '' ) ).then( function( res ) {
			res.data.forEach(function(c) {c.fullname = c.firstname+' '+c.lastname; });
			return res.data; 
		});
	};
	$scope.getWorks = function(val) {
		/*return $http.get(appURL+'/utils/works'+( val ? '?search=' + val : '' ) ).then( function( res ) {
			return res.data; 
		});*/
		return [];
	};
	$scope.calendar = {isopen:false};
	$scope.openCalendar = function(event, obj) {
		event.preventDefault();
		event.stopPropagation();
		if( obj===undefined ) { 
			$scope.calendar.isopen = true; 
		} else {
			obj.isopen  = true;
		}
	};

	$scope.autocompleteWork = function($item, object) {
		object.subworks = $item.subworks;
	};

	$scope.addDate = function() {
		var nd;
		var tr;
		
		if( $scope.object.dates.length ) {
			var dt = $scope.object.dates[$scope.object.dates.length-1].date;
			var nd = new Date(dt);
			nd.setDate(dt.getDate()+7);
			tr =  $scope.object.dates[$scope.object.dates.length-1].timerange;
		} else {
			nd = new Date();
			tr="21.00-23.00";
 		}
		$scope.object.dates.push({date:nd,timerange:tr, isoutside:false});
	};
	$scope.isSaving = false;
	$scope.isClean = function() {		
		if( $scope.remoteObject === undefined ) return false;
		if( $scope.cantSave == true) return true;
		
		return angular.equals($scope.remoteObject, $scope.object);
	};
	
	$scope.destroy = function() {
		if( confirm('Sei sicuro di volerlo cancellare')) {
			if( $scope.deleteSelf ) {
				$scope.deleteSelf($scope);
			} else {
		    	$http.delete(appURL+'/crud/'+$scope.type+'/'+$scope.object._id).then(
			    	function successCallback(response){
						$location.path($scope.path);
					}, function errorCallback(response) {
						if( response.status==406 ) { // 
							alert("ATTENZIONE! Non puoi cancellarlo dal momento che ci sono altri oggetti che lo referenziano. "+(response.data.type ? response.data.type :'')); 
						}
					}
				);
			}
		} 
	};
	$scope.save = function(avoidExiting) {
		$scope.isSaving = true;
		if( $scope.remoteObject===undefined && $scope.createSelf ) {
			$scope.createSelf($scope);
		} else if( $scope.remoteObject && $scope.updateSelf ) {
			$scope.updateSelf($scope);
		} else {
			if( $scope.encodeEditObject ) { $scope.encodeEditObject($scope.object);}
			$http.post(appURL+'/crud/'+$scope.type, {'object':$scope.object }).then(
				function successCallback(response){
					$scope.isSaving = false;
					if( avoidExiting === undefined ) {
						$location.path($rootScope.prevLocation==$rootScope.curLocation ? $scope.path : $rootScope.prevLocation);
					} else {
						$scope.object._id = response.data;
						$scope.remoteObject = angular.copy($scope.object);
					}
				}, function errorCallback(response) {
					$scope.isSaving = false;
					if( avoidExiting === undefined ) {
						$location.path($rootScope.prevLocation==$rootScope.curLocation ? $scope.path : $rootScope.prevLocation);
					}
				}
			);
		}
	};
	$scope.cancel = function() { 		
		$location.path($rootScope.prevLocation==$rootScope.curLocation ? $scope.path : $rootScope.prevLocation);
	};
	if( $scope.setupEditObject ) { $scope.setupEditObject($scope);}
	$scope.get();
}]);
