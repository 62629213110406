angular.module('worksiteEnterprisesReferencedPeople', [])
.directive('worksiteEnterprisesReferencedPerson', ['$timeout','$uibModal','models','$http','warningService', function ($timeout, $uibModal, models, $http, warningService) {
    return {
		scope: { refperson : '=', section: '=', adddesignations:"=", addcertificates: '=', enterpriseid: '@', validatorid: '@', worksite: '=' },
		restrict: 'C',
		templateUrl: assetsURL+'components/app/worksites/worksite-enterprises-referenced-people/templates/worksite-enterprises-referenced-person.template.html',
		link: function (scope, elem, attrs) {
			if( scope.section == 'Lavoratore' ) {
				scope.adddocuments = true;
				scope.addcertificates = true;
				scope.adddesignations = false;
			}
			scope.viewtype = scope.adddesignations ? 'designations' : (scope.adddocuments ? 'documents' : 'certificates');
			scope.setViewType = function(type) {
				scope.viewtype = type;
			};
		}
	}
}]).directive('worksiteEnterprisesReferencedPeople', ['$timeout', '$http','$uibModal', 'warningService','models', function ($timeout, $http, $uibModal, warningService, models) {
    return {
		scope: { refpeople: '=', section : '@', adddesignations:"@", addcertificates: '@', enterpriseid: '@', validatorid: '@', worksite: '=' },
		restrict: 'C',
		templateUrl: assetsURL+'components/app/worksites/worksite-enterprises-referenced-people/templates/worksite-enterprises-referenced-people.template.html',
		link: function (scope, elem, attrs) {
			scope.selectedrefperson = null;
			scope.selectRefPerson = function(index) {
				if( index<0 || index > scope.refpeople.length-1 ) { return; }
				scope.selectedrefperson = scope.refpeople[index];
			};
			
			scope.selectRefPerson(0);
		}
	}
}]);
