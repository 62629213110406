import './worksites/worksites.module.js';
import './worksite-documents/worksite-documents.module.js';
import './worksite-enterprises/worksite-enterprises.module.js';
import './worksite-enterprises-documents/worksite-enterprises-documents.module.js';
import './worksite-enterprises-documents-notes/worksite-enterprises-documents-notes.module.js';
import './worksite-enterprises-referenced-people/worksite-enterprises-referenced-people.module.js';
import './worksite-messages/worksite-messages.module.js';
import './worksite-organization-charts/worksite-organization-charts.module.js';
import './worksite-reports/worksite-reports.module.js';
import './worksite-checkreports/worksite-checkreports.module.js';
import './worksite-users/worksite-users.module.js';
import './services/check-enterprise-docs/check-enterprise-docs.module.js';

angular.module('worksites', ['worksitesObjects','worksiteUsers', 'worksiteDocuments', 'worksiteEnterprisesDocuments', 'worksiteEnterprisesDocumentsNotes', 'worksiteEnterprisesReferencedPeople','worksiteEnterprises', 'worksiteReports', 'worksiteOrganizationCharts', 'worksiteMessages', 'worksiteCheckReports', 'checkEnterpriseDocs'])
.config(['$routeProvider', function($routeProvider) {
	$routeProvider
		.when('/cantieri', { template: "<worksites-list></worksites-list>" })
		.when('/cantieri/nuovo', { template: "<worksite-edit></worksite-edit>" })
		.when('/cantieri/:id/', { template: "<worksite-detail></worksite-detail>" })
		.when('/cantieri/:id/impresa/:enterpriseid', { template: "<worksite-enterprise-detail></worksite-enterprise-detail>" })
		.when('/cantieri/:id/impresa/:enterpriseid/:section', { template: "<worksite-enterprise-detail></worksite-enterprise-detail>" })
		.when('/cantieri/:id/comunicazioni', { template: "<worksite-messages></worksite-messages>" })
		.when('/cantieri/:id/risorse', { template: "<worksite-assets></worksite-assets>" })
		.when('/cantieri/:id/:chapter', { template: "<worksite-edit></worksite-edit>" })
		.when('/cantieri/:id/:chapter/:section', { template: "<worksite-edit></worksite-edit>" })
}]);
