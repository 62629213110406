angular.module('contactDetail', [])
.component('contactDetail', {
	templateUrl: assetsURL+'components/shared/contacts/contact-detail/contact-detail.template.html',
	controller: ['$scope', 'models',  function($scope ,models ) {
		$scope.type='contacts';
		$scope.path='contatti';
		$scope.decodeObject = function(scope, data) {
			scope.object.fullname = (data.iscompany ? data.companyname : (data.firstname+' '+data.lastname));
			if( !scope.object.fullname.length && !data.iscompany ) {
				scope.object.fullname = data.companyname;
			}
		};
	}
]});
