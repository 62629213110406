angular.module('checkEnterpriseDocs', [])
.factory('checkEnterpriseDocs', [ function () {
	var prevalidateDoc = function(doc) {
		if( !doc ) {
			return "Documento obbligatorio mancante";
		}
		if( doc.required ) {
			if( doc.files && doc.files.length ) {
				if( doc.validity.type > 0 ) {
					var expirydate = doc.expirydate ? new Date(doc.expirydate) : new Date("2000-01-01");
					var now = new Date();
					if( expirydate.getTime() < now.getTime() ) {
						return "Documento scaduto";
					}
				} 
			} else {
				return "Documento obbligatorio mancante";
			}
		} else {
			if( doc.files && doc.files.length ) {
				if( doc.validity.type > 0 ) {
					var expirydate = doc.expirydate ? new Date(doc.expirydate) : new Date("2000-01-01");
					var now = new Date();
					if( expirydate.getTime() < now.getTime() ) {
						return "Documento scaduto";
					}
				} 
			}
		}
		return true;
	};

	var extractNotes = function(doc) {
		var notes = [];
		if( !doc.validationstatus ) { return notes; }
		if( doc.files && doc.files.length ) {
			let file = doc.files[doc.files.length-1];
			if( file.validations && file.validations.length ) {
				let validation = file.validations[file.validations.length-1];
				
				if( validation.pages && validation.pages.length ) {
					validation.pages.forEach(page=> {
						if( page.sections && page.sections.length ) {
							page.sections.forEach(section=> {
								if( section.notes && section.notes.length ) {
									notes.push({note: section.notes, description:section.description});
								}
							});
						}
					});
				}
				if( validation.notes && validation.notes.length ) {
					notes.push({note: validation.notes, description:''});
				}
			}
		}
		return notes;
	}

	return {
		prevalidateDoc : prevalidateDoc,
		checkItp : function(ent) {
			var hashedDocs = {};
			var itp = {};
			
			var docTypes = (ent.enterpriseprofile !='6651ccaa335fea887f68ce78') ? ["Camerale", "DVR", "DURC","Dichiarazione art. 14", "DOMA e CCNL", "Qualificazione SOA (cat. III o superiore)", "Patente a punti (se non in possesso di SOA)"] : ["Camerale", "DURC","Patente a punti (se non in possesso di SOA)"];
			
			docTypes.forEach( doctype => {
				hashedDocs[doctype] =  { status: 2, doc: null };
			});
			
			// Add required enterprise docs
			if( ent.refenterprisedocs ) {
				ent.refenterprisedocs.forEach( doc => {
					if( hashedDocs[doc.type]) {
						hashedDocs[doc.type].doc=doc;
					}
				});
			}
			
			// Add art.97 document if subcontractors are present
			if( ent.refsubcontractors ) {
				var notifiedSubcontractors = 0;
				ent.refsubcontractors.forEach( sub => {
					if( sub.submittalstatus>0 ) {
						notifiedSubcontractors = notifiedSubcontractors+1;
					}
				});
				if( notifiedSubcontractors ) {
					ent.notifiedSubcontractors = notifiedSubcontractors;
					docTypes.push("Incaricato art.97");
					hashedDocs["Incaricato art.97"] = { status: 2, doc: null };
					if( ent.refart97officers ) {
						ent.refart97officers.forEach( refperson => {
							if( refperson.certificates ) {
								for( var i=0;i<refperson.certificates.length;i++ ) {
									if( refperson.certificates[i].type == "Incaricato art.97" ) {
										hashedDocs["Incaricato art.97"].doc.certificates[i];
										break;
									}
								}
							}
						});
					}
				}
			}
			// Check validity
			docTypes.forEach( docType => {
				let doc = hashedDocs[docType].doc;
				let prevalidationStatus = prevalidateDoc(doc);
				if( prevalidationStatus === true ) {
					hashedDocs[docType].status = doc.validationstatus;
					switch( doc.validationstatus ) {
						case 0: 
							hashedDocs[docType].message ="Da validare";
							break;
						case 1: 
							break;
						default: 
							if( doc.files && doc.files.length ) {
								let file = doc.files[doc.files.length-1];
								if( file.validations && file.validations.length ) {
									hashedDocs[docType].message = file.validations[file.validations.length-1].notes;
								}
							}
							break;
					}
				} else {
					hashedDocs[docType].message = prevalidationStatus;
				}
			});
			if( hashedDocs["Incaricato art.97"] ) {
				itp.art97 = { status: hashedDocs["Incaricato art.97"].status, message: (hashedDocs["Incaricato art.97"].status==1 ? "Comunicato" : hashedDocs["Incaricato art.97"].message)};
			}
			itp.cciaa = { status : hashedDocs["Camerale"].status, message: (hashedDocs["Camerale"].status==1 ? "Esibito" : hashedDocs["Camerale"].message + (hashedDocs["Camerale"].expirydate ? "(fino al "+hashedDocs["Camerale"].expirydate.toISOString().substr(0,10)+")" : ""))};
			if( hashedDocs["DVR"] ) {
				itp.dvr = { status : hashedDocs["DVR"].status, message: (hashedDocs["DVR"].status==1 ? "Esibito" : hashedDocs["DVR"].message)};
			}
			itp.durc = { status : hashedDocs["DURC"].status, message: (hashedDocs["DURC"].status==1 ? "Esibito" : hashedDocs["DURC"].message + (hashedDocs["DURC"].expirydate ? "(fino al "+hashedDocs["DURC"].expirydate.toISOString().substr(0,10)+")" : ""))};
			if( hashedDocs["Dichiarazione art. 14"] ) {
				itp.art14 = { status : hashedDocs["Dichiarazione art. 14"].status, message: (hashedDocs["Dichiarazione art. 14"].status==1 ? "Esibita" : hashedDocs["Dichiarazione art. 14"].message)};
			}
			if( hashedDocs["DOMA e CCNL"] ) {
				itp.doma = { status : hashedDocs["DOMA e CCNL"].status, message: (hashedDocs["DOMA e CCNL"].status==1 ? "Esibito" : hashedDocs["DOMA e CCNL"].message)};
				itp.ccnl = { status : hashedDocs["DOMA e CCNL"].status, message: (hashedDocs["DOMA e CCNL"].status==1 ? "Esibito" : hashedDocs["DOMA e CCNL"].message)};
			}
			
			if( hashedDocs["Qualificazione SOA (cat. III o superiore)"] ) {
				if( hashedDocs["Qualificazione SOA (cat. III o superiore)"].status > 0 || hashedDocs["Patente a punti (se non in possesso di SOA)"].status > 0 ) {
					const docTypeSOAPP = hashedDocs["Qualificazione SOA (cat. III o superiore)"].status>0 ?"Qualificazione SOA (cat. III o superiore)" : "Patente a punti (se non in possesso di SOA)";
					const docTypeSOAPPToBeRemoved = hashedDocs["Qualificazione SOA (cat. III o superiore)"].status==0 ?"Qualificazione SOA (cat. III o superiore)" : "Patente a punti (se non in possesso di SOA)";
					
					itp.soapp = { status : hashedDocs[docTypeSOAPP].status, message: (hashedDocs[docTypeSOAPP].status==1 ? "Esibito" : hashedDocs[docTypeSOAPP].message)};
					delete hashedDocs[docTypeSOAPPToBeRemoved];
				} else {
					const message = hashedDocs["Qualificazione SOA (cat. III o superiore)"].message.length ? hashedDocs["Qualificazione SOA (cat. III o superiore)"].message : hashedDocs["Patente a punti (se non in possesso di SOA)"].message;
					itp.soapp = { status: 0, message: message };
				}
			} else {
				if( hashedDocs["Patente a punti (se non in possesso di SOA)"].status > 0 ) {					
					itp.soapp = { status : hashedDocs[ "Patente a punti (se non in possesso di SOA)"].status, message: (hashedDocs[ "Patente a punti (se non in possesso di SOA)"].status==1 ? "Esibito" : hashedDocs[ "Patente a punti (se non in possesso di SOA)"].message)};
				} else {
					itp.soapp = { status: 0, message: hashedDocs["Patente a punti (se non in possesso di SOA)"].message };
				}
			}
			var statuses = [0,0,0,0];
			for (const key in hashedDocs) {
				statuses[hashedDocs[key].status] = statuses[hashedDocs[key].status]+1;
			}
			itp.status = statuses[0]>0 ? 0 : (statuses[2]>0 ? 2 : (statuses[3]>0 ? 3 : 1));
			itp.message = statuses[0]>0 ? "DOCUMENTI DA VALIDARE" : (statuses[2]>0 ? "NEGATIVO" : (statuses[3]>0 ? "POSITIVO CON RISERVE" : "POSITIVO"));
			
			return itp;
		},
		checkPos : function(ent) {
			var docTypes = ["POS"];
			var hashedDocs = {};
			docTypes.forEach( doctype => {
				hashedDocs[doctype] =  { status: 2, doc: null };
			});
			
			// Add required enterprise docs
			if( ent.refoperatingdocs ) {
				ent.refoperatingdocs.forEach( doc => {
					if( hashedDocs[doc.type]) {
						hashedDocs[doc.type].doc=doc;
					}
				});
			}

			let prevalidationStatus = prevalidateDoc(hashedDocs["POS"]);
			let doc = hashedDocs["POS"].doc;
			if( prevalidationStatus === true ) {
				hashedDocs["POS"].status = doc.validationstatus;
				switch( doc.validationstatus ) {
					case 0: 
						hashedDocs["POS"].message ="Da validare";
						break;
					case 1: 
						break;
					default: 
						hashedDocs["POS"].notes = [];
						if( doc.files && doc.files.length ) {
							let file = doc.files[doc.files.length-1];
							if( file.validations && file.validations.length ) {
								let validation = file.validations[file.validations.length-1];
								hashedDocs["POS"].message = validation.notes;
								validation.pages.forEach(page=>{
									page.sections.forEach(section=>{
										if( section.notes && section.notes.length ) {
											hashedDocs["POS"].notes.push(section.notes);
										}
									});
								});
								
							}
						}
						break;
				}
			} else {
				hashedDocs["POS"].message = prevalidationStatus;
			}
			
			var pos = { status: hashedDocs["POS"].status, data:'', message: hashedDocs["POS"].message, notes: hashedDocs["POS"].notes };
			pos.message = pos.status==0 ? "DOCUMENTO DA VALIDARE" : (pos.status==2 ? "NEGATIVO" : (pos.status==3 ? "POSITIVO CON RISERVE" : "POSITIVO"));
			return pos;
		},
		
		checkOverall : function(ent) {
			validated_enterprisedocs = [];
			validated_operatingdocs = [];
			validated_employees = [];
			validated_temporaryworks = [];
			validated_machines = [];
			validated_substances = [];

			if( ent.refenterprisedocs ) {
				ent.refenterprisedocs.forEach( doc =>{
					let notes = extractNotes(doc);
					if( notes.length ) {
						validated_enterprisedocs.push({doc: doc.type, notes:notes});
					}
				});
			} 
			if( ent.refoperatingdocs ) {
				ent.refoperatingdocs.forEach( doc =>{
					let notes = extractNotes(doc);
					if( notes.length ) {
						validated_operatingdocs.push({doc: doc.type, notes:notes});
					}
				});
			} 
						
			if( ent.refemployees ) {
				ent.refemployees.forEach( employee =>{
					var emplyeesNotes = [];
					if( employee.documents && employee.documents.length ) {
						employee.documents.forEach( doc =>{
							let notes = extractNotes(doc);
							if( notes.length ) {
								emplyeesNotes.push({doc: doc.type, notes:notes});
							}
						});
					}
					if( employee.certificates && employee.certificates.length ) {
						employee.certificates.forEach( doc =>{
							let notes = extractNotes(doc);
							if( notes.length ) {
								emplyeesNotes.push({doc: doc.type, notes:notes});
							}
						});
					}
					if( emplyeesNotes.length ) {
						validated_employees.push({name:employee.fullname, docs: emplyeesNotes });
					}
				});
			} 

			if( ent.reftemporaryworks ) {
				ent.reftemporaryworks.forEach( temporarywork =>{
					var temporaryworksNotes = [];
					if( temporarywork.documents && temporarywork.documents.length ) {
						temporarywork.documents.forEach( doc =>{
							let notes = extractNotes(doc);
							if( notes.length ) {
								temporaryworksNotes.push({doc: doc.type, notes:notes});
							}
						});
					}
					if( temporaryworksNotes.length ) {
						validated_temporaryworks.push({name:temporarywork.category+' '+temporarywork.manifacturer+' '+temporarywork.model, docs: temporaryworksNotes });
					}
				});
			} 
			
			if( ent.refmachines ) {
				ent.refmachines.forEach( machine =>{
					var machinesNotes = [];
					if( machine.documents && machine.documents.length ) {
						machine.documents.forEach( doc =>{
							let notes = extractNotes(doc);
							if( notes.length ) {
								machinesNotes.push({doc: doc.type, notes:notes});
							}
						});
					}
					if( machinesNotes.length ) {
						validated_machines.push({name:machine.category+' '+machine.manifacturer+' '+machine.model, docs: machinesNotes });
					}
				});
			} 
			
			if( ent.refsubstances ) {
				ent.refsubstances.forEach( substance =>{
					var substancesNotes = [];
					if( substance.documents && substance.documents.length ) {
						substance.documents.forEach( doc =>{
							let notes = extractNotes(doc);
							if( notes.length ) {
								substancesNotes.push({doc: doc.type, notes:notes});
							}
						});
					}
					if( substancesNotes.length ) {
						validated_substances.push({name:substance.name, docs: substancesNotes });
					}
				});
			} 
			
			var overall = { status: 1, message: "", enterprisedocs: validated_enterprisedocs, operatingdocs: validated_operatingdocs, employees:validated_employees,  temporaryworks: validated_temporaryworks, machines:validated_machines, substances: validated_substances  };
			return overall;
		}
	}
}]);
