angular.module('objectDetail', [])
.controller('ObjectCtrl',['$scope','$routeParams','$http','$location', '$uibModal',function($scope,$routeParams, $http,$location,$uibModal ) {
	$scope.loaded = false;
	$scope.object = null;
	$scope.get = function() {
		var getUrl = appURL+'/crud/'+$scope.type+'/';
		var params = '';
		
		if( $scope.getSelf ) {
			var queryUrl = $scope.getSelf($scope);
			getUrl = queryUrl.url;
			params = queryUrl.params;
		}
		if( $routeParams.id !== undefined && $routeParams.id ) {// edit
		    $http.get(getUrl+$routeParams.id+params).then(
			    function successCallback(response){
					$scope.object = response.data;
					if( $scope.decodeObject ) { $scope.decodeObject($scope, response.data); }
					$scope.loaded = true;
			    }, function errorCallback(response) {
			    	if( response.status==404 ) { $location.path('/404'); }
			    }
			);
		} else {
			$scope.loaded = true;
		}
	};
	$scope.$on('refreshobject', function(event, data) {
		$scope.get();
	});
	$scope.get();
}]);
