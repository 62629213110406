import './contacts-list/contacts-list.component.js';
import './contact-detail/contact-detail.component.js';
import './contact-edit/contact-edit.component.js';

angular.module('contacts', ['contactsList', 'contactDetail', 'contactEdit' ])
.config(['$routeProvider', function($routeProvider) {
	$routeProvider
		.when('/contatti', { template: '<contacts-list></contacts-list>' })
		.when('/contatti/nuovo', { template: '<contact-edit></contact-edit>' })
		.when('/contatti/:id', { template: '<contact-detail></contact-detail>' })
		.when('/contatti/:id/modifica', { template: '<contact-edit></contact-edit>' })
}]);
