import * as XLSX from 'xlsx/xlsx.mjs';

angular.module('createXlsxFile', [])
.factory('createXlsxFile', [ function () {
	return {
		createFromData : function(data) {
			var data = [
			  { name: "Barack Obama", pres: 44 },
			  { name: "Donald Trump", pres: 45 }
			];
			
			/* generate a worksheet */
			var ws = XLSX.utils.json_to_sheet(data);
			
			/* add to workbook */
			var wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "Presidents");
			
			/* write workbook and force a download */
			XLSX.writeFile(wb, "sheetjs.xlsx");

		}
	}
}]);