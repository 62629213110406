angular.module('createMailingList', [])
.factory('createMailingList', [ function () {
	return {
		createFromData : function(data) {
			var tmpUsers = {};
			
			var mailinglist = [];
			data.users.forEach(function(user) {
				tmpUsers[user.contactId]=user;
			});
			var allContacts = [];
			if( data.customers ) {
				data.customers.forEach(function(contact) {
					contact.role = "Committente";
				});
				allContacts = allContacts.concat(data.customers);
			}
			if( data.additionalcses ) {
				data.additionalcses.forEach(function(contact) {
					contact.role = "CSE";
				});
				allContacts = allContacts.concat(data.additionalcses);
			}
			if( data.surveytechnicians ) {
				data.surveytechnicians.forEach(function(contact) {
					contact.role = "Tecnico di sopralluogo";
				});
				allContacts = allContacts.concat(data.surveytechnicians);
			}
			if( data.actors ) {
				allContacts = allContacts.concat(data.actors);
			}
			if( data.otheractors ) {
				allContacts = allContacts.concat(data.otheractors);
			}
			
			if( data.worksmanager && data.worksmanager.contact && data.worksmanager.contact._id ) {
				data.worksmanager.role = "Responsabile dei lavori";
				allContacts.push(data.worksmanager);
			} 
			if( data.cse && data.cse.contact && data.cse.contact._id ) {
				data.cse.role = "CSE";
				allContacts.push(data.cse);
			} 
			// Insert new actors/customers/cse/workmanager if not present
			allContacts.forEach(function(contact) {
				if( contact && contact.contact && contact.contact._id && tmpUsers[contact.contact._id] && tmpUsers[contact.contact._id].status!=0) {
					var emails = [];
					contact.contact.emails.forEach(function(item) {
						if( item.email && item.email.length ) { emails.push({email:item.email, tag:item.tag, selected:true}); }
					});
					contact.contact.emails = emails;
					contact.selected = true;
					mailinglist.push(contact);
				}
			});
			// Insert new enterprises if not present
			if( data.enterprises ) {
				data.enterprises.forEach(function(enterprise) {
					if( enterprise && enterprise.contact && enterprise.contact._id && tmpUsers[enterprise.contact._id] && tmpUsers[enterprise.contact._id].status!=0 ) {
						var emails = [];
						enterprise.contact.emails.forEach(function(item) {
							if( item.email && item.email.length ) { emails.push({email:item.email, tag:item.tag, selected:true}); }
						});
						enterprise.contact.emails = emails;
						enterprise.selected = true;
						enterprise.role = enterprise.contact.iscompany ? "Impresa" : "Lavoratore autonomo";
						mailinglist.push(enterprise);
					}
				});
			}
			
			return mailinglist;			
		}
	}
}]);