import { jsPDF } from 'jspdf';

angular.module('worksiteMessages', [])
.directive('worksiteMessage',['$interpolate','$compile','$templateRequest', function($interpolate, $compile,$templateRequest)  {
    return {
		restrict: 'C',
		templateUrl: assetsURL+'components/app/worksites/worksite-messages/templates/worksite-message.template.html',
        scope: { message: '=', toggledetailaction: '&', index: '@', selectedindex: '=' },
		link: function (scope, elem, attrs) {
			scope.$watchGroup(['index', 'selectedindex'], function(val) {
				if( val === undefined ) { return; }
				scope.showdetail = (scope.index == scope.selectedindex);
			});
			scope.toggleShowDetail = function() {
				if( scope.toggledetailaction ) {
					scope.toggledetailaction()(scope.index);
				}
			};
			scope.downloadReceipt = function() {
				scope.sender = scope.message.sender.name + " &lt;" + scope.message.sender.email + "&gt; ";
				scope.recipients = "";
				scope.message.recipients.forEach(function(recipient) {
					scope.recipients += ("<div>"+ recipient.name + " &lt;" + recipient.email + "&gt; </div>");
				});
				
				const doc = new jsPDF();
				$templateRequest( assetsURL+'components/app/worksites/worksite-messages/templates/worksite-message-receipt.template.html').then(function(html) {
					var output1 = $compile(html)(scope);
					var output = $interpolate(html)(scope);
					setTimeout(function () { 
						scope.$apply( function() {
							console.log(output1);
							console.log(output);
							doc.html(output, {
								callback: function(doc) {
									doc.save(scope.message.timestamp+'-Email.pdf');
							    },
							    x: 15,
							    y: 15,
							    width: 170, //target width in the PDF document
							    windowWidth: 650 //window width in CSS pixels
							});
						});
					}, 100);
				});
			};
		}
	}
}])
.directive('worksiteMessagesList',[function()  {
    return {
		restrict: 'C',
		templateUrl: assetsURL+'components/app/worksites/worksite-messages/templates/worksite-messages-list.template.html',
        scope: { messages: '=' },
		link: function (scope, elem, attrs) {
			scope.selectedindex = -1;
			
			scope.toggleDetailView = function(index) {
				scope.selectedindex = scope.selectedindex == index ? -1 : index;
			};
		}
	}
}]);
