import * as pdfjs from 'pdfjs-dist/webpack';

/* subnavigation directive */
angular.module('widgets',[]).directive('ordercontactedit',['$http', function($http)  {
    return {
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/ordercontactedit.html?t='+RootSeed,
        scope: { object: '=' },
		link: function ($scope, elem, attrs) {
			$scope.getCities = function(val) {
				return $http.get(appURL+'/utils/cities'+( val ? '?search=' + encodeURIComponent(val) : '' ) ).then( function( res ) { return res.data; } );
			};

			$scope.autocompleteStateAndZipcode=function(address) {
				$http.get(appURL+'/utils/cities?search='+encodeURIComponent(address.city), {}).then(
					function successCalbback( response ) {
						if( response.data.length ) {
							var data = response.data;
							if( data[0].zipcode ) { address.zipcode=data[0].zipcode;}
							if( data[0].stateshort ) { address.state=data[0].stateshort;}
							if( data[0].region ) { address.region=data[0].region;}
							address.country = 'Italia';
						}
					}, function errorCallback( response ) {}
				);
			};
		}
	}
}]).directive('checknotice', [function () {
    return {
		scope: { check: '=' },
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/checknotice.html?t='+RootSeed,
		link: function (scope, elem, attrs) {}
	};
}]).directive('healthemergencynotice', [function () {
    return {
		scope: { description: '=' },
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/healthemergencynotice.html?t='+RootSeed,
		link: function (scope, elem, attrs) {}
	};
}]).directive('attacheddocsedit', ['models', function (models) {
    return {
		scope: { attacheddocs: '=', },
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/attacheddocsedit.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.attacheddocname = "";
			scope.removeAttachedDocument = function(index) {
				if( scope.attacheddocs[index].canDelete ) {
					scope.attacheddocs.splice(index, 1);
				}
			};
			scope.addAttachedDocument = function() {
				if( scope.attacheddocname.length ) {
					scope.attacheddocs.push( new models.AttachedDocument(true, scope.attacheddocname, true) );
					scope.attacheddocname = "";
				}
			};
		}
	};
}]).directive('allegeddocstoolbar', ['models', function (models) {
    return {
		scope: { viewtype: '=', objects: '=', enterprise: '@',  objtype: '@' },
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/allegeddocstoolbar.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.setViewtype = function(type) {
				scope.viewtype = type;
			}
		}
	};
}]).directive('allegeddocedit', ['$uibModal','$timeout','models', function ($uibModal, $timeout, models) {
    return {
		scope: { allegeddoc : '=',  height:'@', posturl:'@', cancelaction:'&', okaction:'&',  unsetaction:'&', index: '@',  viewtype: '=' },
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/allegeddocedit.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			if( scope.viewtype === undefined ) { scope.viewtype = 'grid';}
			scope.lastmodified = (new Date()).getTime();
        	scope.error = null;
        	scope.pageNum = 1;
			scope.$watchCollection('[allegeddoc, lastmodified, pageNum]', function(value) {
				if( scope.allegeddoc ) {
					if( scope.allegeddoc.images && scope.allegeddoc.images.length && scope.pageNum > 0 && (scope.pageNum-1)<scope.allegeddoc.images.length ) {
						var val = "url('"+ImageBucket + scope.allegeddoc.images[scope.pageNum-1] + (scope.lastmodified ? "?"+scope.lastmodified:'')+"')";
						$timeout( function() {
							$(elem.find(".allegeddocedit-thumbnail")[0]).css({
								'background-image': val,
								'background-size' : 'contain',
								'background-repeat' : 'no-repeat'
							});
						},1);
					}
				}
			});
        	
    		// Pick pdf
			scope.pickAllegedDoc = function(event) { 
				$timeout( function() { (elem.find("input:file"))[0].click(); },0 ); 
			};
			scope.unsetAllegedDoc = function() {
				if( scope.unsetaction() ) { 
					scope.unsetaction(scope.index); 
				} else {
					scope.allegeddoc=null;
				}
			};
  		  	scope.showPrevPage = function() {
				if( scope.pageNum <= 1) {
					return;
				}
				scope.pageNum--;
  		  	}
  		  	scope.showNextPage = function() {
	  		  	if( !scope.allegeddoc || !scope.allegeddoc.images ) { return; }
				if( scope.pageNum >= scope.allegeddoc.images.length ) {
					return;
				}
				scope.pageNum++;
  		  	}

			scope.showAllegedDoc = function() {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'directives/widgets/templates/allegeddocviewerModal.html?t='+RootSeed,
					controller: ['$scope', '$uibModalInstance', 'allegeddoc', function ($scope, $uibModalInstance, allegeddoc) {
						
						$scope.data = {	allegeddoc: allegeddoc };
						$scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
					}],
					
					resolve: {
						allegeddoc: function () { return scope.allegeddoc; }
					}
				});
				modalInstance.result.then(
					function successCallback() {}, function errorCallback() {}
				);
			};

			scope.previewAllegedDoc = function( file, filetype ) {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'directives/widgets/templates/allegeddoceditorModal.html?t='+RootSeed,
					controller: ['$scope', '$uibModalInstance', 'file', 'filetype', 'imagewidth', 'imageheight', 'posturl', function ($scope, $uibModalInstance, file, filetype, imagewidth, imageheight, posturl) {
						
						$scope.data = {	file: file,
										filetype : filetype,
										allegeddoc : {},
										imagewidth: imagewidth || 1200, 
										imageheight: imageheight || 1600,
										posturl: posturl || appURL+'/utils/images' };
						$scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						$scope.ok = function() {
							$scope.data.allegeddoc._id = models.clientId();
							$uibModalInstance.close($scope.data.allegeddoc);
							if( $scope.okaction && $scope.okaction() ) { $scope.okaction(); }
						};
					}],
					
					resolve: {
						file: function() { return file; },
						filetype: function() { return filetype; },
						imagewidth: function () { return scope.imagewidth; },
						imageheight: function () { return scope.imageheight; },
						posturl: function () { return scope.posturl; }
					}
				});
				modalInstance.result.then(
					function successCallback(allegeddoc) {
						if( scope.okaction && scope.okaction() ) { 
							scope.okaction()(allegeddoc, '');
						} else {
							scope.allegeddoc = allegeddoc;
							scope.lastmodified = (new Date()).getTime();
						}
					}, function errorCallback() {}
				);
			};

		  	scope.editCaption = function() {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'directives/imageeditor/templates/texteditorModal.html?t='+RootSeed,
					controller: ['$scope', '$uibModalInstance', 'text', function (scope, $uibModalInstance, text) {
						scope.data = {text: text};
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function() {
							$uibModalInstance.close(scope.data.text);
						};
					}],
					
					resolve: {
						text: function () { return scope.allegeddoc.caption; }
					}
				});
				modalInstance.result.then(
					function successCallback(text) {
						scope.allegeddoc.caption = text;
					}, function errorCallback() {}
				);
		  	};
			scope.loadAllegedDoc = function(event) {
				if( !event ) { return; }
	        	var file = null;
				if( event.dataTransfer ) { // drag and drop
		        	if( !event.dataTransfer.files ) return;
		        	if( !event.dataTransfer.files.length ) return;
					file = event.dataTransfer.files[0];
	        	} else if( event.target ) { // select file
		        	if( !event.target.files ) return;
		        	if( !event.target.files.length ) return;
					file = event.target.files[0];
	        	}
	        	
				if( file ) { // new selected or dropped file
					var filesize = ((file.size/1024)/1024).toFixed(1); // MB
					if( filesize > 10 ) {
						alert("Il documento ("+filesize+"Mb) non deve superare i 10Mb di dimensione.");
						return;
					}
					if( file.type=="image/jpeg" || file.type=="image/png" ) {
						var reader = new FileReader();
						reader.onloadend = function() {
							scope.previewAllegedDoc(reader.result, file.type);
						}
						scope.$apply(function () {
							reader.readAsDataURL(file);
						});
        			} else if( file.type=="application/pdf" ) {
						var reader = new FileReader();
						reader.onloadend = function() {
							scope.previewAllegedDoc(reader.result, file.type);
						}
						scope.$apply(function () {
							reader.readAsArrayBuffer(file);
						});
	        		} else {
						alert("Il documento dev'essere nel formato PDF, JPEG o PNG");
					}
				} 
			};

			// Bind change event to input file and drag&drop to drop area
			elem.find("input:file").bind('change', scope.loadAllegedDoc);
			var droptarget = (elem.find('.allegeddocedit-droparea'))[0];
			droptarget.addEventListener("dragover", function(e){
				e.preventDefault();
				elem.find('.allegeddocedit-droparea').addClass('dragover');
			}, true);
			droptarget.addEventListener("dragleave", function(e){
				e.preventDefault();
				elem.find('.allegeddocedit-droparea').removeClass('dragover');
			}, true);
			droptarget.addEventListener("drop", function(e){
				e.preventDefault();
				var files = e.dataTransfer.files||e.target.files;
				elem.find('.allegeddocedit-droparea').removeClass('dragover');
				if( files && files.length ) {
					scope.loadAllegedDoc(e);
				} 
			}, true);
		}
	};
}]).directive('allegeddocviewer', ['$http','$uibModal','$window','$timeout', function ($http, $uibModal, $window, $timeout) {
	return {
		scope: { allegeddoc : '=', cancelaction:'&' },
		restrict: 'AEC',
		templateUrl: assetsURL+'directives/widgets/templates/allegeddocviewer.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.spinwheelDiv = elem.find("#spinwheel");
			scope.lastmodified = (new Date()).getTime();
        	scope.pageNum = 1;
        	scope.loading = false;
			scope.$watchCollection('[allegeddoc, lastmodified, pageNum]', function(value) {
				if( scope.allegeddoc ) {
					if( scope.allegeddoc.images && scope.allegeddoc.images.length && scope.pageNum > 0 && (scope.pageNum-1)<scope.allegeddoc.images.length ) {
						var val = "url('"+ImageBucket + scope.allegeddoc.images[scope.pageNum-1] + (scope.lastmodified ? "?"+scope.lastmodified:'')+"')";
						$timeout( function() {
							$(elem.find(".allegeddocviewer-thumbnail")[0]).css({
								'background-image': val,
								'background-size' : 'contain',
								'background-repeat' : 'no-repeat'
							});
						},1);
					}
				}
			});
		  	var w = angular.element($window);
			scope.getWindowWidth = function () { return w.width(); };
			scope.recalculateSize = function() {
				var viewport = {}, 
					 toolbarAndMarginHeight = $(elem.find(".btn-toolbar")).height()+(30+40);
			   
				viewport.w = elem.innerWidth();
				viewport.h = $( window ).height() - toolbarAndMarginHeight /*toolbar*/;
				$(elem.find(".allegeddocviewer-thumbnail")[0]).css({
					'height': viewport.h+'px'
				});
			};
			scope.$watch(scope.getWindowWidth, function (newWidth, oldWidth) {
				scope.recalculateSize();
			});
			w.bind('resize', function () {
				scope.$apply();
			});	
			
	      scope.cancel = function() {
				if( scope.cancelaction && scope.cancelaction() ) { scope.cancelaction(); }
	      };
  		  	scope.showPrevPage = function() {
				if( scope.pageNum <= 1) {
					return;
				}
				scope.pageNum--;
  		  	}
  		  	scope.showNextPage = function() {
	  		  	if( !scope.allegeddoc || !scope.allegeddoc.images ) { return; }
				if( scope.pageNum >= scope.allegeddoc.images.length ) {
					return;
				}
				scope.pageNum++;
  		  	}
		}
	}
}]).directive('allegeddoceditor', ['$http','$uibModal','$window','$timeout', function ($http, $uibModal, $window, $timeout) {
	return {
		scope: { allegeddoc : '=', file : '=', filetype: '=', imagewidth: '@', imageheight:'@', posturl:'@', cancelaction:'&', okaction:'&' },
		restrict: 'AEC',
		templateUrl: assetsURL+'directives/widgets/templates/allegeddoceditor.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.spinwheelDiv = elem.find("#spinwheel");
			scope.pageRendering = false;
			scope.pageNumPending = null,
			scope.pageNum = 1;
			scope.pdfDoc = null;
	      	scope.canvas = null;
	      	scope.progress = 1;
	      	scope.uploading=false;
	      	scope.xhr = null;
			scope.error = undefined;
			scope.filetype = (!scope.filetype ? 'image/jpeg' : scope.filetype);
			scope.renderCanvas = null;
			
		  	scope.createCORSRequest = function() {
				var xhr = new XMLHttpRequest();
				if ("withCredentials" in xhr) {
					// Check if the XMLHttpRequest object has a "withCredentials" property.
					// "withCredentials" only exists on XMLHTTPRequest2 objects.
					return xhr;
				} else if (typeof XDomainRequest != "undefined") {
					// Otherwise, check if XDomainRequest.
					// XDomainRequest only exists in IE, and is IE's way of making CORS requests.
					xhr = new XDomainRequest();
					return xhr;
				} 
				return null;
			};
				  	
	      scope.cancel = function() {
		      if( scope.canvas ) {
					var ctx = scope.canvas.getContext("2d");
					ctx.clearRect(0,0,scope.canvas.width, scope.canvas.height);
				}
				scope.image = null;
				scope.xhr = null;
				scope.uploading=false;
				scope.error = undefined;
				if( scope.cancelaction && scope.cancelaction() ) { scope.cancelaction(); }
	      };
	      
	      scope.abort = function() {
	   		if( scope.xhr ) {
		      	scope.xhr.abort();
		      	scope.uploading=false;
					scope.progress = 1;
					scope.error = undefined;
		     	}
	   	};
	      	
		  	var w = angular.element($window);
			scope.getWindowWidth = function () { return w.width(); };
			scope.recalculateCanvasSize = function() {
				var viewport = {}, 
					 toolbarAndMarginHeight = $(elem.find(".btn-toolbar")).height()+(30+40);
			   
				viewport.w = elem.innerWidth();
				viewport.h = $( window ).height() - toolbarAndMarginHeight /*toolbar*/;
	
				scope.canvas.width = viewport.w;
				scope.canvas.height = viewport.h;
			};
			scope.$watch(scope.getWindowWidth, function (newWidth, oldWidth) {
				if( scope.canvas ) {
					scope.recalculateCanvasSize();
					scope.updateCanvas();
				}
			});
			w.bind('resize', function () {
				scope.$apply();
			});	
	      scope.resizeImage = function() {
				scope.renderCanvas = document.createElement('canvas');
				var widthRatio = scope.imageDoc.width / scope.imageWidth;
				var heightRatio = scope.imageDoc.height / scope.imageHeight;
				if( widthRatio > 1 || heightRatio > 1 ) {
					var scale = Math.max(widthRatio, heightRatio);
					scope.renderCanvas.width = scope.imageDoc.width / scale;
					scope.renderCanvas.height = scope.imageDoc.height / scale;
				} else {
					scope.renderCanvas.width = scope.imageDoc.width;
					scope.renderCanvas.height = scope.imageDoc.height;
				}
				var ctx = scope.renderCanvas.getContext('2d');
				ctx.drawImage(scope.imageDoc, 0, 0, scope.renderCanvas.width, scope.renderCanvas.height );
				var data = scope.dataURItoBlob(scope.renderCanvas.toDataURL(scope.filetype, 0.5));
				ctx.clearRect(0,0,scope.renderCanvas.width, scope.renderCanvas.height);
				return ({data: data, width: scope.renderCanvas.width, height: scope.renderCanvas.height});
			};
	      scope.dataURItoBlob = function(dataURI) {
				var	byteString, 
						mimestring 
				
				if(dataURI.split(',')[0].indexOf('base64') !== -1 ) {
					byteString = atob(dataURI.split(',')[1])
				} else {
					byteString = decodeURI(dataURI.split(',')[1])
				}
				
				mimestring = dataURI.split(',')[0].split(':')[1].split(';')[0]
				
				var content = new Array();
				for (var i = 0; i < byteString.length; i++) {
					content[i] = byteString.charCodeAt(i)
				}
				
				return new Blob([new Uint8Array(content)], {type: mimestring});
			};
	      	
			scope.awsImagePoster = function(signedurl, filetype, imagedata) {
				var awsxhr = scope.createCORSRequest();
				awsxhr.upload.addEventListener("progress", scope.uploadProgress, false);
				awsxhr.addEventListener("load", scope.uploadComplete, false);
				awsxhr.addEventListener("error", scope.uploadFailed, false);
				awsxhr.open('PUT', signedurl, true);
				awsxhr.setRequestHeader('Content-Type', (filetype == 'application/pdf'?'image.jpeg':filetype));
				scope.xhr = awsxhr;
				awsxhr.send(imagedata);
			};
			scope.ok = function(event) {
				$timeout(function () { scope.$apply( function() { scope.uploading = true;})}, 1);			
				scope.uploadresponse = {};
				
				var uploadsCompleted = function(err) {
					if( err ) {
						scope.uploadresponse = {};
						$timeout(function () { scope.$apply( function() {
							scope.error=undefined;
							scope.uploading=undefined;
						})}, 5000 );
						return;
					} else {
						$timeout(function () { scope.$apply( function() {
							if( !scope.allegeddoc ) { scope.allegeddoc = {}; }  
							if( !scope.allegeddoc.images ) {
								scope.allegeddoc.caption = "";
								scope.allegeddoc.images = [];
								scope.allegeddoc.isattached = true;
							}
							scope.allegeddoc.images.push(scope.uploadresponse.id);
							scope.uploading=false;
							scope.uploadresponse = {};
							
							if( scope.okaction && scope.okaction() ) {
								scope.okaction();
							}
						}, 1);});
					}
				};
				var uploadNextPage = function(err) {
					if( err ) {
						scope.uploadresponse = {};
						$timeout(function () { scope.$apply( function() {
							scope.error=undefined;
							scope.uploading=undefined;
							scope.pageNumPending == null;
						})}, 5000 );
						return;
					} else {
						$timeout(function () { scope.$apply( function() {
							if( !scope.allegeddoc ) { scope.allegeddoc = {}; }  
							if( !scope.allegeddoc.images ) {
								scope.allegeddoc.caption = "";
								scope.allegeddoc.images = [];
								scope.allegeddoc.isattached = true;
							}
							scope.allegeddoc.images.push(scope.uploadresponse.id);
							scope.uploadresponse = {};
							
							if( scope.pageNumPending == scope.pdfDoc.numPages ) {
								scope.pageNumPending = null;
								scope.uploading=false;
								if( scope.okaction && scope.okaction() ) {
									scope.okaction();
								}
							} else {
								scope.pageNumPending++;
								getPDFPage(scope.pageNumPending, renderPDFPage );
							}
						}, 1);});
					}
				};

				var getPDFPage = function( pageNum, callback ) {
					scope.pageRendering = true;
					scope.pdfDoc.getPage(pageNum).then( renderPDFPage );
				};
				var renderPDFPage = function( page ) {
					scope.renderCanvas = document.createElement('canvas');
					var viewport = page.getViewport({scale:1.5});
					var widthRatio = viewport.width / scope.imageWidth;
					var heightRatio = viewport.height / scope.imageHeight;
					if( widthRatio > 1 || heightRatio > 1 ) {
						var scale = Math.max(widthRatio, heightRatio);
						scope.renderCanvas.width = viewport.width / scale;
						scope.renderCanvas.height = viewport.height / scale;
					} else {
						scope.renderCanvas.width = viewport.width;
						scope.renderCanvas.height = viewport.height;
					}
					var renderContext = {
						canvasContext: scope.renderCanvas.getContext('2d'),
						viewport: viewport
					};
					var renderTask = page.render(renderContext);
					renderTask.promise.then(loadPDFPage);
				}
				
				var loadPDFPage = function() {
					scope.pageRendering = false;
					var data = scope.dataURItoBlob(scope.renderCanvas.toDataURL('image/jpeg', 0.7));
					var ctx = scope.renderCanvas.getContext('2d');
					ctx.clearRect(0,0,scope.renderCanvas.width, scope.renderCanvas.height);
					var imageData = {data: data, width: scope.renderCanvas.width, height: scope.renderCanvas.height};
					scope.uploadImageData(imageData, uploadNextPage);
				};
				if( scope.imageDoc ) {
					var imageData = scope.resizeImage(); 
					scope.uploadImageData(imageData, uploadsCompleted);
				} else if( scope.pdfDoc ) {
					scope.pageNumPending = 1;
					getPDFPage(scope.pageNumPending, renderPDFPage );
				}
			};
			
			scope.uploadImageData = function( imageData, callback ) {
				var fd = new FormData();
				fd.append('image', JSON.stringify({width:imageData.width, height:imageData.height, type: (scope.filetype == 'application/pdf'?'image/jpeg':scope.filetype) }));
				var xhr = new XMLHttpRequest();
				xhr.open("POST", scope.posturl);
				xhr.onreadystatechange = function() {
					if(xhr.readyState === 4) {
						if(xhr.status === 200) {
							scope.uploadresponse = JSON.parse(xhr.responseText);
							if( !scope.uploadresponse.files || !scope.uploadresponse.files.length ) {
								scope.uploadresponse = {};
								callback("Errore generico. Riprova.")
							} else {
								scope.uploadresponse.totalsize = imageData.data.size;
								scope.uploadresponse.id = scope.uploadresponse.files[0]._id;
								scope.uploadresponse.callback = callback;
								scope.awsImagePoster(scope.uploadresponse.files[0].signedurl, scope.uploadresponse.files[0].filetype, imageData.data );
							}
						} else {
							scope.uploadresponse = {};
							callback("Errore generico. Riprova.")
						}
					}
				};
				xhr.send(fd);
			}
			
			scope.uploadProgress = function(evt) {
				if (evt.lengthComputable) {
					scope.$apply(function () {
						scope.progress = Math.round(evt.loaded * 100 / scope.uploadresponse.totalsize );
						(elem.find($("div[data-role='progressbar']"))[0]).style.width = scope.progress+"%";
					});
				}
			};
			scope.uploadComplete = function(evt) {
				scope.$apply( function() {
					if( scope.xhr.status !=200 ) {
						scope.uploadresponse.callback("Errore generico. Riprova.");
					} else {
						scope.progress = 100;
						scope.uploadresponse.callback();
					}
				});
			};
			
			scope.uploadFailed = function(evt) {
				scope.$apply( function() {
					$http.post(appURL+'/utils/images', { deleteid: scope.uploadresponse.id} ).then( 
						function successCallback(data) {
							scope.uploadresponse.callback("Errore generico. Riprova.");
						}, function errorCallback(response) {
						}
					);
				});
			};
			
			scope.renderPage = function(num) {
				scope.pageRendering = true;
				scope.pdfDoc.getPage(num).then( function( page ) {
					var viewport = page.getViewport({scale:1.0} );
					var imageRatio = viewport.height/viewport.width;
					var canvasRatio = scope.canvas.height/scope.canvas.width;
					var scale = 1.0;
					
					if( imageRatio >= canvasRatio ) {
						scale = scope.canvas.width / viewport.width;
					} else {
						scale = scope.canvas.height / viewport.height;
					}
					/*
					scope.canvas.height = scope.height;
					scope.canvas.width = viewport.width*scope.height/viewport.height;
					*/
					viewport = page.getViewport({scale:scale} );
					// Render PDF page into canvas context
					var renderContext = {
						canvasContext: scope.canvas.getContext('2d'),
						viewport: viewport
					};
					var renderTask = page.render(renderContext);
					
					// Wait for rendering to finish
					renderTask.promise.then(function() {
						scope.pageRendering = false;
						if (scope.pageNumPending !== null) {
						// New page rendering is pending
							scope.renderPage(scope.pageNumPending);
							scope.pageNumPending = null;
						}
					});
				});
  		  	};
  		  	scope.queueRenderPage = function( num ) {
				if( scope.pageRendering ) {
					scope.pageNumPending = num;
				} else {
					scope.renderPage(num);
				}
  		  	};
  		  	scope.showPrevPage = function() {
				if( scope.pageNum <= 1) {
					return;
				}
				scope.pageNum--;
				scope.queueRenderPage(scope.pageNum);
  		  	}
  		  	scope.showNextPage = function() {
				if( scope.pageNum >= scope.pdfDoc.numPages ) {
					return;
				}
				scope.pageNum++;
				scope.queueRenderPage(scope.pageNum);
  		  	}
  		  	
			scope.updateCanvas = function() {
				if( !scope.canvas ) { return; }
				if( scope.imageDoc ) {
					var ctx = scope.canvas.getContext('2d');
					var imageRatio = scope.imageDoc.height/scope.imageDoc.width;
					var canvasRatio = scope.canvas.height/scope.canvas.width;
					var dWidth, dHeight;
					
					if( imageRatio >= canvasRatio ) {
						dWidth = scope.imageDoc.width/scope.imageDoc.height*scope.canvas.height;
						dHeight = scope.canvas.height;
					} else {
						dWidth = scope.canvas.width;
						dHeight = scope.imageDoc.height/scope.imageDoc.width*scope.canvas.width;
					}
					var dx = 0.5*(scope.canvas.width-dWidth);
					var dy = 0.5*(scope.canvas.height-dHeight);
					
					ctx.drawImage(scope.imageDoc, dx, dy, dWidth, dHeight );
				} else if( scope.pdfDoc ) {
					scope.renderPage(scope.pageNum);
				}
			}
  		  	/* Init */
			if( scope.canvas == null ) {
				scope.canvas = elem.find('.drawcanvas')[0];
			} 
			scope.recalculateCanvasSize();
			if( scope.filetype == 'image/jpeg' || scope.filetype == 'image/png' ) {
				scope.spinwheelDiv.css({opacity:1.0});
				
			   scope.imageDoc = new Image();
			    
			   scope.imageDoc.onload = function() {
					scope.updateCanvas();
					scope.spinwheelDiv.css({opacity:0.0, 'z-index':-1000});
			   };
			   scope.imageDoc.src = scope.file;
			} else if( scope.filetype == 'application/pdf' ) {
				const loadingTask = pdfjs.getDocument(scope.file);
				loadingTask.promise.then(pdf => {
					scope.$apply(function() {
						scope.pdfDoc = pdf;
						scope.updateCanvas();
						scope.spinwheelDiv.css({opacity:0.0, 'z-index':-1000});
					});
				}, error => {
					scope.$apply(function() {
						scope.spinwheelDiv.css({opacity:0.0, 'z-index':-1000});
						scope.error = "Impossibile caricare PDF. Verifica che il file non sia corrotto.";
					});
				});
			}
		}
	}
}]).directive('allegeddocsedit', ['ngDraggable', function (ngDraggable) {
    return {
		scope: { allegeddocs: '=',  viewtype: '=' },
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/allegeddocsedit.html?t='+RootSeed,
		link: function (scope, elem, attrs) {	
			
			if( scope.viewtype === undefined ) { scope.viewtype='grid';}
			scope.unsetAllegedDoc= function(index) {
				if( index >= 0 && index < scope.allegeddocs.length ) {
					scope.allegeddocs.splice(index,1);
				}
			};
			scope.addNewAllegedDoc = function(allegeddoc) {
				if( !scope.allegeddocs ) { scope.allegeddocs=[];}
				allegeddoc.caption = "";
				scope.allegeddocs.push(allegeddoc);
			};
         scope.onDropComplete = function (index, obj, evt) {
             var otherObj = scope.allegeddocs[index];
             var otherIndex = scope.allegeddocs.indexOf(obj);
             scope.allegeddocs[index] = obj;
             scope.allegeddocs[otherIndex] = otherObj;
         }
		}
	};
}]).directive('workpicker',['$uibModal','$http', function ($uibModal, $http) {
	return {
		transclude: true,
		scope: { worksheet: '@', work:'='},
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/workpicker.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.pickWork = function() {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'directives/widgets/templates/workpickerModal.html?t='+RootSeed,
					controller: ['$scope', '$uibModalInstance','work', function ($scope, $uibModalInstance, work) {
						$scope.objects=[];
						$scope.loaded= false;
						$scope.categories = [];
						$scope.data = { selectedwork:work };
						$scope.togglecategory = function(category) {
							category.open = !category.open;
							if( !category.open ) { return; }
							if( category.objects.length ) { return; }
							category.loading = true
						    $http.get(appURL+'/crud/works/?category='+category._id).then( 
							    function successCallback(response) {
								    category.loading = false
							    	category.objects = response.data;
								},  function errorCallback(response) {
									category.objects = [];
								    category.loading = false;
							    	alert("Errore temporaneo. Riprova.");
								}
							);
						};
					    $scope.loadCategories = function() {
							$http.get(appURL+'/crud/workscategories').then( 
								function successCallback(response) {
									var data=response.data;
									$scope.loaded = true;
									if( data.length == 0 ) {
										alert("Nessuna categoria trovata");
										return;
									}
									
									$scope.categories = data;
									var idx = 0;
									for( var idx=0;idx<$scope.categories.length;idx++ ) {
										var c = $scope.categories[idx];
										c.open = false;
										c.loading = false;
										c.objects = [];
									}
								}, function errorCallback(response) {
								    $scope.loaded = true;
							    	alert("Errore temporaneo. Riprova.");
								}
							);
						}
					    
					    $scope.loadCategories();
					    
						$scope.cancel = function() {
							$uibModalInstance.close();
						};
						$scope.ok = function() {
							$uibModalInstance.close({_id:$scope.data.selectedwork});
						};
					}],
					resolve: { work : function() { return scope.work;} }
				});
				modalInstance.result.then(
					function successCallback(data) {
						if( data === undefined ) { return; }
						if( data._id !== undefined && data._id !== null ) {
							scope.work = data._id;
						}
					},
					function errorCallback() {}
				);
			};
		}
	}
}]).directive('subnavigation', ['$rootScope',function ($rootScope) {
    return {
		scope: { sections:'=', currentindex:'='},
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/subnavigation.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.previousSection = function() {
				scope.currentindex -= ( scope.currentindex ? 1 : 0);
			}
			scope.nextSection = function() {
				scope.currentindex += ( scope.currentindex < (scope.sections.length-1)? 1 : 0);
			}
			scope.setCurrentIndex = function(idx) {
				scope.currentindex = idx;
			}
		}
	}
}]).directive('ordernavigation', ['$rootScope','warningService', function ($rootScope, warningService ) {
    return {
		scope: { sections:'=', currentindex:'='},
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/ordernavigation.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
		}
	}
}]).directive('worksedit',['models','$uibModal','worksheetService', function (models, $uibModal, worksheetService) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/worksedit.html?t='+RootSeed,
        scope: { zone: '=', options: '=?', contractors : '&?', specifystartdate:'=', startdate:'=', hideaddbutton:'=?' },
		link: function (scope, elem, attrs) {
			scope.options = angular.isDefined(scope.options) ? scope.options : new models.CalendarOptions(models.POSCALENDAR);
			scope.contractors = angular.isDefined(scope.contractors) ? scope.contractors : [];
			scope.deleteWork = function(work) {
				if( confirm('Sei sicuro di voler cancellare questa macrofase? Non potrai tornare indietro una volta eliminata')) {
					var workIndex = scope.zone.works.indexOf(work);
					if( workIndex>=0 ) {
						/*if( scope.zone.works.length == 1 ) {
							alert("Non puoi cancellare questa macrofase. Ogni zona deve averne almeno una.")
						} else */{
							scope.zone.works.splice(workIndex,1);
						}
					}
				}				
			};
			scope.moveWork = function(work, delta) {
				if( !scope.zone.works ) { return; }
				var idx = scope.zone.works.indexOf(work);
				var newIdx = idx+delta;
				if( idx == -1 ) { return; }
				if( (delta<0 && idx>0) || (delta>0 && idx<(scope.zone.works.length-1 ))) {
					var tmp = work;
					scope.zone.works.splice(idx,1);
					scope.zone.works.splice(newIdx,0,tmp);
				}
			};
			scope.moveSubwork = function(work, subwork, delta) {
				if( !work.subworks ) { return; }
				var idx = work.subworks.indexOf(subwork);
				var newIdx = idx+delta;
				if( idx == -1 ) { return; }
				if( (delta<0 && idx>0) || (delta>0 && idx<(work.subworks.length-1 ))) {
					var tmp = subwork;
					work.subworks.splice(idx,1);
					work.subworks.splice(newIdx,0,tmp);
				}
			};
			scope.changeWork = function(work, subwork, delta) {
				if( !scope.zone.works ) { return; }
				var idx = scope.zone.works.indexOf(work);
				var newIdx = idx+delta;
				if( idx == -1 ) { return; }
				if( (delta<0 && idx>0) || (delta>0 && idx<(scope.zone.works.length-1 ))) {
					var tmp = subwork;
					var subIdx = work.subworks.indexOf(subwork);
					work.subworks.splice(subIdx,1);
					scope.zone.works[newIdx].subworks.push(subwork);
				}
			};
			scope.deleteSubwork = function(work, subwork) {
				if( confirm('Sei sicuro di voler cancellare questa sottofase? Non potrai tornare indietro una volta eliminata')) {
					var subworkIndex = work.subworks.indexOf(subwork);
					if( subworkIndex>=0 ) {
						work.subworks.splice(subworkIndex,1);
					}
				}				
			};
			scope.copyWork = function(work) {
				var workIndex = scope.zone.works.indexOf(work);
				if( workIndex>=0 ) {
					var newWork = angular.copy(work);
					scope.zone.works.push(newWork);
				}
			};
			scope.copySubwork = function(work, subwork) {
				var subworkIndex = work.subworks.indexOf(subwork);
				if( subworkIndex>=0 ) {
					var newSubwork = angular.copy(subwork);
					newSubwork.visible = scope.visibility();
					work.subworks.push(newSubwork);
				}
			};
			scope.addNewWork = function() {
				scope.editWork(null);
			};
			scope.addMacroWorksFromArchive = function(object) {
				if( object.macroworks ) {
					object.macroworks.forEach( obj => {
						if( obj.works ) {
							obj.works.forEach( work => {
								work.visible = scope.visibility();
								work.subworks.forEach( subwork => {
									subwork.visible = scope.visibility();
								});
								var newWork = angular.copy(work);
								scope.zone.works.push(newWork);
							});
						}
					});
				}
			};
			scope.addNewSubwork = function(work) {
				scope.editSubwork(work);	
			};
			scope.editWork = function(work) {
				var contractors = angular.copy(scope.contractors());
				if( !contractors ) { contractors = [];}
				var startday = 1;
				if( scope.zone.works.length>0 ) { 
					var prevWork = scope.zone.works[scope.zone.works.length-1];
					var lastTimerange;
					if( prevWork.subworks.length ) {
						var lastSubwork = prevWork.subworks[prevWork.subworks.length-1];
						lastTimerange = lastSubwork.timeranges[lastSubwork.timeranges.length-1];
					} else {
						lastTimerange = prevWork.timeranges[prevWork.timeranges.length-1];
					}
					startday = lastTimerange.startDay+lastTimerange.duration;
				}
				var modalInstance = $uibModal.open({
					templateUrl: 'workeditModal.html',
					controller: ['$scope', '$uibModalInstance', 'work','contractors','startday', 'startdate', function (scope, $uibModalInstance, work, contractors, startday, startdate ) {
						scope.startdate = startdate;
						scope.data = {};
						scope.contractors = contractors;
						scope.wks = worksheetService;
						scope.isNew = (work ? false : true);
						scope.hasNoSubworksAnd1Timerange = (!(work && work.subworks && work.subworks.length) && work && work.timeranges && work.timeranges.length==1) || !work;
						if( scope.hasNoSubworksAnd1Timerange && startdate ) {
							if( work && work.timeranges && work.timeranges.length && work.timeranges[0].startDay ) {
								var newdate = new Date(startdate.valueOf()+864E5*(work.timeranges[0].startDay-1));
								scope.data.startdate = newdate;
							} else {
								var newdate = new Date(startdate.valueOf()+864E5*(startday-1));
								scope.data.startdate = newdate;
							}
							scope.$watch('data.startdate', function(newval) {
								if( newval === undefined ) { return; }
								var tm1 = scope.data.startdate.getTime();
								var tm2 = startdate.getTime();
								var diff_days = Math.round((tm1-tm2)/(864E5));
								if( diff_days < 0 ) {
									scope.data.startdate = new Date(startdate);
									scope.data.startday = 1;
								} else {
									scope.data.startday = diff_days+1;
								}
							});
							scope.$watch('data.startday', function(newval) {
								if( newval === undefined ) { return; }
								scope.data.startdate = new Date(startdate.valueOf()+864E5*(scope.data.startday-1));
							});
						}
						
						scope.type = "work";
						scope.data.workcategory = (work && work.worksheet && work.worksheet.catid ? {_id:work.worksheet.catid} : null);
						scope.data.work = (work && work.worksheet && work.worksheet.workid ? {_id:work.worksheet.workid} : null);
						scope.data.worksheet = (work && work.worksheet && work.worksheet.id ? {_id:work.worksheet.id} : null);
						scope.data.worksheetdata = (work && work.worksheet && work.worksheet.data ? work.worksheet.data : null);
						scope.data.name = work ? work.name : '';
						scope.data.color = work ? work.color : '100,179,223';
						scope.data.menatwork = work ? work.menatwork : 1;
						scope.data.duration = (work && work.timeranges && work.timeranges.length==1) ? work.timeranges[0].duration : 1;
						scope.data.startday = (work && work.timeranges && work.timeranges.length==1) ? work.timeranges[0].startDay : startday;
						scope.data.contractor = (work && work.contractor && work.contractor.name && work.contractor.name.length) ?  work.contractor.name : 'Appalto da definire';
						scope.ok = function () {
							$uibModalInstance.close(scope.data);
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.data.isSettingUp = true;
						worksheetService.getWorkscategories(scope.data);
					}],
					resolve: {work: function() { return work;}, contractors: function() { return contractors;}, startday: function() { return startday; }, startdate: function() { return (scope.specifystartdate && scope.startdate)?scope.startdate:null;} }
				});
				modalInstance.result.then(
					function successCallback(data) {
						if( !scope.zone.works ) { scope.zone.works=[];}
						if( work ) {
							work.name = data.name;
							work.color = data.color;
							work.menatwork = data.menatwork;
							if( work.timeranges && work.timeranges.length==1 ) {
								work.timeranges[0].duration = data.duration;
								work.timeranges[0].startDay = data.startday;
							}
							work.contractor = { name: data.contractor };
							// Check if data was already present. In this case don't change it!
							var worksheetdata = data.worksheetdata;
							if( data.workcategory && data.workcategory._id && data.work && data.work._id && data.worksheet && data.worksheet._id ) {
								worksheetdata = ((work.worksheet.catid === data.workcategory._id) && (work.worksheet.workid === data.work._id) && (work.worksheet.id === data.worksheet._id))?data.worksheetdata:((data.worksheet && data.worksheet.data)?data.worksheet.data:null);
							}
							if( worksheetdata !== null ) {
								//if( worksheetdata.workname === undefined ) {
									worksheetdata.workname = work.name;
								//}
								delete(worksheetdata._id);
								delete(worksheetdata.work);
							}
							work.worksheet = (data.workcategory && data.workcategory._id && data.work && data.work._id && data.worksheet && data.worksheet._id) ? { catid: data.workcategory._id, workid:data.work._id, id: data.worksheet._id, data: worksheetdata} : {catid:null, workid:null, id:null, data:worksheetdata};
						} else {
							/*
							var startday = 1;
							if( scope.zone.works.length>0 ) { 
								var prevWork = scope.zone.works[scope.zone.works.length-1];
								var lastTimerange;
								if( prevWork.subworks.length ) {
									var lastSubwork = prevWork.subworks[prevWork.subworks.length-1];
									lastTimerange = lastSubwork.timeranges[lastSubwork.timeranges.length-1];
								} else {
									lastTimerange = prevWork.timeranges[prevWork.timeranges.length-1];
								}
								startday = lastTimerange.startDay+lastTimerange.duration;
							}
							*/
							var worksheetdata = (data.worksheet && data.worksheet.data) ? data.worksheet.data : null;
							if( worksheetdata !== null ) {
								worksheetdata.workname = data.name;
								delete(worksheetdata._id);
								delete(worksheetdata.work);
							}
	
							var worksheet = (data.workcategory && data.workcategory._id && data.work && data.work._id && data.worksheet && data.worksheet._id) ? { catid: data.workcategory._id, workid:data.work._id, id: data.worksheet._id, data: worksheetdata} : {catid:null, workid:null, id:null, data:null};
							var newWork = new models.Work(data.name, 1, [new models.Timerange(data.startday,data.duration)], {name:data.contractor}, data.color, worksheet, scope.visibility());
							scope.zone.works.push(newWork);
						}
					}, function errorCallback() {}
				);
			};
			scope.editSubwork = function(work, subwork) {
				var contractors = angular.copy(scope.contractors());
				if( !contractors ) { contractors = [];}
				if( subwork ) {
					if( subwork.contractor && subwork.contractor.name ) {
						/*if( !(contractors.filter(function(c) {
							return c.fullname == subwork.contractor.name;
						}).length) ) {
							contractors.push({fullname:subwork.contractor.name});
						}*/
					}
				}
				var startday = (work.subworks.length ? work.subworks[0].timeranges[0].startDay : work.timeranges[0].startDay);
				if( work.subworks.length>0 ) { 
					var prevSubwork = work.subworks[work.subworks.length-1];
					startday = prevSubwork.timeranges[0].startDay+prevSubwork.timeranges[0].duration;
				}
				
				var modalInstance = $uibModal.open({
					templateUrl: 'workeditModal.html',
					controller: ['$scope', '$uibModalInstance', 'subwork','contractors','startday','startdate', function (scope, $uibModalInstance, subwork, contractors,startday,startdate ) {
						scope.contractors = contractors;
						scope.wks = worksheetService;
						scope.type = "subwork";
						scope.isNew = (subwork ? false : true);
						scope.hasNoSubworksAnd1Timerange = (scope.isNew || (subwork && subwork.timeranges && subwork.timeranges.length==1));
						scope.data = {};
						if( scope.hasNoSubworksAnd1Timerange && startdate ) {
							if( subwork && subwork.timeranges && subwork.timeranges.length && subwork.timeranges[0].startDay ) {
								var newdate = new Date(startdate.valueOf()+864E5*(subwork.timeranges[0].startDay-1));
								scope.data.startdate = newdate;
							} else {
								var newdate = new Date(startdate.valueOf()+864E5*(startday-1));
								scope.data.startdate = newdate;
							}
							scope.$watch('data.startdate', function(newval) {
								if( newval === undefined ) { return; }
								var tm1 = scope.data.startdate.getTime();
								var tm2 = startdate.getTime();
								var diff_days = Math.round((tm1-tm2)/(864E5));
								if( diff_days < 0 ) {
									scope.data.startdate = new Date(startdate);
									scope.data.startday = 1;
								} else {
									scope.data.startday = diff_days+1;
								}
							});
							scope.$watch('data.startday', function(newval) {
								if( newval === undefined ) { return; }
								scope.data.startdate = new Date(startdate.valueOf()+864E5*(scope.data.startday-1));
							});
						}
						scope.data.name = subwork ? subwork.name : '';
						scope.data.menatwork = subwork ? subwork.menatwork : 1;
						scope.data.workcategory = (subwork && subwork.worksheet && subwork.worksheet.catid ? {_id: subwork.worksheet.catid } : null);
						scope.data.work = (subwork && subwork.worksheet && subwork.worksheet.workid ? {_id: subwork.worksheet.workid } : null);
						scope.data.worksheet = (subwork && subwork.worksheet && subwork.worksheet.id ? {_id: subwork.worksheet.id } : null);
						scope.data.worksheetdata = (subwork && subwork.worksheet && subwork.worksheet.data ? subwork.worksheet.data : null);
						scope.data.duration = (subwork && subwork.timeranges && subwork.timeranges.length==1) ? subwork.timeranges[0].duration : 1;
						scope.data.startday = (subwork && subwork.timeranges && subwork.timeranges.length==1) ? subwork.timeranges[0].startDay : 1;
						scope.data.contractor = (subwork && subwork.contractor && subwork.contractor.name) ?  subwork.contractor.name : 'Appalto da definire';
						scope.ok = function () {
							$uibModalInstance.close(scope.data);
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.data.isSettingUp = true;
						worksheetService.getWorkscategories(scope.data);
					}],
					resolve: {subwork: function() { return subwork;}, contractors: function() { return contractors;}, startday: function() { return startday; }, startdate: function() { return (scope.specifystartdate && scope.startdate)?scope.startdate:null;} }
				});
				modalInstance.result.then(
					function successCallback(data) {
						if( !work.subworks ) { work.subworks=[];}
						if( subwork ) {
							subwork.name = data.name;
							subwork.menatwork = data.menatwork;
							subwork.contractor = { name: data.contractor };
							if( subwork.timeranges && subwork.timeranges.length == 1 ) {
								subwork.timeranges[0].duration = data.duration;
								subwork.timeranges[0].startDay = data.startday;
							}
							// Check if data was already present. In this case don't change it!
							var worksheetdata = data.worksheetdata;
							if( data.workcategory && data.workcategory._id && data.work && data.work._id && data.worksheet && data.worksheet._id ) {
								worksheetdata = ((subwork.worksheet.catid === data.workcategory._id) && (subwork.worksheet.workid === data.work._id) && (subwork.worksheet.id === data.worksheet._id))?data.worksheetdata:((data.worksheet && data.worksheet.data)?data.worksheet.data:null);
							}
							if( worksheetdata !== null ) {
								//if( worksheetdata.workname === undefined ) {
									worksheetdata.workname = subwork.name;
								//}
								delete(worksheetdata._id);
								delete(worksheetdata.work);
							}
							subwork.worksheet = (data.workcategory && data.workcategory._id && data.work && data.work._id && data.worksheet && data.worksheet._id) ? { catid: data.workcategory._id, workid:data.work._id, id: data.worksheet._id, data: worksheetdata} : {catid:null, workid:null, id:null, data: data.worksheetdata };
						} else {
							/*
							var startday = (work.subworks.length ? work.subworks[0].timeranges[0].startDay : work.timeranges[0].startDay);
							if( work.subworks.length>0 ) { 
								var prevSubwork = work.subworks[work.subworks.length-1];
								startday = prevSubwork.timeranges[0].startDay+prevSubwork.timeranges[0].duration;
							}
							*/
							var worksheetdata = (data.worksheet && data.worksheet.data) ? data.worksheet.data : null;
							if( worksheetdata !== null ) {
								worksheetdata.workname = data.name;
								delete(worksheetdata._id);
								delete(worksheetdata.work);
							}
							var worksheet = (data.workcategory && data.workcategory._id && data.work && data.work._id && data.worksheet && data.worksheet._id) ? { catid: data.workcategory._id, workid:data.work._id, id: data.worksheet._id, data: worksheetdata} : {catid:null, workid:null, id:null, data: null };
							var newSubwork = new models.Subwork(data.name, 1, [new models.Timerange(data.startday, data.duration)], {name:data.contractor}, worksheet, scope.visibility());
							work.subworks.push(newSubwork);
						}
					}, function errorCallback() {}
				);
			};
			scope.visibility = function() {
				var obj = {};
				obj[scope.options._id] = true;
				return obj;
			};
			if( !scope.options._id ) { scope.options._id = models.clientId();} // Update hash for options
		}
	}
}]).directive('faqs',['$http', function ($http) {
    return {
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/faqs.html?t='+RootSeed,
        scope: {},
		link: function (scope, elem, attrs) {
			scope.faqcategories = [];
			scope.loadCategories = function() {
				$http.get( appURL+'/crud/faqscategories').then( 
					function successCallback( response ) {
						var faqcategories =response.data;
						faqcategories.forEach(function(category) {
							category.faqs = [];
							category.open = false;
						})
						scope.faqcategories = faqcategories;
					}, function errorCallback(response) {
						alert('Si è verificato un errore. Si prega di riprovare');
					}
				);
			};
			scope.toggleCategory = function( category ) {
				if( category.open ) { 
					category.open = false;
					return;
				}
				$http.get( appURL+'/crud/faqs?category='+category._id ).then( 
					function successCallback( response ) {
						var faqs = response.data;
						faqs.forEach(function(faq) {
							faq.visible = false;
						})
						category.faqs = faqs;
						category.open = true;
					}, function errorCallback(response) {
						alert('Si è verificato un errore. Si prega di riprovare');
					}
				);
			};
			scope.toggleFaq = function(faq) {
				faq.visible = !faq.visible;
			}
			scope.loadCategories();
		}
	}
}]).directive('tutorials',['$http','$rootScope', function ($http, $rootScope ) {
    return {
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/tutorials.html?t='+RootSeed,
        scope: {},
		link: function (scope, elem, attrs) {
			scope.tutorialcategories = [];
			scope.currentpage = $rootScope.instantsupport;
			scope.pagetutorial = null;
			scope.loadCategories = function() {
				$http.get( appURL+'/crud/tutorialscategories').then( 
					function successCallback( response ) {
						var tutorialcategories=response.data;
						tutorialcategories.forEach(function(category) {
							category.tutorials = null;
							category.open = false;
						})
						
						if( scope.currentpage.inimage ) {
							var categoryId = null;
							for( var i=0;i<tutorialcategories.length;i++ ) {
								if( tutorialcategories[i].name === "Tutto sull'editor di immagini" ) {
									categoryId = tutorialcategories[i]._id;
									break;
								}
							}				
							$http.get( appURL+'/crud/tutorials?category='+categoryId+'&categoryname=Editor' ).then( 
								function successCallback( response ) {
									var tutorials = response.data;
									if( tutorials.length ) {
										scope.pagetutorial = tutorials[0];
									} else {
										scope.currentpage.category = null;
										scope.tutorialcategories = tutorialcategories;
									}
								}, function errorCallback(response) {
									alert('Si è verificato un errore. Si prega di riprovare');
								}
							);
						} else if( scope.currentpage.category ) {
							var categoryId = null;
							for( var i=0;i<tutorialcategories.length;i++ ) {
								if( tutorialcategories[i].name === scope.currentpage.category ) {
									categoryId = tutorialcategories[i]._id;
									break;
								}
							}
							if( categoryId && scope.currentpage.chapter && scope.currentpage.section ) {
								var query = (scope.currentpage.chapter<10 ? '0' + scope.currentpage.chapter : ''+scope.currentpage.chapter)+'.'+(scope.currentpage.section<10 ? '0' + scope.currentpage.section : ''+scope.currentpage.section);
								$http.get( appURL+'/crud/tutorials?category='+categoryId+'&categoryname='+query  ).then( 
									function successCallback( response ) {
										var tutorials = response.data;
										if( tutorials.length ) {
											scope.pagetutorial = tutorials[0];
										} else {
											scope.currentpage.category = null;
											scope.tutorialcategories = tutorialcategories;
										}
									}, function errorCallback(response) {
										alert('Si è verificato un errore. Si prega di riprovare');
									}
								);
							} else if( categoryId && scope.currentpage.chapterName ) {
								$http.get( appURL+'/crud/tutorials?category='+categoryId+'&categoryname='+ scope.currentpage.chapterName  ).then( 
									function successCallback( response ) {
										var tutorials = response.data;
										if( tutorials.length ) {
											scope.pagetutorial = tutorials[0];
										} else {
											scope.currentpage.category = null;
											scope.tutorialcategories = tutorialcategories;
										}
									}, function errorCallback(response) {
										alert('Si è verificato un errore. Si prega di riprovare');
									}
								);
							} else {
								scope.currentpage.category = null;
								scope.tutorialcategories = tutorialcategories;
							}
						} else {
							scope.tutorialcategories = tutorialcategories;
						}
					}, function errorCallback(response) {
						alert('Si è verificato un errore. Si prega di riprovare');
					}
				);
			};
			scope.toggleCategory = function( category ) {
				if( category.open ) { 
					category.open = false;
					return;
				}
				category.open = true;
				$http.get( appURL+'/crud/tutorials?category='+category._id ).then( 
					function successCallback( response ) {
						var tutorials = response.data;
						tutorials.forEach(function(tutorial) {
							tutorial.visible = false;
						})
						category.tutorials = tutorials;
					}, function errorCallback(response) {
						alert('Si è verificato un errore. Si prega di riprovare');
					}
				);
			};
			scope.toggletutorial = function(tutorial) {
				tutorial.visible = !tutorial.visible;
			}
			scope.loadCategories();
		}
	}
}]).directive('tickets',['$http','$rootScope', function ($http, $rootScope) {
    return {
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/tickets.html?t='+RootSeed,
        scope: {},
		link: function (scope, elem, attrs) {
			scope.tickets = [];
			scope.ticket = {};
			scope.ticketSection = 0;
			scope.ticketJustSent = false;
			scope.isloading = false;
			scope.ticketMessage = "";
			scope.message = null;
			scope.addMessageToTicket = function(ticket) {
				scope.message = {sender: $rootScope.currentUser.username, senderIsUser: true, date: new Date(), message:''};
			};
			scope.cancelMessageToTicket = function(ticket) {
				scope.message = null;
			};
			scope.sendMessageToTicket = function(ticket) {
				if( !scope.message ) { return; }
				scope.message.date = new Date();
				ticket.messages.push(scope.message);
				ticket.lastdate = scope.message.date;
				ticket.lastsenderisuser = true;
			    $http.post(appURL+'/crud/tickets', {'object':ticket}).then( 
				    function successCallback(response) {
					    scope.message = null;
						scope.loadTickets();
						scope.ticketJustSent = true;
				    }, function errorCallback(response) {
					    alert('Errore temporaneo riprova');
				    }
				);
			};
			scope.writeTicket = function() {
				scope.ticket = { type: 0, status: 0, priority: 0, title:'', date: new Date(), useremail:$rootScope.currentUser.email, messages:[{sender: $rootScope.currentUser.username, senderIsUser: true, date: new Date(), message:''}]};
			};
			scope.closeAlert = function() {};
			scope.changeTicketStatus = function( ticket, status ) {
				ticket.status = status;
			    $http.post(appURL+'/crud/tickets', {'object':ticket}).then( 
				    function successCallback(response) {
						scope.loadTickets();
				    }, function errorCallback(response) {
					    alert('Errore temporaneo riprova');
				    }
				);
			};
			scope.sendTicket = function() {
			    $http.post(appURL+'/crud/tickets', {'object':scope.ticket}).then( 
				    function successCallback(response) {
					    scope.ticketJustSent = true;
						scope.ticketSection = 0;
					    $http.post(appURL+'/services/sendticket', {'object':scope.ticket}).then( 
						    function successCallback(response) {
						    }, function errorCallback(response) {
						    }
						);
				    }, function errorCallback(response) {
					    alert('Errore temporaneo riprova');
				    }
				);
			};
			scope.deleteTicket = function(ticket) {
				if( confirm("Sei sicuro di voler eliminare questo ticket?") ) {
				    $http.delete(appURL+'/crud/tickets/'+ticket._id ).then( 
					    function successCallback(response) {
							scope.loadTickets();
					    }, function errorCallback(response) {
						    alert('Errore temporaneo riprova');
					    }
					);
				}
			};
			scope.loadTickets = function() {
				$http.get( appURL+'/crud/tickets').then( 
					function successCallback( response ) {
						var tickets = response.data;
						tickets.forEach(function(ticket) {
							ticket.open = false;
						})
						scope.tickets = tickets;
					    scope.$watch( function(scope) {
						    return scope.tickets.map(function(ticket) {
						    	return ticket.open;
							});
						}, function (newVal) {
							for( var i=0;i<scope.tickets.length;i++ ) {
								if( scope.tickets[i].open ) {
									scope.loadTicket(i);
									break;
								}
							}
						}, true);
					}, function errorCallback(response) {
						alert('Si è verificato un errore. Si prega di riprovare');
					}
				);
			};
			scope.loadTicket = function( index ) {
				scope.isloading = true;
				$http.get( appURL+'/crud/tickets/'+scope.tickets[index]._id).then( 
					function successCallback( response ) {
						var ticket = response.data;
						scope.tickets[index] = ticket;
						scope.tickets[index].open = true;
						scope.isloading = false;
					}, function errorCallback(response) {
						scope.isloading = false;
						alert('Si è verificato un errore. Si prega di riprovare');
					}
				);
			};
			scope.$watch('ticketSection', function(val) {
				if( val === undefined ) { return; }
				if( val == 0 ) {
					scope.loadTickets();
				}
			});
			scope.loadTickets();
		}
	}
}]).directive('instantsupport',['$uibModal','$rootScope', function ($uibModal, $rootScope ) {
    return {
		transclude: true,
		scope: {},
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/instantsupport.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.currentpage = $rootScope.instantsupport;
			scope.showSupport = function() {
				var modalInstance = $uibModal.open({
					size: 'lg',
					templateUrl: "modalSupport.html",
					controller: ['$scope', '$uibModalInstance', function (scope, $uibModalInstance ) {
						scope.ok = function() {
							$uibModalInstance.close();
						};
					}],
					
					resolve: {}
				});
			}
		}
	}
}]).directive('worknavigation',['$uibModal','$http','models', function ($uibModal, $http,models) {
    return {
		scope: { zones:'=', hidepscsection:'@', hidepossection:'@'  },
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/worknavigation.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.totalworks = 0;
			scope.currentWork = null;
			scope.currentSubwork = null;
			scope.currentWorksheetWork = null;
			if( scope.zones && scope.zones.length ) {
				for( var i=0;i<scope.zones.length;i++ ) {
					var zone = scope.zones[i];
					if( zone.works && zone.works.length ) {
						for( var j=0;j<zone.works.length;j++ ) {
							var work = zone.works[j];
							scope.totalworks++;
							if( work.subworks && work.subworks.length ) {
								for( var k=0;k<work.subworks.length;k++ ) {
									scope.totalworks++;
								}
							}
						}
					}
				}
			};
			scope.removeWorksheet = function() {
				scope.currentWorksheetWork.worksheet = { catid: null, workid: null, id: null, data: null};
			};
			scope.createWorksheet = function() {
				scope.currentWorksheetWork.worksheet = { catid: null, workid: null, id: null, data: (new models.EmbeddedWorksheet())};
			}
			scope.pickWorksheet = function() {
				if( !scope.currentWorksheetWork ) { return; }
				var modalInstance = $uibModal.open({
					templateUrl: 'posworksheetModal.html',
					controller: ['$scope', '$uibModalInstance','worksheetService', function (scope, $uibModalInstance, worksheetService ) {
						scope.wks = worksheetService;
						scope.data = {};
						scope.data.workcategory = null;
						scope.data.work = null;
						scope.data.worksheet = null;
						
						scope.reset = function () {
							scope.data.workcategory = null;
							scope.data.work = null;
							scope.data.worksheet = null;
							$uibModalInstance.close(scope.data);
						};
						scope.ok = function () {
							$uibModalInstance.close(scope.data);
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.data.isSettingUp = true;
						worksheetService.getWorkscategories(scope.data);
					}],
					resolve: {}
				});
				modalInstance.result.then(
					function successCallback(data) {
						if( data.worksheet && data.worksheet._id)
						return $http.get( appURL+'/crud/worksheets/'+data.worksheet._id ).then( function( res ) {
							var embeddedWorksheet = res.data;
							embeddedWorksheet.workname = data.work.name;
							delete(embeddedWorksheet._id);
							delete(embeddedWorksheet.work);
							scope.currentWorksheetWork.worksheet = {};
							scope.currentWorksheetWork.worksheet.catid = data.workcategory._id;
							scope.currentWorksheetWork.worksheet.workid = data.work._id;
							scope.currentWorksheetWork.worksheet.id = data.worksheet._id;
							scope.currentWorksheetWork.worksheet.data = embeddedWorksheet;
						});
					}, function errorCallback() {}
				);
			};
			scope.previousWork = function() {
				scope.currentindex -= ( scope.currentindex ? 1 : 0);
				scope.setCurrentIndex(scope.currentindex);
			};
			scope.nextWork = function() {
				scope.currentindex += ( scope.currentindex < (scope.totalworks-1)? 1 : 0);
				scope.setCurrentIndex(scope.currentindex);
			};
			scope.setCurrentIndex = function(idx) {
				scope.currentindex = idx;
				scope.currentZone = null;
				scope.currentWork = null;
				scope.currentSubwork = null;
				scope.currentWorksheetWork = null;
				var found = false;
				var index=-1;
				if( scope.zones && scope.zones.length ) {
					for( var i=0;i<scope.zones.length && !found;i++ ) {
						var zone = scope.zones[i];
						if( zone.works && zone.works.length ) {
							for( var j=0;j<zone.works.length && !found;j++ ) {
								var work = zone.works[j];
								index++;
								if( index == scope.currentindex ) {
									scope.currentZone = scope.zones[i];
									scope.currentWork = scope.currentZone.works[j];
									scope.currentWorksheetWork = scope.currentWork;
									found = true;
									break;
								}
								if( work.subworks && work.subworks.length ) {
									for( var k=0;k<work.subworks.length;k++ ) {
										index++;
										if( index == scope.currentindex ) {
											scope.currentZone = scope.zones[i];
											scope.currentWork = scope.currentZone.works[j];
											scope.currentSubwork = scope.currentWork.subworks[k];
											scope.currentWorksheetWork = scope.currentSubwork;
											found = true;
											break;
										}
									}
								}
							}
						}
					}
				}				
			};
			scope.setCurrentWork = function(wk) {
				scope.currentindex = -1;
				scope.currentZone = null;
				scope.currentWork = null;
				scope.currentSubwork = null;
				scope.currentWorksheetWork = null;
				var found = false;
				var index=-1;
				if( scope.zones && scope.zones.length ) {
					for( var i=0;i<scope.zones.length && !found;i++ ) {
						var zone = scope.zones[i];
						if( zone.works && zone.works.length ) {
							for( var j=0;j<zone.works.length && !found;j++ ) {
								var work = zone.works[j];
								index++;
								if( work == wk ) {
									scope.currentindex = index;
									scope.currentZone = scope.zones[i];
									scope.currentWork = scope.currentZone.works[j];
									scope.currentWorksheetWork = scope.currentWork;
									found = true;
									break;
								}
								if( work.subworks && work.subworks.length ) {
									for( var k=0;k<work.subworks.length;k++ ) {
										index++;
										if( work.subworks[k] == wk ) {
											scope.currentindex = index;
											scope.currentZone = scope.zones[i];
											scope.currentWork = scope.currentZone.works[j];
											scope.currentSubwork = scope.currentWork.subworks[k];
											scope.currentWorksheetWork = scope.currentSubwork;
											found = true;
											break;
										}
									}
								}
							}
						}
					}
				}
			};
			scope.setCurrentIndex(0);
		}
	}
}]).directive('texttemplate',['$uibModal','$http', function ($uibModal, $http) {
    return {
		transclude: true,
		scope: { template: '@', text:'=', type: '@' },
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/texttemplate.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.loadTemplate = function() {
				if( scope.type === undefined ) { return; }
				if( scope.text === undefined ) { return; }
				if( scope.template === undefined ) { return; }
				$http.get( appURL+'/crud/texttemplates/'+scope.type+'s' ).then(
					function successCallback( response ) {
						var data=response.data;
						if( data.length ) {
							var shareddocs = [];
							var privatedocs = [];
							data.forEach(function(c) {
								if( c.shared ) { 
									shareddocs.push(c); 
								} else {
									privatedocs.push(c); 
								}
							});
							var modalInstance = $uibModal.open({
								templateUrl: assetsURL+'directives/widgets/templates/texttemplatemodal.html?t='+RootSeed,
								controller: ['$scope', '$uibModalInstance', 'shareddocs', 'privatedocs','doctype', function (scope, $uibModalInstance, shareddocs, privatedocs, doctype) {
									scope.options = {doctype: doctype, type:'default', shareddoc: null, privatedoc: null};
									scope.shareddocs = shareddocs;
									scope.privatedocs = privatedocs;
									if( scope.shareddocs.length ) {
										scope.options.shareddoc = scope.shareddocs[0];
									}
									if( scope.privatedocs.length ) {
										scope.options.privatedoc = scope.privatedocs[0];
									}
									scope.cancel = function () {
										$uibModalInstance.dismiss('cancel');
									};
									scope.ok = function() {
										$uibModalInstance.close(scope.options);
									};
								}],
								
								resolve: {
									shareddocs: function () { return shareddocs; },
									privatedocs: function () { return privatedocs; },
									doctype: function() { return scope.type; }
								}
							});
							modalInstance.result.then(
								function successCallback(options) {
									if( options.type == 'default' ) {
										$http.get(appURL+'/crud/'+scope.type+'sectiontexts/type/'+scope.template, {}).then( 
											function successCallback(response) {
												var data=response.data;
												if( data && data.text) {
													scope.text = data.text;
												}
											}, function errorCallback(response) {
												// No alert: it simply does nothing
												//alert("Errore generico. Riprova.")
											}
										);
									} else if( options.type == 'shareddoc' ) {
										$http.get(appURL+'/crud/'+scope.type+'s/'+options.shareddoc._id+'/'+scope.template, {}).then( 
											function successCallback(response) {
												var data=response.data;
												if( data && data[scope.template]) {
													scope.text = data[scope.template];
												}
											}, function errorCallback(response) {
												alert("Errore generico. Riprova.")
											}
										);
									} else if( options.type == 'privatedoc' ) {
										$http.get(appURL+'/crud/'+scope.type+'s/'+options.privatedoc._id+'/'+scope.template, {}).then( 
											function successCallback(response) {
												var data=response.data;
												if( data && data[scope.template]) {
													scope.text = data[scope.template];
												}
											}, function errorCallback(response) {
												alert("Errore generico. Riprova.")
											}
										);
									}
								}, function errorCallback() {}
							);
						}
					}, function errorCallback(response) {
					}
				);
			}
		}
	}
}]).directive('worktypeedit',['$uibModal','$http', function ($uibModal, $http) {
    return {
		transclude: true,
		scope: { worktype: '=' },
		templateUrl: assetsURL+'directives/widgets/templates/worktypeedit.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.pickWorktype = function() {
				$http.get( appURL+'/crud/worktypes').then(
					function successCallback(response) {
						var data=response.data;
						if( data.length ) {
							var modalInstance = $uibModal.open({
								templateUrl: assetsURL+'directives/widgets/templates/worktypeModal.html?t='+RootSeed,
								controller: ['$scope', '$uibModalInstance', 'worktypes', function (scope, $uibModalInstance, worktypes ) {
									scope.worktypes = worktypes;
									scope.cancel = function () {
										$uibModalInstance.dismiss('cancel');
									};
									scope.ok = function(worktype) {
										$uibModalInstance.close(worktype.description);
									};
								}],
								
								resolve: {
									worktypes: function () { return data; }
								}
							});
							modalInstance.result.then(
								function successCallback(worktype) {
									scope.worktype = worktype;
								}, function errorCallback() {}
							);
						}
					}, function errorCallback(response) {
					}
				);
			};
		}
	}
}]).directive('hospitalpicker',['$uibModal','$timeout', 'uiGmapGoogleMapApi', '$q', function ($uibModal,$timeout, uiGmapGoogleMapApi,$q) {
    return {
		transclude: true,
		scope: { hospital: '=', map:'=', city:'=' },
		templateUrl: assetsURL+'directives/widgets/templates/hospitalpicker.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.loading = false;
			scope.findNearestHospital = function() {
				if( scope.hospital === undefined ) { return; }
				if( scope.map === undefined ) { return; }
				if( scope.map && scope.map.center && scope.map.center.longitude && scope.map.center.latitude ) {
					scope.loading = true;
					scope.center = new google.maps.LatLng(scope.map.center.latitude, scope.map.center.longitude);
					scope.tmpMap = new google.maps.Map(document.createElement('div'), {
						center: scope.center,
						zoom: 15,
					});
					scope.request = {
						location: scope.center,
						radius: 20000,
						query: (scope.city?'ospedali vicino '+scope.city:'ospedali')
					};
		  			scope.hospitals = [];
		  			scope.markers = [];
		  			scope.service = new google.maps.places.PlacesService(scope.tmpMap);
		  			var gService = scope.service;
		  			scope.searchDetail = function(placeId, promises) {
					   var d = $q.defer();
						gService.getDetails({ placeId: placeId } , function(result, status) {
							if (status == google.maps.places.PlacesServiceStatus.OK) {
								scope.hospitals.push(result);
								var marker = {
									id: scope.hospitals.length-1,
									coords: {
										latitude: result.geometry.location.lat(),
										longitude: result.geometry.location.lng()
									},
									options: { draggable: false },
								};
								scope.markers.push(marker);
							}
							d.resolve();
						});
					   promises.push(d.promise); 
					}
					scope.service.textSearch(scope.request, function(places, status) {			
						if (status == google.maps.places.PlacesServiceStatus.OK) {
							var promises = [];
							var count = Math.min(places.length, 5);
							var distResults = [];
							var R = 6371; // Radius of the earth in km
							
							for( var i=0;i<places.length;i++ ) {
								var place = places[i];
								if( place.name.match(/((H|h)ospital)|((O|o)spedale)|((P|p)residio (O|o)spedaliero)/) == null ) {
									continue;
								}
								if( place.name.match(/((A|a)zienda (O|o)spedaliera)/) != null ) {
									continue;
								}
								var lat1 = (scope.map.center.latitude);
								var lat2 = (place.geometry.location.lat());
								var lon1 = (scope.map.center.longitude);
								var lon2 = (place.geometry.location.lng());
								var dLat = (lat2 - lat1) * Math.PI / 180;  // deg2rad below
								var dLon = (lon2 - lon1) * Math.PI / 180;
								var a = 0.5 - Math.cos(dLat)/2 + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *  (1 - Math.cos(dLon))/2;
								var d = R * 2 * Math.asin(Math.sqrt(a));
								distResults.push({dist: d, place: place});
							}
							var sortedDistResults = distResults.sort(function(a, b) {
								if(a.dist < b.dist) {
									return -1;
								}
								if(a.dist > b.dist){
									return 1;
								}
								return 0;
							});
							for ( var i = 0; i < count; i++) {
								if( sortedDistResults[i] ) {
									scope.searchDetail(sortedDistResults[i].place.place_id, promises);
								}
							}
							$q.all(promises).then(function() {
								scope.loading = false;
								scope.ready = false;
								var modalInstance = $uibModal.open({
									templateUrl: assetsURL+'directives/widgets/templates/hospitalpickerModal.html?t='+RootSeed,
									controller: ['$scope', '$uibModalInstance', 'uiGmapGoogleMapApi', '$timeout', 'center', 'hospitals', 'markers', function (scope, $uibModalInstance, uiGmapGoogleMapApi, $timeout, center, hospitals, markers) {
										scope.render = false;
										scope.map={ 
											center: { longitude:center.longitude, latitude: center.latitude}, 
											zoom:10,
											options: { 
												scrollwheel: false,
												panControl: true,
												mapTypeControl : false,
												draggable: true,
												streetViewControl: false,
												zoomControl: true,
												fullscreenControl: false,
												mapTypeId : 'roadmap'
											},
											markerOptions: {
												draggable: false,
												icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
											}
										}; 
										scope.hospitals = hospitals;
										scope.markers = markers;
										scope.cancel = function () {
											$uibModalInstance.dismiss('cancel');
										};
										scope.ok = function( hospital ) {
											$uibModalInstance.close( hospital );
										};
										uiGmapGoogleMapApi.then(function(maps) {
											$timeout(function () { scope.render = true; },0);
									    });
										
									}],
									resolve: {
										center: function () { return scope.map.center; },
										hospitals: function () { return scope.hospitals; },
										markers: function () { return scope.markers; }
									}
								});
								modalInstance.result.then(
									function successCallback(pickedHospital) {
										var address={};
										if( pickedHospital.address_components ) {
											var street = "";
											var streetNumber = "";
											var city = "";
											var state = "";
											var region = "";
											var zipcode = "";
											
											pickedHospital.address_components.forEach(function(addr) {
												addr.types.forEach(function(type) {
													if( type=='street_number') {
														streetNumber = addr.short_name;
													}
													if( type=='route') {
														street = addr.short_name;
													}
													if( type=='locality') {
														city = addr.short_name;
													}
													if( type=='administrative_area_level_1') {
														region = addr.short_name;
													}
													if( type=='administrative_area_level_2') {
														state = addr.short_name;
													}
													if( type=='postal_code') {
														zipcode = addr.short_name;
													}
												});
											});
											scope.hospital.address.region = region;
											scope.hospital.address.state = state;
											scope.hospital.address.city = city;
											scope.hospital.address.zipcode = zipcode;
											scope.hospital.address.address = (street.length?street+", "+streetNumber:"");
											scope.hospital.map.center.longitude = pickedHospital.geometry.location.lng();
											scope.hospital.map.center.latitude = pickedHospital.geometry.location.lat();
										}
									}, function errorCallback() {}
								);
							});							
						} else {
							scope.loading = false;
						}
					});
				}
			}
		}
	}	
}]).directive('richtexteditorwithtempplate',[function () {
    return {
		scope: { text: '=', placeholder:'@', type:'@', template:'@', disableimages: '@' },
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/richtexteditorwithtemplate.html?t='+RootSeed,
		link: function (scope, elem, attrs) {}
	}
}]).directive('statusprogressbar',[function () {
    return {
		scope: { steps:'=', startmessage:'@', endmessage:'@'},
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/statusprogressbar.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.counts = [0,0,0];
			scope.$watch('steps', function(newvalue) {
				scope.counts = [0,0,0];
				scope.steps.forEach(function(c) {
					if( c.status !== undefined ) {
						scope.counts[c.status]++;
					}
				});
				for( var i=0;i<scope.counts.length;i++ ) {
					scope.counts[i] = Math.floor(scope.counts[i]/scope.steps.length*100);
				}
			}, true);
		}
	}
}]).directive('statusmessagebar',[function () {
    return {
		scope: { status:'=', type:'@', showtext:'@',small:'@' },
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/statusmessagebar.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			if( scope.type == 'chapter' ) {
				scope.startmessage = "Compila ogni sezione per completare il capitolo";
				scope.endmessage="Hai compilato tutte le sezioni, puoi passare al prossimo capitolo";
				scope.errormessage="Verifica tutte le sezioni: ci sono dati mancanti o incompleti";
			} else if( scope.type == 'section' ) {
				scope.startmessage = "Compila ogni campo per completare la sezione";
				scope.endmessage="Hai compilato tutti i campi della sezione, puoi passare alla prossima";
				scope.errormessage="Verifica tutti i campi: ci sono dati mancanti o incompleti";
			}
		}
	}
}]).directive('chapterheader',['$rootScope', function ($rootScope) {
    return {
		scope: { chapter:'=', currentindex:'=', healthemergency:'=?', healthemergencydescription:'@' },
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/chapterheader.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.incompletesections = 0;
			scope.$watch('currentindex', function(val) {
				$rootScope.instantsupport.section = scope.currentindex+1;
			});
			scope.$watch('chapter', function(val) {
				if( val === undefined ) { return; }
				scope.incompletesections = 0;
				scope.chapter.sections.forEach(function(s) {
					if(s.status==1) { scope.incompletesections++; }
				}); 
			}, true);
		}
	}
}]).directive('pickfromenterprise',['$uibModal','$http','models', function ($uibModal, $http, models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/pickfromenterprise.html?t='+RootSeed,
        scope: { objects: '=', objtype:'@', enterprise:'@' },
		link: function (scope, elem, attrs) {
			scope.pickForEnterprise = function() {
				var modalInstance = $uibModal.open({
					size: 'lg',
					templateUrl: 'pickFromEnterpriseModal.html',
					controller: ['$scope', '$uibModalInstance','$http', 'enterprise','objects','objtype', function (scope, $uibModalInstance, $http, enterprise, objects, objtype ) {
						scope.options = {loading : true, objects:[]};
						$http.get( appURL+'/crud/enterprises?returnallvalues=1&searchunique='+ encodeURIComponent(enterprise)).then(
							function successCallback(response) {
								var alldata = response.data;
								if( alldata === undefined || alldata.length == 0 ) {
									scope.options.loading = false;
									return;
								}
								var objtypeparts =  objtype.split('.');
								var firstKey = objtypeparts[0];
								
								var data = alldata[0];							
								var addActor = function( actor ) {
									if( actor &&  actor.contact ) {
										var fullname = (actor.contact.iscompany ? actor.contact.companyname : actor.contact.lastname+' '+actor.contact.firstname);
										
										var canShow = true;
										for( var i=0;i<objects.length;i++ ) {
											var obj = objects[i];
											if( obj.parentId === actor._id ) {
												canShow = false;
												break;
											}
										}
										if( canShow ) {
											scope.options.objects.push({ name:fullname, selected:false, object: actor});
										} 
									}
								}
								if( firstKey === 'employees' && data[firstKey]) {
									if( objtypeparts.length == 3 ) {
										for( var i=0;i<data[firstKey].length;i++ ) {
											if( data[firstKey][i]._id === objtypeparts[1] ) {
												var archivedobj = data[firstKey][i];
												if( archivedobj[objtypeparts[2]] !== undefined ) {
													archivedobj[objtypeparts[2]].forEach(function(c) {
														var canShow = true;
														for( var j=0;j<objects.length;j++ ) {
															var obj = objects[j];
															if( obj._id === c._id || obj.parentId === c._id) {
																canShow = false;
																break;
															}
														}
														if( canShow ) {
															scope.options.objects.push({name: ((c.caption === undefined||c.caption.length==0) ? 'Documento senza titolo' : ' '+c.caption), selected: false, object: c});
														}
													});	
												}
												break;
											}
										}
									} else {										
			 							data[firstKey].forEach(function(c) {
											addActor(c);
										});
									}
								}
								if( (firstKey === 'allegeddocs' || firstKey === 'substancesallegeddocs')  && data[firstKey]) {
		 							data[firstKey].forEach(function(c) {
										var canShow = true;
										for( var i=0;i<objects.length;i++ ) {
											var obj = objects[i];
											var isEqual = false;
											if( obj.images.length == c.images.length ) {
												var isEqual = true;
												for( var j=0;j<obj.images.length;j++ ) {
													if( c.images.indexOf(obj.images[j]) == -1 ) {
														isEqual = false;
														break;
													}
												}
											}
											if( isEqual ) {
												canShow = false;
												break;
											}
										}
										if( canShow ) {
											scope.options.objects.push({name: ((c.caption === undefined || !c.caption.length)?'Senza titolo':c.caption), selected: false, object: c});
										}
									});
								}
								if( (firstKey === 'safetychief' || firstKey === 'doctor'  || firstKey === 'workerschief') && data[firstKey].allegeddocs ) {
		 							data[firstKey].allegeddocs.forEach(function(c) {
										var canShow = true;
										for( var i=0;i<objects.length;i++ ) {
											var obj = objects[i];
											var isEqual = false;
											if( obj.images.length == c.images.length ) {
												var isEqual = true;
												for( var j=0;j<obj.images.length;j++ ) {
													if( c.images.indexOf(obj.images[j]) == -1 ) {
														isEqual = false;
														break;
													}
												}
											}
											if( isEqual ) {
												canShow = false;
												break;
											}
										}
										if( canShow ) {
											scope.options.objects.push({name: ((c.caption === undefined || !c.caption.length)?'Senza titolo':c.caption), selected: false, object: c});
										}
									});
								}
								
								if( (firstKey === 'temporaryworks' || firstKey === 'machines' || firstKey === 'plants' || objtype === 'dpis') && data[firstKey]) {
									if( objtypeparts.length == 3 ) {
										for( var i=0;i<data[firstKey].length;i++ ) {
											if( data[firstKey][i]._id === objtypeparts[1] ) {
												var archivedobj = data[firstKey][i];
												if( archivedobj[objtypeparts[2]] !== undefined ) {
													archivedobj[objtypeparts[2]].forEach(function(c) {
														var canShow = true;
														for( var j=0;j<objects.length;j++ ) {
															var obj = objects[j];
															if( obj._id === c._id || obj.parentId === c._id) {
																canShow = false;
																break;
															}
														}
														if( canShow ) {
															scope.options.objects.push({name: ((c.caption === undefined||c.caption.length==0) ? 'Documento senza titolo' : ' '+c.caption), selected: false, object: c});
														}
													});	
												}
												break;
											}
										}
									} else {
										data[firstKey].forEach(function(c) {
											var canShow = true;
											for( var i=0;i<objects.length;i++ ) {
												var obj = objects[i];
												if( obj.parentId === c._id ) {
													canShow = false;
													break;
												}
											}
											if( canShow ) {
												scope.options.objects.push({name: (c.category+((c.model === undefined||c.model.length==0) ? '' : ' '+c.model)), selected: false, object: c});
											}
										});	
									}
								}
								scope.options.loading = false;
							}, function errorCallback(response) {
								scope.options.loading = false;
								alert('Si è verificato un errore. Si prega di riprovare');
							}
						);				
		
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function () {
							$uibModalInstance.close(scope.options);
						};
					}],
					
					resolve: {
						enterprise: function () { return scope.enterprise; },
						objects: function() { return scope.objects; },
						objtype: function() { return scope.objtype; }
					}
				});
			
				modalInstance.result.then(
					function successCallback(options) {
						options.objects.forEach(function(c) {
							if( c.selected ) {
								c.object.parentId = c.object._id;
								c.object._id = models.clientId();
								scope.objects.push(angular.copy(c.object));
							}
						});
					}, function errorCallback() {}
				);
			}
			elem.bind('click', scope.pickForEnterprise);
		}
	}
}]).directive('substanceedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/substanceedit.html?t='+RootSeed,
        scope: { substance: '=', zones:'=', hideworks:"="  },
		link: function (scope, elem, attrs) {
			scope.addDescription = function() {
				scope.substance.descriptions.push({name:'Nuovo prodotto'});
			};
			scope.deleteDescription = function( index ) {
				if( index >=0 && index < scope.substance.descriptions.length ) {
					scope.substance.descriptions.splice( index, 1);
				}
			};
			scope.deselectAllWorks = function() {
				scope.substance.works = {};
			};
			scope.selectAllWorks = function() {
				scope.substance.works = {};
				scope.zones.forEach(function(zone) {
					zone.works.forEach(function(work) {
						scope.substance.works[work._id]=true;
						work.subworks.forEach(function(subwork) {
							scope.substance.works[subwork._id]=true;
						});
					});
				})
			};
		}
	}
}]).directive('substancesedit',['$uibModal', function ($uibModal) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/substancesedit.html?t='+RootSeed,
        scope: { substances: '=', zones:'=', hideworks:"=", dontshowhideworksoption:'@'  },
		link: function (scope, elem, attrs) {
			scope.selectedsubstance = null;
			scope.hasWorks = function(index) {
				if( scope.substances[index].works === undefined || scope.substances[index].works === null ) { return false; }
				var result = false;
				for( var k in scope.substances[index].works) {
					if(scope.substances[index].works[k]===true) {
						result = true;
						break;
					}
				}
				return result;
			}
			scope.pickFromSubstancesArchive = function() {
				if( scope.disableedititem === true ) { return; }
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'directives/catobjectseditor/templates/catobjectspickerModal.html?t='+RootSeed,
					controller: ['$scope', '$uibModalInstance','section','type','objectclass', function (scope, $uibModalInstance, section, type, objectclass ) {
						scope.searchtext="";
						scope.data = {	objects: [], section: section, type: type, objectclass: objectclass };
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function() {
							$uibModalInstance.close(scope.data.objects);
						};
					}],
					
					resolve: { 
						section: function () { return 'SUB'; },
						objectclass: function () { return 'multidescriptionobjectitem'; },
						type: function () { return 'substances'; }
					}
				});
				modalInstance.result.then(
					function successCallback(objects) {
						if( objects === undefined ) { return; }
						objects.forEach(function(c) {
							if( c.selected === true ) {
								var newObject = {name:c.name, works:{}, descriptions:[]};
								c.descriptions.forEach(function(d) {
									if( d.selected === true ) {
										var newDesc = {name:d.name};
										newObject.descriptions.push(newDesc);
									}
								});
								var found=false;
								for(var i=0; i<scope.substances.length;i++ ) {
									if(scope.substances[i].name == newObject.name ) {
										var descFound=false;
										var cObj = scope.substances[i];
										newObject.descriptions.forEach(function(dsc) {
											for( var j=0;j<cObj.descriptions.length;j++ ) {
												if( cObj.descriptions[j].name == dsc.name ) {
													descFound = true;
													break;
												}
											}
											if( descFound == false ) {
												cObj.descriptions.push(dsc);
											}
										});
										found = true;
									}
								}
								if( found==false ) {
									scope.substances.push(newObject);
								}
								scope.substances.sort(function(a, b) {
									var nameA=a.name, nameB=b.name;
									return (nameA < nameB) ? -1 : ((nameA > nameB) ? 1 : 0);
								});
							}
						});
					}, function errorCallback() {}
				);
			};
			scope.selectSubstance = function(index) {
				scope.selectedsubstance = scope.substances[index];
			};
			scope.addSubstance = function() {
				var newObject = {name:'Nuova sostanza', descriptions:[], works:{}};
				scope.substances.push(newObject);
				scope.selectedsubstance = newObject;
			};
			scope.deleteSubstance = function( index ) {
				if( index >= scope.substances.length ) { return; }
				scope.substances.splice(index, 1);
				scope.selectedsubstance = (scope.substances.length ? scope.substances[0]:null);
			};
			if( scope.substances && scope.substances.length ) { 
				scope.selectedsubstance = scope.substances[0];
			}
		}
	}
}]).directive('dpiedit',['$q','$http', function ($q, $http) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/dpiedit.html?t='+RootSeed,
        scope: { dpi: '=', zones:'=', hideworks:"="  },
		link: function (scope, elem, attrs) {
			scope.dpis = [];
			scope.promises = [];
			var parentScope=scope;
  			scope.getDPIs = function(category, promises) {
			   var d = $q.defer();
				$http.get( appURL+'/crud/devices?category='+category ).then( 
					function successCallback( response ) {
						var devices = response.data;
						for( var i=0; i<devices.length; i++ ) {
							parentScope.dpis.push({name: devices[i].name, description: devices[i].description, image: devices[i].image});
						}
						d.resolve();
					}, function errorCallback(response) {
						d.resolve();
						alert('Si è verificato un errore. Si prega di riprovare');
					}
				);
			   promises.push(d.promise); 
			};
			$http.get( appURL+'/crud/devicescategories').then( 
				function successCallback( response ) {
					var devicescategories = response.data;
					for( var i=0;i<devicescategories.length; i++ ) {
						parentScope.getDPIs(devicescategories[i]._id, parentScope.promises);
					}
				}, function errorCallback(response) {
					alert('Si è verificato un errore. Si prega di riprovare');
				}
			);
			
			$q.all(scope.promises).then(function() {
				parentScope.checkIfDPIIsCustom();
			});
			scope.checkIfDPIIsCustom = function() {
				var found = false;
				for( var i=0;i<scope.dpis.length; i++ ) {
					if( scope.dpi.category === scope.dpis[i].name ) {
						found = true;
						break;
					}
				}
				if( found === false) {
					parentScope.dpis.push({name: scope.dpi.category, description: '', image: null});
				}
			}
			scope.assignDPIDescAndImage = function() {
				for( var i=0;i<scope.dpis.length; i++ ) {
					if( scope.dpi.category === scope.dpis[i].name ) {
						scope.dpi.description = scope.dpis[i].description;
						scope.dpi.image = scope.dpis[i].image;
						break;
					}
				}
			}
			scope.deselectAllWorks = function() {
				scope.dpi.works = {};
			};
			scope.selectAllWorks = function() {
				scope.dpi.works = {};
				scope.zones.forEach(function(zone) {
					zone.works.forEach(function(work) {
						scope.dpi.works[work._id]=true;
						work.subworks.forEach(function(subwork) {
							scope.dpi.works[subwork._id]=true;
						});
					});
				})
			}
			scope.$watch('dpi.category', function(val) {
				if( val === undefined ) { return; }
				scope.checkIfDPIIsCustom();
			}, true);
		}
	}
}]).directive('dpisedit',['models',function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/dpisedit.html?t='+RootSeed,
        scope: { dpis: '=', zones:'=', enterprise : '=', hideworks:"=" },
		link: function (scope, elem, attrs) {
			scope.hasWorks = function(index) {
				if( scope.dpis[index].works === undefined || scope.dpis[index].works === null ) { return false; }
				var result = false;
				for( var k in scope.dpis[index].works) {
					if(scope.dpis[index].works[k]===true) {
						result = true;
						break;
					}
				}
				return result;
			}
			scope.selecteddpi = null;
			scope.selectDPI = function(index) {
				scope.selecteddpi = scope.dpis[index];
			};
			scope.addDPI = function() {
				
				var newObject =  new models.DPI();
				scope.dpis.push(newObject);
				scope.selecteddpi = newObject;
			};
			scope.deleteDPI = function( index ) {
				if( index >= scope.dpis.length ) { return; }
				scope.dpis.splice(index, 1);
				scope.selecteddpi = (scope.dpis.length ? scope.dpis[0]:null);
			};
			if( scope.dpis.length ) { 
				scope.selecteddpi = scope.dpis[0];
			}
		}
	}
}]).directive('enterprisedpiedit',['$q','$http', function ($q, $http) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/enterprisedpiedit.html?t='+RootSeed,
        scope: { dpi: '='  },
		link: function (scope, elem, attrs) {
			scope.dpis = [];
			scope.promises = [];
			var parentScope=scope;
  			scope.getDPIs = function(category, promises) {
			   var d = $q.defer();
				$http.get( appURL+'/crud/devices?category='+category ).then( 
					function successCallback( response ) {
						var devices=response.data;
						for( var i=0; i<devices.length; i++ ) {
							parentScope.dpis.push({name: devices[i].name, description: devices[i].description, image: devices[i].image});
						}
						d.resolve();
					}, function errorCallback(response) {
						d.resolve();
						alert('Si è verificato un errore. Si prega di riprovare');
					}
				);
			   promises.push(d.promise); 
			};
			$http.get( appURL+'/crud/devicescategories').then( 
				function successCallback( response ) {
					var devicescategories=response.data;
					for( var i=0;i<devicescategories.length; i++ ) {
						parentScope.getDPIs(devicescategories[i]._id, parentScope.promises);
					}
				}, function errorCallback(response) {
					alert('Si è verificato un errore. Si prega di riprovare');
				}
			);
			
			$q.all(scope.promises).then(function() {
				parentScope.checkIfDPIIsCustom();
			});
			scope.checkIfDPIIsCustom = function() {
				var found = false;
				for( var i=0;i<scope.dpis.length; i++ ) {
					if( scope.dpi.category === scope.dpis[i].name ) {
						found = true;
						break;
					}
				}
				if( found === false) {
					parentScope.dpis.push({name: scope.dpi.category, description: '', image: null});
				}
			}
			scope.assignDPIDescAndImage = function() {
				for( var i=0;i<scope.dpis.length; i++ ) {
					if( scope.dpi.category === scope.dpis[i].name ) {
						scope.dpi.description = scope.dpis[i].description;
						scope.dpi.image = scope.dpis[i].image;
						break;
					}
				}
			}
			scope.$watch('dpi.category', function(val) {
				if( val === undefined ) { return; }
				scope.checkIfDPIIsCustom();
			}, true);
		}
	}
}]).directive('enterprisedpisedit',['models',function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/enterprisedpisedit.html?t='+RootSeed,
        scope: { dpis: '=' },
		link: function (scope, elem, attrs) {
			scope.selecteddpi = null;
			scope.selectDPI = function(index) {
				scope.selecteddpi = scope.dpis[index];
			};
			scope.addDPI = function() {
				var newObject =  new models.DPI();;
				scope.dpis.push(newObject);
				scope.selecteddpi = newObject;
			};
			scope.deleteDPI = function( index ) {
				if( index >= scope.dpis.length ) { return; }
				scope.dpis.splice(index, 1);
				scope.selecteddpi = (scope.dpis.length ? scope.dpis[0]:null);
			};
			if( scope.dpis.length ) { 
				scope.selecteddpi = scope.dpis[0];
			}
		}
	}
}]).directive('imagessectionedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/imagessectionedit.html?t='+RootSeed,
        scope: { section: '=', description : '@?', layouts : '=?', compactview : '=?', enterprises : "="  },
		link: function (scope, elem, attrs) {
			scope.compactview = (scope.compactview === undefined ? false : scope.compactview);
			if( scope.description === undefined ) { scope.description = 'Fotografie'; }
			scope.name = 'layout'+(new Date()).getTime();
		}
	}
}]).directive('imagessectionsedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/imagessectionsedit.html?t='+RootSeed,
        scope: { sections: '=', description : '@?', layouts : '=?', compactview : '=?', enterprises : "=" },
		link: function (scope, elem, attrs) {
			scope.selectedsection = null;
			scope.compactview = (scope.compactview === undefined ? false : scope.compactview);
			if( scope.description === undefined ) { scope.description = 'Fotografie'; }
			scope.selectSection = function(index) {
				scope.selectedsection = scope.sections[index];
			};
			scope.$watch('sections', function(newVal) {
				if( newVal === undefined ) { return; }
				if( scope.sections.length ) { 
					scope.selectedsection = scope.sections[0];
				} else {
					scope.selectedsection = null;
				}
			});
			scope.addSection = function() {
				if( scope.sections.length >= 10 ) { return; }
				var newObject = {name:'', layout:0, images:[]};
				scope.sections.push(newObject);
				scope.selectedsection = newObject;
			};
			scope.deleteSection = function() {
				scope.sections.splice(scope.sections.indexOf(scope.selectedsection), 1);
				scope.selectedsection = (scope.sections.length ? scope.sections[0]:null);
			};
			if( scope.sections.length ) { 
				scope.selectedsection = scope.sections[0];
			} else {
				scope.selectedsection = null;
			}
		}
	}
}]).directive('textsectionedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/textsectionedit.html?t='+RootSeed,
        scope: { section: '=' },
		link: function (scope, elem, attrs) {
		}
	}
}]).directive('textsectionsedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/textsectionsedit.html?t='+RootSeed,
        scope: { sections: '=' },
		link: function (scope, elem, attrs) {
			scope.$watchCollection('sections', function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				if( newVal.length != oldVal.length ) { scope.selectedsection = scope.sections[0]; }
			});
			scope.selectedsection = null;
			scope.selectSection = function(index) {
				scope.selectedsection = scope.sections[index];
			};
			scope.addSection = function() {
				var newObject = {name:'', text:'', imagesections:[]};
				scope.sections.push(newObject);
				scope.selectedsection = newObject;
			};
			scope.deleteSection = function() {
				scope.sections.splice(scope.sections.indexOf(scope.selectedsection), 1);
				scope.selectedsection = (scope.sections.length ? scope.sections[0]:null);
			};
			if( scope.sections.length ) { 
				scope.selectedsection = scope.sections[0];
			}
		}
	}
}]).directive('picklayout',['$http', function ($http) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/picklayout.html?t='+RootSeed,
        scope: { layouts: '=', images: '=?', imageid: '=?' },
		link: function (scope, elem, attrs) {
			scope.addLayout = function( layout ) {
				if(  !layout ) { return; }
				$http.post(appURL+'/utils/images', { duplicateid: layout.imageid }).then( 
					function successCallback(response) {
						var data=response.data;
						if( data && data._id) {
							if( scope.images ) {
								scope.images.push({id:data._id, caption:layout.name});
							}
							if( scope.imageid ) {
								scope.imageid = data._id;
							}
						}
					}, function errorCallback(response) {
						alert("Errore generico. Riprova.")
					}
				);

			};
		}
	}
}]).directive('layoutedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/layoutedit.html?t='+RootSeed,
        scope: { layout: '=' },
		link: function (scope, elem, attrs) {}
	}
}]).directive('layoutsedit',['models','$http', function (models,$http) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/layoutsedit.html?t='+RootSeed,
        scope: { layouts: '=' },
		link: function (scope, elem, attrs) {
			scope.selectedlayout = null;
			scope.selectLayout = function(index) {
				scope.selectedlayout = scope.layouts[index];
			};
			scope.addLayout = function() {
				var newObject = new models.Layout();
				scope.layouts.push(newObject);
				scope.selectedlayout = newObject;
			};
			scope.copyLayout = function() {
				if(  !scope.selectedlayout) { return; }
				$http.post(appURL+'/utils/images', { duplicateid: scope.selectedlayout.imageid }).then( 
					function successCallback(response) {
						var data=response.data;
						if( data && data._id) {
							var newObject = new models.Layout();
							newObject.papersize = scope.selectedlayout.papersize;
							newObject.imageid = data._id;
							newObject.name = "Copia di "+scope.selectedlayout.name;
							scope.layouts.push(newObject);
							scope.selectedlayout = newObject;
						}
					}, function errorCallback(response) {
						alert("Errore generico. Riprova.")
					}
				);

			};
			scope.deleteLayout = function() {
				if( scope.layouts.length == 1 || !scope.selectedlayout) { return; }
				scope.layouts.splice(scope.layouts.indexOf(scope.selectedlayout), 1);
				scope.selectedlayout = (scope.layouts.length ? scope.layouts[0]:null);
			};
			if( scope.layouts.length ) { 
				scope.selectedlayout = scope.layouts[0];
			}
		}
	}
}]).directive('assetedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/assetedit.html?t='+RootSeed,
        scope: { asset: '=', zones:'=', hideworks:"=" },
		link: function (scope, elem, attrs) {
			scope.deselectAllWorks = function() {
				scope.asset.works = {};
			};
			scope.selectAllWorks = function() {
				scope.asset.works = {};
				scope.zones.forEach(function(zone) {
					zone.works.forEach(function(work) {
						scope.asset.works[work._id]=true;
						work.subworks.forEach(function(subwork) {
							scope.asset.works[subwork._id]=true;
						});
					});
				})
			}
		}
	}
}]).directive('assetsedit',['models', function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/assetsedit.html?t='+RootSeed,
        scope: { assets: '=', zones:'=', enterprise : '=', objtype : '@', noworksentence : '@', hideworks:"=" },
		link: function (scope, elem, attrs) {
			scope.hasWorks = function(index) {
				if( scope.assets[index].works === undefined || scope.assets[index].works === null ) { return false; }
				var result = false;
				for( var k in scope.assets[index].works) {
					if(scope.assets[index].works[k]===true) {
						result = true;
						break;
					}
				}
				return result;
			}
			scope.selectedasset = null;
			scope.selectAsset = function(index) {
				scope.selectedasset = scope.assets[index];
			};
			scope.addAsset = function() {
				var newObject = new models.Asset();
				scope.assets.push(newObject);
				scope.selectedasset = newObject;
			};
			scope.deleteAsset = function(index) {
				if( index >= scope.assets.length ) { return; }
				scope.assets.splice(index, 1);
				scope.selectedasset = (scope.assets.length ? scope.assets[0]:null);
			};
			scope.$watch('assets', function(newVal) {
				if( newVal ) {
					if( scope.selectedasset == null && scope.assets.length ) { 
						scope.selectedasset = scope.assets[0];
					}
				}
			}, true);
		}
	}
}]).directive('assetsallegeddocsedit',[ function () {
    return {
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/assetsallegeddocsedit.html?t='+RootSeed,
        scope: { assets: '=', enterprise : '@', objtype : '@', viewtype : '=' },
		link: function (scope, elem, attrs) {
			scope.selectedasset = null;
			scope.$watch('assets', function(newVal) {
				if( newVal ) {
					if( scope.selectedasset == null && scope.assets.length ) { 
						scope.selectedasset = scope.assets[0];
					}
				}
			}, true);
		}
	}
}]).directive('employedworkersallegeddocsedit',[ function () {
    return {
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/employedworkersallegeddocsedit.html?t='+RootSeed,
        scope: { employees: '=', enterprise : '@', objtype : '@', viewtype : '=' },
		link: function (scope, elem, attrs) {
			scope.selectedemployee = null;
			scope.selectEmployee = function(index) {
				scope.selectedemployee = scope.employees[index];
			};
			scope.$watch('employees', function(newVal) {
				if( newVal ) {
					if( scope.selectedemployee == null && scope.employees.length ) { 
						scope.selectedemployee = scope.employees[0];
					}
				}
			}, true);
		}
	}
}]).directive('enterpriseassetedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/enterpriseassetedit.html?t='+RootSeed,
        scope: { asset: '=' },
		link: function (scope, elem, attrs) {
			scope.viewtype='grid';
		}
	}
}]).directive('enterpriseassetsedit',['models', function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/enterpriseassetsedit.html?t='+RootSeed,
        scope: { assets: '=', objtype : '@' },
		link: function (scope, elem, attrs) {
			scope.selectedasset = null;
			scope.selectAsset = function(index) {
				scope.selectedasset = scope.assets[index];
			};
			scope.addAsset = function() {
				var newObject = new models.Asset();
				scope.assets.push(newObject);
				scope.selectedasset = newObject;
			};
			scope.deleteAsset = function() {
				var index = scope.assets.indexOf(scope.selectedasset);
				if( index<0 || index >= scope.assets.length ) { return; }
				scope.assets.splice(index, 1);
				scope.selectedasset = (scope.assets.length ? scope.assets[0]:null);
			};
			scope.$watch('assets', function(newVal) {
				if( newVal ) {
					if( scope.selectedasset == null && scope.assets.length ) { 
						scope.selectedasset = scope.assets[0];
					}
				}
			}, true);
		}
	}
}]).directive('letteredit',['models','$http', '$uibModal','lettertextService', function (models,$http, $uibModal, lettertextService) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/letteredit.html?t='+RootSeed,
        scope: { letter: '=', document:'=', lettertypes : '=' },
		link: function (scope, elem, attrs) {
			scope.categoryletters = [];
			scope.categorylettername = '';
			scope.addDelegateDate = false;
			scope.addAttendants = false;
			scope.addRecipients = false;
			scope.addContractors = false;
			scope.originalText = null;
			scope.addRecipient = function() {
				var newObject = {contact: undefined};
				if( scope.letter.recipients === undefined ) { scope.letter.recipients=[]; }
				scope.letter.recipients.push(newObject);
			};
			scope.deleteRecipient = function(index) {
				scope.letter.recipients.splice(index, 1);
			};
			scope.addAttendant = function() {
				var newObject = {contact: undefined};
				if( scope.letter.attendants === undefined ) { scope.letter.attendants=[]; }
				scope.letter.attendants.push(newObject);
			};
			scope.deleteAttendant = function(index) {
				scope.letter.attendants.splice(index, 1);
			};
			scope.updateLetter = function() {
				if( scope.letter.text && scope.letter.text.length>1 ) {
					scope.letter.text = lettertextService.substituteClasses(scope.letter.text, {letter: scope.letter, doc: scope.document});
				} else if( scope.originalText ) {
					scope.letter.text = lettertextService.substituteTags(scope.originalText, {letter: scope.letter, doc: scope.document});
				}
			};
			scope.updateLetterCategory = function( ) {
				scope.letter.text = "";
				scope.letter.type = null;
				if( scope.lettertypes === undefined ) { return; }
				scope.lettertypes.forEach(function(lettertype) {
					if( lettertype.category == scope.letter.category ) {
						scope.categoryletters = lettertype.letters;
						/*if( scope.categoryletters.length ) {
							scope.letter.type = scope.categoryletters[0].name;
							scope.addRecipients = scope.categoryletters[0].addRecipients;
							scope.addDelegateDate = scope.categoryletters[0].addDelegateDate;
							scope.addContractors = scope.categoryletters[0].addContractors;
							scope.updateLetter();
						}*/
					}
				});
			};
			scope.updateLetterType= function( ) {
				scope.letter.text = null;
				scope.originalText = null;
			};

			// Next two trigger if category is changed
			scope.$watch('letter.category', function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				if( scope.lettertypes === undefined ) { return; }
				scope.lettertypes.forEach(function(lettertype) {
					if( lettertype.category === scope.letter.category ) {
						scope.categoryletters = lettertype.letters;
					}
				});
			});
			scope.$watch('lettertypes', function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				if( scope.letter === undefined ) { return; }
				scope.lettertypes.forEach(function(lettertype) {
					if( lettertype.category === scope.letter.category ) {
						scope.categoryletters = lettertype.letters;
					}
				});
			}, true);
			
			// Next triggers if type is changed
			scope.$watchGroup(['letter.type', 'categoryletters'], function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				if( angular.equals(newVal, oldVal ) ) { return; }
				scope.categoryletters.forEach(function(c) {
					if( c.name === scope.letter.type) {
						scope.addRecipients = c.addRecipients;
						scope.addAttendants = (scope.addAttendants === undefined ? false : c.addAttendants);
						scope.addDelegateDate = c.addDelegateDate;
						scope.addContractors = c.addContractors;
					}
				});
				if( scope.letter.type == "" ) {
					scope.letter.text = "";
					scope.originalText = null;
					return;
				}
				if( scope.letter.type && scope.letter.type.length ) {
					$http.get(appURL+'/crud/lettertexts/type/'+encodeURIComponent(scope.letter.type), {}).then( 
						function successCallback(response) {
							var data=response.data;
							if( data && data.text) {
								scope.originalText = data.text;
								scope.letter.title = data.description;
								scope.updateLetter();
							}
						}, function errorCallback(response) {
						}
					);
				}
			});
			
			// Next trigger if data are changed

			scope.$watch('letter.recipients', function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				if( angular.equals(newVal, oldVal ) ) { return; }
				scope.updateLetter();
			}, true);
			scope.$watch('letter.attendants', function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				if( angular.equals(newVal, oldVal ) ) { return; }
				scope.updateLetter();
			}, true);
			scope.$watchGroup(['letter.issuedate', 'letter.delegatedate', 'letter.subcontractor'], function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				if( angular.equals(newVal, oldVal ) ) { return; }
				scope.updateLetter();
			});

			scope.showRecipients = function() {
				return scope.letter.category && scope.letter.type && scope.addRecipients;
			};
			scope.showAttendants = function() {
				return scope.letter.category && scope.letter.type && scope.addAttendants;
			};
			scope.showDelegateDate = function() {
				return scope.letter.category && scope.letter.type && scope.addDelegateDate;
			};
			scope.showContractorsList = function() {
				return scope.letter.category && scope.letter.type && scope.addContractors;
			};
			if( scope.document && scope.document.company && scope.document.company.contractors ) {
				scope.contractors = scope.document.company.contractors.concat(scope.document.company.freelancers);
			}
		}
	}
}]).directive('lettersedit',['models','$http',  function (models, $http) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/lettersedit.html?t='+RootSeed,
        scope: { letters: '=', document : '=', doctype:'@'},
		link: function (scope, elem, attrs) {
			scope.lettertypes = [];
			scope.selecteletter = null;
			scope.selectLetter = function(index) {
				scope.selectedletter = scope.letters[index];
			};
			scope.addLetter= function() {
				var newObject = new models.Letter();
				scope.letters.push(newObject);
				scope.selectedletter = newObject;
			};
			scope.deleteLetter = function() {
				scope.letters.splice(scope.letters.indexOf(scope.selectedletter), 1);
				scope.selectedletter = (scope.letters.length ? scope.letters[0]:null);
			};
			scope.$watch('letters', function(newVal) {
				if( newVal ) {
					if( scope.selectedletter == null && scope.letters.length ) { 
						scope.selectedletter = scope.letters[0];
					}
				}
			}, true);
			scope.getLetters = function() {
				$http.get(appURL+'/crud/lettertexts/?nolimit=true&docType='+scope.doctype, {}).then( 
					function successCallback(response) {
						var data=response.data;
						if( data && data.length) {
							var lettersByCategory = {};
							var category;
							data.forEach(function(c) {
								if( c.category && c.category.length ) {
									if( lettersByCategory[c.category] === undefined ) { 
										lettersByCategory[c.category] = []; 
									}
									category = c.category;
								} else {
									if( lettersByCategory['Categoria non definita']=== undefined ) {
										lettersByCategory['Categoria non definita'] = [];
									}
									category = 'Categoria non definita';
								}
								lettersByCategory[category].push(c);
							});
							var sortedKeys = Object.keys(lettersByCategory).sort();
							var lettertypes = [];
							sortedKeys.forEach(function(key) {
								lettertypes.push({category:key, letters:lettersByCategory[key]});
							});
							scope.lettertypes = lettertypes;
						}
					}, function errorCallback(response) {
						alert('Si è verificato un errore. Si prega di riprovare');
					}
				);
			}
			scope.getLetters();
		}
	}
}]).directive('enterpriseemployedworkeredit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/enterpriseemployedworkeredit.html?t='+RootSeed,
        scope: { person: '=' },
		link: function (scope, elem, attrs) {
			scope.viewtype = { general: 'grid', formation:'grid'};
		}
	}
}]).directive('enterpriseemployedworkersedit',['models', function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/enterpriseemployedworkersedit.html?t='+RootSeed,
        scope: { employees: '=', enterprise : '=' },
		link: function (scope, elem, attrs) {
			scope.selectedemployee = null;
			scope.selectEmployee = function(index) {
				scope.selectedemployee = scope.employees[index];
			};
			scope.addEmployee = function() {
				var newObject = new models.Employee();
				scope.employees.push(newObject);
				scope.selectedemployee = newObject;
			};
			scope.deleteEmployee = function(index) {
				if( index >= scope.employees.length ) { return; }
				scope.employees.splice(index, 1);
				scope.selectedemployee = (scope.employees.length ? scope.employees[0]:null);
			};
			if( scope.employees.length ) { 
				scope.selectedemployee = scope.employees[0];
			}
		}
	}
}]).directive('professionaledit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/professionaledit.html?t='+RootSeed,
        scope: { professional: '=' },
		link: function (scope, elem, attrs) {
			scope.viewtype = { formation:'grid'};
		}
	}
}]).directive('professionalsedit',['models', function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/professionalsedit.html?t='+RootSeed,
        scope: { professionals: '=' },
		link: function (scope, elem, attrs) {
			scope.selectedprofessional = null;
			scope.selectProfessional= function(index) {
				scope.selectedprofessional = scope.professionals[index];
			};
			scope.addProfessional = function() {
				var newObject = new models.Professional();
				scope.professionals.push(newObject);
				scope.selectedprofessional = newObject;
			};
			scope.deleteProfessional = function(index) {
				if( index >= scope.professionals.length ) { return; }
				scope.professionals.splice(index, 1);
				scope.selectedprofessional = (scope.professionals.length ? scope.professionals[0]:null);
			};
			if( scope.professionals.length ) { 
				scope.selectedprofessional = scope.professionals[0];
			}
		}
	}
}]).directive('employedworkeredit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/employedworkeredit.html?t='+RootSeed,
        scope: { person: '=' },
		link: function (scope, elem, attrs) {}
	}
}]).directive('employedworkersedit',['models', function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/employedworkersedit.html?t='+RootSeed,
        scope: { employees: '=', enterprise : '=' },
		link: function (scope, elem, attrs) {
			scope.selectedemployee = null;
			scope.selectEmployee = function(index) {
				scope.selectedemployee = scope.employees[index];
			};
			scope.addEmployee = function() {
				var newObject = new models.Employee();
				scope.employees.push(newObject);
				scope.selectedemployee = newObject;
			};
			scope.deleteEmployee = function(index) {
				if( index >= scope.employees.length ) { return; }
				scope.employees.splice(index, 1);
				scope.selectedemployee = (scope.employees.length ? scope.employees[0]:null);
			};
			if( scope.employees.length ) { 
				scope.selectedemployee = scope.employees[0];
			}
		}
	}
}]).directive('freelanceredit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/freelanceredit.html?t='+RootSeed,
        scope: { person: '=' },
		link: function (scope, elem, attrs) {}
	}
}]).directive('freelancersedit',['models', function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/freelancersedit.html?t='+RootSeed,
        scope: { freelancers: '=' },
		link: function (scope, elem, attrs) {
			scope.selectedfreelancer = null;
			scope.selectFreelancer = function(index) {
				scope.selectedfreelancer = scope.freelancers[index];
			};
			scope.addFreelancer = function() {
				var newObject = new models.Freelancer();
				scope.freelancers.push(newObject);
				scope.selectedfreelancer = newObject;
			};
			scope.deleteFreelancer = function(index) {
				if( index >= scope.freelancers.length ) { return; }
				scope.freelancers.splice(index, 1);
				scope.selectedfreelancer = (scope.freelancers.length ? scope.freelancers[0]:null);
			};
			if( scope.freelancers.length ) { 
				scope.selectedfreelancer = scope.freelancers[0];
			}
		}
	}
}]).directive('contractorsedit',['models', function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/contractorsedit.html?t='+RootSeed,
        scope: { contractors: '=' },
		link: function (scope, elem, attrs) {
			scope.selectedcontractor = null;
			scope.selectContractor = function(index) {
				scope.selectedcontractor = scope.contractors[index];
			};
			scope.addContractor = function() {
				var newObject = new models.Contact();
				scope.contractors.push(newObject);
				scope.selectedcontractor = newObject;
			};
			scope.deleteContractor = function( index ) {
				if( index >= scope.contractors.length ) { return; }
				scope.contractors.splice(index, 1);
				scope.selectedcontractor = (scope.contractors.length ? scope.contractors[0]:null);
			};
			if( scope.contractors.length ) { 
				scope.selectedcontractor = scope.contractors[0];
			}
		}
	}
}]).directive('posworkedit',['$http', function ($http) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/posworkedit.html?t='+RootSeed,
        scope: { work: '=' },
		link: function (scope, elem, attrs) {}, 
		controller: function($scope) {
			$scope.getContacts = function(val) {
				return $http.get( appURL+'/utils/contacts'+( val ? '?search=' + val : '' ) ).then( function( res ) {
					res.data.forEach(function(c) {c.fullname = c.firstname+' '+c.lastname; });
					return res.data; 
				});
			};
			$scope.autocompleteContact = function($item) {
				$scope.work.subcontractor = ($item.iscompany ? $item.companyname : (($item.title.length?$item.title+' ':'')+' '+$item.firstname+' ' +$item.lastname));
			};
		}
	}
}]).directive('noiseratingedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/noiseratingedit.html?t='+RootSeed,
        scope: { noiserate: '=' },
		link: function (scope, elem, attrs) {
			scope.$watch('noiserate.exposure', function(newVal) {
				if( newVal === undefined ) { return; }
				switch( scope.noiserate.exposure ) {
					case 0:
						scope.noiserate.dues = "<p>Nessuno</p>";
						break;
					case 1:
						scope.noiserate.dues = "<p>Informazione e Formazione<br>Vengono messi a disposizione i DPI<br>Sorveglianza sanitaria su richiesta</p>";
						break;
					case 2:
						scope.noiserate.dues = "<p>Informazione e Formazione<br>Vengono messi a disposizione i DPI e ci si assicura che siano indossati<br>Sorveglianza sanitaria</p>";
						break;
					case 3:
						scope.noiserate.dues = "<p>Misure immediate per riportare l’esposizione sotto gli 87 dBA<br>Utilizzo obbligatorio dei DPI e calcolo di attenuazione degli stessi<br>Sorveglianza sanitaria</p>";
						break;
					default:
						break;
				}
			});
		}
	}
}]).directive('noiseratingsedit',['models', function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/noiseratingsedit.html?t='+RootSeed,
        scope: { rates: '=' },
		link: function (scope, elem, attrs) {
			scope.selectedrate = null;
			scope.$watchCollection('rates', function(newVal) {
				if( newVal === undefined ) { return; }
				if( scope.rates.length ) { 
					scope.selectedrate = scope.rates[0];
				}
			});
			scope.selectRate= function(index) {
				scope.selectedrate = scope.rates[index];
			};
			if( scope.rates.length ) { 
				scope.selectedrate = scope.rates[0];
			}
		}
	}
}]).directive('vibrationratingedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/vibrationratingedit.html?t='+RootSeed,
        scope: { vibrationrate: '=', type:'@' },
		link: function (scope, elem, attrs) {
			scope.$watch('vibrationrate.exposure', function(newVal) {
				if( newVal === undefined ) { return; }
				if( scope.type === 'handarm' ) {
					switch( scope.vibrationrate.exposure ) {
						case 0:
							scope.vibrationrate.dues = "<p>Per essi non è prevista alcuna prescrizione particolare.</p>";
							break;
						case 1:
							scope.vibrationrate.dues = "<p>Per essi verranno adottate le seguenti prescrizioni:<ul><li>a) informazione e formazione dei lavoratori esposti al rischio di vibrazioni meccaniche secondo art. 203 comma 1 lett. f del D.Lgs. 81/08 e s.m.i.</li><li>b) sorveglianza sanitaria periodica con periodicità di norma annuale o con periodicità diversa, stabilita dal medico competente</li><li>c) predisposizione di programma di manutenzione macchine ed attrezzature</li><li>d) limitazione della durata e dell’intensità dell’esposizione</li><li>e) fornitura di DPI, al fine di attenuare l’esposizione</li></ul></p>";
							break;
						case 2:
							scope.vibrationrate.dues = "<p>Per essi verranno messe in atto misure tecniche ed organizzative mirate a ridurre al minimo l’esposizione e i rischi che ne conseguono:<ul><li>a) altri metodi di lavoro che richiedono una minore esposizione a vibrazioni meccaniche</li><li>b) la scelta di attrezzature di lavoro adeguate concepite nel rispetto dei principi ergonomici e che producono, tenuto conto del lavoro da svolgere, il minor livello possibile di vibrazioni</li><li>c) la fornitura di attrezzature accessorie per ridurre i rischi di lesioni provocate dalle vibrazioni quali maniglie o guanti che attenuano la vibrazione trasmessa al sistema mano-braccio</li><li>d) la limitazione della durata e dell'intensità dell'esposizione</li><li>e) l'organizzazione di orari di lavoro appropriati, con adeguati periodi di riposo</li><li>f) adeguati programmi di manutenzione delle attrezzature di lavoro, del luogo di lavoro e dei sistemi sul luogo di lavoro</li><li>g) l'adeguata informazione e formazione dei lavoratori sull'uso corretto e sicuro delle attrezzature di lavoro, in modo da ridurre al minimo la loro esposizione a vibrazioni meccaniche</li><li>h) la progettazione e la riorganizzazione dei luoghi e dei posti di lavoro</li></ul></p>";
							break;
						default:
							break;
					}
				} else {
					switch( scope.vibrationrate.exposure ) {
						case 0:
							scope.vibrationrate.dues = "<p>Per essi non è prevista alcuna prescrizione particolare.</p>";
							break;
						case 1:
							scope.vibrationrate.dues = "<p>Per essi dovranno essere adottate le seguenti prescrizioni:<ul><li>a) informazione e formazione dei lavoratori esposti al rischio di vibrazioni meccaniche secondo art. 203 comma 1 lett. f del D.Lgs. 81/08 e s.m.i.</li><li>b) sorveglianza sanitaria periodica con periodicità di norma annuale o con periodicità diversa, stabilita dal medico competente</li><li>c) predisposizione di programma di manutenzione macchine ed attrezzature</li><li>d) limitazione della durata e dell’intensità dell’esposizione</li><li>e) fornitura di DPI, al fine di attenuare l’esposizione</li></ul></p>";
							break;
						case 2:
							scope.vibrationrate.dues = "<p>Per essi verranno messe in atto misure tecniche ed organizzative mirate a ridurre al minimo l’esposizione e i rischi che ne conseguono:<ul><li>a) altri metodi di lavoro che richiedono una minore esposizione a vibrazioni meccaniche</li><li>b) la scelta di attrezzature di lavoro adeguate concepite nel rispetto dei principi ergonomici e che producono, tenuto conto del lavoro da svolgere, il minor livello possibile di vibrazioni</li><li>c) la fornitura di attrezzature accessorie per ridurre i rischi di lesioni provocate dalle vibrazioni quali sedili che attenuano efficacemente le vibrazioni trasmesse al corpo intero</li><li>d) la limitazione della durata e dell'intensità dell'esposizione</li><li>e) l'organizzazione di orari di lavoro appropriati, con adeguati periodi di riposo</li><li>f) adeguati programmi di manutenzione delle attrezzature di lavoro, del luogo di lavoro e dei sistemi sul luogo di lavoro</li><li>g) l'adeguata informazione e formazione dei lavoratori sull'uso corretto e sicuro delle attrezzature di lavoro, in modo da ridurre al minimo la loro esposizione a vibrazioni meccaniche</li><li>h) la progettazione e la riorganizzazione dei luoghi e dei posti di lavoro</li></ul></p>";
							break;
						default:
							break;
					}
				}
			});
		}
	}
}]).directive('vibrationratingsedit',['models', function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/vibrationratingsedit.html?t='+RootSeed,
        scope: { rates: '=', type:'@'},
		link: function (scope, elem, attrs) {
			scope.selectedrate = null;
			scope.selectRate= function(index) {
				scope.selectedrate = scope.rates[index];
			};
			scope.$watchCollection('rates', function(newVal) {
				if( newVal === undefined ) { return; }
				if( scope.rates.length ) { 
					scope.selectedrate = scope.rates[0];
				}
			});
			if( scope.rates.length ) { 
				scope.selectedrate = scope.rates[0];
			}
		}
	}
}]).directive('formationedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/formationedit.html?t='+RootSeed,
      scope: { course: '=' },
		link: function (scope, elem, attrs) {
			scope.time = new Date().getTime();
			if( scope.course ) {
				if( scope.course.expirytype === undefined )  { scope.course.expirytype=0; }
				if( scope.course.validity === undefined )  { scope.course.validity=1; }
				if( scope.course.validitytype === undefined )  { scope.course.validitytype='anni'; }
			}
			scope.$watch('course', function(newValue, oldValue) {
				if( newValue === undefined ) { return; }
				if( scope.course.expirytype == 1) {
					if( scope.course.date != null && scope.course.validity > 0 )  {
						var duration = scope.course.validitytype == 'Giorni' ? 1 : (scope.course.validitytype == 'Mesi' ? 30 : 365);	
						scope.course.expirydate = new Date(scope.course.date.getTime()+scope.course.validity*86400*1000*duration);
					}
				}
			}, true);
		}
	}
}]).directive('servicedirectiveedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/servicedirectiveedit.html?t='+RootSeed,
        scope: { servicedirective: '=' },
		link: function (scope, elem, attrs) {}
	}
}]).directive('servicedirectivesedit',['models', function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/servicedirectivesedit.html?t='+RootSeed,
        scope: { servicedirectives: '=' },
		link: function (scope, elem, attrs) {
			scope.selectedservicedirective = null;
			scope.selectServicedirective = function(index) {
				scope.selectedservicedirective = scope.servicedirectives[index];
			};
			scope.$watch('servicedirectives', function(newvalue, oldvalue) {
				if( newvalue != oldvalue ) {
					if( !scope.servicedirectives.length ) { 
						scope.selectedservicedirective = null;
					} else {
						scope.selectedservicedirective = scope.servicedirectives[0];
					}
				}
			});
			scope.addServicedirective = function() {
				var newObject = new models.ServicedirectivePrescription();
				scope.servicedirectives.push(newObject);
				scope.selectedservicedirective = newObject;
			};
			scope.deleteServicedirective = function() {
				var index = scope.servicedirectives.indexOf(scope.selectedservicedirective);
				if( index < 0 || index >= scope.servicedirectives.length ) { return; }
				scope.servicedirectives.splice(index, 1);
				scope.selectedservicedirective = (scope.servicedirectives.length ? scope.servicedirectives[0]:null);
			};
		}
	}
}]).directive('specialcostsectionedit',['models','$uibModal', function (models, $uibModal) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/specialcostsectionedit.html?t='+RootSeed,
        scope: { section: '=' },
		link: function (scope, elem, attrs) {
			scope.selectedcost = null;
			scope.showEmptyRow = false;
			scope.changeDecimalDigits = function( curObj ) {
				switch( curObj.unit ) {
					case 'corpo':
					case 'cad':
					case 'giorno':
					case 'mese':
						curObj.decimaldigits = 0;
						break;
					case 'ora':
						curObj.decimaldigits = 1;
						break;
					default:
						curObj.decimaldigits = 2;
						break;
				}	
			};
			scope.addNewCost = function(category) {
				category.costs.push(new models.PSCCost(category.type));
				scope.selectedcost = category.type+"-"+(category.costs.length-1);
			}
			scope.addNewCostFromArchive = function(category) {
				if( category === undefined ) { return; }
				scope.newobjects = [];
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'directives/catobjectseditor/templates/catobjectspickerModal.html?t='+RootSeed,
					controller: ['$scope', '$uibModalInstance','section','objects','type','objectclass',  function (scope, $uibModalInstance, section,objects, type, objectclass ) {
						scope.data = {	objects: [], section: section, type: type, objectclass: objectclass };
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function() {
							$uibModalInstance.close(scope.data.objects);
						};
					}],
					
					resolve: { 
						section: function () { return category.type; },
						objects: function () { return scope.newobjects; },
						objectclass: function () { return 'costobjectitem'; },
						type: function () { return 'specialcosts'; }
					}
				});
				modalInstance.result.then(
					function successCallback(objects) {
						if( objects === undefined ) { return; }
						objects.forEach(function(c) {
							if( c.selected === true ) {
								var newObject = new models.PSCCost(category.type, c.name, c.code, c.unit, c.decimaldigits, c.price, c.addDuration);
								var found=false;
								for(var i=0; i<category.costs.length;i++ ) {
									if(category.costs[i].name == newObject.name ) {
										found = true;
									}
								}
								if( found==false ) {
									category.costs.push(newObject);
								}
								category.costs.sort(function(a, b) {
									var nameA=a.name, nameB=b.name;
									return (nameA < nameB) ? -1 : ((nameA > nameB) ? 1 : 0);
								});
							}
						});
					}, function errorCallback() {}
				);
			};
			
			scope.deleteCost = function(category, cost) {
				var idx = category.costs.indexOf(cost);
				if( idx != -1 ) {
					scope.selectedcost = null;
					category.costs.splice(idx, 1);
				}
				scope.closeEdit();
			}
			scope.addCostRow = function(cost) {
				if( cost.rows.length == 1 && cost.rows[0].description==='' && scope.showEmptyRow==false ) {
					scope.showEmptyRow = true;
				} else {
					cost.rows.push(new models.PSCCostRow());
				}
			}
			scope.deleteCostRow = function(cost, row) {
				if( cost.rows.length == 1 ) {
					cost.rows[0].description = "";
					cost.rows[0].quantity = 0;
					scope.showEmptyRow = false;
				} else {
					var idx = cost.rows.indexOf(row);
					if( idx != -1 ) {
						cost.rows.splice(idx, 1);
					}
				}
			}
			scope.closeEdit = function() {
				scope.selectedcost = null;
				scope.section.total = 0;
				scope.section.categories.forEach(function(category) {
					category.total = 0;
					category.costs.forEach(function(cost) {
						cost.total = 0;
						cost.quantity = 0;
						cost.rows.forEach(function(costrow) {
							costrow.total = costrow.quantity*cost.price;
							if( cost.addDuration ) { 
								costrow.total *= costrow.duration; 
								cost.quantity += (costrow.quantity*costrow.duration);
							} else {
								cost.quantity += costrow.quantity;
							}
							cost.total += costrow.total;
						});
						category.total += cost.total;
					});
					scope.section.total += category.total;
				});
			}
			scope.displayNameForCategory = function(category) {
				var name;
				switch(category.type) {
					case 'A':
						name = "A. Apprestamenti";
						break;
					case 'B':
						name = "B. Misure preventive e protettive";
						break;
					case 'C':
						name = "C. Impianti";
						break;
					case 'D':
						name = "D. Mezzi e servizi di protezione collettiva";
						break;
					case 'E':
						name = "E. Procedure contenute nel PSC";
						break;
					case 'F':
						name = "F. Sfasamento spaziale o temporale";
						break;
					case 'G':
						name = "G. Misure di coordinamento";
						break;
					default:
						break;
				}
				if( category.costs.length ) {
					if( category.costs.length == 1 ) {
						name += " (1 voce)";
					} else {
						name += " ("+category.costs.length+" voci)";
					}
				}
				return name;
			}
			scope.selectCost = function(id) {
				scope.selectedcost = id;
				scope.showEmptyRow = false;
			}
		}
	}
}]).directive('specialcostsectionsedit',['models', function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/specialcostsectionsedit.html?t='+RootSeed,
        scope: { sections: '=' },
		link: function (scope, elem, attrs) {
			scope.total = 0;
			scope.selectedsection = null;
			scope.selectSection = function(index) {
				scope.selectedsection = scope.sections[index];
			};
			scope.$watch('sections', function(newvalue, oldvalue) {
				if( newvalue != oldvalue ) {
					scope.updateTotal();
				}
			}, true);
			scope.updateTotal = function() {
				scope.total = 0;
				scope.sections.forEach(function(section) {
					scope.total += section.total;
				});
			};
			scope.addSection = function() {
				var newObject = new models.PSCCostSection('');
				scope.sections.push(newObject);
				scope.selectedsection = newObject;
			};
			scope.deleteSection = function() {
				var index = scope.sections.indexOf(scope.selectedsection);
				if( index<0 || index >= scope.sections.length ) { return; }
				scope.sections.splice(index, 1);
				scope.selectedsection = (scope.sections.length ? scope.sections[0]:null);
			};
			scope.duplicateSection = function() {
				var index = scope.sections.indexOf(scope.selectedsection);
				if( index<0 || index >= scope.sections.length ) { return; }
				var newSection = JSON.parse(JSON.stringify(scope.selectedsection));
				newSection.name = newSection.name + "[COPIA]";
				scope.sections.push(newSection);
				scope.selectedsection = newSection;
			};
			scope.moveUp = function() {
				var index = scope.sections.indexOf(scope.selectedsection);
				if( index == 0 ) { return; }
				scope.sections.splice(index, 1);
				scope.sections.splice(index-1, 0, scope.selectedsection);
    		};
			scope.moveDown = function() {
				var index = scope.sections.indexOf(scope.selectedsection);
				if( index == scope.sections.length-1 ) { return; }
				scope.sections.splice(index, 1);
				scope.sections.splice(index+1, 0, scope.selectedsection);
    		};

			if( !scope.sections.length ) { 
				scope.selectedsection = null;
			} else {
				scope.selectedsection = scope.sections[0];
			}
			scope.updateTotal();
		}
	}
}]).directive('dailymenatworksectionsedit',['models', function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/dailymenatworksectionsedit.html?t='+RootSeed,
        scope: { sections: '=', workerdays:'=', workerhourlycost:'=' },
		link: function (scope, elem, attrs) {
			scope.total = 0;
			scope.grandtotal = 0;
			scope.$watch('workerhourlycost', function(newvalue, oldvalue) {
				if( newvalue != oldvalue ) {
					scope.workerdays = Math.ceil(scope.total/(scope.workerhourlycost*8));
				}
			});
			scope.$watch('sections', function(newvalue, oldvalue) {
				if( newvalue != oldvalue ) {
					scope.updateTotal();
				}
			}, true);
			scope.updateTotal = function() {
				scope.total = 0;
				scope.grandtotal = 0;
				scope.sections.forEach(function(section) {
					section.works.forEach(function(work) {
						scope.total += work.percentage*work.cost*0.01;
						scope.grandtotal += work.cost;
					});
				});
				scope.workerdays = Math.ceil(scope.total/(scope.workerhourlycost*8));
			}
			scope.selectedsection = null;
			scope.selectSection = function(index) {
				scope.selectedsection = scope.sections[index];
			};
			scope.addSection = function() {
				var newObject = new models.DailymenatworkSection();
				scope.sections.push(newObject);
				scope.selectedsection = newObject;
			};
			scope.deleteSection = function() {
				var index = scope.sections.indexOf(scope.selectedsection);
				if( index<0 || index >= scope.sections.length ) { return; }
				scope.sections.splice(index, 1);
				scope.selectedsection = (scope.sections.length ? scope.sections[0]:null);
			};
			if( !scope.sections.length ) { 
				scope.selectedsection = null;
			} else {
				scope.selectedsection = scope.sections[0];
			}
			scope.updateTotal();
		}
	}
}]).directive('dailymenatworksectionedit',['models', function ( models ) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/dailymenatworksectionedit.html?t='+RootSeed,
        scope: { section: '=' },
		link: function (scope, elem, attrs) {
			scope.work = { edit: -1, inEdidObject:{}};
			scope.total = 0;
			scope.grandtotal = 0;
			scope.$watch('section.works', function(newvalue, oldvalue) {
				if( newvalue != oldvalue ) {
					scope.updateTotal();
				}
			}, true);
			scope.updateTotal = function() {
				scope.total = 0;
				scope.grandtotal = 0;
				scope.section.works.forEach(function(work) {
					scope.total += work.percentage*work.cost*0.01;
					scope.grandtotal += work.cost;
				});
			};
			scope.addNewWork = function() {
				var newObject = new models.DailymenatworkWork("Lavori generici");
				scope.section.works.push(newObject);
				scope.work={edit:scope.section.works.length-1, inEditObject:angular.copy( newObject )};
			}
			scope.editWork = function(idx) {
				if( idx<0 || idx >= scope.section.works.length ) { return; } 
				scope.work={edit:idx, inEditObject:angular.copy( scope.section.works[idx] )};
			};
			scope.deleteWork = function(idx) {
				if( idx<0 || idx >= scope.section.works.length ) { return; } 
				scope.work.edit = -1
				scope.section.works.splice(idx,1);
				
			};
			scope.saveWork = function(idx) {
				if( idx<0 || idx >= scope.section.works.length ) { return; } 
				scope.work.edit = -1
				scope.section.works[idx]= angular.copy(scope.work.inEditObject);			
			};
			scope.updateTotal();
		}
	}
}]).directive('buildingdocedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/buildingdocedit.html?t='+RootSeed,
        scope: { doc: '=' },
		link: function (scope, elem, attrs) {
		}
	}
}]).directive('buildingdocsedit',['models', function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/buildingdocsedit.html?t='+RootSeed,
        scope: { docs: '=' },
		link: function (scope, elem, attrs) {
			scope.selecteddoc = null;
			scope.selectDoc = function(index) {
				scope.selecteddoc = scope.docs[index];
			};
			scope.$watch('docs', function(newvalue, oldvalue) {
				if( newvalue != oldvalue ) {
					if( !scope.docs.length ) { 
						scope.selecteddoc = null;
					} else {
						scope.selecteddoc = scope.docs[0];
					}
				}
			});
			scope.addDoc = function() {
				var newObject = new models.BuildingDoc();
				scope.docs.push(newObject);
				scope.selecteddoc = newObject;
			};
			scope.deleteDoc = function() {
				var index = scope.docs.indexOf(scope.selecteddoc);
				if( index<0 || index >= scope.docs.length ) { return; }
				scope.docs.splice(index, 1);
				scope.selecteddoc = (scope.docs.length ? scope.docs[0]:null);
			};
		}
	}
}]).directive('buildingdocsheetedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/buildingdocsheetedit.html?t='+RootSeed,
        scope: { sheet: '=' },
		link: function (scope, elem, attrs) {}
	}
}]).directive('buildingdocsheetsedit',['models','$uibModal', function (models, $uibModal) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/buildingdocsheetsedit.html?t='+RootSeed,
        scope: { sheets: '=' },
		link: function (scope, elem, attrs) {
			scope.selectedsheet = null;
			scope.selectSheet = function(index) {
				scope.selectedsheet = scope.sheets[index];
			};
			scope.addSheet = function() {
				var newObject = new models.EmbeddedBuildingdocSheet();
				scope.sheets.push(newObject);
				scope.selectedsheet = newObject;
			};
			scope.deleteSheet = function(index) {
				if( index >= scope.sheets.length ) { return; }
				scope.sheets.splice(index, 1);
				scope.selectedsheet = (scope.sheets.length ? scope.sheets[0]:null);
			};
			if( scope.sheets.length ) { 
				scope.selectedsheet = scope.sheets[0];
			}
		}
	}
}]).directive('servicedirectivesheetedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/servicedirectivesheetedit.html?t='+RootSeed,
        scope: { sheet: '=' },
		link: function (scope, elem, attrs) {}
	}
}]).directive('servicedirectivesheetsedit',['models','$uibModal', function (models, $uibModal) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/servicedirectivesheetsedit.html?t='+RootSeed,
        scope: { sheets: '=' },
		link: function (scope, elem, attrs) {
			scope.selectedsheet = null;
			scope.changeSheet = function(sheet) {
				scope.selectedsheet = sheet;
			};
			scope.copySheet = function() {
				var sheet = angular.copy(scope.selectedsheet);
				sheet.name = "Copia di "+scope.selectedsheet.name;
				scope.sheets.push(sheet);
				scope.changeSheet(sheet);	
			};
			scope.moveSheet = function(delta) {
				if( !scope.sheets ) { return; }
				var idx = scope.sheets.indexOf(scope.selectedsheet);
				var newIdx = idx+delta;
				if( idx == -1 ) { return; }
				if( (delta<0 && idx>0) || (delta>0 && idx<(scope.sheets.length-1 ))) {
					var tmp = scope.selectedsheet;
					scope.sheets.splice(idx,1);
					scope.sheets.splice(newIdx,0,tmp);
				}
			};
			scope.addSheet = function() {
				var newObject = new models.EmbeddedServicedirectiveSheet();
				scope.sheets.push(newObject);
				scope.selectedsheet = newObject;
			};
			scope.addSheetFromArchive = function() {
				scope.newobjects = [];
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'directives/catobjectseditor/templates/catobjectspickerModal.html?t='+RootSeed,
					controller: ['$scope', '$uibModalInstance','objects', function (scope, $uibModalInstance,objects ) {
						scope.searchtext="";
						scope.data = {	objects: [], section: 'MIS', type: 'servicedirectives', objectclass: 'worksheetobjectitem', sheetcrudurl : 'servicedirectivesheets?servicedirective' };
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function() {
							$uibModalInstance.close(scope.data.objects);
						};
					}],
					
					resolve: { 
						objects: function () { return scope.newobjects; },
					}
				});
				modalInstance.result.then(
					function successCallback(objects) {
						if( objects === undefined ) { return; }
						objects.forEach(function(c) {
							if( c.selected === true ) {
								c.sheets.forEach(function(sheet) {
									if( sheet.selected === true ) {
										var newSheet = angular.copy(sheet);
										delete newSheet['_id'];
										delete newSheet['shared'];
										delete newSheet['servicedirective'];
										var found=false;
										for(var i=0; i<scope.sheets.length;i++ ) {
											if(scope.sheets[i].name == newSheet.name ) {
												found = true;
											}
										}
										if( found==false ) {
											scope.sheets.push(newSheet);
										}
									}
								});
							}
						});
						if( scope.sheets.length ) { scope.selectedsheet = scope.sheets[scope.sheets.length-1];}
					}, function errorCallback() {}
				);
			};

			scope.deleteSheet = function() {
				scope.sheets.splice(scope.sheets.indexOf( scope.selectedsheet ),1);
				if( scope.sheets.length ) { 
					scope.selectedsheet = scope.sheets[0];
				} else {
					scope.selectedsheet = null;
				}
			};
			if( scope.sheets.length ) { 
				scope.selectedsheet = scope.sheets[0];
			}
		}
	}
}]).directive('maintenancesheetedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/maintenancesheetedit.html?t='+RootSeed,
        scope: { sheet: '=' },
		link: function (scope, elem, attrs) {
			scope.editingscheduledtimeframe = -1;
			scope.toggleTimeframeEdit = function( index ) {
				scope.editingscheduledtimeframe = index;
			};
			scope.addScheduledWork = function() {
				if( scope.sheet.scheduledworks === undefined || scope.sheet.scheduledworks.length === undefined ) { scope.sheet.scheduledworks = []; }
				scope.sheet.scheduledworks.push({description:'Nuova lavorazione', timeframe:'1 mese'});
			};
			scope.deleteScheduledWork = function(idx) {
				if( scope.sheet.scheduledworks === undefined || scope.sheet.scheduledworks.length === undefined ) { return; }
				if( idx >=0 && idx < scope.sheet.scheduledworks.length ) {
					scope.sheet.scheduledworks.splice(idx, 1);
				}
			};
		}
	}
}]).directive('maintenancesheetsedit',['models','$uibModal', function (models, $uibModal) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/maintenancesheetsedit.html?t='+RootSeed,
        scope: { sheets: '=' },
		link: function (scope, elem, attrs) {
			scope.selectedsheet = null;

			scope.changeSheet = function(sheet) {
				scope.selectedsheet = sheet;
			};
			scope.copySheet = function() {
				var sheet = angular.copy(scope.selectedsheet);
				sheet.name = "Copia di "+scope.selectedsheet.name;
				scope.sheets.push(sheet);
				scope.changeSheet(sheet);	
			};
			scope.moveSheet = function(delta) {
				if( !scope.sheets ) { return; }
				var idx = scope.sheets.indexOf(scope.selectedsheet);
				var newIdx = idx+delta;
				if( idx == -1 ) { return; }
				if( (delta<0 && idx>0) || (delta>0 && idx<(scope.sheets.length-1 ))) {
					var tmp = scope.selectedsheet;
					scope.sheets.splice(idx,1);
					scope.sheets.splice(newIdx,0,tmp);
				}
			};
			scope.addSheet = function() {
				var newObject = new models.EmbeddedMaintenanceSheet();
				scope.sheets.push(newObject);
				scope.selectedsheet = newObject;
			};
			scope.addSheetFromArchive = function() {
				scope.newobjects = [];
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'directives/catobjectseditor/templates/catobjectspickerModal.html?t='+RootSeed,
					controller: ['$scope', '$uibModalInstance','objects', function (scope, $uibModalInstance,objects ) {
						scope.searchtext="";
						scope.data = {	objects: [], section: 'MAN', type: 'maintenances', objectclass: 'worksheetobjectitem', sheetcrudurl : 'maintenancesheets?maintenance' };
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function() {
							$uibModalInstance.close(scope.data.objects);
						};
					}],
					
					resolve: { 
						objects: function () { return scope.newobjects; },
					}
				});
				modalInstance.result.then(
					function successCallback(objects) {
						if( objects === undefined ) { return; }
						objects.forEach(function(c) {
							if( c.selected === true ) {
								c.sheets.forEach(function(sheet) {
									if( sheet.selected === true ) {
										var newSheet = angular.copy(sheet);
										delete newSheet['_id'];
										delete newSheet['shared'];
										delete newSheet['maintenance'];
										var found=false;
										for(var i=0; i<scope.sheets.length;i++ ) {
											if(scope.sheets[i].name == newSheet.name ) {
												found = true;
											}
										}
										if( found==false ) {
											scope.sheets.push(newSheet);
										}
									}
								});
							}
						});
						if( scope.sheets.length ) { scope.selectedsheet = scope.sheets[scope.sheets.length-1];}
					}, function errorCallback() {}
				);
			};

			scope.deleteSheet = function() {
				scope.sheets.splice(scope.sheets.indexOf( scope.selectedsheet ),1);
				if( scope.sheets.length ) { 
					scope.selectedsheet = scope.sheets[0];
				} else {
					scope.selectedsheet = null;
				}
			};
			
			if( scope.sheets.length ) { 
				scope.selectedsheet = scope.sheets[0];
			}
		}
	}
}]).directive('worksheetedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/worksheetedit.html?t='+RootSeed,
        scope: { worksheet: '=', hidepscsection: '@', hidepossection: '@' },
		link: function (scope, elem, attrs) {}
	}
}]).directive('zonetemplateobjectedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/zonetemplateobjectedit.html?t='+RootSeed,
        scope: { zonetemplateobject: '=' },
		link: function (scope, elem, attrs) {}
	}
}]).directive('macroworkobjectedit',[function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/macroworkobjectedit.html?t='+RootSeed,
        scope: { macroworkobject: '=' },
		link: function (scope, elem, attrs) {
			scope.contractors = [];
			scope.specifystartdate = false;
		}
	}
}]).directive('addressedit',['$http', function ($http) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/addressedit.html?t='+RootSeed,
        scope: { address: '=' },
		link: function (scope, elem, attrs) {
			scope.getCities = function(val) {
				return $http.get( appURL+'/utils/cities'+( val ? '?search=' + encodeURIComponent(val) : '' ) ).then( 
					function successCallback( response ) { 
						return response.data; 
					}, function errorCallback( response ) {} 
				);
			};
		
			scope.getCountries = function(val) {
				return $http.get( appURL+'/utils/countries'+( val ? '?search=' + encodeURIComponent(val) : '' )).then( 
					function successCallback( response ) { 
						return response.data; 
					}, function errorCallback( response ) {} 
				);
			};
		
			scope.autocompleteAddress=function() {
				$http.get( appURL+'/utils/cities?search='+encodeURIComponent(scope.address.city), {}).then(
					function successCallback( response) {
						var data=response.data;
						if( data.length ) {
							if( data[0].zipcode ) { scope.address.zipcode=data[0].zipcode;}
							if( data[0].stateshort ) { scope.address.state=data[0].stateshort;}
							if( data[0].region ) { scope.address.region=data[0].region;}
							scope.address.country = 'Italia';
						}
					}, function errorCallback() {}
				);
			};
		}
	}
}]).directive('contactedit',['$uibModal','$http','models',function($uibModal, $http, models)  {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/contactedit.html?t='+RootSeed,
        scope: { contact: '=', shownotes:'@', editrole:'@', contacttype:'@',editsubcontractors:'=', editcolor:'@',deletecallback:'&'},
		link: function ($scope, elem, attrs) {
			$scope.editcontact = function() {
				var modalInstance = $uibModal.open({
					templateUrl: 'editcontactmodal.html',
					size: 'lg',
					controller: ['$scope', '$uibModalInstance', 'contact','contacttype', function (scope, $uibModalInstance, contact, contacttype) {
						scope.contacttype = contacttype;
						scope.updatecontacts = true;
						scope.object = (contact === undefined ? {} : angular.copy(contact));
						scope.object.emails = (scope.object.emails === undefined ? [{tag:'', email:''}] : scope.object.emails);
						scope.object.phones = (scope.object.phones === undefined ? [{tag:'', phone:''}] : scope.object.phones);
						scope.object.tags = (scope.object.tags===undefined? []:scope.object.tags);
						if( scope.object._id === undefined ) {
							scope.object._id = models.clientId();
						}
						if( scope.object.birthdate ) {scope.object.birthdate = new Date(scope.object.birthdate);}
						scope.originalid = scope.object._id;
						scope.updatestoredcontactdisabled = false;
						scope.$watch('object', function(newval) {
							if( newval === undefined ) { return; }
							if( scope.object._id !== scope.originalid ) {
								scope.updatecontacts = false;
								scope.updatestoredcontactdisabled = true;
							}
						}, true);
						var typesearch='';
						if(scope.object.isfreelancer === undefined ) {
							scope.object.isfreelancer = false; 
						}
						if( scope.contacttype === 'person' ) {
							scope.object.iscompany = false; 
							typesearch = "&type=person";
						} else if ( scope.contacttype === 'freelancer' ) {
							scope.object.iscompany = true; 
							scope.object.isfreelancer = true; 
							// Compatibility 
							if( (!scope.object.companyname || !scope.object.companyname.length) && (scope.object.lastname && scope.object.lastname.length && scope.object.firstname && scope.object.firstname.length )) {
								scope.object.companyname = scope.object.firstname+' '+scope.object.lastname;
							}
							typesearch = "&type=society";
						} else if( scope.contacttype === 'society' ) {
							scope.object.iscompany = true; 
							typesearch = "&type=society";
						} else if( scope.contacttype === 'agency' ) {
							scope.object.iscompany = true; 
							typesearch = "&type=society";
						}
						scope.getContacts = function(val) {
							return $http.get( appURL+'/utils/contacts'+( val ? '?search=' + encodeURIComponent(val)+typesearch : '' ) ).then( 
								function successCallback( response ) {
									return response.data; 
								}, function errorCallback( response ) {}
							);
						};
						scope.getAteco = function(val) {
							return $http.get( appURL+'/utils/ateco'+( val ? '?search=' + encodeURIComponent(val) : '' ) ).then( 
								function successCallback( response ) {
									return response.data; 
								}, function errorCallback( response ) {}
							);
						};
						scope.autocompleteAteco = function($item) {
							scope.object.ateco = { code: $item.code, description: $item.description };
						};
						
						scope.autocompleteContact = function($item) {
							var isfreelancer = scope.object.isfreelancer;
							scope.object = $item;
							if( scope.object.isfreelancer === undefined ) {
								scope.object.isfreelancer = isfreelancer;
							}
						};
						scope.getCities = function(val) {
							return $http.get( appURL+'/utils/cities'+( val ? '?search=' + encodeURIComponent(val) : '' ) ).then( 
								function successCallback( response ) {
									return response.data; 
								}, function errorCallback( response ) {}
							);
						};
					
						scope.getCountries = function(val) {
							return $http.get( appURL+'/utils/countries'+( val ? '?search=' + encodeURIComponent(val) : '' )).then( 
								function successCallback( response ) {
									return response.data; 
								}, function errorCallback( response ) {}
							);
						};
					
						scope.autocompleteAddress=function(address) {
							$http.get( appURL+'/utils/cities?search='+encodeURIComponent(address.city), {}).then(
								function successCallback( response ) {
									var data=response.data;
									if( data.length ) {
										if( data[0].zipcode ) { address.zipcode=data[0].zipcode;}
										if( data[0].stateshort ) { address.state=data[0].stateshort;}
										if( data[0].region ) { address.region=data[0].region;}
										address.country = 'Italia';
									}
								}, function errorCallback( response ) {}
							);
						};
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function() {
							$uibModalInstance.close({object: scope.object, update: scope.updatecontacts});
						};
					}],
					resolve: {
						contact: function () { return $scope.contact.contact; },
						contacttype: function() { return $scope.contacttype; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
						if( data.update == true ) {
							$http.post(appURL+'/crud/contacts', {'object':data.object }).then(
								function successCallback(response){							
									data.object._id = response.data;
									$scope.contact.contact = angular.copy(data.object);
								}, function errorCallback(response){
								}
							);
						} else {
							$scope.contact.contact = angular.copy(data.object);
						}
					}, function errorCallback() {}
				);
			};
			
			$scope.resetContact = function() {
				$scope.contact.contact = undefined;
				if( $scope.deletecallback !== undefined ) {
					$scope.deletecallback();
				}
			};
			$scope.$watch('editsubcontractors', function(newValue) {
				if( newValue ) {
					$scope.updateContractors();
				}
			}, true);
			$scope.updateContractors = function() {
				$scope.contractors=[];
				if( !$scope.editsubcontractors ) { return;}
				var found=false;
				var tmpContractors = [];
				for( var i=0;i<$scope.editsubcontractors.length;i++ ) {
					if( !found && $scope.editsubcontractors[i].contact ) { 
						if( $scope.contact.contact && $scope.editsubcontractors[i].contact._id==$scope.contact.contact._id ) { 
							found=true;
						} else {
							var cnt = $scope.editsubcontractors[i].contact;
							tmpContractors.push({fullname:cnt.companyname, _id:cnt._id});
						}
					}
				}
				$scope.contractors=tmpContractors;
			};
			$scope.getRoles = function(val) {
				return $http.get( appURL+'/utils/roles'+( val ? '?search=' + val : '' ) ).then( 
					function successCallback( response ) {
						return response.data; 
					}, function errorCallback( response ) {}
				);
			};
		}
	}
}]).directive('googlemapzoom',[ function () {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/googlemapzoom.html?t='+RootSeed,
		restrict: 'C',
        scope: { zoom: '=' },
		link: function (scope, elem, attrs) {
			scope.inc = function() {
				scope.zoom = Math.min(scope.zoom+1, 20);
			};
			scope.dec = function() {
				scope.zoom = Math.max(scope.zoom-1, 4);
			};
		}
	}
}]).directive('googlemap',['uiGmapGoogleMapApi','$timeout', function (uiGmapGoogleMapApi, $timeout) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/googlemap.html?t='+RootSeed,
        scope: { center: '=', zoom: '=', type: '@?', address: '=?', halfcolumn : '=?', hidelocatebutton :'=?' },
		link: function (scope, elem, attrs) {
			scope.ready = false;
			if( scope.type === undefined ) { scope.type = 'roadmap'; }
			if( scope.address ) {
				scope.srcAddress = JSON.parse(JSON.stringify(scope.address));
			} else {
				scope.srcAddress = {};
			}
			scope.showUpdateAlert = false;
			scope.halfcolumn = (scope.halfcolumn === undefined ? false : scope.halfcolumn);
			scope.hidelocatebutton = (scope.hidelocatebutton === undefined ? false : scope.hidelocatebutton);
			scope.render = false;
			scope.map={ 
				center: { longitude:9.276364, latitude: 45.655122}, 
				zoom:15,
				options: { 
					scrollwheel: false,
					panControl: true,
					mapTypeControl : false,
					draggable: true,
					streetViewControl: false,
					zoomControl: true,
					fullscreenControl: false,
					mapTypeId : scope.type
				},
				events: {
					zoom_changed: function( map, eventName, args ) {
						if( scope.zoom !== undefined ) {
							scope.zoom = map.getZoom();
						}
					}
				}
			}; 
			scope.$watch('address', function(newVal) {
				if( newVal === undefined) { return; }
					if( scope.address.city != scope.srcAddress.city || scope.address.address != scope.srcAddress.address) {
						scope.showUpdateAlert = true;
					}
			}, true);
			scope.$watch('center', function(newVal) {
				if( newVal === undefined) { return; }
				  	scope.map.center.longitude = scope.center.longitude;
				  	scope.map.center.latitude = scope.center.latitude;
				  	scope.marker.coords.longitude = scope.center.longitude;
				  	scope.marker.coords.latitude = scope.center.latitude;
			}, true);
			scope.marker = {
				id: 0,
				coords: {
					latitude: scope.map.center.latitude,
					longitude: scope.map.center.longitude
				},
				options: { draggable: true },
				events: {
					dragend: function (marker, eventName, args) {
						var lat = marker.getPosition().lat();
						var lon = marker.getPosition().lng();
						scope.center.longitude = lon;
						scope.center.latitude = lat;
						
						// Try to get address
						if( scope.address === undefined ) { return; }
						var geocoder = new google.maps.Geocoder();
						geocoder.geocode({'location': {lat:lat, lng:lon}}, function(results, status) {
							if (status === google.maps.GeocoderStatus.OK) {
								if (results[0]) {
									var adcomp = results[0].address_components;
									if( adcomp === undefined || adcomp.length==0) { return;}
									var address = {};
									adcomp.forEach(function(c) {
										if( c.types !== undefined && c.types.length ) {
											c.types.forEach(function(d) {
												if( d=='country' ) {
													address.country = (c.long_name=='Italy'?'Italia':c.long_name);
												} else if( d=='postal_code') {
													address.zipcode = c.long_name;
												} else if( d=='administrative_area_level_1' ) {
													address.region = c.long_name;
												} else if( d=='administrative_area_level_2' ) {
													address.state = c.short_name;
												} else if( d=='administrative_area_level_3' ) {
													address.city = c.long_name;
												} else if( d=='route' ) {
													address.route = c.long_name;
												} else if( d=='street_number' ) {
													address.streetnumber = c.long_name;
												}
											});
										}
									});
									if( address.country ) { scope.address.country = address.country; }
									if( address.region ) { scope.address.region = address.region; }
									if( address.zipcode ) { scope.address.zipcode = address.zipcode; }
									if( address.state ) { scope.address.state = address.state; }
									if( address.city ) { scope.address.city = address.city; }
									if( address.route ) { scope.address.address = address.route; }
									if( address.streetnumber ) { scope.address.address = scope.address.address +', '+address.streetnumber; }
								}
							} 
						});
					}
				}
			};
		    scope.geocode = function() {
				if( scope.address === undefined || scope.map===undefined || scope.address.address ===undefined || scope.address.city===undefined) { return; }
			   var geocoder = new google.maps.Geocoder();
				geocoder.geocode({ 'address': scope.address.address+', '+scope.address.city }, function(results, status) {
			  		if (status == google.maps.GeocoderStatus.OK) {
				  		var loc = results[0].geometry.location;
				  		var lat = loc.lat();
				  		var lng = loc.lng();
				  		scope.center.longitude = lng;
				  		scope.center.latitude = lat;
						scope.marker.coords.longitude = lng;
						scope.marker.coords.latitude = lat;
				  		scope.map.center.longitude = lng;
				  		scope.map.center.latitude = lat;
				  		scope.srcAddress = JSON.parse(JSON.stringify(scope.address));
				  		scope.showUpdateAlert = false;
				  	}
				});
			};
			uiGmapGoogleMapApi.then(function(maps) {
				scope.ready = true;
				if( scope.center && scope.center.latitude !== undefined && scope.center.longitude !== undefined ) {
				  	scope.map.center.longitude = scope.center.longitude;
				  	scope.map.center.latitude = scope.center.latitude;
				  	scope.marker.coords.longitude = scope.center.longitude;
				  	scope.marker.coords.latitude = scope.center.latitude;
				}
				if( scope.zoom !== undefined ) {
					scope.map.zoom = scope.zoom;
				}
				$timeout(function () { scope.render = true; },0);
		    });
		}
	}
}])
.service('ngDraggable', [function() {
    var scope = this;
    scope.inputEvent = function(event) {
        if (angular.isDefined(event.touches)) {
            return event.touches[0];
        }
        //Checking both is not redundent. If only check if touches isDefined, angularjs isDefnied will return error and stop the remaining scripty if event.originalEvent is not defined.
        else if (angular.isDefined(event.originalEvent) && angular.isDefined(event.originalEvent.touches)) {
            return event.originalEvent.touches[0];
        }
        return event;
    };

    scope.touchTimeout = 100;
}])
.directive('ngDrag', ['$rootScope', '$parse', '$document', '$window', 'ngDraggable', function ($rootScope, $parse, $document, $window, ngDraggable) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                scope.value = attrs.ngDrag;
                var offset,_centerAnchor=false,_mx,_my,_tx,_ty,_mrx,_mry;
                var _hasTouch = ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch;
                var _pressEvents = 'touchstart mousedown';
                var _moveEvents = 'touchmove mousemove';
                var _releaseEvents = 'touchend mouseup';
                var _dragHandle;

                // to identify the element in order to prevent getting superflous events when a single element has both drag and drop directives on it.
                var _myid = scope.$id;
                var _data = null;

                var _dragOffset = null;

                var _dragEnabled = false;

                var _pressTimer = null;

                var onDragStartCallback = $parse(attrs.ngDragStart) || null;
                var onDragStopCallback = $parse(attrs.ngDragStop) || null;
                var onDragSuccessCallback = $parse(attrs.ngDragSuccess) || null;
                var allowTransform = angular.isDefined(attrs.allowTransform) ? scope.$eval(attrs.allowTransform) : true;

                var getDragData = $parse(attrs.ngDragData);

                // deregistration function for mouse move events in $rootScope triggered by jqLite trigger handler
                var _deregisterRootMoveListener = angular.noop;

                var initialize = function () {
                    element.attr('draggable', 'false'); // prevent native drag
                    // check to see if drag handle(s) was specified
                    // if querySelectorAll is available, we use this instead of find
                    // as JQLite find is limited to tagnames
                    if (element[0].querySelectorAll) {
                        var dragHandles = angular.element(element[0].querySelectorAll('[ng-drag-handle]'));
                    } else {
                        var dragHandles = element.find('[ng-drag-handle]');
                    }
                    if (dragHandles.length) {
                        _dragHandle = dragHandles;
                    }
                    toggleListeners(true);
                };

                var toggleListeners = function (enable) {
                    if (!enable)return;
                    // add listeners.

                    scope.$on('$destroy', onDestroy);
                    scope.$watch(attrs.ngDrag, onEnableChange);
                    scope.$watch(attrs.ngCenterAnchor, onCenterAnchor);
                    // wire up touch events
                    if (_dragHandle) {
                        // handle(s) specified, use those to initiate drag
                        _dragHandle.on(_pressEvents, onpress);
                    } else {
                        // no handle(s) specified, use the element as the handle
                        element.on(_pressEvents, onpress);
                    }
                    // if(! _hasTouch && element[0].nodeName.toLowerCase() == "img"){
                    if( element[0].nodeName.toLowerCase() == "img"){
                        element.on('mousedown', function(){ return false;}); // prevent native drag for images
                    }
                };
                var onDestroy = function (enable) {
                    toggleListeners(false);
                };
                var onEnableChange = function (newVal, oldVal) {
                    _dragEnabled = (newVal);
                };
                var onCenterAnchor = function (newVal, oldVal) {
                    if(angular.isDefined(newVal))
                        _centerAnchor = (newVal || 'true');
                };

                var isClickableElement = function (evt) {
                    return (
                        angular.isDefined(angular.element(evt.target).attr("ng-cancel-drag"))
                    );
                };
                /*
                 * When the element is clicked start the drag behaviour
                 * On touch devices as a small delay so as not to prevent native window scrolling
                 */
                var onpress = function(evt) {
                    // console.log("110"+" onpress: "+Math.random()+" "+ evt.type);
                    if(! _dragEnabled)return;

                    if (isClickableElement(evt)) {
                        return;
                    }

                    if (evt.type == "mousedown" && evt.button != 0) {
                        // Do not start dragging on right-click
                        return;
                    }

                    var useTouch = evt.type === 'touchstart' ? true : false;


                    if(useTouch){
                        cancelPress();
                        _pressTimer = setTimeout(function(){
                            cancelPress();
                            onlongpress(evt);
                        },ngDraggable.touchTimeout);
                        $document.on(_moveEvents, cancelPress);
                        $document.on(_releaseEvents, cancelPress);
                    }else{
                        onlongpress(evt);
                    }

                };

                var cancelPress = function() {
                    clearTimeout(_pressTimer);
                    $document.off(_moveEvents, cancelPress);
                    $document.off(_releaseEvents, cancelPress);
                };

                var onlongpress = function(evt) {
                    if(! _dragEnabled)return;
                    evt.preventDefault();

                    offset = element[0].getBoundingClientRect();
                    if(allowTransform)
                        _dragOffset = offset;
                    else{
                        _dragOffset = {left:document.body.scrollLeft, top:document.body.scrollTop};
                    }


                    element.centerX = element[0].offsetWidth / 2;
                    element.centerY = element[0].offsetHeight / 2;

                    _mx = ngDraggable.inputEvent(evt).pageX;//ngDraggable.getEventProp(evt, 'pageX');
                    _my = ngDraggable.inputEvent(evt).pageY;//ngDraggable.getEventProp(evt, 'pageY');
                    _mrx = _mx - offset.left;
                    _mry = _my - offset.top;
                    if (_centerAnchor) {
                        _tx = _mx - element.centerX - $window.pageXOffset;
                        _ty = _my - element.centerY - $window.pageYOffset;
                    } else {
                        _tx = _mx - _mrx - $window.pageXOffset;
                        _ty = _my - _mry - $window.pageYOffset;
                    }

                    $document.on(_moveEvents, onmove);
                    $document.on(_releaseEvents, onrelease);
                    // This event is used to receive manually triggered mouse move events
                    // jqLite unfortunately only supports triggerHandler(...)
                    // See http://api.jquery.com/triggerHandler/
                    // _deregisterRootMoveListener = $rootScope.$on('draggable:_triggerHandlerMove', onmove);
                    _deregisterRootMoveListener = $rootScope.$on('draggable:_triggerHandlerMove', function(event, origEvent) {
                        onmove(origEvent);
                    });
                };

                var onmove = function (evt) {
                    if (!_dragEnabled)return;
                    evt.preventDefault();

                    if (!element.hasClass('dragging')) {
                        _data = getDragData(scope);
                        element.addClass('dragging');
                        $rootScope.$broadcast('draggable:start', {x:_mx, y:_my, tx:_tx, ty:_ty, event:evt, element:element, data:_data});

                        if (onDragStartCallback ){
                            scope.$apply(function () {
                                onDragStartCallback(scope, {$data: _data, $event: evt});
                            });
                        }
                    }

                    _mx = ngDraggable.inputEvent(evt).pageX;//ngDraggable.getEventProp(evt, 'pageX');
                    _my = ngDraggable.inputEvent(evt).pageY;//ngDraggable.getEventProp(evt, 'pageY');

                    if (_centerAnchor) {
                        _tx = _mx - element.centerX - _dragOffset.left;
                        _ty = _my - element.centerY - _dragOffset.top;
                    } else {
                        _tx = _mx - _mrx - _dragOffset.left;
                        _ty = _my - _mry - _dragOffset.top;
                    }

                    moveElement(_tx, _ty);

                    $rootScope.$broadcast('draggable:move', { x: _mx, y: _my, tx: _tx, ty: _ty, event: evt, element: element, data: _data, uid: _myid, dragOffset: _dragOffset });
                };

                var onrelease = function(evt) {
                    if (!_dragEnabled)
                        return;
                    evt.preventDefault();
                    $rootScope.$broadcast('draggable:end', {x:_mx, y:_my, tx:_tx, ty:_ty, event:evt, element:element, data:_data, callback:onDragComplete, uid: _myid});
                    element.removeClass('dragging');
                    element.parent().find('.drag-enter').removeClass('drag-enter');
                    reset();
                    $document.off(_moveEvents, onmove);
                    $document.off(_releaseEvents, onrelease);

                    if (onDragStopCallback ){
                        scope.$apply(function () {
                            onDragStopCallback(scope, {$data: _data, $event: evt});
                        });
                    }

                    _deregisterRootMoveListener();
                };

                var onDragComplete = function(evt) {


                    if (!onDragSuccessCallback )return;

                    scope.$apply(function () {
                        onDragSuccessCallback(scope, {$data: _data, $event: evt});
                    });
                };

                var reset = function() {
                    if(allowTransform)
                        element.css({transform:'', 'z-index':'', '-webkit-transform':'', '-ms-transform':''});
                    else
                        element.css({'position':'',top:'',left:''});
                };

                var moveElement = function (x, y) {
                    if(allowTransform) {
                        element.css({
                            transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ' + x + ', ' + y + ', 0, 1)',
                            'z-index': 99999,
                            '-webkit-transform': 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ' + x + ', ' + y + ', 0, 1)',
                            '-ms-transform': 'matrix(1, 0, 0, 1, ' + x + ', ' + y + ')'
                        });
                    }else{
                        element.css({'left':x+'px','top':y+'px', 'position':'fixed'});
                    }
                };
                initialize();
            }
        };
    }])
.directive('ngDrop', ['$parse', '$timeout', '$window', '$document', 'ngDraggable', function ($parse, $timeout, $window, $document, ngDraggable) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            scope.value = attrs.ngDrop;
            scope.isTouching = false;

            var _lastDropTouch=null;

            var _myid = scope.$id;

            var _dropEnabled=false;

            var onDropCallback = $parse(attrs.ngDropSuccess);// || function(){};

            var onDragStartCallback = $parse(attrs.ngDragStart);
            var onDragStopCallback = $parse(attrs.ngDragStop);
            var onDragMoveCallback = $parse(attrs.ngDragMove);

            var initialize = function () {
                toggleListeners(true);
            };

            var toggleListeners = function (enable) {
                // remove listeners

                if (!enable)return;
                // add listeners.
                scope.$watch(attrs.ngDrop, onEnableChange);
                scope.$on('$destroy', onDestroy);
                scope.$on('draggable:start', onDragStart);
                scope.$on('draggable:move', onDragMove);
                scope.$on('draggable:end', onDragEnd);
            };

            var onDestroy = function (enable) {
                toggleListeners(false);
            };
            var onEnableChange = function (newVal, oldVal) {
                _dropEnabled=newVal;
            };
            var onDragStart = function(evt, obj) {
                if(! _dropEnabled)return;
                isTouching(obj.x,obj.y,obj.element);

                if (attrs.ngDragStart) {
                    $timeout(function(){
                        onDragStartCallback(scope, {$data: obj.data, $event: obj});
                    });
                }
            };
            var onDragMove = function(evt, obj) {
                if(! _dropEnabled)return;
                isTouching(obj.x,obj.y,obj.element);

                if (attrs.ngDragMove) {
                    $timeout(function(){
                        onDragMoveCallback(scope, {$data: obj.data, $event: obj});
                    });
                }
            };

            var onDragEnd = function (evt, obj) {

                // don't listen to drop events if this is the element being dragged
                // only update the styles and return
                if (!_dropEnabled || _myid === obj.uid) {
                    updateDragStyles(false, obj.element);
                    return;
                }
                if (isTouching(obj.x, obj.y, obj.element)) {
                    // call the ngDraggable ngDragSuccess element callback
                    if(obj.callback){
                        obj.callback(obj);
                    }

                    if (attrs.ngDropSuccess) {
                        $timeout(function(){
                            onDropCallback(scope, {$data: obj.data, $event: obj, $target: scope.$eval(scope.value)});
                        });
                    }
                }

                if (attrs.ngDragStop) {
                    $timeout(function(){
                        onDragStopCallback(scope, {$data: obj.data, $event: obj});
                    });
                }

                updateDragStyles(false, obj.element);
            };

            var isTouching = function(mouseX, mouseY, dragElement) {
                var touching= hitTest(mouseX, mouseY);
                scope.isTouching = touching;
                if(touching){
                    _lastDropTouch = element;
                }
                updateDragStyles(touching, dragElement);
                return touching;
            };

            var updateDragStyles = function(touching, dragElement) {
                if(touching){
                    element.addClass('drag-enter');
                    dragElement.addClass('drag-over');
                }else if(_lastDropTouch == element){
                    _lastDropTouch=null;
                    element.removeClass('drag-enter');
                    dragElement.removeClass('drag-over');
                }
            };

            var hitTest = function(x, y) {
                var bounds = element[0].getBoundingClientRect();// ngDraggable.getPrivOffset(element);
                x -= $document[0].body.scrollLeft + $document[0].documentElement.scrollLeft;
                y -= $document[0].body.scrollTop + $document[0].documentElement.scrollTop;
                return  x >= bounds.left
                    && x <= bounds.right
                    && y <= bounds.bottom
                    && y >= bounds.top;
            };

            initialize();
        }
    };
}])
.directive('ngDragClone', ['$parse', '$timeout', 'ngDraggable', function ($parse, $timeout, ngDraggable) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var img, _allowClone=true;
            var _dragOffset = null;
            scope.clonedData = {};
            var initialize = function () {

                img = element.find('img');
                element.attr('draggable', 'false');
                img.attr('draggable', 'false');
                reset();
                toggleListeners(true);
            };


            var toggleListeners = function (enable) {
                // remove listeners

                if (!enable)return;
                // add listeners.
                scope.$on('draggable:start', onDragStart);
                scope.$on('draggable:move', onDragMove);
                scope.$on('draggable:end', onDragEnd);
                preventContextMenu();

            };
            var preventContextMenu = function() {
                //  element.off('mousedown touchstart touchmove touchend touchcancel', absorbEvent_);
                img.off('mousedown touchstart touchmove touchend touchcancel', absorbEvent_);
                //  element.on('mousedown touchstart touchmove touchend touchcancel', absorbEvent_);
                img.on('mousedown touchstart touchmove touchend touchcancel', absorbEvent_);
            };
            var onDragStart = function(evt, obj, elm) {
                _allowClone=true;
                if(angular.isDefined(obj.data.allowClone)){
                    _allowClone=obj.data.allowClone;
                }
                if(_allowClone) {
                    scope.$apply(function () {
                        scope.clonedData = obj.data;
                    });
                    element.css('width', obj.element[0].offsetWidth);
                    element.css('height', obj.element[0].offsetHeight);

                    moveElement(obj.tx, obj.ty);
                }

            };
            var onDragMove = function(evt, obj) {
                if(_allowClone) {

                    _tx = obj.tx + obj.dragOffset.left;
                    _ty = obj.ty + obj.dragOffset.top;

                    moveElement(_tx, _ty);
                }
            };
            var onDragEnd = function(evt, obj) {
                //moveElement(obj.tx,obj.ty);
                if(_allowClone) {
                    reset();
                }
            };

            var reset = function() {
                element.css({left:0,top:0, position:'fixed', 'z-index':-1, visibility:'hidden'});
            };
            var moveElement = function(x,y) {
                element.css({
                    transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, '+x+', '+y+', 0, 1)', 'z-index': 99999, 'visibility': 'visible',
                    '-webkit-transform': 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, '+x+', '+y+', 0, 1)',
                    '-ms-transform': 'matrix(1, 0, 0, 1, '+x+', '+y+')'
                    //,margin: '0'  don't monkey with the margin,
                });
            };

            var absorbEvent_ = function (event) {
                var e = event;//.originalEvent;
                e.preventDefault && e.preventDefault();
                e.stopPropagation && e.stopPropagation();
                e.cancelBubble = true;
                e.returnValue = false;
                return false;
            };

            initialize();
        }
    };
}])
.directive('ngPreventDrag', ['$parse', '$timeout', function ($parse, $timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var initialize = function () {

                element.attr('draggable', 'false');
                toggleListeners(true);
            };


            var toggleListeners = function (enable) {
                // remove listeners

                if (!enable)return;
                // add listeners.
                element.on('mousedown touchstart touchmove touchend touchcancel', absorbEvent_);
            };


            var absorbEvent_ = function (event) {
                var e = event.originalEvent;
                e.preventDefault && e.preventDefault();
                e.stopPropagation && e.stopPropagation();
                e.cancelBubble = true;
                e.returnValue = false;
                return false;
            };

            initialize();
        }
    };
}])
.directive('ngCancelDrag', [function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            element.find('*').attr('ng-cancel-drag', 'ng-cancel-drag');
        }
    };
}])
.directive('ngDragScroll', ['$window', '$interval', '$timeout', '$document', '$rootScope', function($window, $interval, $timeout, $document, $rootScope) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var intervalPromise = null;
            var lastMouseEvent = null;

            var config = {
                verticalScroll: attrs.verticalScroll || true,
                horizontalScroll: attrs.horizontalScroll || true,
                activationDistance: attrs.activationDistance || 75,
                scrollDistance: attrs.scrollDistance || 15
            };


            var reqAnimFrame = (function() {
                return window.requestAnimationFrame ||
                    window.webkitRequestAnimationFrame ||
                    window.mozRequestAnimationFrame ||
                    window.oRequestAnimationFrame ||
                    window.msRequestAnimationFrame ||
                    function( /* function FrameRequestCallback */ callback, /* DOMElement Element */ element ) {
                        window.setTimeout(callback, 1000 / 60);
                    };
            })();

            var animationIsOn = false;
            var createInterval = function() {
                animationIsOn = true;

                function nextFrame(callback) {
                    var args = Array.prototype.slice.call(arguments);
                    if(animationIsOn) {
                        reqAnimFrame(function () {
                            $rootScope.$apply(function () {
                                callback.apply(null, args);
                                nextFrame(callback);
                            });
                        })
                    }
                }

                nextFrame(function() {
                    if (!lastMouseEvent) return;

                    var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                    var viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

                    var scrollX = 0;
                    var scrollY = 0;

                    if (config.horizontalScroll) {
                        // If horizontal scrolling is active.
                        if (lastMouseEvent.clientX < config.activationDistance) {
                            // If the mouse is on the left of the viewport within the activation distance.
                            scrollX = -config.scrollDistance;
                        }
                        else if (lastMouseEvent.clientX > viewportWidth - config.activationDistance) {
                            // If the mouse is on the right of the viewport within the activation distance.
                            scrollX = config.scrollDistance;
                        }
                    }

                    if (config.verticalScroll) {
                        // If vertical scrolling is active.
                        if (lastMouseEvent.clientY < config.activationDistance) {
                            // If the mouse is on the top of the viewport within the activation distance.
                            scrollY = -config.scrollDistance;
                        }
                        else if (lastMouseEvent.clientY > viewportHeight - config.activationDistance) {
                            // If the mouse is on the bottom of the viewport within the activation distance.
                            scrollY = config.scrollDistance;
                        }
                    }



                    if (scrollX !== 0 || scrollY !== 0) {
                        // Record the current scroll position.
                        var currentScrollLeft = ($window.pageXOffset || $document[0].documentElement.scrollLeft);
                        var currentScrollTop = ($window.pageYOffset || $document[0].documentElement.scrollTop);

                        // Remove the transformation from the element, scroll the window by the scroll distance
                        // record how far we scrolled, then reapply the element transformation.
                        var elementTransform = element.css('transform');
                        element.css('transform', 'initial');

                        $window.scrollBy(scrollX, scrollY);

                        var horizontalScrollAmount = ($window.pageXOffset || $document[0].documentElement.scrollLeft) - currentScrollLeft;
                        var verticalScrollAmount =  ($window.pageYOffset || $document[0].documentElement.scrollTop) - currentScrollTop;

                        element.css('transform', elementTransform);

                        lastMouseEvent.pageX += horizontalScrollAmount;
                        lastMouseEvent.pageY += verticalScrollAmount;

                        $rootScope.$emit('draggable:_triggerHandlerMove', lastMouseEvent);
                    }

                });
            };

            var clearInterval = function() {
                animationIsOn = false;
            };

            scope.$on('draggable:start', function(event, obj) {
                // Ignore this event if it's not for this element.
                if (obj.element[0] !== element[0]) return;

                if (!animationIsOn) createInterval();
            });

            scope.$on('draggable:end', function(event, obj) {
                // Ignore this event if it's not for this element.
                if (obj.element[0] !== element[0]) return;

                if (animationIsOn) clearInterval();
            });

            scope.$on('draggable:move', function(event, obj) {
                // Ignore this event if it's not for this element.
                if (obj.element[0] !== element[0]) return;

                lastMouseEvent = obj.event;
            });
        }
    };
}]).directive('btncustom', [function () {
    return {
        restrict: 'C',
        scope: {
            hoverClass: '@'
        },
        link: function (scope, element) {
            element.on('mouseenter', function() {
                element.addClass('active');
            });
            element.on('mouseleave', function() {
                element.removeClass('active');
            });
        }
    };
}]).service('models', [function() {
	// typecheck.js in routes
	var parent = this;
	
	// Constants
	this.PSCCALENDAR = 1;
	this.POSCALENDAR = 2;
	
	this.seed = 0;
	this.clientId = function() {
		parent.seed++;
		return '_'+((new Date()).getTime())+'_'+parent.seed;
	};
	this.Weekday = function(name, isworking) {
		this.name = name || 'Lunedì';
		this.isworking = (isworking !== undefined) ? isworking : true;
	};
	this.Holiday = function(name, date) {
		this.name = name || 'Natale';
		this.date = new Date(date);
		this.date.setHours(12);
	};

	this.defaultWeekdays = function() {
		var weekdays = [new parent.Weekday('Domenica',false), new parent.Weekday('Luned&igrave;',true), new parent.Weekday('Marted&igrave;',true), new parent.Weekday('Mercoled&igrave;',true), new parent.Weekday('Gioved&igrave;',true), new parent.Weekday('Venerd&igrave;',true), new parent.Weekday('Sabato',false) ];
		return weekdays;
	};
	
	this.defaultHolidays = function() { 
		var holidays = [new parent.Holiday('Capodanno','2000-01-01'), new parent.Holiday('Epifania','2000-01-06'), new parent.Holiday('Pasqua','2016-03-27'), new parent.Holiday('Luned&igrave; dell\'Angelo','2016-03-28'), new parent.Holiday('Festa della Liberazione','2000-04-25'), new parent.Holiday('Festa del lavoro','2000-05-01'), new parent.Holiday('Festa della Repubblica','2000-06-02'), new parent.Holiday('Ferragosto','2000-08-15'), new parent.Holiday('Immacolata','2000-12-08'), new parent.Holiday('Natale','2000-12-25'), new parent.Holiday('Pasqua','2017-04-16'), new parent.Holiday('Luned&igrave; dell\'Angelo','2017-04-17'), new parent.Holiday('Pasqua','2018-04-21'), new parent.Holiday('Luned&igrave; dell\'Angelo','2018-04-22')];
		return holidays;
	};

	this.ImageId = function() {
		return null;
	};
	this.ImageObj=  function(id, caption) {
		this.id = (id===undefined ? null : id);
		this.caption = (caption===undefined ? '' : caption);
	};
	this.Phone=  function(phone, tag) {
		this.phone = (phone===undefined ? '' : phone);
		this.tag = (tag===undefined ? '' : tag);
	};
	this.Email=  function(email, tag) {
		this.email = (email===undefined ? '' : email);
		this.tag = (tag===undefined ? '' : tag);
	};
	this.MapCoords=  function(longitude, latitude, zoom) {
		this.center = {longitude: (longitude===undefined ? 9.276364 : parseFloat(longitude)), latitude:(latitude===undefined ? 45.655122 : parseFloat(latitude)) };
		this.zoom = (zoom===undefined ? 15 : parseFloat(zoom));
	};
	
	this.Address = function(address, city, zipcode, state, region, country) {
		this.address = (address===undefined ? '' : address);
		this.city = (city===undefined ? '' : city);
		this.zipcode = (zipcode===undefined ? '' : zipcode);
		this.state = (state===undefined ? '' : state);
		this.region = (region===undefined ? '' : region);
		this.country = (country===undefined ? '' : country);
	};
	this.Account = function(name,fiscalid, vat, phones, emails, legaladdress, operatingaddress) {
		this.name = (name===undefined ? '' : name);
		this.fiscalid = (fiscalid===undefined ? '' : fiscalid);
		this.vat = (vat===undefined ? '' : vat);
		this.phones = (phones===undefined ? [new parent.Phone()] : angular.copy(phones));
		this.emails= (emails===undefined ? [new parent.Email()] : angular.copy(emails));
		this.legaladdress = (legaladdress===undefined ? new parent.Address() : angular.copy(legaladdress));
		this.operatingaddress = new parent.Address();
	};
	this.WorksiteEnterpriseConformance = function() {
		this.status = 0;
		this.warnings = 0;
		this.errors = 0;
	};
	
	this.WorksiteEnterprise = function(isfreelancer) {
		this.contact = new parent.Contact(true);
		this.contact.isfreelancer = (isfreelancer === undefined ? false : isfreelancer);
		this.work = '';
		this.activityobject = '';
		this.issupplier = false;
		this.isnotified = false;
		this.firstaccessstatus = 2;
		this.worksiteaccessdate = new Date();
		this.worksiteduration = 30;
		this.onhold = false;
		this.color = '100,109,223';
		this.code = '';
		this.notes = '';
		this.referencedenterprise = {_id:null, account: null, status:0};
		this.conformance = { status: 0, notes:0, enterprise: new parent.WorksiteEnterpriseConformance(), documents:  new parent.WorksiteEnterpriseConformance(), workers:  new parent.WorksiteEnterpriseConformance() };
		this.refemployees = [];
		this.refsubcontractors = [];
		this.reftemporaryworks = [];
		this.refmachines = [];
		this.refsubstances = [];
		this.refenterprisedocs = [];
		this.refoperatingdocs = [];
		this.customcheckreportspos = [];
		this.customcheckreportsitp = [];
		this.checkreportspos = new parent.WorksiteDocSection(['chkpos']);
		this.checkreportsitp = new parent.WorksiteDocSection(['chkitp']);
	};
	this.WorksiteEnterpriseSubcontractor = function(isfreelancer) {
		this.contact = new parent.Contact(true);
		this.contact.isfreelancer = (isfreelancer === undefined ? false : isfreelancer);
		this.work = '';
		this.issupplier = false;
		this.submittalstatus = 0;
	};
	
	this.Enterprise = function(name, fiscalid, vat, phones, emails, legaladdress) {
		this.name = (name===undefined ? '' : name);
		this.ateco = { code:"", description:"" };
		this.fiscalid = (fiscalid===undefined ? '' : fiscalid);
		this.vat = (vat===undefined ? '' : vat);
		this.inail = '';
		this.inps = '';
		this.cassaedile = '';
		this.ccnl = '';
		this.activityobject = '';
		this.phones = (phones===undefined ? [new parent.Phone()] : angular.copy(phones));
		this.emails= (emails===undefined ? [new parent.Email()] : angular.copy(emails));
		this.pec = '';
		this.legaladdress = (legaladdress===undefined ? new parent.Address() : angular.copy(legaladdress));
		this.operatingaddress = new parent.Address();
		this.depositaddress = new parent.Address();
		this.executivechiefs = [];
		this.safetychiefs = [];
		this.workerschiefs = [];
		this.doctor = new parent.Actor();
		this.delegates = [];
		this.managers = [];
		this.technicians = [];
		this.safetyofficers = [];
		this.worksitechiefs = [];
		this.emergencychiefs = [];
		this.healthemergencyofficers = [];
		this.art97officers = [];
		this.firstaidpeople = [];
		this.firefightpeople = [];
		this.employees = [];
		this.temporaryworks = [];
		this.machines = [];
		this.plants = [];
		this.dpis = [];
		this.allegeddocs = [];
		this.enterprisedocs = [];
		this.noiseratesdocs = [];
		this.vibrationratesdocs = []; 
		this.noiserates = []; 
		this.vibrationrates1 = []; 
		this.vibrationrates2 = []; 
		this.usenoiseratesdocs = 0;
		this.usevibrationratesdocs = 0;
		this.firstpagelogo=null; 
		this.innerpagelogo=null; 
	};
	
	this.Professionalstudy = function(name, fiscalid, vat, phones, emails, legaladdress) {
		this.name = (name===undefined ? '' : name);
		this.fiscalid = (fiscalid===undefined ? '' : fiscalid);
		this.vat = (vat===undefined ? '' : vat);
		this.phones = (phones===undefined ? [new parent.Phone()] : angular.copy(phones));
		this.emails= (emails===undefined ? [new parent.Email()] : angular.copy(emails));
		this.legaladdress = (legaladdress===undefined ? new parent.Address() : angular.copy(legaladdress));
		this.professionals = [];
		this.firstpagelogo=null; 
		this.innerpagelogo=null; 
	};
	this.Professional = function(contact, formationdocs ) {
		this._id = parent.clientId();
		this.contact = (contact===undefined ? undefined : angular.copy(contact));
		this.formationdocs = (formationdocs===undefined ? [] : angular.copy(formationdocs));
		this.signaturelogo=null; 
	};

	this.Contact = function(iscompany, title, firstname, lastname, companyname, fiscalid, vat, tags, phones, emails, address) {
		this.iscompany = (iscompany===undefined ? false : iscompany);
		this.isfreelancer = false;
		this.title = (title===undefined ? '' : title);
		this.firstname = (firstname===undefined ? '' : firstname);
		this.lastname = (lastname===undefined ? '' : lastname);
		this.companyname = (companyname===undefined ? '' : companyname);
		this.fiscalid = (fiscalid===undefined ? '' : fiscalid);
		this.vat = (vat===undefined ? '' : vat);
		this.phones = (phones===undefined ? [new parent.Phone()] : angular.copy(phones));
		this.emails= (emails===undefined ? [new parent.Email()] : angular.copy(emails));
		this.address = (address===undefined ? {} : angular.copy(address));
	};
	this.Actor = function(contact, notes, role ) {
		this._id = parent.clientId();
		this.contact = (contact===undefined ? undefined : angular.copy(contact));
		this.notes = (notes===undefined ? '' : notes);
		this.role = (role===undefined ? '' : role);
	};
	this.OptionalActor = function(contact, notes, role, exists) {
		this._id = parent.clientId();
		this.contact = (contact===undefined ? undefined : angular.copy(contact));
		this.notes = (notes===undefined ? '' : notes);
		this.role = (role===undefined ? '' : role);
		this.exists = (exists===undefined ? false : exists);
	};
	this.Employee = function(contact, role, contract, ability, formation, formationdocs, generaldocs ) {
		this._id = parent.clientId();
		this.contact = (contact===undefined ? undefined : angular.copy(contact));
		this.role = (role===undefined ? '' : role);
		this.contract = (contract===undefined ? 'Indeterminato' : contract);
		this.contractexpirydate = null;
		this.ability = (ability===undefined ? 'Idoneo' : ability);
		this.formation = (formation===undefined ? [] : angular.copy(formation));
		this.documents = [];
		this.certificates = [];
		// Legacy
		this.formationdocs = (formationdocs===undefined ? [] : angular.copy(formationdocs));
		this.generaldocs = (generaldocs===undefined ? [] : angular.copy(generaldocs));
	};
	this.Freelancer = function(contact, activity, education, ability ) {
		this.contact = (contact===undefined ? undefined : angular.copy(contact));
		this.activity = (activity===undefined ? '' : activity);
		this.education = (education===undefined ? '' : education);
		this.ability = (ability===undefined ? 'Idoneo' : ability);
		this.useeducationdocs = false;
		this.useabilitydocs = false;
	};
	this.Signatures = function() {
		this.safetychief = { exists: false, description: '' };
		this.safetyofficer = { exists: false, description: '' };
		this.doctor = { exists: false, description: '' };
		this.workerschief = { exists: false, description: '' };
		this.enterprise = { exists: false, description: '' };
		this.cse = { exists: false, description: '' };
	};
	this.Letter = function() {
		this._id = parent.clientId();
		this.category = "";
		this.type = "";
		this.title = "Lettera di....";
		this.recipients = [];
		this.subcontractor = null;
		this.issuedate = new Date();
		this.delegatedate = new Date();
		this.text = "";
	};
	this.CheckReport = function() {
		this._id = parent.clientId();
		this.reportindex = 1;
		this.title = "Verifica";
		this.recipients = [];
		this.issuedate = new Date();
		this.place = "";
		this.file = { fileId: null, status: 'waiting', error: null, revision:0 };
	};

	this.Report = function() {
		this._id = parent.clientId();
		this.category = "";
		this.subcategory = "";
		this.type = "";
		this.reportindex = 1;
		this.title = "Verbale di....";
		this.recipients = [];
		this.issuedate = new Date();
		this.starttime = new Date(1970, 0, 1, 9, 0, 0);
		this.endtime = new Date(1970, 0, 1, 10, 0, 0);
		this.text = "";
		this.enterprises = [];
		this.attendants = [];
		this.prescriptions_prev = [];
		this.prescriptions_A = [];
		this.prescriptions_B = [];
		this.prescriptions_C = [];
		this.prescriptions_D = [];
		this.prescriptions_E = [];
		this.prescriptions_F = [];
		this.addimagessection = false;
		this.addlayoutsection = false;
		this.addpresentemployees = false;
		this.addpresentassets = false;
		this.images = [];
		this.layouts = [];
		this.allegeddocs = [];
		this.presentemployees = [];
		this.presentassets = [];
		this.file = { fileId: null, status: 'waiting', error: null, revision:0 };
	};
	this.OrganizationChart = function() {
		this._id = parent.clientId();
		this.title = "Organigramma";
		this.issuedate = new Date();
		this.enterprises = [];
		this.techniciansandvisitors = [];
		this.file = { fileId: null, status: 'waiting', error: null, revision:0 };
	};
	this.Context = function() {
		this.north = { exists: false, description: '' };
		this.northeast = { exists: false, description: '' };
		this.east = { exists: false, description: '' };
		this.southeast = { exists: false, description: '' };
		this.south = { exists: false, description: '' };
		this.southwest = { exists: false, description: '' };
		this.west = { exists: false, description: '' };
		this.northwest = { exists: false, description: '' };
	};
	this.Layout = function(name, papersize, imageid ) {
		this._id = parent.clientId();
		this.name = (name===undefined ? 'Layout di cantiere' : name);
		this.papersize = (papersize===undefined ? 'A4' : papersize);
		this.imageid = (imageid===undefined ? null : imageid);
	};
	this.Asset = function(category, manifacturer, model, serialid, documentation, works,allegeddocs ) {
		this._id = parent.clientId();
		this.category = (category===undefined ? '' : category);
		this.manifacturer = (manifacturer===undefined ? '' : manifacturer);
		this.model = (model===undefined ? '' : model);
		this.serialid = (serialid===undefined ? '' : serialid);
		this.works = {};
		this.allegeddocs = (allegeddocs===undefined ? [] : allegeddocs);
		this.documents = [];
	};
	this.Substance = function(name) {
		this._id = parent.clientId();
		this.name = (name===undefined ? '' : name);
		this.works = {};
		this.descriptions = [];
		this.documents = [];
	};
	this.DPI = function(category, role, data, works ) {
		this._id = parent.clientId();
		this.category = (category===undefined ? 'Elmetto' : category);
		this.role = (role===undefined ? '' : role);
		this.data = (data===undefined ? '' : data);
		this.works = {};
	};
	this.Rate = function(role, exposure, dues, exposurevalue ) {
		this.role = (role===undefined ? '' : role);
		this.exposure = (exposure===undefined ? 0 : exposure);
		this.dues = (dues===undefined ? '' : dues);
		this.exposurevalue =  (exposurevalue===undefined ? 0 : exposurevalue);
	};
	this.Catobject = function(notes, objects, images, drawings) {
		this.norisk  = false;
		this.notes = (notes===undefined ? '' : notes);
		this.objects = (objects===undefined ? [] : angular.copy(objects));
		this.images = (images===undefined ? [] : angular.copy(images));
		this.drawings = (drawings===undefined ? [] : angular.copy(drawings));
	};
	this.CatobjectNoRiskOption = function(notes, objects, images, drawings) {
		this.norisk  = false;
		this.notes = (notes===undefined ? '' : notes);
		this.objects = (objects===undefined ? [] : angular.copy(objects));
		this.images = (images===undefined ? [] : angular.copy(images));
		this.drawings = (drawings===undefined ? [] : angular.copy(drawings));
	};
	this.Catobjectwithgarbage = function(notes, objects, images, drawings, garbage) {
		this.notes = (notes===undefined ? '' : notes);
		this.objects = (objects===undefined ? [] : angular.copy(objects));
		this.images = (images===undefined ? [] : angular.copy(images));
		this.drawings = (drawings===undefined ? [] : angular.copy(drawings));
		this.garbage = (garbage===undefined ? [] : angular.copy(garbage));
	};
	this.Calendar = function(weekdays, holidays, workingdates, nonworkingdates, interferences) {
		this.weekdays = (weekdays===undefined ? angular.copy(parent.defaultWeekdays()) : angular.copy(weekdays));
		this.holidays = (holidays===undefined ? angular.copy(parent.defaultHolidays()) : angular.copy(holidays));
		this.workingdates = (workingdates===undefined ? [] : angular.copy(workingdates));
		this.nonworkingdates = (nonworkingdates===undefined ? [] : angular.copy(nonworkingdates));
		this.interferences = (interferences===undefined ? [] : angular.copy(interferences));
	};
	this.CalendarOptions = function(caltype) {
		this._id=parent.clientId();	
		this.startday = 1;
		this.rowheight = 36;
		this.colwidth = 10;
		this.zoneswidth = 8;
		this.zonetitleheight = 25;
		this.workswidth = 150;
		this.shownonworkingdays = true;
		this.showcalendar = true;
		this.showdaycounts = true;
		this.showinterferences = 2;
		this.removehiddenzones = false;
		this.color = 'zone';
		this.caneditinterferences = true;
		this.canaddinterferencesnotestocosts = true;
		this.caneditzones = true;
		this.caneditstartdate = true;
		this.caneditcalendar = true;
		this.caneditviewoptions = true;
		this.canaddtimeranges = true;
		this.candrag = true;
		this.search = '';
		
		if( caltype !== undefined ) {
			switch(caltype) {
				case parent.PSCCALENDAR:
					break;
				case parent.POSCALENDAR:
					this.showcalendar = false;
					this.shownonworkingdays = false;
					this.caneditcalendar = false;
					this.canaddinterferencesnotestocosts = false;
					break;
				default:
					break;
			}
		}
	};
	this.Timerange = function(startday, duration, sTD, eTD) {
		this.startDay = (startday===undefined ? 1 : startday);;
		this.duration = (duration===undefined ? 5 : duration);;
		this.sTD = (sTD===undefined ? 0 : sTD);
		this.eTD = (eTD===undefined ? 0 : eTD);
	};
	this.EmbeddedWorksheet = function() {
		this.risksB1 = [];
		this.risksB2 = [];
		this.risksB3 = [];
		this.prescriptions_A = [];
		this.prescriptions_B = [];
		this.prescriptions_C = [];
		this.prescriptions_D = [];
		this.workname = 'Lavorazione non definita';
		this.code ='';
		this.name = 'Nuova scheda';
		this.textA1 = '';
		this.textA2 = '';
		this.textA3 = '';
		this.textA4 = '';
		this.textA5 = '';
		this.prescriptions = '';
		this.interferencestype = 'crono';
		this.interferences = '';
	};
	this.WorksheetRef = function( worksheet ) {
		if( worksheet && worksheet.catid && worksheet.workid && worksheet.id ) {
			this.catid = worksheet.catid;
			this.workid = worksheet.workid;
			this.id = worksheet.id;
			this.data = worksheet.data;
		} else {
			this.catid = null;
			this.workid = null;
			this.id = null;
		}
	};
	this.PSCProcedure = function() {
		this.actions = [];
		this.details = [];
	};
	this.PSCCostRow= function() {
		this.description = '';
		this.quantity = 0;
		this.total = 0;
		this.duration = 0;
	};
	this.PSCCost= function(type, name, code, unit, decimaldigits, price, addDuration) {
		this.type = (type !== undefined ? type : 'A');
		this.unit = (unit !== undefined ? unit : 'corpo');
		this.decimaldigits = (decimaldigits !== undefined ? decimaldigits : 0);
		this.code = (code !== undefined ? code : '');
		this.name = (name !== undefined ? name : 'Nuova voce di computo');
		this.price = (price !== undefined ? price : 20);
		this.addDuration = (addDuration !== undefined ? addDuration : false);
		this.quantity = 0;
		this.total = 0;
		this.rows = [new parent.PSCCostRow()];
	};
	this.PSCCostCategory = function(type) {
		this.type = (type !== undefined ? type : 'A');
		this.total = 0;
		this.costs = [];
	};
	this.TextSection = function() {
		this.text = '';
		this.imagesections = [];
	};
	this.PSCCostSection = function(name) {
		this.name =  (name !== undefined ? name : '');
		this.total = 0;
		this.categories = [new parent.PSCCostCategory('A'), new parent.PSCCostCategory('B'), new parent.PSCCostCategory('C'), new parent.PSCCostCategory('D'), new parent.PSCCostCategory('E'), new parent.PSCCostCategory('F'), new parent.PSCCostCategory('G')];
	};
	this.DailymenatworkSection = function(name, works) {
		this.name =  (name !== undefined ? name : '');
		this.works = (works !== undefined ? angular.copy(works) : []);
	};
	this.DailymenatworkWork = function(name, cost, percentage) {
		this.name =  (name !== undefined ? name : '');
		this.cost = (cost !== undefined ? cost : 0);
		this.percentage = (percentage !== undefined ? percentage : 0);
	};
	this.BuildingDoc = function() {
		this.title = '';
		this.person = new parent.OptionalActor();
		this.place = '';
		this.date = new Date();;
		this.notes = '';
	};
	this.EmbeddedBuildingdocSheet = function() {
		this.code = '';
		this.name = 'Nuova scheda';
		this.docs = [];
	};
	this.ServicedirectivePrescription = function() {
		this.description = '';
		this.pscinformation = '';
		this.safeusedescription = '';
		this.checks = '';
		this.checkstimeframe = '';
		this.maintenances = '';
		this.maintenancestimeframe = '';
	};
	this.EmbeddedServicedirectiveSheet = function() {
		this.code = '';
		this.name = 'Nuova scheda';
		this.servicedirectives = [];
	};
	this.EmbeddedMaintenanceSheet = function() {
		this.name = 'Nuova scheda';
		this.description = '';
		this.risksdescription = '';
		this.information = '';
		this.scheduledworks = [];
		this.criticalissues = [new parent.CriticalIssue("Accessi ai luoghi di lavoro"), new parent.CriticalIssue("Sicurezza dei luoghi di lavoro"),new parent.CriticalIssue("Impianti di alimentazione e di scarico"),new parent.CriticalIssue("Approvvigionamento e movimentazione materiali e attrezzature"),new parent.CriticalIssue("Igiene sul lavoro"),new parent.CriticalIssue("Interferenze e protezione terzi"),new parent.CriticalIssue("Tavole allegate")];
	};
	this.Workcontractor = function(contractor) {
		if( contractor && contractor.name ) {
			this.name = contractor.name;
		} else {
			this.name = 'Appalto da definire';
		}
	};
	this.Subwork = function(name, menatwork, timeranges, contractor, worksheet, visible) {
		this._id = parent.clientId();
		this.name = (name===undefined ? 'Nuovo lavoro' : name);
		this.menatwork = (menatwork===undefined ? 1 : menatwork);;
		this.timeranges = angular.copy(timeranges);
		this.contractor = new parent.Workcontractor(contractor);
		this.worksheet = new parent.WorksheetRef(worksheet);
		this.visible = (visible===undefined ? {} : visible);
	};
	this.Work = function(name, menatwork, timeranges, contractor, color, worksheet, visible) {
		this._id = parent.clientId();
		this.name = (name===undefined ? 'Nuovo lavoro' : name);
		this.menatwork = (menatwork===undefined ? 1 : menatwork);;
		this.timeranges = angular.copy(timeranges);
		this.contractor = new parent.Workcontractor(contractor);
		this.color = (color===undefined ? '100,109,223' : color);
		this.worksheet = new parent.WorksheetRef(worksheet);
		this.visible = (visible===undefined ? {} : visible);
		this.subworks = [];
	};
	this.Zone = function(name, description, image, color, works, interferences, visible) {
		this._id = parent.clientId();
		this.isHeader = false;
		this.name = (name===undefined ? 'Nuova zona' : name);
		this.description = (description===undefined ? '' : description);
		this.details = "";
		this.image = (image===undefined ? null : image);
		this.color = (color===undefined ? '100,109,223' : color);
		this.works = (works===undefined ? [] : angular.copy(works));
		this.interferences = (interferences===undefined ? [] : angular.copy(interferences));
		this.visible = (visible===undefined ? {} : visible);
	};
	this.AttachedDocument = function(attached, name, canDelete) {
		this.attached = attached;
		this.name = (name===undefined ? 'Nuovo documento' : name);
		this.canDelete = (canDelete===undefined ? false : canDelete);
	};
	this.CriticalIssue = function(title, servicedirectives, auxdirectives ) {
		this.title = (title===undefined ? '' : title);
		this.servicedirectives = (servicedirectives===undefined ? [] : angular.copy(servicedirectives));
		this.auxdirectives = (auxdirectives===undefined ? [] : angular.copy(auxdirectives));
	};
	this.ScheduledWork = function( description, timeframe ) {
		this.description = (description===undefined ? '' : description);
		this.timeframe = (timeframe===undefined ? '' : timeframe);
	};

	this.WorksiteDocument = function( description, issuedate, revision, notes, filename, type) {
		this.creationDate = new Date();
		this.description = description;
		this.issuedate = issuedate;
		this.revision = revision;
		this.notes = notes;
		this.filename = filename;
		this.type = type;
	};
	this.WorksiteUser = function(contactId, isenterprise) {
		this.contactId = contactId;
		this.isenterprise = isenterprise===undefined ? false : true;
		this.level = isenterprise===undefined ? 1 : 2;
		this.status = 0;
		this.activationdate = null;
		this.deactivationdate = null;
		this.lastAccess = null;
		this.enterpriseid = null;
		this.enterpriseaccount = null;
		this.sharecode = "";
	}
	this.WorksiteDocSection = function( doctypes ) {
		this.doctypes = doctypes ? JSON.parse(JSON.stringify(doctypes)) : [];
		this.docs = [];
	};

	this.EnterpriseDocType= function(type) {
		this.type = type === undefined ? "Impresa" : type;
		this.enterprisesprofiles = {};
		this.description = "";
		this.index = 1;
		this.subindex = 1;
		this.isgroupable = false;
		this.groupname = "";
		this.validity = { type:0, duration:1, units:'anni'};
		this.allowmultiplefiles = false;
		this.cansetrequired = true;
		this.required = false;
		this.hastemplate = false;
		this.template = "";
		this.customvalidation = false;
		this.validationpages = [];
		this.expirynotifications = [];
	};

	this.EnterpriseDocFile= function(name, type, file, images ) {
		this.name = name;
		this.type = type;
		this.file = file;
		this.images = images ? JSON.parse(JSON.stringify(images)) : [];
		this.uploaddate = new Date();
	};

	this.EnterpriseDoc = function(type, name) {
		this.type = type;
		this.name = (name===undefined ? type : name);
		this.description = "";
		this.validity = {};
		this.issuedate = null;
		this.expirydate = null;
		this.files = [];
	};

	this.RefEnterpriseDoFilecSectionValidation = function( description, options, sources ) {
		this.description = (description===undefined ? '' : description);
		this.options = (options===undefined ? 1 : options);
		this.sources = (sources===undefined ? '' : sources);
		this.status = 0;
		this.notes = "";
	};
	this.RefEnterpriseDocFilePageValidation = function( description, sections ) {
		this.description = (description===undefined ? '' : description);
		this.sections = [];
		if( sections !== undefined && sections.length ) {
			var self = this;
			sections.forEach(function(section) {
				self.sections.push(new parent.RefEnterpriseDoFilecSectionValidation(section.description, section.options, section.sources));
			});
		}
	};
	this.RefEnterpriseDocFileValidation = function( validatorid ) {
		this.validatorid = validatorid;
		this.status = 0;
		this.notes = "";
		this.deadlinedate = null;
		this.pages = [];
	};
	this.RefEnterpriseDocFile= function(name, type, file, images ) {
		this.name = name;
		this.type = type;
		this.file = file;
		this.images = images ? JSON.parse(JSON.stringify(images)) : [];
		this.validations = [];
		this.uploaddate = new Date();
	};

	this.RefEnterpriseDoc = function(refId, type, name) {
		this.refId = refId;
		this.cansetrequired = true;
		this.required = false;
		this.worksiteonly = false;
		this.allowmultiplefiles = false;
		this.hastemplate = false;
		this.template = "";
		this.type = type;
		this.name = (name===undefined ? type : name);
		this.description = "";
		this.validity = {};
		this.issuedate = null;
		this.expirydate = null;
		this.files = [];
		this.validationstatus=0;
	};
	this.RefEmployee = function(refId, fullname) {
		this.refId = refId;
		this.fullname = fullname;
		this.documents = [];
		this.certificates = [];
	};
	this.RefPerson = function(refId, fullname) {
		this.refId = refId;
		this.fullname = fullname;
		this.designations = [];
		this.certificates = [];
	};
	this.RefAsset = function(refId, category, manifacturer, model, serialid, assetcategory) {
		this.refId = refId;
		this.category = category;
		this.manifacturer = manifacturer;
		this.model = model;
		this.serialid = serialid;
		this.assetcategory = assetcategory;
		this.documents = [];
	};
	this.RefSubstance = function(refId, name) {
		this.refId = refId;
		this.name = name;
		this.documents = [];
	};

}]);