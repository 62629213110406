angular.module('worksitesObjects', [])
.component('worksitesList', {
	templateUrl: assetsURL+'components/app/worksites/worksites/templates/worksites-list.template.html',
	controller: ['$scope', '$routeParams','$http','$uibModal', '$location', '$rootScope', 'warningService', function WorksitesCtrl($scope, $routeParams, $http, $uibModal, $location, $rootScope, warningService) {
		$scope.ImageBucket = ImageBucket;
		$scope.worksiteURL = worksiteURL;
		$scope.type='worksites';
		$scope.path='cantieri';
		$scope.supportDaterange = true;
		$scope.limit=20;
		$scope.oneAtATime = true;
		$scope.nowdate = new Date().toISOString();
		$scope.decodeObjects = function(scope, data) {
			scope.objects.forEach(function(obj) {
				obj.status = 0;
				if( obj.startdate && obj.enddate ) {
					if( obj.startdate <= $scope.nowdate ) {
						if( obj.enddate >= $scope.nowdate ) {
							obj.status = obj.isSuspended ? 2 : 1;
						} else {
							obj.status = 3;
						}
					}
				}
				obj.enterpriseStatuses = [[],[],[],[],[]];
				obj.enterprises.forEach(function(ent) {
					if( ent.referencedenterprise && ent.referencedenterprise._id ) {
						obj.enterpriseStatuses[ent.conformance.status].push(ent);
					} else {
						obj.enterpriseStatuses[4].push(ent);
					}
				});
			});
		};
	 	$scope.isopen=new Array();
		$scope.shodDocsIndex = -1;
		$scope.showDocsAtIndex = function( index ) {
			if( index == $scope.shodDocsIndex ) {
				$scope.shodDocsIndex = -1;
			} else {
				$scope.shodDocsIndex = index;
			}
		}
		$scope.healthEmergency = ($rootScope.settings ? $rootScope.settings.healthEmergency : false);
		
		$scope.shouldGenerateDraftPDF = function (obj, doc) {
			generatePDFService.generatePDF('pscs', obj, doc, true);
		};
		$scope.shouldGeneratePDF = function (obj, doc) {
			generatePDFService.generatePDF('pscs', obj, doc, false);
		};
		$scope.sharePDF = function(obj, doc) {
			var host = $location.host();
			warningService.showModal("success", "Puoi condividere il documento con altri usando il seguente link. Copia e incolla l'indirizzo e invialo tramite mail.<br><span class='text-muted'>"+appURL+"/docs/pscs/"+doc.pdf+"</span>", "Chiudi");
		};
		$scope.addNewWorksite = function() {
			var self = this;
			$http.post(appURL+'/services/account/canadd',{type:'worksites'}).then( 
				function successCallback( response ) {
					const data = response.data;
					if( data.status === true ) {
						self.proceedToAddNewWorksite();
					} else {
						if( $rootScope.currentUser.subscriptionplans['Cantiere'] === undefined ) {
							var proceedToTrial = function() {
								$http.post(appURL+'/services/account/activatetrial/Cantiere',{}).then( 
									function successCallback( response ) {
										var data=response.data;
										if( data && data.expirydate ) {
											data.expirydate  = new Date(data.expirydate);
										}
										$rootScope.currentUser.subscriptionplans['Cantiere'] = data;
										self.proceedToAddNewWorksite();
									}, function errorCallback() {
										alert("Errore generico di connessione al server");
									}
								);
							};
							warningService.showModal("warning", "Per creare un nuovo cantiere devi attivare il piano di prova gratuito per 15 giorni. Se al termine del periodo di prova deciderai di continuare ad usare il servizio potrai rinnovarlo ad un prezzo speciale", "Annulla", "Attiva prova", null, proceedToTrial);
						} else {
							var proceedToOrder = function() {
								$location.path('/ordini/nuovocantiere');
							};
							if( data.type == 'limit') {
								warningService.showModal("warning", "Hai raggiunto il numero massimo di cantieri gestibili previsto per il tuo piano di abbonamento. Puoi cancellarne uno per liberare spazio o cliccare su 'Cambia piano' per passare ad un piano superiore con un numero maggiore di cantieri archiviabili.", "Annulla", "Cambia piano", null, proceedToOrder);
							} else {
								warningService.showModal("warning", "Il tuo piano è scaduto e non puoi creare nuovi cantieri. Rinnova ora per continuare ad usare i servizi.", "Annulla", "Rinnova ora", null, proceedToOrder);
							}
						}
					}
				}, function errorCallback() {
					alert("Errore generico di connessione al server");
				}
			);
		};
		
		$scope.proceedToAddNewWorksite = function () {
			var modalInstance = $uibModal.open({
				templateUrl: 'newWorksiteModal.html',
				controller: ['$scope', '$uibModalInstance','healthEmergency', function (scope, $uibModalInstance, healthEmergency) {
					scope.healthEmergency = healthEmergency;
					scope.getPscs = function(val) {
						return $http.get(appURL+'/crud/pscs'+( val ? '?limit=100&minimize=true&search=' + encodeURIComponent(val) : '' ) ).then( function( res ) {
							res.data.forEach(function(c) {
								c.customer = c.customers && c.customers.length ? (c.customers[0].contact.iscompany ? c.customers[0].contact.companyname : c.customers[0].contact.lastname + ' ' + c.customers[0].contact.firstname) : "";
								c.description = c.subject+' in '+c.place.city; 
							});
							return res.data; 
						});
					};
					scope.getPscsByCode = function(val) {
						scope.psccodenotfound = false;
						return $http.get(appURL+'/crud/pscs'+( val ? '?limit=100&minimize=true&code=' + encodeURIComponent(val) + "&time="+(new Date()).getTime() : '' ) ).then( 
							function successCallback( res ) {
								res.data.forEach(function(c) {
									c.customer = c.customers && c.customers.length ? (c.customers[0].contact.iscompany ? c.customers[0].contact.companyname : c.customers[0].contact.lastname + ' ' + c.customers[0].contact.firstname) : "";
									c.description = c.subject+' in '+c.place.city; 
								});
								if( !res.data.length ) { scope.psccodenotfound = true; }
								return res.data; 
							}, function errorCallback( res ) {
								scope.psccodenotfound = true;
							}
						);
					};
					
					$http.get(appURL+'/crud/professionalstudies?nolimit=true&returnallvalues=true').then( function successCallback( res ) {
						scope.getWorksites = function(val) {
							return $http.get(appURL+'/crud/worksites'+( val ? '?search=' + val : '' ) ).then( 
								function successCallback ( response ) {
									response.data.forEach(function(c) {c.description = c.subject+' in '+c.place.city; });
									return response.data; 
								}, function errorCallback( response ) {}
							);
						};
						scope.steps = { cur:1, /*worksitedataidx:0,*/  count: 2 };
						scope.options = {};
						scope.options.professionalstudies = res.data;
						scope.options.professionalstudy = null;
						scope.options.type = 'scratch';
						scope.options.rolestype = 'cse';
						/*
						scope.$watch('options.type', function(val) {
							if( val === undefined ) { return; }
							if( scope.options.type === 'scratch' ) {
								scope.steps.count = 2;
								scope.steps.worksitedataidx = 0;
							} else if( scope.options.type === 'psc' ) {
								scope.steps.count = 2;
								scope.steps.worksitedataidx = 0;
							} else if( scope.options.type === 'psccode' ) {
								scope.steps.count = 2;
								scope.steps.worksitedataidx = 0;
							}
						});
						*/
						scope.goBack = function() {
							if( scope.steps.cur == 1 ) { return; }
							scope.steps.cur--;
						}
						scope.goForward = function() {
							if( scope.steps.cur == scope.steps.total ) { return; }
							scope.steps.cur++;
						};

						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function () {
							var options = scope.options;
							if( options.type == 'scratch') {
								$uibModalInstance.close(scope.options);
								return;
							} else if( options.type == 'psc' || (options.type == 'psccode' && !scope.psccodenotfound)) {
								var obj = {};
								const pscId = options.type == 'psc' ? options.psc._id : options.psccode._id;
								scope.inCreation = true;
								$http.post(appURL+'/services/worksites/createfrompsc/'+pscId,obj ).then(
									function successCallback(response) {
										var data=response.data;
										if( data ) {
											scope.inCreation = false;
											$rootScope.locals.newworksitefrompscdata = data.pscdata;
											$uibModalInstance.close(scope.options);
										} else {
											scope.inCreation = false;
											alert('Si è verificato un errore. Si prega di riprovare');
										}
									}, function errorCallback(response) {
										scope.inCreation = false;
										alert('Si è verificato un errore. Si prega di riprovare');
									}
								);
								return;
							}
							$uibModalInstance.close(options);
						};
					}, function errorCallback() {});
				}],
				
				resolve: {
					healthEmergency: function () { return $scope.healthEmergency; },
				}
			});
			modalInstance.result.then(
				function successCallback(options) {
					$rootScope.locals.newworksiterolestype = options.rolestype;
					if( options.professionalstudy ) {
						$rootScope.locals.newworksiteprofessionalstudy = options.professionalstudy;
					}
					$location.path('/cantieri/nuovo');
				}, function errorCallback() {}
			);
		};
	  	$scope.object = {};
	}]
})
.component('worksiteDetail', {
	templateUrl: assetsURL+'components/app/worksites/worksites/templates/worksite-detail.template.html',
	controller: ['$window','$route', '$rootScope', '$scope', '$routeParams', '$http', '$location', '$uibModal', '$timeout', '$interval', 'models','worksheetService','lettertextService','generatePDFService','warningService',  function($window, $route, $rootScope, $scope, $routeParams, $http, $location, $uibModal, $timeout, $interval, models, worksheetService, lettertextService, generatePDFService, warningService ) {
	$scope.loaded = false;
	$scope.appURL = appURL;
	$scope.assetsURL = assetsURL;
	$scope.websiteURL = websiteURL;
	$scope.ImageBucket = ImageBucket;
	$scope.openLink = function(link) {
		$location.path(link);
	};
	$scope.openEnterpriseLink = function(enterpriseId, section) {
		if( enterpriseId ) {
			if( section ) {
				if( section == "notif" ) {
					$location.path('cantieri/'+$routeParams.id+'/A/4');
				} else {
					$location.path('cantieri/'+$routeParams.id+'/impresa/'+enterpriseId+"/"+section);
				}
			} else {
				$location.path('cantieri/'+$routeParams.id+'/impresa/'+enterpriseId);
			}
		} else {
			alert("L'impresa non è ancora stata attivata");
		}
	};
	
	$scope.progress = 0;
	
    $http.get(appURL+'/services/worksites/'+$routeParams.id+ '/detail' ).then( 
	    function successCallback( response ) {
		    var data = response.data;
		    if( data.startdate ) {
				data.startdate = new Date(data.startdate);
				data.startdate.setHours(12);
				data.enddate = new Date(data.enddate);
				data.enddate.setHours(12);
				const now = new Date();
				var progress = (now.getTime()- data.startdate.getTime())/( data.enddate.getTime() - data.startdate.getTime());
				if( progress > 0 && progress < 1.0 ) {
					$scope.progress = Math.floor(100*progress);
				}
			}
			
			$scope.loaded = true;
			$scope.object = data;
		}, function errorCallback( response ) {
			if( response.status==404 ) { $location.path('/404');}
		}
	);
}]})
.component('worksiteEnterpriseDetail', {
	templateUrl: assetsURL+'components/app/worksites/worksites/templates/worksite-enterprise-detail.template.html',
	controller: ['$window','$route', '$rootScope', '$scope', '$routeParams', '$http', '$location', '$uibModal', '$timeout', '$interval', 'models','worksheetService','lettertextService','generatePDFService','warningService','createMailingList',  function($window, $route, $rootScope, $scope, $routeParams, $http, $location, $uibModal, $timeout, $interval, models, worksheetService, lettertextService, generatePDFService, warningService, createMailingList) {
	$scope.loaded = false;
	$scope.enterpriseId = $routeParams.enterpriseid;
	$scope.stateView = 'POS';
	$scope.appURL = appURL;
	$scope.assetsURL = assetsURL;
	$scope.websiteURL = websiteURL;
	$scope.ImageBucket = ImageBucket;
	$scope.setState = function(state) {
		$scope.stateView = state;
	};
	$scope.openLink = function(link) {
		$location.path(link);
	};
	$scope.exit = function() {
		$location.path('cantieri/'+$routeParams.id);
	};
	$scope.viewtype = 'documents';
	$scope.setViewType = function(type) {
		$scope.viewtype = type;
	};
	$scope.isSaving = false;
	$scope.remoteObject = null;
	$scope.isClean = function() {		
		if( $scope.remoteObject === undefined ) return false;
		return angular.equals($scope.remoteObject, $scope.object);
	}
	$scope.object = null;
	$scope.selectedemployee = null;
	$scope.selectedtemporarywork = null;
	$scope.selectedmachine = null;
	$scope.selectedsubstance = null;
	$scope.selectEmployee = function( item ) {
		$scope.selectedemployee = item;
	};
	$scope.selectTemporaryWork = function( item ) {
		$scope.selectedtemporarywork = item;
	};
	$scope.selectMachine = function( item ) {
		$scope.selectedmachine = item;
	};
	$scope.selectSubstance = function( item ) {
		$scope.selectedsubstance = item;
	};
	$scope.tabsection = 'Stato';
	$scope.chapters = [ "Stato", "Anagrafica", "Lavoratori", "Organigramma", "Sicurezza", "Documenti Operativi", "Opere Provvisionali", "Macchine", "Sostanze"];
	$scope.chapterIndex = 0;
	$scope.goBack = function() {
		if( $scope.chapterIndex >0 ) {
			$scope.chapterIndex = $scope.chapterIndex-1;
			$scope.tabsection = $scope.chapters[$scope.chapterIndex];
		}	
	};
	$scope.goForward = function() {
		if( $scope.chapterIndex < ($scope.chapters.length-1) ) {
			$scope.chapterIndex = $scope.chapterIndex+1;
			$scope.tabsection = $scope.chapters[$scope.chapterIndex];
		}	
	};
	$scope.goTo = function(index) {
		if( index >=0 && index < $scope.chapters.length ) {
			$scope.chapterIndex = index;
			$scope.tabsection = $scope.chapters[$scope.chapterIndex];
		}	
	};
	
	$scope.orgChartChapters = [ "Preposti", "Addetti primo soccorso", "Addetti antincendio" ];
	$scope.orgChartIndex = 0;
	$scope.goBackForOrgChart = function() {
		if( $scope.orgChartIndex >0 ) {
			$scope.orgChartIndex = $scope.orgChartIndex-1;
		}	
	};
	$scope.goForwardForOrgChart = function() {
		if( $scope.orgChartIndex < ($scope.orgChartChapters.length-1) ) {
			$scope.orgChartIndex = $scope.orgChartIndex+1;
		}	
	};
	$scope.goToForOrgChart = function(index) {
		if( index >=0 && index < $scope.orgChartChapters.length ) {
			$scope.orgChartIndex = index;
		}	
	};

	$scope.safetyChapters = [ "Responsabili SPP", "Medico competente", "RLS"];
	$scope.safetyIndex = 0;
	$scope.goBackForSafety = function() {
		if( $scope.safetyIndex >0 ) {
			$scope.safetyIndex = $scope.safetyIndex-1;
		}	
	};
	$scope.goForwardForSafety = function() {
		if( $scope.safetyIndex < ($scope.safetyChapters.length-1) ) {
			$scope.safetyIndex = $scope.safetyIndex+1;
		}	
	};
	$scope.goToForSafety = function(index) {
		if( index >=0 && index < $scope.safetyChapters.length ) {
			$scope.safetyIndex = index;
		}	
	};
	
	$scope.accountId = $rootScope.currentUser.account;
	
    $http.post(appURL+'/services/enterprises-docs/getworksiterelated', { worksiteId: $routeParams.id, enterpriseId: $routeParams.enterpriseid } ).then( 
	    function successCallback( response ) {
		    var data = response.data;
			$scope.loaded = true;
			$scope.object = data;
			if( $scope.object.worksite.enterprise.customcheckreportspos === undefined ) { $scope.object.worksite.enterprise.customcheckreportspos = []; }
			if( $scope.object.worksite.enterprise.customcheckreportsitp === undefined ) { $scope.object.worksite.enterprise.customcheckreportsitp = []; }
			if( $scope.object.worksite.enterprise.customcheckreportsoverall === undefined ) { $scope.object.worksite.enterprise.customcheckreportsoverall = []; }
			if( $scope.object.worksite.enterprise.checkreportspos === undefined ) { $scope.object.worksite.enterprise.checkreportspos = new models.WorksiteDocSection(['chkpos']); }
			if( $scope.object.worksite.enterprise.checkreportsitp === undefined ) { $scope.object.worksite.enterprise.checkreportsitp = new models.WorksiteDocSection(['chkitp']); }
			if( $scope.object.worksite.enterprise.checkreportsoverall === undefined ) { $scope.object.worksite.enterprise.checkreportsoverall = new models.WorksiteDocSection(['chkoverall']); }
			
			$scope.mailinglist = [];
			if( $scope.object.worksite.enterprise.contact && $scope.object.worksite.enterprise.contact._id && $scope.object.worksite.enterprise.referencedenterprise ) {
				var mailinglistData = { users: [{contactId: $scope.object.worksite.enterprise.contact._id, status: $scope.object.worksite.enterprise.referencedenterprise.status}], enterprises:[ $scope.object.worksite.enterprise ]};
				$scope.mailinglist = createMailingList.createFromData(mailinglistData);						
			}
			$scope.remoteObject = angular.copy($scope.object);
			switch( $scope.object.worksite.rolestype ) {
				case 'csewm':
				case 'rdl':
					$scope.chapters.splice(2, 0, "Documenti ITP");
					if( $scope.object.worksite.enterprise.refsubcontractors &&  $scope.object.worksite.enterprise.refsubcontractors.length>0 ) {
						$scope.chapters.splice(2, 0, "Subappalti");
					}
					break;
				case 'cseas':
					$scope.chapters.splice(2, 0, "Documenti ITP");
					break;
				default:
					break;
			}			
			// For freelancer no POS
			if( $scope.object.enterpriseprofile == '6651ccaa335fea887f68ce78' ) {
				$scope.stateView = 'ITP';
			}
			if( $scope.object.worksite.enterprise.refemployees.length ) {
				$scope.selectedemployee = $scope.object.worksite.enterprise.refemployees[0];
			}
			if( $scope.object.worksite.enterprise.reftemporaryworks.length ) {
				$scope.selectedtemporarywork = $scope.object.worksite.enterprise.reftemporaryworks[0];
			}
			if( $scope.object.worksite.enterprise.refmachines.length ) {
				$scope.selectedmachine = $scope.object.worksite.enterprise.refmachines[0];
			}
			if( $scope.object.worksite.enterprise.refsubstances.length ) {
				$scope.selectedsubstance = $scope.object.worksite.enterprise.refsubstances[0];
			}
			//Legacy
			if( $scope.object.worksite.enterprise.refsubcontractors === undefined ) {
				$scope.object.worksite.enterprise.refsubcontractors = [];
			}
			
			if( $routeParams.section ) {
			
				switch( $routeParams.section ) {
					case 'itp':
						$scope.chapterIndex = $scope.chapters.indexOf("Documenti ITP");
						break;
					case 'operativi':
						$scope.chapterIndex = $scope.chapters.indexOf("Documenti Operativi");
						break;
					case 'lavoratori':
						$scope.chapterIndex = $scope.chapters.indexOf("Lavoratori");
						break;
					default:
						break;
				}
			}
		
			if( $scope.chapterIndex < 0 ) {
				$scope.chapterIndex = 0;
			}
			$scope.tabsection = $scope.chapters[$scope.chapterIndex];
		}, function errorCallback( response ) {
			if( response.status==404 ) { $location.path('/404');}
		}
	);
	
	$scope.asyncSaveEdit = function( successCall, errorCall ) { 
		$scope.isSaving = true;
		$http.post(appURL+'/services/enterprises-docs/updateworksiterelated', {worksite:$scope.object.worksite, enterpriseId:  $scope.object.worksite.enterprise.referencedenterprise._id, filesIdsToBeAdded: [], filesIdsToBeRemoved:[] }).then( 
			function successCallback( response ) { 
				$scope.isSaving = false;
				$scope.remoteObject = angular.copy($scope.object);
				successCall();
			}, function errorCallback( response ) {
				alert("Errore di sistema. Riprova più tardi.");
				$scope.isSaving = false;
				errorCall();
			}
		);
	};
	$scope.changeSubcontractorStatus = function(index, status) {
		if( index<0 || index >= $scope.object.worksite.enterprise.refsubcontractors.length ) { return; }
		$scope.isSaving = true;
		$scope.object.worksite.enterprise.refsubcontractors[index].submittalstatus = status;
		$http.post(appURL+'/services/enterprises-subcontractors/updateworksiterelated', {worksite:$scope.object.worksite, enterpriseId: $scope.object.worksite.enterprise.referencedenterprise._id, enterprise: $scope.object.worksite.enterprise  }).then( 
			function successCallback(response){
				$scope.isSaving = false;
				$scope.object.worksite.enterprise.refsubcontractors = response.data;
				$scope.remoteObject = angular.copy($scope.object);
			}, function errorCallback(response) {
				$scope.isSaving = false;
				alert("Errore di sistema. Riprova più tardi.");
			}
		);
	};
}]})
.component('worksiteMessages', {
	templateUrl: assetsURL+'components/app/worksites/worksites/templates/worksite-messages.template.html',
	controller: ['$window','$route', '$rootScope', '$scope', '$routeParams', '$http', '$location',  function($window, $route, $rootScope, $scope, $routeParams, $http, $location ) {
	$scope.firstLoaded = false;
	$scope.appURL = appURL;
	$scope.assetsURL = assetsURL;
	$scope.websiteURL = websiteURL;
	$scope.ImageBucket = ImageBucket;
	$scope.exit = function() {
		$location.path('cantieri/'+$routeParams.id);
	};
	$scope.object = null;
	$scope.accountId = $rootScope.currentUser.account;
	$scope.startdate = (new Date("2021-01-01")).toISOString().substr(0,10);
	$scope.enddate = (new Date()).toISOString().substr(0,10);
	$scope.rangetag = '';
	$scope.skip = 0;
	$scope.limit = 100000;
	$scope.search = '';
	$scope.type = 'all';
	$scope.typeDescriptions = {'all':"Tutte", "useractivation":"Attivazioni utente", "customreportscse":"Verbali CSE", "customreportsrdl":"Verbali RdL", "other":"Altre"};
	$scope.setType = function(type) {
		$scope.type = type;	
	};
	$scope.$watchCollection('[startdate, enddate, rangetag, type, search]', function(newValues) {	
		$scope.skip=0;
		$scope.reloadMessages();
	});
	
	$scope.reloadMessages = function() {
		$scope.refreshing = true;
	    $http.post(appURL+'/services/worksites-messages/', { worksiteId: $routeParams.id, startdate: $scope.startdate, enddate: $scope.enddate, search: $scope.search, type: $scope.type} ).then( 
		    function successCallback( response ) {
			    var data = response.data;
				$scope.firstLoaded = true;
				$scope.object = data;
				$scope.refreshing = false;
			}, function errorCallback( response ) {
				if( response.status==404 ) { $location.path('/404');}
			}
		);
	}
}]})
.component('worksiteAssets', {
	templateUrl: assetsURL+'components/app/worksites/worksites/templates/worksite-assets.template.html',
	controller: ['$window','$route', '$rootScope', '$scope', '$routeParams', '$http', '$location','createMailingList','models', function($window, $route, $rootScope, $scope, $routeParams, $http, $location, createMailingList, models ) {
	$scope.loaded = false;
	$scope.appURL = appURL;
	$scope.mailinglist = null;
	$scope.exit = function() {
		$location.path('cantieri/'+$routeParams.id);
	};
	$scope.asyncSaveEdit = function( successCall, errorCall ) {
		$http.post(appURL+'/services/worksites/assets', {'object':$scope.object }).then(
			function successCallback(response){
				successCall();
			}, function errorCallback(response) {
				errorCall();
			}
		);
	};
	
    $http.get(appURL+'/services/worksites/'+$routeParams.id+ '/assets' ).then( 
	    function successCallback( response ) {
		    var data = response.data;
			if( data.organizationcharts === undefined ) { data.organizationcharts = new models.WorksiteDocSection(['och']);}
			for( var i=0; i<data.organizationcharts.docs.length; i++ ) {
				if( data.organizationcharts.docs[i].issuedate ) {
					data.organizationcharts.docs[i].issuedate = new Date(data.organizationcharts.docs[i].issuedate);
				}
			}
			if( data.customorganizationcharts === undefined ) { data.customorganizationcharts = [];}

			$scope.loaded = true;
			$scope.object = data;
			$scope.showPanel = 'employees';
			
			var getAuthStatus = function(docs) {
				var statuses = [0,0,0,0];
				var nowTime = (new Date()).getTime();
				docs.forEach(function(doc) {
					var basicCheckFailed = false;
					if( doc.required && !doc.files.length ) {
						basicCheckFailed = true;
					} else if( doc.files.length ) {
						if( doc.validity.type > 0 ) {
							var expirydate = doc.expirydate ? new Date(doc.expirydate) : new Date("2000-01-01");
							if( expirydate.getTime() < nowTime ) {
								basicCheckFailed = true;
							}
						} 
					}
					if( basicCheckFailed ) {
						statuses[2]=statuses[2]+1;
					} else {
						if( doc.files.length ) {
							statuses[doc.validationstatus]=statuses[doc.validationstatus]+1;
						} else {
							statuses[1]=statuses[1]+1;
						}
					}
				});
				return (statuses[0]>0 || statuses[2]>0)?false:true;
			};
			data.enterprises.forEach(function(enterprise) {
				
				enterprise.refemployees.forEach(function(item) {
					item.authorized = false;
					if( item.role === 'Datore di lavoro' ) {
						item.authorized = true;
						return;
					}
					var isAuth = getAuthStatus(item.documents);
					if( isAuth ) {
						isAuth = getAuthStatus(item.certificates);
						if( isAuth ) {
							item.authorized = true;
						}
					}
				});
				enterprise.reftemporaryworks.forEach(function(item) {
					item.authorized = false;
					var isAuth = getAuthStatus(item.documents);
					if( isAuth ) {
						item.authorized = true;
					}
				});
				enterprise.refmachines.forEach(function(item) {
					item.authorized = false;
					var isAuth = getAuthStatus(item.documents);
					if( isAuth ) {
						item.authorized = true;
					}
				});
				enterprise.refsubstances.forEach(function(item) {
					item.authorized = false;
					var isAuth = getAuthStatus(item.documents);
					if( isAuth ) {
						item.authorized = true;
					}
				});				
			});
			$scope.mailinglist = createMailingList.createFromData(data);
		}, function errorCallback( response ) {
			if( response.status==404 ) { $location.path('/404');}
		}
	);
}]})
.component('worksiteEdit', {
	templateUrl: assetsURL+'components/app/worksites/worksites/templates/worksite-edit.template.html',
	controller: ['$window','$route', '$rootScope', '$scope', '$routeParams', '$http', '$location', '$uibModal', '$timeout', '$interval', 'models','worksheetService','lettertextService','generatePDFService','warningService','createMailingList',  function($window, $route, $rootScope, $scope, $routeParams, $http, $location, $uibModal, $timeout, $interval, models, worksheetService, lettertextService, generatePDFService, warningService, createMailingList ) {
	var dir = assetsURL+'components/app/worksites/worksites/templates/';
	$scope.appURL = appURL;
	$scope.assetsURL = assetsURL;
	$scope.websiteURL = websiteURL;
	
	$rootScope.showMainToolbar = false;
	$scope.ImageBucket = ImageBucket;
	$scope.showDocuments = false;
	$scope.documents = [];
	$scope.allegeddocsviewtypes = { report : 'grid' };
	/*
	$scope.pdfabledocsCount = 0;
	$scope.downloadabledocsCount = 0;
	$scope.lastrevdate = '';
	*/
	$scope.loadingdocs = true;
	$scope.currentUser = $rootScope.currentUser;
	$scope.showWorkerHourUI = false;
	/*
	$scope.showInterferencesAndRisksNotes = false;
	$scope.interferencesAndRisksNotes = [];*/
	$scope.healthEmergency = ($rootScope.settings ? $rootScope.settings.healthEmergency : false);
	
	$scope.getCities = function(val) {
		return $http.get(appURL+'/utils/cities'+( val ? '?search=' + encodeURIComponent(val) : '' ) ).then( function( res ) { return res.data; } );
	};
	
	$scope.getCountries = function() {
		$http.get(appURL+'/utils/countries').then( 
			function successCallback( response ) { 
				$scope.countries = response.data; 
			}, function errorCallback( response ) {}
		);
	};
	$scope.autocompleteStateAndZipcode=function(address) {
		$http.get(appURL+'/utils/cities?search='+encodeURIComponent(address.city), {}).then(
			function successCalbback( response ) {
				if( response.data.length ) {
					var data = response.data;
					if( data[0].zipcode ) { address.zipcode=data[0].zipcode;}
					if( data[0].stateshort ) { address.state=data[0].stateshort;}
					if( data[0].region ) { address.region=data[0].region;}
					address.country = 'Italia';
				}
			}, function errorCallback( response ) {}
		);
	};
	$scope.docChapters = [
		{ title:'Anagrafica', description: "In questa sezione inserisci i dati generali della commessa, l'immagine ed il logo da inserire in prima pagina ed altre informazioni.", url:dir+'worksite-edit-section-A.html'+'?t='+RootSeed, section:'A', status:0, sections:[{title:'Dati studio', status:0}, {title:'Dati generali', status:0}, {title:'Indirizzo del cantiere', status:0}, {title:'Soggetti', status:0}, {title:'Imprese', status:0}, {title:'Aspetto & Layout', status:0}, {title:'Stato cantiere', status:0}, {title:'Funzioni', status:0} ] },
		
		{ title:'Utenti', description: "In questa sezione gestisci gli utenti che possono accedere alla piattaforma online per visionare e caricare documenti", url:dir+'worksite-edit-section-B.html'+'?t='+RootSeed, section:'B', status:0, sections:[{title:'Soggetti', status:0}, {title:'Imprese', status:0}]}, 

		{ title:'Documenti generali', description: "In questa sezione gestici tutta la documentazione associata al cantiere", url:dir+'worksite-edit-section-C.html'+'?t='+RootSeed, section:'C', status:0, sections:[{title:'Nomine e Documenti CSE', status:0}, {title:'PSC', status:0}, {title:'Costi della sicurezza', status:0}, {title:'Fascicolo dell\'opera', status:0}, {title:'Notifica-integrazioni', status:0}, {title:'DUVRI e piano emergenza interno', status:0}, {title:'Doc. Progetto', status:0}, {title:'Procedure specifiche cantiere', status:0}] }, 

		{ title:'Documenti gestionali', description: "In questa sezione inserisci tutte le informazioni necessarie a gestire le misure di coordinamento.", url:dir+'worksite-edit-section-D.html'+'?t='+RootSeed, section:'D', status:0, sections:[{title:'Apprestamenti', status:0}, {title:'Attrezzature', status:0}, {title:'Infrastrutture', status:0}  ] }, 

		{ title:'Verbali', description: "In questa sezione carica e crea verbali delle riunione di cantiere.", url:dir+'worksite-edit-section-E.html'+'?t='+RootSeed, section:'E', status:0, sections:[{title:'Verbali riunioni', status:0}, {title:'Verbali CSE', status:0}, {title:'Comunicazioni RdL', status:0}  ] }, 

		{ title:'PSC', description: "In questa sezione gestisci il PSC", url:dir+'worksite-edit-section-F.html'+'?t='+RootSeed, section:'F', status:0, sections:[{title:'PSC', status:0}] }, 

		{ title:'Costi sicurezza', description: "In questa sezione gestisci i costi della sicurezza", url:dir+'worksite-edit-section-G.html'+'?t='+RootSeed, section:'G', status:0, sections:[{title:'Costi', status:0}] }, 

		{ title:'Fascicolo dell\'opera', description: "In questa sezione inserisci tutti i dati necessari per compilare il fascicolo dell'opera.", url:dir+'worksite-edit-section-H.html'+'?t='+RootSeed, section:'H', status:0, sections:[{title:'Introduzione', status:0}, {title:'Descrizione generale', status:0}, {title:'Manutenzione', status:0}, {title:'Prescrizioni', status:0}, {title:'Misure preventivi e protettive', status:0}, {title:'Documenti', status:0}, {title:'Pianificazione', status:0}, {title:'Elementi di dettaglio', status:0}, {title:'Documentazione fotografica', status:0}] }, 
		/*, 
		{ title:'Documenti generali', image:"chapter19-valutazione-dei-rischi", description: "In questa sezione inserisci le zone omogenee di lavoro oltre a quella generale corrispondente all'area esterna di cantiere, le lavorazioni ed i rischi.", url:dir+'PSC2_B.html'+'?t='+RootSeed, section:'B', status:0, sections:[{title:'Area di cantiere', status:0} ,{title:'Rischi interni', status:0}, {title:'Rischi esterni', status:0}, {title:'Rischi per l\'esterno', status:0}, {title:'Organizzazione del cantiere', status:0}, {title:'Recinzione e accessi', status:0}, {title:'Servizi igienico-assistenziali', status:0}, {title:'Viabilit&agrave; principale e accesso ai fornitori', status:0}, {title:'Impianti', status:0}, {title:'Zone di carico e scarico', status:0}, {title:'Zone di deposito', status:0}, {title:'Premessa rischi lavorazioni', status:0}, {title:'Lavorazioni', status:0}, {title:'Segnaletica', status:0}, {title:'Gesti', status:0}, {title:'Pericoli', status:0}, {title:'Tubazione', status:0}, {title:'Bombole', status:0}] }, 
		{ title:'Emergenze', image:"chapter12-emergenze", description: "In questa sezione inserisci tutte le informazioni necessarie a fronteggiare emergenze in cantiere.", url:dir+'PSC4_D.html'+'?t='+RootSeed, section:'D', status:0, sections:[{title:'Introduzione', status:0}, {title:'Primo soccorso', status:0}, {title:'Antincendio', status:0}, {title:'Contatti', status:0}, {title:'Riepilogo', status:0}, {title:'Numeri', status:0}, {title:'Ospedale', status:0}, {title:'Piano di emergenza', status:0}  ] }, 
		{ title:'Complementari', image:"chapter9-procedure-complementari-al-psc", description: "In questa sezione inserisci tutte le informazioni necessarie a integrare le prescrizioni contentute nel PSC.", url:dir+'PSC5_E.html'+'?t='+RootSeed, section:'E', status:0, sections:[{title:'Introduzione', status:0}, {title:'Aspetti relativi alla gestione ed organizzazione del cantiere', status:0}, {title:'Aspetti relativi alle infrastrutture di cantiere e mezzi e servizi di protezione collettiva', status:0}, {title:'Aspetti relativi agli apprestamenti di cantiere', status:0}, {title:'Aspetti relativi alle attrezzature di cantiere', status:0}, {title:'Aspetti relativi alle lavorazioni', status:0}, {title:'Chiusura del cantiere', status:0}] }, 
		{ title:'Allegati', image:"chapter16-allegati", description: "In questa sezione inserisci l'elenco dei documenti allegati e la normativa di riferimento.", url:dir+'PSC6_F.html'+'?t='+RootSeed, section:'F', status:0, sections:[{title:'Normativa', status:0},  {title:'Documenti tecno-grafici', status:0}, {title:'Documenti tecnico-professionali', status:0}] }, 
		{title:'Stima dei costi', image: "chapter22-stima-dei-costi", description: "In questa sezione inserisci i costi di sicurezza relativi al cantiere", url:dir+'PSC8_CST.html'+'?t='+RootSeed, section:'CST', status:0, sections:[{title:'Introduzione', status:0}, {title:'Costi', status:0}]}, { title:'Modulistica', image:"chapter17-modulistica", description: "Crea la modulistica (lettere di nomina, etc.) in questa sezione", url:dir+'PSC9_All1.html'+'?t='+RootSeed, section:'All1', status:0, sections:[{title:'Lettere', status:0}]}*/];
	/*
	$scope.wks = worksheetService;
	$scope.selectedwork = null;
	$scope.selectedzone = null;
	$scope.showZonePanel = 0;
	$scope.showGantt = false;
	*/
	$scope.stats = {todos:0, done:0, drafts:0, total:1};
	$scope.elapsedtime = 0;
	$scope.isSaving = false;
	$rootScope.inEditing = true;
	/*
	$scope.contractors = {maincontractor:'', subcontractors:[], freelancers:[]};
	*/
	$scope.openLink = function(evt, id, chapter) {
		if( !$scope.loaded ) { 
			evt.preventDefault();
			return; 
		}
		var location = chapter ? "/cantieri/"+id+"/"+chapter+"/" : "/cantieri/"+id;
		if( $rootScope.inEditing ) {
			var saveAndProceed = function() {
				$scope.saveEdit(location);
			};
			var confirmProceedWithoutSaving = function() {
				$location.path(location);
			};
			warningService.showModal("warning", "Vuoi salvare le modifiche?", "Annulla", "SI", null, saveAndProceed, "NO", confirmProceedWithoutSaving);
		} else {
			$location.path(location);
		}
	};
	/*
	$scope.editFilename = function(id, docs, docindex) {
		var modalInstance = $uibModal.open({
			templateUrl: assetsURL+'directives/widgets/templates/filenamemodal.html?t='+RootSeed,
			controller: ['$scope', '$uibModalInstance','filename', function (scope, $uibModalInstance, filename) {
				scope.filename = filename;
				scope.cancel = function () {
					$uibModalInstance.dismiss('cancel');
				};
				scope.ok = function() {
					$uibModalInstance.close(scope.filename);
				};
			}],
			
			resolve: {
				filename: function() { return (docs[docindex].filename ? docs[docindex].filename : docs[docindex].description); }
			}
		});
		modalInstance.result.then(
			function successCallback(filename) {
				docs[docindex].filename = filename;
				var obj =  {'object':{_id: id, documents:docs }};
				$http.post(appURL+'/services/pscs/docs', obj).then(
					function successCallback(response) {
					}, function errorCallback(response) {
						alert('Attenzione! Non è stato possibile salvare le modifiche. Riprova pi&ugrave; tardi');
					}
				);
			}, function errorCallback() {}
		);
	};
	
	$scope.loadDefaultText  = function( item ) {
		if( confirm("Sei sicuro di voler ricaricare il contenuto preimpostato? Perderai il testo attuale.") ){
			$http.get(appURL+'/crud/pscsectiontexts/type/'+item, {}).then( 
				function successCallback(response) {
					var data = response.data;
					if( data && data.text) {
						$scope.object[item] = data.text;
					}
				}, function errorCallback(response) {
					alert("Errore generico. Riprova.")
				}
			);
		}
	};
	
	$scope.sharePDF = function(obj, doc) {
		var host = $location.host();
		warningService.showModal("success", "Puoi condividere il documento con altri usando il seguente link. Copia e incolla l'indirizzo e invialo tramite mail.<br><span class='text-muted'>"+appURL+"/docs/pscs/"+doc.pdf+"</span>", "Chiudi");
	};
	
	$scope.shouldGeneratePDF = function (obj, doc, isdraft) {
		generatePDFService.generatePDF('pscs', obj, doc, isdraft);
	};

	$scope.shouldGeneratePDFs = function (obj, isdraft) {
		var pdfableDocs = [];
		$scope.documents.forEach(function(doc) {
			if( doc.selected && !doc.datamissing && (!doc.lastpdfdate || doc.lastpdfdate<=$scope.lastrevdate) ) {
				doc.selected = false;
				pdfableDocs.push(doc);
			}
		});
		generatePDFService.generatePDFs('pscs', obj, pdfableDocs, isdraft);
	};
	$scope.downloadPDFs = function() {
		var offset = 0;
		$scope.documents.forEach(function(doc) {
			if( doc.selected && doc.lastpdfdate && doc.pdf ) {
				doc.selected = false;
				offset += 1000;
	            $timeout(function () {
	                var a = document.createElement('a');
	                document.body.appendChild(a);
	                a.download = name;
	                a.href = appURL+"/docs/pscs/"+doc.pdf;
	                a.click();
	                document.body.removeChild(a);
	            }, offset);
			}
		});
	};
	
	$scope.sharePDFs = function() {
		var links = "";
		$scope.documents.forEach(function(doc) {
			if( doc.selected && doc.lastpdfdate && doc.pdf ) {
				doc.selected = false;
				links += "<span>"+doc.description+"</span><br><span>"+appURL+"/docs/pscs/"+doc.pdf+"</span><br><br>";
			}
		});
		warningService.showModal("success", "Puoi condivideri i documenti con altri usando i seguenti link. Copia e incolla il testo e invialo tramite email.<br>"+links, "Chiudi");
	};
	
	$scope.toggleDocuments = function() {
		if( $scope.showDocuments ) { 
			$window.onbeforeunload = function() { return 'Sei sicuro di voler uscire?'; };
			$scope.closeDocuments(); 
		} else {
			$window.onbeforeunload = undefined;
			$scope.openDocuments();
		}
	}
	$scope.closeDocuments = function() {
		$scope.showDocuments = false;
	}
	
	$scope.sharePSC = function(objectId, subject, place) {
		var modalInstance = $uibModal.open({
			size: 'lg',
			templateUrl: 'sharePSCModal.html',
			controller: ['$scope', '$uibModalInstance', 'objectId','subject','place', function (scope, $uibModalInstance, objectId, subject, place) {
				scope.emails = [{tag:'', email:''}];
				scope.customText = "Invio link per scaricare il Piano Sicurezza di Coordinamento del cantiere relativo a '"+subject+"' a "+place+". Seguendo i pochi semplici passi riportati qui sotto sarà possibile produrre direttamente il Piano Operativo di Sicurezza.  ";
				scope.fullname = "";
				scope.cancel = function () {
					$uibModalInstance.dismiss('cancel');
				};
				scope.share = function () {
					$uibModalInstance.close({objectId: objectId, customText: scope.customText, emails: scope.emails, fullname: scope.fullname});
				};
			}],
			
			resolve: { objectId: function () { return objectId; }, subject: function () { return subject; }, place: function () { return place; } }
		});
	
		modalInstance.result.then(
			function successCallback(obj) {
				$http.post(appURL+'/services/pscs/sharepscs',{'object':{_id:obj.objectId, customText: obj.customText, emails: obj.emails, fullname: obj.fullname}}).then(
					function successCallback(response) {
						alert('Perfetto! Abbiamo inviato le istruzioni per scaricare il PSC e produrre il POS');
		
					}, function errorCallback(response) {
						alert('Si è verificato un errore. Si prega di riprovare');
					}
				);
			}, function errorCallback() {}
		);
	}
	
	$scope.openDocuments = function() {
		$scope.loadingDocs = true;
		$scope.showDocuments = true;
		$http.get(appURL+'/services/pscs/'+$routeParams.id+ '/docs' ).then( 
			function successCallback( response ) {
				$scope.loadingDocs = false;
				$scope.lastrevdate = response.data.lastrevdate;
				$scope.documents = response.data.documents;
				$scope.documents.forEach(function(doc) {
					doc.selected = false;
				});
				$scope.$watch(function($scope) {
					return $scope.documents.map(function(obj) { return obj.selected; }); }, function(newVal) {
						$scope.downloadabledocsCount = 0;
						$scope.pdfabledocsCount = 0;
						$scope.documents.forEach(function(doc) {
							if( doc.selected && doc.lastpdfdate && doc.pdf ) {
								$scope.downloadabledocsCount++;
							}
							if( doc.selected && !doc.datamissing && (!doc.lastpdfdate || doc.lastpdfdate<=$scope.lastrevdate) ) {
								$scope.pdfabledocsCount++;
							}
						});
				}, true);
			}, function errorCallback( response ) {
				$scope.documents=[];
				$scope.loadingDocs = false;
			}
		);
	}
	$scope.toggleSelectionForDocument = function(doc) {
		doc.selected = !doc.selected;
	}
	*/
	$scope.deleteWorksite = function (objectId) {
		var modalInstance = $uibModal.open({
			templateUrl: 'deleteWorksiteModal.html',
			controller: ['$scope', '$uibModalInstance', 'objectId', function (scope, $uibModalInstance, objectId) {
				scope.cancel = function () {
					$uibModalInstance.dismiss('cancel');
				};
				scope.delete = function () {
					$uibModalInstance.close(objectId);
				};
			}],
			
			resolve: { objectId: function () { return objectId; } }
		});
	
		modalInstance.result.then(
			function successCallback(objId) {
				$http.delete(appURL+'/crud/worksites/'+objId,{}).then(
					function successCallback(response) {
						$location.path('/cantieri');
		
					}, function errorCallback(response) {
						alert('Si è verificato un errore. Si prega di riprovare');
					}
				);
			}, function errorCallback() {}
		);
	};
	/*
	$scope.loadDefaultTemplate = function( status, template, value ) {
		if( status ) {
			$http.get(appURL+'/crud/pscssectiontexts/type/'+template, {}).then( 
				function successCallback(response) {
					var data = response.data;
					if( data && data.text) {
						value.description = data.text;
					}
				}, function errorCallback(response) {
					// No alert: it simply does nothing
					//alert("Errore generico. Riprova.")
				}
			);
		}
	}
	*/
	/* End custom add/delete functions */
	$scope.remoteObject = undefined;
	$scope.loaded = false; 
	$scope.object = null;
	$scope.selection = {employee:null};

	$scope.updateStatuses = function() {
		var c = $scope.currentChapter;
		var s = c.sections;
		var d = $scope.object;
		var cs = $scope.docChapters;
		var sum = 0;
		if( d === null) { return;}
		var study = d.professionalstudy;
		
		switch(c.section) {
			case 'A':
				s[0].status = (study !== undefined && study.name !== undefined && study.name.length && study.legaladdress.city !== undefined && study.legaladdress.city.length>0 && study.legaladdress.address !== undefined && study.legaladdress.address.length>0)?2:1;
				
				s[1].status = (d.subject !== undefined && d.subject.length>0 && d.startdate && d.enddate)?2:1;
				if( d.agencies.length && s[1].status == 2 ) {
					var check = 0;
					for( var j=0;j<d.agencies.length;j++ ) {
						if( d.agencies[j].contact ) { check++;}
					}
					if( check != d.agencies.length ) { s[1].status=1;}
				}
				s[2].status = (d.place.city !== undefined && d.place.city.length>0 && d.place.address !== undefined && d.place.address.length>0)?2:1;
				
				
				s[3].status =  (d.customers.length && d.customers[0].contact && (d.worksmanager.exists===true?(d.worksmanager.contact?true:false):true) && (d.cse.contact?true:false))?2:1;
				if( d.customers.length && s[3].status == 2 ) {
					var check = 0;
					for( var j=0;j<d.customers.length;j++ ) {
						if( d.customers[j].contact ) { check++;}
					}
					if( check != d.customers.length ) { s[3].status=1;}
				}
				if( d.additionalcses.length && s[3].status == 2 ) {
					var check = 0;
					for( var j=0;j<d.additionalcses.length;j++ ) {
						if( d.additionalcses[j].contact ) { check++;}
					}
					if( check != d.additionalcses.length ) { s[3].status=1;}
				}
				if( d.surveytechnicians.length && s[3].status == 2 ) {
					var check = 0;
					for( var j=0;j<d.surveytechnicians.length;j++ ) {
						if( d.surveytechnicians[j].contact ) { check++;}
					}
					if( check != d.surveytechnicians.length ) { s[3].status=1;}
				}
				if( d.actors.length && s[3].status == 2 ) {
					var check = 0;
					for( var j=0;j<d.actors.length;j++ ) {
						if( d.actors[j].contact ) { check++;}
					}
					if( check != d.actors.length ) { s[3].status=1;}
				}
				
				s[4].status = (d.enterprises.length && d.enterprises[0].contact)?2:1;
				if( d.enterprises.length && s[4].status == 2 ) {
					var check = 0;
					for( var j=0;j<d.enterprises.length;j++ ) {
						if( d.enterprises[j].contact && ((d.enterprises[j].contact.iscompany && d.enterprises[j].contact.companyname && d.enterprises[j].contact.companyname.length) || (!d.enterprises[j].contact.iscompany && d.enterprises[j].contact.lastname && d.enterprises[j].contact.lastname.length)) ) { check++;}
					}
					if( check != d.enterprises.length ) { s[4].status=1;}
				}

				s[5].status = 2;
				s[6].status = 2;
				s[7].status = 2;
				break;
				
			case 'B':
				s[0].status = 2;
				s[1].status = 2;
				break;
			case 'C':
				s[0].status = 2;
				s[1].status = 2;
				s[2].status = 2;
				s[3].status = 2;
				s[4].status = 2;
				s[5].status = 2;
				s[6].status = 2;
				s[7].status = 2;
				break;

			case 'D':
				s[0].status = 2;
				s[1].status = 2;
				s[2].status = 2;
				break;
			case 'E':
				s[0].status = 2;
				s[1].status = 2;
				s[2].status = 2;
				break;
			case 'F':
				s[0].status = 2;
				break;
			case 'G':
				s[0].status = 2;
				break;
			case 'H':
				s[0].status = (d.fdoNotRequired==1 || (d.fdoNotRequired==2 && d.fdoNotRequiredText.lenght>0) || (d.enddate && d.textFDO1 !== undefined && d.textFDO1.length>0))?2:1;
				s[1].status = (d.fdoNotRequired==1 || (d.fdoNotRequired==2 && d.fdoNotRequiredText.lenght>0) || (d.textFDO6 !== undefined && d.textFDO6.length>0))?2:1;
				s[2].status = (d.fdoNotRequired==1 || (d.fdoNotRequired==2 && d.fdoNotRequiredText.lenght>0) || (d.textFDO2 !== undefined && d.textFDO2.length>0))?2:1;
				s[3].status = 2;
				s[4].status = (d.fdoNotRequired==1 || (d.fdoNotRequired==2 && d.fdoNotRequiredText.lenght>0) || (d.textFDO3 !== undefined && d.textFDO3.length>0))?2:1;
				s[5].status = (d.fdoNotRequired==1 || (d.fdoNotRequired==2 && d.fdoNotRequiredText.lenght>0) || (d.textFDO4 !== undefined && d.textFDO4.length>0))?2:1;
				s[6].status = (d.fdoNotRequired==1 || (d.fdoNotRequired==2 && d.fdoNotRequiredText.lenght>0) || (d.textFDO5 !== undefined && d.textFDO5.length>0))?2:1;
				s[7].status = 2;
				s[8].status = 2;
				break;
				/*
			case 'A':
				var ctx = d.context;
				s[0].status = (d.specifystartdate === false || (d.specifystartdate === true && d.startdate && d.startdate))?2:1;
				s[1].status = (d.customers.length && d.customers[0].contact && (d.worksmanager.exists===true?(d.worksmanager.contact?true:false):true) && (d.safetycoordinatorspresence<=1?(d.csp.contact?true:false):true) && ((d.safetycoordinatorspresence>1 || (d.safetycoordinatorspresence==1 && !d.samecspandcse))?(d.cse.contact?true:false):true))?2:1;
				
				if( d.customers.length && s[1].status == 2 ) {
					var check = 0;
					for( var j=0;j<d.customers.length;j++ ) {
						if( d.customers[j].contact ) { check++;}
					}
					if( check != d.customers.length ) { s[1].status=1;}
				}
				if( d.actors.length && s[1].status == 2 ) {
					var check = 0;
					for( var j=0;j<d.actors.length;j++ ) {
						if( d.actors[j].contact ) { check++;}
					}
					if( check != d.actors.length ) { s[1].status=1;}
				}
				s[2].status= (d.dontspecifycontractors == true || d.dontspecifycontractors == false && d.contractors.length) ? 2 : 1;
				
				if( d.dontspecifycontractors == false && d.contractors.length && s[2].status == 2) {
					var check = 0;
					for( var j=0;j<d.contractors.length;j++ ) {
						if( d.contractors[j].contact && ((d.contractors[j].contact.iscompany && d.contractors[j].contact.companyname && d.contractors[j].contact.companyname.length) || (!d.contractors[j].contact.iscompany && d.contractors[j].contact.lastname && d.contractors[j].contact.lastname.length)) ) { check++;}
					}
					if( check != d.contractors.length ) { s[2].status=1;}
				}
				s[3].status = ((d.citymaptype<=1||(d.citymaptype==2&&d.citymapimage!=null)) && (d.areamaptype<=1||(d.areamaptype==2&&d.areamapimage!=null)))?2:1;
				s[4].status = ((d.geomaptype<=1||(d.geomaptype==2&&d.geomapimage!=null)) && (d.satmaptype<=1||(d.satmaptype==2&&d.satmapimage!=null)) && (ctx.north.exists===true?((ctx.north.description !== undefined && ctx.north.description.length)?true:false):true) && (ctx.northeast.exists===true?((ctx.northeast.description !== undefined && ctx.northeast.description.length)?true:false):true) && (ctx.east.exists===true?((ctx.east.description !== undefined && ctx.east.description.length)?true:false):true) && (ctx.southeast.exists===true?((ctx.southeast.description !== undefined && ctx.southeast.description.length)?true:false):true) && (ctx.south.exists===true?((ctx.south.description !== undefined && ctx.south.description.length)?true:false):true) && (ctx.southwest.exists===true?((ctx.southwest.description !== undefined && ctx.southwest.description.length)?true:false):true) && (ctx.west.exists===true?((ctx.west.description !== undefined && ctx.west.description.length)?true:false):true) && (ctx.northwest.exists===true?((ctx.northwest.description !== undefined && ctx.northwest.description.length)?true:false):true))?2:1;
				s[5].status = 2;
				s[6].status = 2;
				s[7].status = 2;
				s[8].status = (d.textA4 !== undefined && d.textA4.length)?2:1;
				s[9].status = (d.textA5 !== undefined && d.textA5.length && d.zones.length)?2:1;
				s[10].status = 2;
				s[11].status = 2;
				break;
			case 'B':
				s[0].status = (d.textB2 !== undefined && d.textB2.length)?2:1;
				s[1].status = (d.placerisks.objects !== undefined && d.placerisks.objects.length>0)?2:1;
				s[2].status = (d.externalrisks.objects !== undefined && d.externalrisks.objects.length>0)?2:1;
				s[3].status = (d.internalrisks.objects !== undefined && d.internalrisks.objects.length>0)?2:1;
				s[4].status = (d.textB3 !== undefined && d.textB3.length)?2:1;
				s[5].status = (d.notesB31a.objects !== undefined && d.notesB31a.objects.length>0)?2:1;
				s[6].status = (d.notesB31b.objects !== undefined && d.notesB31b.objects.length>0)?2:1;
				s[7].status = (d.notesB31c.objects !== undefined && d.notesB31c.objects.length>0 && d.notesB31e.objects !== undefined && d.notesB31e.objects.length>0)?2:1;
				s[8].status = (d.notesB31d.objects !== undefined && d.notesB31d.objects.length>0)?2:1;
				s[9].status = (d.notesB31g.objects !== undefined && d.notesB31g.objects.length>0)?2:1;
				s[10].status = (d.notesB31h.objects !== undefined && d.notesB31h.objects.length>0)?2:1;
				s[11].status = ((d.healthEmergencyCompliant ? (d.textB4he !== undefined && d.textB4he.length>0) : true) && (d.textB4 !== undefined && d.textB4.length>0))?2:1;
				s[12].status = 2;
				s[13].status = 2;
				s[14].status = 2;
				s[15].status = 2;
				s[16].status = 2;
				s[17].status = 2;
				break;
			case 'D':
				s[0].status = ((d.healthEmergencyCompliant ? (d.textD1he !== undefined && d.textD1he.length>0) : true) && (d.textD1 !== undefined && d.textD1.length>0))?2:1;
				s[1].status = (d.textD2 !== undefined && d.textD2.length>0)?2:1;
				s[2].status = (d.textD3 !== undefined && d.textD3.length>0)?2:1;
				s[3].status = (d.textD4 !== undefined && d.textD4.length>0)?2:1;
				s[4].status = (d.textD5 !== undefined && d.textD5.length>0)?2:1;
				s[5].status = (d.emergencyphones !== undefined && d.emergencyphones.length>0)?2:1;
				s[6].status = (d.dontspecifyhospital || (d.hospital !== undefined && d.hospital.address !== undefined && d.hospital.address.city && d.hospital.address.city.length && d.hospital.address.address && d.hospital.address.address.length))?2:1;
				s[7].status = 2;
				break;
			case 'E':
				s[0].status = (d.textE1 !== undefined && d.textE1.length>0)?2:1;
				s[1].status = 2;
				s[2].status = 2;
				s[3].status = 2;
				s[4].status = 2;
				s[5].status = 2;
				s[6].status = (d.textF2 !== undefined && d.textF2.length>0)?2:1;
				break;
			case 'F':
				s[0].status = (d.textF1 !== undefined && d.textF1.length>0)?2:1;
				s[1].status = 2;
				s[2].status = 2;
				break;
			case 'CST':
				s[0].status = (d.textCST1 !== undefined && d.textCST1.length>0 && d.textCST2 !== undefined && d.textCST2.length>0)?2:1;
				s[1].status = 2;
				break;
			case 'All1':
				s[0].status = 2;
				break;
				*/
			default:
				break;
		}
		for( var i=0;i<s.length;i++) {
			sum += s[i].status;
		}
		$scope.currentChapter.status = Math.floor(sum/s.length);
		var idx = $scope.docChapters.indexOf($scope.currentChapter);

		var counts = [0,0,0];
		for( var i=0;i<cs.length;i++) {
			if( i===idx ) {
				counts[$scope.currentChapter.status]++;
			} else {
				counts[$scope.object.statuses[i]]++;
			}
		}
		$scope.stats.drafts = counts[0];
		$scope.stats.todos = counts[1];
		$scope.stats.done = counts[2];
		$scope.stats.total = cs.length;
	};
	$scope.$watch('object', function(values) {
		$scope.updateStatuses();
	}, true);
	
	$scope.addWorksiteEnterprise = function() {
		var newObj = new models.WorksiteEnterprise(false);
		$scope.object.enterprises.push( newObj );
	};
	$scope.addWorksiteFreelancer = function() {
		var newObj = new models.WorksiteEnterprise(true);
		$scope.object.enterprises.push( newObj );
	};
	$scope.deleteWorksiteEnterprise = function(index) {
		if( $scope.object.enterprises[index].contact &&  $scope.object.enterprises[index].contact._id ) {
			var enterpriseHasSubcontractors = false;
			$scope.object.enterprises.forEach(function(c) {
				if( c.parentcontractor && c.parentcontractor._id===$scope.object.enterprises[index].contact._id ) { 
					enterpriseHasSubcontractors=true;
				}
			});
			if( enterpriseHasSubcontractors ) { 
				alert("ATTENZIONE! L'impresa ha dei subappalti. Elimina prima i subappalti per poter cancellare questa impresa");
			} else {
				$scope.object.enterprises.splice(index,1)
			}
		} else {
			$scope.object.enterprises.splice(index,1)
		}
	};
	/* Custom functions */
	
	$scope.addAgency = function() {
		$scope.object.agencies.push({});	
	};
	$scope.addCustomer = function() {
		$scope.object.customers.push( new models.Actor() );
	};
	$scope.addActor = function() {
		$scope.object.actors.push( new models.Actor() );
	};
	$scope.addOtherActor = function() {
		$scope.object.otheractors.push( new models.Actor() );
	};
	$scope.addAdditionalCSE= function() {
		$scope.object.additionalcses.push( new models.Actor() );
	};
	$scope.addSurveyTechnician= function() {
		$scope.object.surveytechnicians.push( new models.Actor() );
	};
	$scope.deleteObject = function(objects, idx) {
		if( objects && objects.length ) {
			if( idx>=0 && idx<objects.length)
			objects.splice(idx,1)
		}
	};
	$scope.addExistsFlag = function() {
		$scope.object.worksmanager.exists = true;
	}
	/*
	$scope.addFreelancer = function() {
		$scope.object.contractors.push( {contact:{iscompany:false}} );
	};
	$scope.cloneTextSection = function(obj, srcKey, destKey) {
		$timeout(function() {
			for( var i=obj[destKey].length-1;i>=0;i--) {
				obj[destKey].splice(i,1);
			}
			if( obj[srcKey] ) {
				obj[srcKey].forEach(function(section) {
					obj[destKey].push(angular.copy(section));
				});
			}			
		},200);
	};
	*/
	/*
	$scope.deleteZone = function() {
		$scope.object.zones.splice($scope.object.zones.indexOf($scope.selectedzone),1);
		$scope.changeZone($scope.object.zones[$scope.object.zones.length-1]);
	};
	$scope.changeZone = function(zone) {
		$scope.selectedzone = zone;
		$scope.selectedwork = (( zone.works && zone.works.length ) ? zone.works[0] : null);
	};
	$scope.addZone = function(isHeader) {
		var zone = new models.Zone('Nuova zona', 'Area di intervento', null, '128,64,64', [new models.Work('Nuovo lavoro', 1, [new models.Timerange(1,10)], {name:'Appalto da definire'}, '100,179,223')]);
		if( isHeader === true ) { zone.isHeader = true; } 
		$scope.object.zones.push(zone);
		$scope.changeZone(zone);	
	};
	$scope.copyZone = function() {
		var zone = angular.copy($scope.selectedzone);
		zone._id = models.clientId();
		zone.name = "Copia di "+$scope.selectedzone.name;
		$scope.object.zones.push(zone);
		$scope.changeZone(zone);	
	};
	*/
	/* End custom add function */
	/*
	$scope.getContractors = function(val) {
		return $http.get(appURL+'/utils/contractors/'+$scope.object._id+( val ? '?search=' + val : '' ) ).then( function( res ) {
			return res.data; 
		});
	};
	$scope.deleteWork = function() {
		$scope.selectedzone.works.splice($scope.selectedzone.works.indexOf($scope.selectedwork),1);
		$scope.selectedwork = $scope.selectedzone.works[$scope.selectedzone.works.length-1];
	};
	$scope.changeWork = function(work) {
		$scope.selectedwork = work;
	};
	$scope.addWork = function() {
		if( !$scope.selectedzone.works ) { $scope.selectedzone.works=[];}
		var startday = 1;
		$scope.selectedzone.works.forEach(function(c) {
			startday += c.duration;
		});
		$scope.selectedzone.works.push({name:'Nuovo lavoro', subworks:[], duration:10, startday: startday, contractors:[{name:''}], image:'', color:'#f132c2'});
		$scope.selectedwork = $scope.selectedzone.works[$scope.selectedzone.works.length-1];	
	};
	*/
	$scope.colors = [{name:'Nero', value:'#000000'}, {name:'Rosso', value:'#ff0000'},{name:'Verde', value:'#00ff00'},{name:'Blu', value:'#0000ff'}];
	$scope.fonts = ['Helvetica', 'Courier', 'Times'];
	$scope.fontsizes = [9,10,12,14,16];
	
	$scope.sharedpscdocs = [];	
	$scope.get = function() {
		if( $routeParams.id !== undefined && $routeParams.id ) {// edit
		    $http.get(appURL+'/services/worksites/'+$routeParams.id+ '/' +$scope.currentChapter.section ).then( 
			    function successCallback( response ) {
				    var data = response.data;
					switch($scope.currentChapter.section) {
						case 'A':
							// Section 1
							data.startdate = new Date(data.startdate);
							data.startdate.setHours(12);
							data.enddate = new Date(data.enddate);
							data.enddate.setHours(12);
							
							if( data.additionalcses === undefined ) { data.additionalcses = []; }
							if( data.surveytechnicians === undefined ) { data.surveytechnicians = []; }
							if( data.otheractors === undefined ) { data.otheractors = []; }
							if( data.rolestype === undefined ) { data.rolestype = 'cse'; }
							var now = new Date();
							now.setHours(12);
							$scope.nowtime = now.getTime();
							
							/*
							if( data.documents === undefined) { 
							   data.documents = [];
							   data.documents.push({ type:"psc",description:"PSC", lastrevdate: new Date(), datamissing: false});
							   data.documents.push({ type:"fdo",description:"Fascicolo dell'opera", lastrevdate: new Date(), datamissing: false});
							   data.documents.push({ type:"stc",description:"Stima dei costi", lastrevdate: new Date(), datamissing: false, lastpdfdate: new Date("October 13, 2014 11:13:00")});
							   data.documents.push({ type:"lay",description:"Layout di cantiere", lastrevdate: new Date(), datamissing: true, lastpdfdate: new Date("October 13, 2014 11:13:00")});
							   data.documents.push({ type:"sig",description:"Cartellonistica", lastrevdate: new Date(), datamissing: true});
							   data.documents.push({ type:"eme",description:"Emergenza", lastrevdate: new Date(), datamissing: true});
							}
							if( data.safetycoordinatorspresence === undefined ) { data.safetycoordinatorspresence=0; }
							$scope.safetycoordinatorspresence = data.safetycoordinatorspresence;
							*/
							$scope.contracttype = data.contracttype;
							data.enterprises.forEach(function(enterprise) {
								if( enterprise.worksiteaccessdate === undefined ) {
									enterprise.worksiteaccessdate = new Date();
									enterprise.worksiteduration = 30;
									enterprise.onhold = false;
								} else {
									enterprise.worksiteaccessdate = new Date(enterprise.worksiteaccessdate);
								}
							});
							break;
						case 'B':
							var tmpUsers = {};
							var shouldSync = false;
							
							$scope.worksite = { _id: data._id, place: data.place, subject: data.subject, shareCode: data.shareCode, messagesender: data.messagesender };
							$scope.contacts = {};
							$scope.enterprises = {};
							data.users.forEach(function(user) {
								tmpUsers[user.contactId]=user;
								$scope.contacts[user.contactId]=null;
							});
							var allContacts = [];
							data.customers.forEach(function(contact) {
								contact.role = "Committente";
							});
							data.additionalcses.forEach(function(contact) {
								contact.role = "CSE";
							});
							data.surveytechnicians.forEach(function(contact) {
								contact.role = "Tecnico di sopralluogo";
							});
							allContacts = allContacts.concat(data.customers);
							allContacts = allContacts.concat(data.additionalcses);
							allContacts = allContacts.concat(data.surveytechnicians);
							allContacts = allContacts.concat(data.actors);
							allContacts = allContacts.concat(data.otheractors);
							if( data.worksmanager && data.worksmanager.contact && data.worksmanager.contact._id ) {
								data.worksmanager.role = "Responsabile dei lavori";
								allContacts.push(data.worksmanager);
							} 
							if( data.cse && data.cse.contact && data.cse.contact._id ) {
								data.cse.role = "CSE";
								allContacts.push(data.cse);
							} 
							// Insert new actors/customers/cse/workmanager if not present
							allContacts.forEach(function(contact) {
								if( contact && contact.contact && contact.contact._id ) {
									if( tmpUsers[contact.contact._id] === undefined ) {
										var newUser = new models.WorksiteUser(contact.contact._id);
										data.users.push(newUser);
										shouldSync = true;
									}
									$scope.contacts[contact.contact._id] = contact;
								}
							});
							// Insert new enterprises if not present
							data.enterprises.forEach(function(enterprise) {
								if( enterprise.contact && enterprise.contact._id ) {
									if( tmpUsers[enterprise.contact._id] === undefined ) {
										var newUser = new models.WorksiteUser(enterprise.contact._id, true);
										data.users.push(newUser);
										shouldSync = true;
									}
									// for legacy
									if( enterprise.worksiteaccessdate === undefined ) {
										enterprise.worksiteaccessdate = new Date();
										enterprise.worksiteduration = 30;
										enterprise.onhold = false;
									}
									$scope.contacts[enterprise.contact._id] = enterprise;
									$scope.enterprises[enterprise.contact._id] = enterprise;
								}
							});
							// Delete removed actors/customers/enterprises/cse/workmanager if already present
							for( var i=data.users.length-1; i>=0; i-- ) {
								if( $scope.contacts[data.users[i].contactId] == null ) {
									data.users.splice(i, 1);
									shouldSync = true;
								}
							}
							break;
						case 'C':
							if( data.sharedPscId !== undefined ) {
								$http.get(appURL+'/services/worksites-docs/sharedpsc/'+data.sharedPscId ).then( 
									function successCallback(response) {
										$scope.sharedpscdocs = response.data;
									}, function errorCallback(response) {
									}
								);
							}
							if( data.documents_cse === undefined ) { data.documents_cse = new models.WorksiteDocSection(['cse']); }
							if( data.documents_notifications === undefined ) { data.documents_notifications = new models.WorksiteDocSection(['not']); }
							if( data.documents_procedures_general === undefined ) { data.documents_procedures_general = new models.WorksiteDocSection(['oth']); }
							if( data.documents_procedures_managing === undefined ) { data.documents_procedures_managing = new models.WorksiteDocSection(['oth']); }
							if( data.documents_procedures_operating === undefined ) { data.documents_procedures_operating = new models.WorksiteDocSection(['oth']); }
							
							var documents = [data.documents_let, data.documents_cse, data.documents_psc, data.documents_costs, data.documents_fdo, data.documents_notifications, data.documents_duvri, data.documents_prj, data.documents_procedures_general, data.documents_procedures_managing, data.documents_procedures_operating];
							documents.forEach(function(docsection) {
								for( var i=0; i<docsection.docs.length; i++ ) {
									if( docsection.docs[i].issuedate ) {
										docsection.docs[i].issuedate = new Date(docsection.docs[i].issuedate);
									}
								}
							});
							$scope.mailinglist = createMailingList.createFromData(data);						
							break;
						case 'D':
							$scope.attributionList = ['Impresa affidataria','Impresa in subappalto','Lavoratore autonomo'];
							data.enterprises.forEach(function(ent) {
								if( ent && ent.contact ) {
									let contact = ent.contact;
									if( contact.iscompany ) {
										$scope.attributionList.push(contact.companyname);
									} else {
										$scope.attributionList.push((contact.title.length ? contact.title+' ' : '')+contact.firstname+' '+contact.lastname);
									}
								}
							});
							break;

						case 'E':
							$scope.sharedpscdocs = [];
							if( data.reportsmeetings === undefined ) { data.reportsmeetings = new models.WorksiteDocSection(['rep']);}
							if( data.reportscse === undefined ) { data.reportscse = new models.WorksiteDocSection(['rep']);}
							if( data.reportsrdl === undefined ) { data.reportsrdl = new models.WorksiteDocSection(['rep']);}
							var reports = [data.reportsmeetings, data.reportscse, data.reportsrdl];
							reports.forEach(function(reportsection) {
								for( var i=0; i<reportsection.docs.length; i++ ) {
									if( reportsection.docs[i].issuedate ) {
										reportsection.docs[i].issuedate = new Date(reportsection.docs[i].issuedate);
									}
								}
							});
							
							if( data.customreportsmeetings === undefined ) { data.customreportsmeetings = [];}
							if( data.customreportscse === undefined ) { data.customreportscse = [];}
							if( data.customreportsrdl === undefined ) { data.customreportsrdl = [];}
							var customreports = [data.customreportsmeetings, data.customreportscse, data.customreportsrdl];
							customreports.forEach(function(reports) {
								for( var i=0; i<reports.length; i++ ) {
									if( reports[i].issuedate ) {
										reports[i].issuedate = new Date(reports[i].issuedate);
									}
									if( reports[i].starttime ) {
										reports[i].starttime = new Date(reports[i].starttime);
									}
									if( reports[i].endtime ) {
										reports[i].endtime = new Date(reports[i].endtime);
									}
									if( reports[i].prescriptions_D === undefined ) {
										reports[i].prescriptions_D = [];
									}
									if( reports[i].prescriptions_E === undefined ) {
										reports[i].prescriptions_E = [];
									}
									if( reports[i].prescriptions_F === undefined ) {
										reports[i].prescriptions_F = [];
									}
									if( reports[i].attendants === undefined ) {
										reports[i].attendants = [];
									}
									if( reports[i].allegeddocs === undefined ) {
										reports[i].allegeddocs = [];
									}
									
									var prescriptions = [reports[i].prescriptions_prev, reports[i].prescriptions_A, reports[i].prescriptions_B, reports[i].prescriptions_C, reports[i].prescriptions_D, reports[i].prescriptions_E, reports[i].prescriptions_F];
									for( var j=0; j<prescriptions.length; j++ ) {
										prescriptions[j].forEach(function(prescription) {
											prescription.prescriptiondeadline = new Date(prescription.prescriptiondeadline);
										});
									}
									reports[i].allegeddocs.forEach(function(c) {
										if( c.isattached === undefined ) { c.isattached = true;}
										if( c._id === undefined ) { c._id=models.clientId(); }
									});
								}
							});
							
							$scope.mailinglist = createMailingList.createFromData(data);						
							break;
							
						case 'H':
							if( data.fdoNotRequired === undefined ) { data.fdoNotRequired = 0;}
							data.enddate = new Date(data.enddate);
							data.enddate.setHours(12);
							break;
							/*
						case 'A':
							if( data.dontspecifycontractors === undefined ) { data.dontspecifycontractors = false; }
							if( !data.contractors ) {data.contractors = [];}
							if( data.context === undefined ) { data.context = new models.Context(); }
							if( data.citymapzoom === undefined ) { data.citymapzoom = 8; }
							if( data.areamapzoom === undefined ) { data.areamapzoom = 17; }
							if( data.geomapzoom === undefined ) { data.geomapzoom = 10; }
							if( data.satmapzoom === undefined ) { data.satmapzoom = 20; }
							if( !data.contextimages ) { data.contextimages = [];}
							if( !data.worksiteimages ) { data.worksiteimages = [];}
							if( !data.images ) { data.images = [];}
							// Convert from images to sections
							if( data.images.length && data.images[0].name === undefined ) {
								var section = { name: 'Senza titolo', layout:0, images: angular.copy(data.images)};
								data.images = [section];
							}
							if( data.useexternallayout === undefined ) { data.useexternallayout = false; }

							if( data.layouts === undefined ) { data.layouts = [];}
							if( !data.layouts.length ) { 
								var imageid = data.layoutimage === undefined ? null : data.layoutimage;
								var papersize = data.layoutpapersize === undefined ? 'A4' : data.layoutpapersize;
								data.layouts.push(new models.Layout('Layout', papersize, imageid));
							}
							if( data.useexternalgantt === undefined ) { data.useexternalgantt = false; }
							data.agencies.forEach(function(agency) {
								if( agency.name !== undefined ) {
									agency.contact = { iscompany: true, companyname: agency.name };
									delete agency.name;
								}
							});
							if( !data.ganttpapersize ) { data.ganttpapersize = 'A4';}
							if( !data.textA5 && data.textB1 ) {  // For compatibility after moving from chapter B to A
								data.textA5 = angular.copy(data.textB1);
								delete data.textB1;
							}
							if( Object.prototype.toString.call( data.textA4 ) !== '[object Array]' ) {
								data.textA4 = [{text:data.textA4, imagesections:[]}];
							}
							if( !data.zones || !data.zones.length ) { 
								data.zones = [new models.Zone('Area di intervento', 'Area di intervento', null, '128,64,64', [])]; 
							}
							if( !data.zones[0].works || !data.zones[0].works.length ) {
								data.zones[0].works.push(new models.Work('Nuovo lavoro', 1,[new models.Timerange(1,10)], {name:'Appalto da definire'}, '100,179,223'));
							}
							if( !data.calendar) { data.calendar = new models.Calendar(); }
							if( !data.calendar.holidays || !data.calendar.holidays.length ) { 
								data.calendar.holidays = new models.defaultHolidays(); 
							} else {
								data.calendar.holidays.forEach(function(c) {
									c.date = new Date(c.date);
									c.date.setHours(12);
								});
							}
							if( !data.calendar.weekdays ) { 
								data.calendar.weekdays = new models.defaultWeekdays(); 
							}
							if( !data.calendar.workingdates ) {
								data.calendar.workingdates = [];
							}
							for( var z=0;z<data.calendar.workingdates[z];z++ ) {
								data.calendar.workingdates[z] = new Date(data.calendar.workingdates[z]);
								data.calendar.workingdates[z].setHours(12);
							}
							if( !data.calendar.nonworkingdates ) {
								data.calendar.nonworkingdates = [];
							}
							for( var z=0;z<data.calendar.nonworkingdates[z];z++ ) {
								data.calendar.nonworkingdates[z] = new Date(data.calendar.nonworkingdates[z]);
								data.calendar.nonworkingdates[z].setHours(12);
							}
							if( !data.calendaroptions ) { 
								data.calendaroptions = new models.CalendarOptions(models.PSCCALENDAR); 
							}
							data.calendaroptions.canaddinterferencesnotestocosts = true;
							$scope.contractors = { maincontractors: data.contractors };
							$scope.changeZone(data.zones.length ? data.zones[0] : null);
							break;
						case 'B':
							if( !data.mainzonedescription ) { data.mainzonedescription="";}
							if( !data.zones || !data.zones.length ) { 
								data.zones = [new models.Zone('Area di intervento', 'Area di intervento', null, '128,64,64', [])]; 
							}
							if( !data.zones[0].works || !data.zones[0].works.length ) {
								data.zones[0].works.push(new models.Work('Nuovo lavoro', 1,[new models.Timerange(1,10)], {name:'Appato da definire'}, '100,179,223'));
							}
							data.zones.forEach(function(z) {
								if( z.works ) {
									z.works.forEach(function(w) {
										if( w.worksheet && w.worksheet.data ) {
											if( w.worksheet.data.interferencestype === undefined ) { w.worksheet.data.interferencestype='crono';}
											if( w.worksheet.data.prescriptions_A === undefined ) { w.worksheet.data.prescriptions_A=[];}
											if( w.worksheet.data.prescriptions_B === undefined ) { w.worksheet.data.prescriptions_B=[];}
											if( w.worksheet.data.prescriptions_C === undefined ) { w.worksheet.data.prescriptions_C=[];}
											if( w.worksheet.data.prescriptions_D === undefined ) { w.worksheet.data.prescriptions_D=[];}
										}
										if( w.subworks ) {
											w.subworks.forEach(function(s) {
												if( s.worksheet && s.worksheet.data ) {
													if( s.worksheet.data.interferencestype === undefined ) { s.worksheet.data.interferencestype='crono';}
													if( s.worksheet.data.prescriptions_A === undefined ) { s.worksheet.data.prescriptions_A=[];}
													if( s.worksheet.data.prescriptions_B === undefined ) { s.worksheet.data.prescriptions_B=[];}
													if( s.worksheet.data.prescriptions_C === undefined ) { s.worksheet.data.prescriptions_C=[];}
													if( s.worksheet.data.prescriptions_D === undefined ) { s.worksheet.data.prescriptions_D=[];}
												}
											})
										}
									});
								}
							});
							if( !data.placerisks ) { data.placerisks = new models.Catobject();}
							if( !data.externalrisks ) { data.externalrisks = new models.Catobject();}
							if( !data.internalrisks ) { data.internalrisks = new models.Catobject();}
							var objArray = [data.placerisks, data.externalrisks, data.internalrisks];
							objArray.forEach(function(d) {
								d.objects.forEach(function(c) {
									if( c.name === undefined ) { c.name = 'Nuovo';}
									if( c.descriptions === undefined ) { c.descriptions=[];}
									if( c.risklevel === undefined ) { c.risklevel = 2; }
									if( c.images === undefined ) { c.images = []; }
								});
							});
							if( !data.notesB31a ) { data.notesB31a = new models.Catobject(); }
							if( !data.notesB31b ) { data.notesB31b = new models.Catobject(); }
							if( !data.notesB31c ) { data.notesB31c = new models.Catobject(); }
							if( !data.notesB31d ) { data.notesB31d = new models.Catobject(); }
							if( !data.notesB31e ) { data.notesB31e = new models.Catobject(); }
							if( !data.notesB31f ) { data.notesB31f = new models.Catobject(); }
							if( !data.notesB31g ) { data.notesB31g = new models.Catobject(); }
							if( !data.notesB31h ) { data.notesB31h = new models.Catobjectwithgarbage(); }
							var objArray = [data.notesB31a.objects, data.notesB31b.objects, data.notesB31c.objects, data.notesB31d.objects, data.notesB31e.objects, data.notesB31f.objects, data.notesB31g.objects, data.notesB31h.objects, data.notesB31h.garbage];
							objArray.forEach(function(d) {
								if( d.name === undefined ) { d.name = 'Nuovo';}
								if( d.descriptions === undefined ) { d.descriptions=[];}
								if( d.images === undefined ) { d.images = []; }
							});
							var notesArray = [data.placerisks, data.externalrisks, data.internalrisks, data.notesB31a, data.notesB31b, data.notesB31c, data.notesB31d, data.notesB31e, data.notesB31f, data.notesB31g, data.notesB31h];
							notesArray.forEach(function(notes) {
								if( !notes.images ) { notes.images = []; }
								var section = null;
								if( notes.drawings && notes.drawings.length ) { // Old model
									// Convert from drawings to sections
									section = { name: 'Immagini', layout:0, images: angular.copy(notes.drawings)};
									delete(notes.drawings);
								}
								if( notes.images.length && notes.images[0].name === undefined ) { // Old model
									// Convert from images to sections
									if( section ) {
										notes.images.forEach(function(image) {
											section.images.push(angular.copy(image));
										});
									} else {
										section = { name: 'Immagini', layout:0, images: angular.copy(notes.images)};
									}
								}
								if( section ) {
									notes.images = [section];
								}
							});
							if( data.layouts === undefined ) { data.layouts = [];}
							if( !data.layouts.length ) { 
								var imageid = data.layoutimage === undefined ? null : data.layoutimage;
								var papersize = data.layoutpapersize === undefined ? 'A4' : data.layoutpapersize;
								data.layouts.push(new models.Layout('Layout', papersize, imageid));
							}
							if( !data.signsB51 ) { data.signsB51 = []; }
							if( !data.signsB52 ) { data.signsB52 = []; }
							if( !data.signsB53 ) { data.signsB53 = []; }
							if( !data.signsB54 ) { data.signsB54 = []; }
							if( data.hidesignsB52 === undefined ) { data.hidesignsB52 = false; }
							if( data.hidesignsB53 === undefined ) { data.hidesignsB53 = false; }
							//if( !data.signsB55 ) { data.signsB55 = []; }
							var objArray = [data.signsB51, data.signsB52, data.signsB53, data.signsB54];
							objArray.forEach(function(d) {
								if( d.name === undefined ) { d.name = 'Nuovo';}
								if( d.description === undefined ) { d.description='Nuova descrizione';}
								if( d.image === undefined ) { d.image=null;}
							});
							break;
						case 'D':
							if( !data.emergencyphones ) { data.emergencyphones = []; }
							if( !data.emergencyphones.length ) { data.emergencyphones.push(new models.Phone('',''));}
							if( !data.hospital ) {  data.hospital={}; }
							if( !data.hospital.address || !data.hospital.address.address ) { data.hospital.address =  new models.Address(); }
							if( !data.hospital.map ) { data.hospital.map =  new models.MapCoords(); }
							if( data.emergencyimages === undefined ) { data.emergencyimages = [];}
							break;
						case 'E':
							for( var i=2;i<=6;i++) {
								if( !data['proceduresE'+i] ) { data['proceduresE'+i] = new models.PSCProcedure(); }
								
							}
							var objArray = [data.proceduresE2, data.proceduresE3, data.proceduresE4, data.proceduresE5, data.proceduresE6];
							// convert old data format
							objArray.forEach(function(d) {
								var actions = [];
								d.actions.forEach(function(action) {
									if( action.descriptions !== undefined ) {
										action.descriptions.forEach(function(desc) {
											actions.push({description: desc.name, field1: desc.field1 });
										});
									}
								});
								if( actions.length ) {
									d.actions = actions;
								}
							});
							break;
						case 'F':
							if( !data.notesF ) { data.notesF = new models.Catobject(); }
							if( !data.attachedDrawings ) {
								data.attachedDrawings = [ new models.AttachedDocument(false, "Cronoprogramma"), new models.AttachedDocument(false, "Layout di cantiere"), new models.AttachedDocument(false, "Fotodocumentazione dell'area di intervento"), new models.AttachedDocument(false, "Cartellonistica"), new models.AttachedDocument(false, "Misure di gestione delle emergenze")];
							}
							if( !data.attachedDocuments) {
								data.attachedDocuments = [ new models.AttachedDocument(false, "Analisi delle lavorazioni per zone omogenee"), new models.AttachedDocument(false, "Stima dei costi della sicurezza"), new models.AttachedDocument(false, "Fascicolo con le caratteristiche dell'opera")];
							}
							break;
						case 'CST':
							if( !data.costsections ) {
								data.costsections=[];
							}
							$scope.interferencesAndRisksNotes = [];							
							var notes = [];
							data.placerisks.objects.forEach(function(risk) {
								risk.descriptions.forEach(function(d) {
									if( d.addnotetocosts ) { notes.push(d.name);}
								});
							});
							if( notes.length ) { $scope.interferencesAndRisksNotes.push({name:"Rischi interni", notes:notes}); }			
							notes = [];
							data.externalrisks.objects.forEach(function(risk) {
								risk.descriptions.forEach(function(d) {
									if( d.addnotetocosts ) { notes.push(d.name);}
								});
							});
							if( notes.length ) { $scope.interferencesAndRisksNotes.push({name:"Rischi esterni", notes:notes}); }
							
							notes = [];
							data.internalrisks.objects.forEach(function(risk) {
								risk.descriptions.forEach(function(d) {
									if( d.addnotetocosts ) { notes.push(d.name);}
								});
							});
							if( notes.length ) { $scope.interferencesAndRisksNotes.push({name:"Rischi per l'esterno", notes:notes}); }
							
							notes = [];
							data.zones.forEach(function(zone) {
								zone.interferences.forEach(function(c) {
									if( c.addnotestocosts ) { notes.push(c.notes);}
								});
							});
							if( notes.length ) { $scope.interferencesAndRisksNotes.push({name:"Inteferenze di zona", notes:notes}); }
							
							break;
						case 'All1':
							if( data.letters === undefined ) { data.letters = []; }
							data.letters.forEach(function(c) {
								c.issuedate = (c.issuedate === undefined ? new Date() : new Date(c.issuedate));
								c.issuedate.setHours(12);
								c.delegatedate = (c.delegatedate === undefined ? new Date() : new Date(c.delegatedate));
								c.delegatedate.setHours(12);
							})
							break;
							*/
						default:
							break;
					}
					/*
					if( !data.notifications ) { data.notifications = [{name:'Lettere di nomina', list:[1,2,3]}, {name:'Aggiornamento dati', list:[1,2]}]; }
					*/
					
					var finalize = function() {
						$scope.loaded = true;
					}
					if( shouldSync ) {
						$scope.object = data; 
						$scope.remoteObject = angular.copy($scope.object);
						for( var i=0;i<$scope.docChapters.length; i++ ) {
							$scope.docChapters[i].status = $scope.object.statuses[i];
						}
						$scope.elapsedtime = data.elapsedtime;
						$scope.updateStatuses();
						$scope.asyncSaveEdit(finalize, function() {});
					} else {
						$scope.object = data; 
						$scope.remoteObject = angular.copy($scope.object);
						for( var i=0;i<$scope.docChapters.length; i++ ) {
							$scope.docChapters[i].status = $scope.object.statuses[i];
						}
						$scope.elapsedtime = data.elapsedtime;
						$scope.loaded = true;
						$scope.updateStatuses();
					}
			    }, function errorCallback(response){
			    	if( response.status==404 ) { $location.path('/404');}
			    }
			);
		} else { // new
			$scope.object = { 
				// general
				elapsedtime:0, statuses:[0,0,0,0,0,0,0], messages:[],
				// section A
				healthEmergencyCompliant: $scope.healthEmergency, messagesender:'', professionalstudy: new models.Professionalstudy(), 				subject:'', job:'', duration:30, durationunit: 'giorni', durationnotes:'', startdate: new Date(),  enddate: new Date(), maxworkers: 10, maxcontractors:2, budget: 0, budgetnotes:'', contracttype:0, agencies:[], workerhourlycost:28, workerdays:0, dailymenatworksections:[new models.DailymenatworkSection("Unica", [new models.DailymenatworkWork("Opere edili – Nuova costruzione", 0, 40), new models.DailymenatworkWork("Opere edili – Ristrutturazione", 0, 45), new models.DailymenatworkWork("Opere edili – Restauro e manutenzione", 0, 55), new models.DailymenatworkWork("Opere edili – Opere in cemento armato", 0, 32), new models.DailymenatworkWork("Opere edili – Strutture prefabbricate in c.a", 0, 15), new models.DailymenatworkWork("Opere idrauliche – Acquedotti", 0, 30), new models.DailymenatworkWork("Opere idrauliche – Fognature", 0, 38), new models.DailymenatworkWork("Opere impiantistiche – Igienico sanitari", 0, 43), new models.DailymenatworkWork("Opere impiantistiche – Elettrici", 0, 45), new models.DailymenatworkWork("Opere impiantistiche – Riscaldamento", 0, 40), new models.DailymenatworkWork("Opere impiantistiche – Condizionamento", 0, 30), new models.DailymenatworkWork("Opere impiantistiche – Ascensore e montacarichi", 0, 55), new models.DailymenatworkWork("Opere di finitura – Pavimenti e rivestimenti", 0, 40), new models.DailymenatworkWork("Opere di finitura - Tinteggiature", 0, 40), new models.DailymenatworkWork("Opere di finitura – Serramenti", 0, 20)])],
				place : new models.Address(),  map: new models.MapCoords(), 
				customers: [new models.Actor()], worksmanagerfromstudy: false, worksmanager: new models.OptionalActor(), csefromstudy: true, cse: new models.OptionalActor(), additionalcses:[], surveytechnicians:[], actors:[], otheractors:[], enterprises:[], 
				firstpagelogo: null, innerpagelogo: null, firstpageimage: null, userpageimage: null,
				isSuspended: false, rolestype: 'cse',
				// section B
				users:[],
				
				// section C
				documents_let: new models.WorksiteDocSection(['let']), documents_cse: new models.WorksiteDocSection(['cse']), documents_psc: new models.WorksiteDocSection(['psc', 'lay', 'lav', 'gantt', 'ganttnotes']), documents_costs:new models.WorksiteDocSection(['cst']), documents_fdo:new models.WorksiteDocSection(['fdo']), documents_notifications:new models.WorksiteDocSection(['not']), documents_duvri:new models.WorksiteDocSection(['oth']), documents_prj:new models.WorksiteDocSection(['oth']), documents_procedures_general:new models.WorksiteDocSection(['oth']), documents_procedures_managing:new models.WorksiteDocSection(['oth']), documents_procedures_operating:new models.WorksiteDocSection(['oth']),
				
				// section D 
				preparations:[], tools:[], infrastructures:[],
				
				// section E
				reportlogo: null, reportsmeetings: new models.WorksiteDocSection(['rep']), reportscse:new models.WorksiteDocSection(['rep']), reportsrdl:new models.WorksiteDocSection(['rep']), customreportsmeetings:[], customreportscse: [], customreportsrdl: [],
				
				// section H
				fdoNotRequired: 0, fdoNotRequiredText:'', enddate: new Date(), textFDO1:'', textFDO2:'', textFDO3:'', textFDO4:'', textFDO5:'', textFDO6:[], maintenanceprescriptions: [{title:"Accessi al fabbricato", description:""}, {title:"Modalita' esecutive delle attivita'", description:""}, {title:"Servizi igienici", description:""}, {title:"Deposito materiali", description:""}, {title:"Utilizzo di attrezzature di lavoro", description:""}, {title:"Utilizzo di impianti", description:""}, {title:"Utilizzo di sostanze chimiche o pericolose", description:""}, {title:"Divieto di accesso ad aree non interessate dai lavori di manutenzione", description:""}, {title:"Presenza terzi", description:""}, {title:"Gestione delle emergenze", description:""}], maintenancesheets:[], servicedirectivesheets:[], contextdocsheet:new models.EmbeddedBuildingdocSheet(), archdocsheet:new models.EmbeddedBuildingdocSheet(), plantdocsheet:new models.EmbeddedBuildingdocSheet(), addfdoimagessection: false, fdoimages:[], fdodetailsE2:[],fdodetailsE3:[],fdodetailsE4:[],fdodetailsE5:[],fdodetailsE6:[],
				// section assets
				organizationcharts:new models.WorksiteDocSection(['och']), customorganizationcharts:[],
				/* 
				// section A
				citymaptype: 1, citymapzoom: 8, citymapimage:null, areamaptype: 1, areamapzoom: 17, areamapimage:null, geomaptype: 1, geomapzoom: 10, geomapimage:null, satmaptype: 1, satmapzoom: 20, satmapimage:null, context: new models.Context(),  contextimages:[], worksiteimages:[], , samecspandcse: false, cspfromstudy: true, csp: new models.OptionalActor(), dontspecifycontractors: false, contractors:[], usecustomtextA31:false, textA31:'', textA32:'', textA33:'', textA34:'', addgeologicalreport: false, geologicalreport:'', textA4:[],  addimagessection: false, images:[], textA5:'', zones:[new models.Zone('Area di intervento', 'Area di intervento', null, '128,64,64', [new models.Work('Nuovo lavoro', 1, [new models.Timerange(1,10)], {name:'Appalto da definire'}, '100,179,223')])], calendar: new models.Calendar(), calendaroptions: new models.CalendarOptions(models.PSCCALENDAR), useexternallayout: false, useexternalgantt: false, 
				// section D 
				eachcontractormanagesemergency:false, textD1:'', textD1he:'', textD2:'', textD3:'', textD4:'', textD5:'', emergencyphones:[new models.Phone('','')], hospital:{address: new models.Address(), map: new models.MapCoords()}, emergencyimages: [],
				// section E 
				textE1:'', proceduresE2:new models.PSCProcedure(), proceduresE3:new models.PSCProcedure(), proceduresE4:new models.PSCProcedure(), proceduresE5:new models.PSCProcedure(), proceduresE6:new models.PSCProcedure(), textF2:'', notesF: (new models.Catobject()),
				// section F 
				textF1:'', attachedDrawings : [ new models.AttachedDocument(false, "Cronoprogramma"), new models.AttachedDocument(false, "Layout di cantiere"), new models.AttachedDocument(false, "Fotodocumentazione dell'area di intervento"), new models.AttachedDocument(false, "Cartellonistica"), new models.AttachedDocument(false, "Misure di gestione delle emergenze")], attachedDocuments : [ new models.AttachedDocument(false, "Analisi delle lavorazioni per zone omogenee"),  new models.AttachedDocument(false, "Stima dei costi della sicurezza"), new models.AttachedDocument(false, "Fascicolo con le caratteristiche dell'opera")],
				// section CST 
				textCST1:'', textCST2:'', costsections:[], specifyordinarycost: false, ordinarycost: 0,
				// Section All1 
				letterlogo: null, letters: []
				*/
			};
			/*
			$http.get(appURL+'/crud/pscsectiontexts/type/textA0_1_0', {}).then( function(result) {
				if( result && result.data && result.data.text) {
					$scope.object.textA0 = result.data.text;
				}*/
				if( $rootScope.locals.newworksiterolestype ) {
					$scope.object.rolestype = $rootScope.locals.newworksiterolestype;
					$rootScope.locals.newworksiterolestype = undefined;
				}
				if( $rootScope.locals.newworksiteprofessionalstudy ) {
					$scope.object.professionalstudy = {};
					$scope.object.professionalstudy.name = $rootScope.locals.newworksiteprofessionalstudy.name;
					$scope.object.professionalstudy.fiscalid = $rootScope.locals.newworksiteprofessionalstudy.fiscalid;
					$scope.object.professionalstudy.vat = $rootScope.locals.newworksiteprofessionalstudy.vat;
					$scope.object.professionalstudy.phones = angular.copy($rootScope.locals.newworksiteprofessionalstudy.phones);
					$scope.object.professionalstudy.emails = angular.copy($rootScope.locals.newworksiteprofessionalstudy.emails);
					$scope.object.professionalstudy.legaladdress = angular.copy($rootScope.locals.newworksiteprofessionalstudy.legaladdress);
					$scope.object.professionalstudy.professionals = angular.copy($rootScope.locals.newworksiteprofessionalstudy.professionals);
					$scope.object.firstpagelogo =  $rootScope.locals.newworksiteprofessionalstudy.firstpagelogo;
					$scope.object.innerpagelogo =  $rootScope.locals.newworksiteprofessionalstudy.innerpagelogo;

					$rootScope.locals.newworksiteprofessionalstudy = undefined;
				}	
				
				if( $rootScope.locals.newworksitefrompscdata ) {
					var psc = $rootScope.locals.newworksitefrompscdata;
					
					$scope.object.healthEmergencyCompliant = psc.healthEmergencyCompliant;
					$scope.object.sharedPscId = psc._id;
					
					// Chapter A
					$scope.object.subject = psc.subject;
					$scope.object.job = psc.job;
					$scope.object.duration = psc.duration;
					$scope.object.durationunit = psc.durationunit;
					$scope.object.durationnotes = psc.durationnotes;
					$scope.object.startdate = new Date(psc.startdate);
					$scope.object.startdate.setHours(12);
					$scope.object.enddate = new Date(psc.enddate);
					$scope.object.enddate.setHours(12);
					$scope.object.maxworkers = psc.maxworkers;
					$scope.object.maxcontractors = psc.maxcontractors;
					$scope.object.budget = psc.budget;
					$scope.object.budgetnotes = psc.budgetnotes;
					$scope.object.contracttype = psc.contracttype;
					$scope.object.agencies =psc.agencies;
					$scope.object.workerhourlycost = psc.workerhourlycost;
					$scope.object.dailymenatworksections = psc.dailymenatworksections;
					$scope.object.workerdays = psc.workerdays;
		
					$scope.object.place = psc.place;
					$scope.object.map = psc.map;
					
					$scope.object.customers = psc.customers;
					$scope.object.worksmanagerfromstudy = false;
					$scope.object.worksmanager = psc.worksmanager;
					$scope.object.csefromstudy = psc.csefromstudy;
					$scope.object.cse = psc.cse;
					$scope.object.actors = psc.actors;
					
					$scope.object.firstpagelogo = psc.firstpagelogo;
					$scope.object.innerpagelogo = psc.innerpagelogo;
					$scope.object.firstpageimage = psc.firstpageimage;
		
					// Chapter B
					$scope.object.enterprises = psc.enterprises;


					// Chapter D
					$scope.object.preparations = psc.preparations;
					$scope.object.tools = psc.tools;
					$scope.object.infrastructures = psc.infrastructures;
		
					// Chapter G
					$scope.object.fdoNotRequired = psc.fdoNotRequired;
					$scope.object.fdoNotRequiredText = psc.fdoNotRequiredText;
					$scope.object.textFDO1 = psc.textFDO1;
					$scope.object.textFDO2 = psc.textFDO2;
					$scope.object.textFDO3 = psc.textFDO3;
					$scope.object.textFDO4 = psc.textFDO4;
					$scope.object.textFDO5 = psc.textFDO5;
					$scope.object.textFDO6 = psc.textFDO6;
					$scope.object.textA4 = psc.textA4;
					$scope.object.maintenanceprescriptions = psc.maintenanceprescriptions;
					$scope.object.maintenancesheets = psc.maintenancesheets;
					$scope.object.servicedirectivesheets = psc.servicedirectivesheets;
					$scope.object.contextdocsheets = psc.contextdocsheets;
					$scope.object.archdocsheets = psc.archdocsheets;
					$scope.object.plantdocsheets = psc.plantdocsheets;
					$scope.object.addfdoimagessection = psc.addfdoimagessection;
					$scope.object.fdoimages = psc.fdoimages;
					$scope.object.fdodetailsE2 = psc.fdodetailsE2;
					$scope.object.fdodetailsE3 = psc.fdodetailsE3;
					$scope.object.fdodetailsE4 = psc.fdodetailsE4;
					$scope.object.fdodetailsE5 = psc.fdodetailsE5;
					$scope.object.fdodetailsE6 = psc.fdodetailsE6;
				}
				$scope.loaded = true;
			//});
			
		}
	};
 
	$scope.cancelEdit = function() {
		$rootScope.inEditing = false;
		$rootScope.showMainToolbar = true;
		if( $scope.remoteObject ) {
			$route.reload();
		} else {
			$location.path('/cantieri');
		}
	};
	
	$scope.forceSave = function() {
		for( var i=0;i<$scope.docChapters.length; i++ ) {
			$scope.object.statuses[i] = $scope.docChapters[i].status;
		}
		$http.post(appURL+'/services/worksites/'+$scope.currentChapter.section, {'object':$scope.object }).then(
			function successCallback(response){
					$scope.remoteObject = angular.copy($scope.object);
			}, function errorCallback(response) {
				alert('Attenzione! Non è stato possibile salvare le modifiche. Riprova pi&ugrave; tardi');
			}
		);
	};
	
	$scope.asyncSaveEdit = function( successCall, errorCall ) {
		for( var i=0;i<$scope.docChapters.length; i++ ) {
			$scope.object.statuses[i] = $scope.docChapters[i].status;
		}
		$scope.object.elapsedtime = $scope.elapsedtime;
		$http.post(appURL+'/services/worksites/'+$scope.currentChapter.section, {'object':$scope.object }).then(
			function successCallback(response){
				$scope.remoteObject = angular.copy($scope.object);
				$scope.updateStatuses();
				successCall();
			}, function errorCallback(response) {
				errorCall();
			}
		);
	};
	$scope.saveEdit = function($redirectPath) {
		$scope.isSaving = true;
		for( var i=0;i<$scope.docChapters.length; i++ ) {
			$scope.object.statuses[i] = $scope.docChapters[i].status;
		}
		$scope.object.elapsedtime = $scope.elapsedtime;
		$http.post(appURL+'/services/worksites/'+$scope.currentChapter.section, {'object':$scope.object }).then(
			function successCallback(response){
				var data=response.data;
				$scope.isSaving = false;
				if( !$scope.object._id ) {
					$scope.object._id = data._id;
					$scope.remoteObject = angular.copy($scope.object);
					$location.path('/cantieri/'+data._id);
				} else {
					$scope.remoteObject = angular.copy($scope.object);
					if( $redirectPath ) {
						$location.path($redirectPath);
					}
				}
				$scope.updateStatuses();
			}, function errorCallback(response) {
				$scope.isSaving = false;
				alert('Attenzione! Non è stato possibile salvare le modifiche. Riprova pi&ugrave; tardi');
			}
		);
	};
	$scope.updateTime = function() {
		if( $scope.clock === undefined ) { return; }
		var endtime = (new Date()).getTime();
		$scope.elapsedtime += (endtime-$scope.starttime);
		$scope.starttime = endtime;
		$scope.clock = $timeout($scope.updateTime, 1000);
	};
	$scope.isSaveEditEnabled = function() {
		if( $scope.remoteObject === undefined ) {
			$rootScope.inEditing = false;
			return false;
		}
		$rootScope.inEditing = !angular.equals($scope.remoteObject, $scope.object);
		if( $rootScope.inEditing === true && $scope.clock === undefined ) {
			$scope.starttime = (new Date()).getTime();
			$scope.clock = $timeout($scope.updateTime, 1000);
		} else if( $rootScope.inEditing === false && $scope.clock !== undefined) {
			if( $scope.clock ) {
				$timeout.cancel($scope.clock);
				$scope.clock = undefined;
			}
		}
		return !$rootScope.inEditing;
	}
	$scope.previousChapter = function() {
		var idx = $scope.docChapters.indexOf($scope.currentChapter)-1;
		if( idx>=0 ) { $scope.changeChapter(idx,0);}
	};
	$scope.nextChapter = function() {
		var idx = $scope.docChapters.indexOf($scope.currentChapter)+1;
		if( idx<$scope.docChapters.length ) { $scope.changeChapter(idx, 0);}
	};
	$scope.changeChapter = function(idx, secIdx) {
		if( $scope.currentChapter &&  $scope.currentChapter != $scope.docChapters[idx] ) {
			if( $rootScope.inEditing && !$rootScope.isSaveEditEnabled() ) {
				if( confirm("Le modifiche non sono state salvate. Vuoi tornare a salvarle?") ) {
					return;
				}
			}
		}
		$scope.loaded = false;
		$scope.currentChapter = $scope.docChapters[idx];
		$scope.currentChapter.index = idx;
		$scope.currentSection = secIdx;
		$scope.object = null;
		$scope.remoteObject = null;
		$rootScope.instantsupport = { category: 'Tutto sui Cantieri', chapter: (idx+1), section: (secIdx+1) };
		$scope.get();
	};
	var chapterIndex = 0;
	var sectionIndex = 0;
	if( $routeParams.chapter ) {
		for( var i=0;i<$scope.docChapters.length;i++ ) {
			if( $scope.docChapters[i].section==$routeParams.chapter ) {
				chapterIndex=i;
				break;
			}
		}
		if( $routeParams.section ) {
			if( $scope.docChapters[chapterIndex].sections[$routeParams.section] !== undefined ) {
				sectionIndex = parseInt($routeParams.section);
			}
		}
	}
	$scope.changeChapter(chapterIndex, sectionIndex);
	}]
})