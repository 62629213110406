angular.module('worksiteOrganizationCharts', [])
.directive('worksiteOrganizationCharts', ['models', '$uibModal', 'createXlsxFile','$http', function (models, $uibModal, createXlsxFile, $http) {
    return {
        scope: { worksite: '=', saveaction: '&', customorganizationcharts:'=', docs:'=' },
		restrict: 'C',
		templateUrl: assetsURL+'components/app/worksites/worksite-organization-charts/templates/worksite-organization-charts.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.addOrganizationChart = function() {
				$http.post(appURL+'/services/worksites-organization-charts/new', {}).then(
					function successCallback(response){							
						var newObject = new models.OrganizationChart();
						newObject._id = response.data._id;
						scope.editOrganizationChart(-1, newObject);
					}, function errorCallback(response){
					}
				);
			};
			scope.deleteOrganizationChart = function(index) {
				if( index<0 || index >= scope.customorganizationcharts.length ) { return; }
				scope.customorganizationcharts.splice(index, 1);
				
				var successCb = function() {};
				var errorCb = function() {
					alert("Attenzione! Errore di salvataggio");
				};
				scope.saveaction()(successCb, errorCb);
			};
			
			scope.editOrganizationChart = function(index, newObject) {
				var organizationchart = newObject ? newObject : scope.customorganizationcharts[index];
				var isNew = newObject ? true : false;
				
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/app/worksites/worksite-organization-charts/templates/worksite-organization-chart-addModal.html',
					controller: ['$scope', '$uibModalInstance', 'saveaction', 'worksite', 'enterprises','customorganizationcharts', 'isNew','index', 'organizationchart','docs',  function ( scope, $uibModalInstance, saveaction, worksite, enterprises, customorganizationcharts, isNew, index, organizationchart, docs ) {
						scope.isSaving = false;
						scope.curStep = 1;
						scope.organizationChartType = 'employees';
						scope.authType = 'all';
						
						scope.availableEnterprises = [];
						var availableEnterprises = [];
						
						scope.goBack = function() {
							scope.curStep = scope.curStep - 1;
						};
						scope.goForward = function() {
							scope.curStep = scope.curStep + 1;
						};
						
						scope.selectAll = function() {
							scope.availableEnterprises.forEach(function(enterprise) {
								enterprise.selected = true;
							});
						};
						scope.deselectAll = function() {
							scope.availableEnterprises.forEach(function(enterprise) {
								enterprise.selected = false;
							});
						};
						
						scope.createData = function( selectedEnterprises ) {
							var data = { enterprises: [], techniciansandvisitors: [] };
							/*
							var hashedEnterprises = {};
							var enterprisesWithContractors = [];
							var tmpEnterprises = JSON.parse(JSON.stringify(scope.availableEnterprises));
							tmpEnterprises.forEach( function(ent) {
								ent.subcontractors = [];
								enr.print = false;
								hashedEnterprises[ent._id]=ent;
							});
							
							selectedEnterprises.forEach( function(ent) {
								hashedEnterprises[ent._id].print = true;
								if( ent.parentcontractor && ent.parentcontractor._id ) {
									var parentEnterprise = null;
									var currentEnterprise = ent;
									
									do {
										parentEnterprise = hashedEnterprises[currentEnterprise.parentcontractor._id];
										currentEnterprise = parentEnterprise;
									} while( currentEnterprise && currentEnterprise.parentcontractor && currentEnterprise.parentcontractor._id );
									
									if( parentEnterprise ) {
										parentEnterprise.subcontractors.push(ent);
									}
								} else {
									enterprisesWithContractors.push(ent);
								}
							});
							var flattedEnterprises = [];
							enterprisesWithContractors.forEach( function(ent) {
								flattedEnterprises.push(ent);
								ent.subcontractors.forEach( function(subent) {
									flattedEnterprises.push(subent);
								});
							});
							*/
							var flattedEnterprises = JSON.parse(JSON.stringify(selectedEnterprises));
							var enterprisesWithAuthAssets = [];
							flattedEnterprises.forEach( function (ent) {
								var durcexpirydate = null;
								if( ent.refenterprisedocs ) {
									for( i=0;i<ent.refenterprisedocs.length; i++ ) {
										var refdoc = ent.refenterprisedocs[i];
										if( refdoc.type == 'DURC' ) {
											if( refdoc.validationstatus==1 ) {
												durcexpirydate = new Date(refdoc.expirydate);
											}
											break;
										}
									}
								}
								
								var enterprise = { companyname: ent.companyname, work: ent.work, employees: [], assets: [] };
								ent.employees.forEach( function(person) {
									var employee = { refId: person.refId, fullname: person.fullname, authorized: person.authorized, healthcomplianceexpirydate: null,  formationexpirydate: null, durcexpirydate: durcexpirydate, contract: person.contract, contractexpirydate: person.contractexpirydate, assignedroles: person.assignedroles };
									if( person.documents ) {
										for( i=0;i<person.documents.length; i++ ) {
											var refdoc = person.documents[i];
											if( refdoc.type == 'Idoneità sanitaria' ) {
												if( refdoc.validationstatus==1 ) {
													employee.healthcomplianceexpirydate = new Date(refdoc.expirydate);
												}
												break;
											}
										}
									}
									if( person.certificates ) {
										for( i=0;i<person.certificates.length; i++ ) {
											var refdoc = person.certificates[i];
											if( refdoc.type == 'Formazione generale+specifica' ) {
												if( refdoc.validationstatus==1 ) {
													employee.formationexpirydate = new Date(refdoc.expirydate);
												}
												break;
											}
										}
									}
									enterprise.employees.push(employee);
								});
								ent.assets.forEach( function( item ) {
									var documents = [];
									item.documents.forEach( doc => {
										var refdoc = { name: doc.name, issuedate:null, expirydate:null };
										if( doc.validationstatus == 1 ) {
											if( doc.issuedate ) { 	
												refdoc.issuedate = new Date(doc.issuedate);
											}
											if( doc.expirydate ) {
												refdoc.expirydate = new Date(doc.expirydate);
											}
											documents.push(refdoc);
										} else if( doc.required ) {
											documents.push(refdoc);
										}
									});
									
									enterprise.assets.push({category: item.item, manifacturer:item.manifacturer, model: item.model, serialid: item.serialid, authorized: item.authorized, documents: documents});
								});
								
								if( scope.authType == 'authonly') {
									enterprise.employees = enterprise.employees.filter((item) => item.authorized==true);
									enterprise.assets = enterprise.assets.filter((item) => item.authorized==true);
								}
								enterprisesWithAuthAssets.push(enterprise);
							});
							data.enterprises = enterprisesWithAuthAssets;
													
							return data;
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function () {
							const ents = scope.availableEnterprises.filter(ent => ent.selected == true );
							if( !ents.length ) {
								$uibModalInstance.dismiss('cancel');
								alert("Attenzione! Non ci sono imprese selezionate");
								return;
							}
							
							scope.isSaving = true;
							const filename = "Organigramma "+ (scope.organizationChartType == 'employees' ? 'personale' : (scope.organizationChartType == 'assets' ? 'mezzi-attrezzature-opere provvisionali' : 'completo'));
							const type = "organizationchart_"+ scope.organizationChartType;
							const data = scope.createData(ents);
							organizationchart.title = filename;
							organizationchart.enterprises = data.enterprises;
							organizationchart.techniciansandvisitors = [];
							if( isNew ) {
								customorganizationcharts.push(organizationchart);
							} else {
								customorganizationcharts[index] = organizationchart;
							}
							
							/*
							if( scope.options.fileType == 'xlsx' ) {
								//createXlsxFile.createFromData(null);
								scope.isSaving = false;
								$uibModalInstance.dismiss('cancel');
								return;
							}
							*/
							var successPrintCb = function() {
								$http.post(appURL+'/services/worksites/print', { 'worksiteId':worksite._id, 'objType': type, 'srcObjKeyPath': 'customorganizationcharts', 'srcObjId': organizationchart._id, 'filename': filename }).then(
									function successCallback(response){																
										const data = response.data;
										if( data.fileId == null ) {
											scope.isSaving = false;
											$uibModalInstance.dismiss('cancel');
											alert("Attenzione! Non e' stato possibile generare il PDF. Riprova")
											return;
										}
										var newDoc = {};
										newDoc.creationdate = new Date();
										newDoc.fileId = data.fileId;
										newDoc.type = 'application/pdf';
										newDoc.category = 'other';
										newDoc.description = filename;
										newDoc.issuedate = new Date();
										newDoc.revision = 0;
										newDoc.notes = "";
										
										newDoc.additionalData = { enterprises:[], type:scope.organizationChartType };
										ents.forEach( ent => {
											newDoc.additionalData.enterprises.push( { companyname:ent.companyname, work: ent.work });
										})
										
										newDoc.filename = filename;
										docs.docs.push(newDoc);
										
										organizationchart.file = { fileId:data.fileId, status:'success', error:null, revision:newDoc.revision };
										var successSaveCallback = function() {
											scope.isSaving = false;
											$uibModalInstance.close(null);
										};
										
										var errorSaveCallback = function() {
											scope.isSaving = false;
											$uibModalInstance.dismiss('cancel');
											alert("Attenzione! Non e' stato possibile salvare le modifiche. Riprova")
										};
										saveaction()(successSaveCallback, errorSaveCallback);
									}, function errorCallback(response){
										scope.isSaving = false;
										$uibModalInstance.dismiss('cancel');
										alert("Attenzione! Non e' stato possibile generare il PDF. Riprova")
									}
								);
							};
							
							var errorCb = function() {
								scope.isSaving = false;
								$uibModalInstance.dismiss('cancel');
								alert("Attenzione! Non e' stato possibile salvare le modifiche. Riprova")
							};
							
							var employeesids = [];
							var employeesHash = {};
							organizationchart.enterprises.forEach( ent => {
								if( ent.employees ) {
									ent.employees.forEach( emp => {
										if( emp.refId ) {
											employeesids.push(emp.refId);
											employeesHash[emp.refId] = emp;
										}
									})
								}
							});
							$http.post(appURL+'/services/worksites-organization-charts/getemployeesdata', { 'employeesids':employeesids }).then(
								function successCallback(response){																
									const data = response.data;
									data.employees.forEach( emp => {
										if( employeesHash[emp._id] ) {
											employeesHash[emp._id].contract = emp.contract;
											employeesHash[emp._id].contractexpirydate = emp.contractexpirydate;
											if( emp.role && emp.role === 'Datore di lavoro' ) {
												employeesHash[emp._id].assignedroles = 'Datore di lavoro' + (employeesHash[emp._id].assignedroles && employeesHash[emp._id].assignedroles.length ? " \ " : "") + employeesHash[emp._id].assignedroles;
											}
										}
									});
									saveaction()(successPrintCb, errorCb);
								}, function errorCallback(response){
									scope.isSaving = false;
									$uibModalInstance.dismiss('cancel');
									alert("Attenzione! Non e' stato possibile recuperare i dati per generare l'organigramma. Riprova")
								}
							);
						};
						const safetyPeopleHash = {
							"refsafetyofficers" : "Preposto", 
							"reffirstaidpeople" : "Addetto PS", 
							"reffirefightpeople" : "Addetto antincendio", 
							"refworkerschiefs" : "RLS", 
							"refsafetychiefs" : "RSPP" 
						};
						
						for( var i=0;i<enterprises.length;i++ ) {
							var enterprise = enterprises[i];
							if( enterprise.referencedenterprise && enterprise.referencedenterprise._id && enterprise.referencedenterprise.status == 2 ) {
								var newrefenterprise = { companyname: enterprise.contact.companyname, _id: enterprise.contact._id, refId: enterprise.referencedenterprise._id, work:enterprise.work, currentwork:"", place:"", selected: false, parentcontractor: enterprise.parentcontractor, refenterprisedocs: enterprise.refenterprisedocs };
								newrefenterprise.employees = enterprise.refemployees;
								if( enterprise.refemployees ) {
									var refemployeesHash = {};
									enterprise.refemployees.forEach( emp => {
										if( emp.fullname ) {
											refemployeesHash[emp.fullname]=emp;
										}
									});
									for (const [key, role] of Object.entries(safetyPeopleHash)) {
										if( enterprise[key] ) {
											enterprise[key].forEach( person => {
												if( person.fullname ) {
													if( refemployeesHash[person.fullname] ) {
														var emp = refemployeesHash[person.fullname];
														if( emp.assignedroles === undefined ) {
															emp.assignedroles = "";
														} 
														emp.assignedroles += (emp.assignedroles.length ? " / ":"") + role;
													}
												}
											});
										}
									}
								}
								newrefenterprise.assets = enterprise.reftemporaryworks.concat(enterprise.refmachines).concat(enterprise.refsubstances);
								availableEnterprises.push(newrefenterprise);
							}
						}
						scope.availableEnterprises = availableEnterprises;
						scope.selectedCount = function() {
							return scope.availableEnterprises.filter( ent => ent.selected == true ).length;
						};
						
					}],
					resolve: { 
						saveaction: function() { return scope.saveaction; },
						worksite: function() { return scope.worksite; },
						enterprises: function() { return scope.worksite.enterprises; },
						customorganizationcharts: function() { return scope.customorganizationcharts; },
						isNew: function() { return isNew; },
						index: function() { return index; },
						organizationchart: function() { return organizationchart; },
						docs: function() { return scope.docs; },
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
						/*
						if( !data.enterprises.length ) { return; }
						const title = "Organigramma "+ (data.type == 'employees' ? 'personale' : (data.type == 'assets' ? 'mezzi-attrezzature-opere provvisionali' : 'completo'));
						if( data.file == 'pdf' ) {
							const newDoc = { title : title, issuedate: new Date(), file: {fileId:null} };
							scope.docs.push(newDoc);
						} else {
							//createXlsxFile.createFromData(null);
						}
						*/
					}, function errorCallback() {
					}
				);
			};
		}
	};
}]);
		